import React from 'react';
import Table from '../../Tables/TableStructure/Table';

const columns = [
  {
    id: 'category',
    label: 'Category',
    icon: 'none',
  },
  {
    id: 'subCategory',
    label: 'Subcategory',
    icon: 'none',
  },
  {
    id: 'subject',
    label: 'Subject',
    icon: 'none',
  },
  {
    id: 'store',
    label: 'Store',
    icon: 'none',
  },
];

const mockData = [
  {
    id: 1,
    category: 'Application',
    subCategory: 'Service Status',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 2,
    category: 'Application',
    subCategory: 'Service Status',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 3,
    category: 'Application',
    subCategory: 'Service Status',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 4,
    category: 'Application',
    subCategory: 'Service Status',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 5,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 6,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 7,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 8,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 9,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
  {
    id: 10,
    category: 'Performance',
    subCategory: 'Memory',
    subject: 'Nike Gift Card Processor',
    store: '952 Durban NFS',
  },
];

function ViewMoreTable() {
  return <Table columns={columns} data={mockData} />;
}

export default ViewMoreTable;

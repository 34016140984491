import flattenNestedObject from './flattenNestedObject';

const initialState = {
  planarTree: [],
  totalEvents: 0,
  selectedEventsCount: 0,
  selectedStoresCount: 0,
  selectedData: [],
  lastAction: '',
};

const countSubCategory = function countSubCategory(selectedArray, planarTree) {
  let subCategoryCount = 0;
  let storeCount = 0;
  selectedArray.forEach(nodeId => {
    const selectedNodecount = planarTree.find(branch => branch.id === nodeId);
    // Only count sub-category numbers
    if (selectedNodecount !== undefined && selectedNodecount.id.split('-').length === 3) {
      subCategoryCount += selectedNodecount.count;
      storeCount += selectedNodecount.stores;
    }
  });
  return {
    subCategoryCount: subCategoryCount,
    storeCount: storeCount,
  };
};

const tierListSelection = function tierListSelection(selectedNodeId, planarTree) {
  const splitNodeId = selectedNodeId.split('-');
  const splitNodeIdLength = splitNodeId.length;
  let relatedNodeIds = [];

  if (splitNodeIdLength === 1) {
    // this is a device
    relatedNodeIds = planarTree
      .filter(node => node.deviceId === splitNodeId[0])
      .map(node => node.id);
  } else if (splitNodeIdLength === 2) {
    // this is a category
    relatedNodeIds = planarTree
      .filter(node => node.deviceId === splitNodeId[0] && node.catId === splitNodeId[1])
      .map(node => node.id);
  } else if (splitNodeIdLength === 3) {
    // this is a subCategory
    relatedNodeIds.push(selectedNodeId);
  } else {
    // something went wrong
    console.log('componentActiveEventReducer.js', 'Invalid Selected Node: ' + selectedNodeId);
  }

  return relatedNodeIds;
};

const componentActiveEventReducer = (state = initialState, action) => {
  const {type, payload} = action;
  const selectedNodeId = payload;
  const index = state.selectedData.indexOf(selectedNodeId);

  switch (type) {
    case 'COMPONENT_ACTIVE_EVENT_DEFAULT_SELECTION_LOAD':
      if (index > -1) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          selectedData: [...state.selectedData, selectedNodeId],
        };
      }

    case 'COMPONENT_ACTIVE_EVENT_LOAD_TREE_DATA':
      const {planarArray, totalEvents, allNodeIds} = flattenNestedObject(payload);
      const defaultCounts = countSubCategory(allNodeIds, planarArray);

      return {
        ...state,
        totalEvents: totalEvents,
        selectedEventsCount: totalEvents,
        selectedStoresCount: defaultCounts.storeCount,
        planarTree: planarArray,
        selectedData: allNodeIds,
      };

    case 'COMPONENT_ACTIVE_EVENT_ADD_REMOVE_SELECTED_DATA':
      let selectionCopy = [...state.selectedData];
      let lastAction = 'COMPONENT_ACTIVE_EVENT_ADD_REMOVE_SELECTED_DATA';

      const relatedNodeIds = tierListSelection(selectedNodeId, state.planarTree);

      if (index > -1) {
        selectionCopy = selectionCopy.filter(val => !relatedNodeIds.includes(val));
        lastAction = 'REMOVE';
      } else if (index === -1) {
        selectionCopy = [...selectionCopy, ...relatedNodeIds];
        lastAction = 'ADD';
      } else {
        lastAction = 'Selected Node Error';
      }

      const {subCategoryCount, storeCount} = countSubCategory(selectionCopy, state.planarTree);

      return {
        ...state,
        selectedData: selectionCopy,
        selectedEventsCount: subCategoryCount,
        selectedStoresCount: storeCount,
        lastAction: lastAction,
      };

    default:
      return state;
  }
};

export default componentActiveEventReducer;

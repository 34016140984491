import {
  getMinimumStoreInfoPending,
  getMinimumStoreInfoSuccess,
  getMinimumStoreInfoError,
} from './index';
import config from '../../oktaConfig';

function getMinimumStoreInfo(accessToken) {
  return dispatch => {
    dispatch(getMinimumStoreInfoPending());

    fetch(config.resourceServer.insightsApiUrlMinimumStoreInfo, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getMinimumStoreInfoSuccess(data));
      })
      .catch(err => {
        dispatch(getMinimumStoreInfoError(err));
        /* eslint-disable no-console */
        console.error(err);
      });
  };
}

export default getMinimumStoreInfo;

import React, {FC} from 'react';
import styled from 'styled-components';
import Container from 'components/Container';
import Paper from 'components/Paper';
import SGHeadline from 'components/Typography/SGHeadline';
import SGBody from 'components/Typography/SGBody';
import {ENVIRONMENT} from 'oktaConfig';

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
`;

const StyledPaper = styled(Paper)`
  max-width: 500px;
  a {
    color: ${props => props.theme.altText};
  }
  a:visited {
    color: ${props => props.theme.altText};
  }
  a:hover {
    color: ${props => props.theme.disabledStateBlue};
  }
`;

const errorMessageType: FC = (error: any) => {
  const accessGroup =
    ENVIRONMENT === 'prod'
      ? 'Application.RetailStoreInsights.Prod'
      : 'Application.RetailStoreInsights.Dev';

  if (error.errorCode === 'access_denied') {
    return (
      <>
        <SGHeadline variant="h5">Welcome to Insights</SGHeadline>
        <br />
        <SGBody>It looks like you don't have access to this site just yet.</SGBody>
        <br />
        <SGBody>
          If you haven't done so already, please head over to{' '}
          <a href="https://idlocker.nike.com/" target="_new">
            ID Locker
          </a>{' '}
          and submit a access request to the following group:
        </SGBody>
        <br />
        <code>{accessGroup}</code>
        <br />
        <br />
        <SGBody>It might take us a few days to approve your request.</SGBody>
      </>
    );
  } else {
    return <>{`Error: ${error.toString()}`}</>;
  }
};

const Error: FC<{error: any}> = ({error}) => {
  const message = errorMessageType(error);

  return (
    <StyledContainer>
      <StyledPaper>{message}</StyledPaper>
    </StyledContainer>
  );
};

export default Error;

import tierListSelection from './tierListSelection';
import checkForActiveChildren from './checkForActiveChildren';
import nestedObjHammer from './nestedObjHammer';
import countEvents from './countEvents';
import calcDifference from './calcDifference';
import addIDFromTree from './addIDFromTree';

const initialDate = new Date();

const initialState = {
  treeMenu: [],
  treeMenuLoaded: false,
  treeMenuError: null,
  topEvents: [],
  topEventsLoaded: false,
  topEventsError: null,
  tableEvents: [],
  tableEventsLoaded: false,
  tableEventsError: null,
  selectedData: [],
  selectedEventType: 'device',
  planarTree: [],
  isActive: [],
  epochDateRange: {
    startDate: new Date(initialDate.getTime() - 90 * 24 * 60 * 60 * 1000).getTime(),
    endDate: new Date().getTime(),
  },
  dateSelection: 'three_months',
  friendlyDateRange: 'Last 3 Months',
  lineChart: [],
  eventCounts: [],
  changeOverPreviousPeriod: [],
};

const getFriendlyDateRange = function getFriendlyDateRange(date) {
  switch (date) {
    case 'one_week':
      return 'Last 7 Days';
    case 'one_month':
      return 'Last 30 Days';
    case 'three_months':
      return 'Last 3 Months';
    case 'six_months':
      return 'Last 6 Months';
    case 'one_year':
      return 'Last 1 Year';
    default:
      const startDate = new Date(date.startDate);
      const endDate = new Date(date.endDate);
      return `${startDate.toDateString()} - ${endDate.toDateString()}`;
  }
};

const componentHistoricalComparisonReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case 'COMPONENT_HISTORICAL_COMPARISON_LOAD_TREE_DATA':
      const {planarArray, selectedNodeIds} = nestedObjHammer(payload);

      return {
        ...state,
        planarTree: planarArray,
        selectedData: selectedNodeIds,
      };

    case 'COMPONENT_HISTORICAL_COMPARISON_CLEAR_TREE_DATA':
      return {
        ...state,
        selectedData: [],
      };

    case 'COMPONENT_HISTORICAL_COMPARISON_SELECT':
      const selectedNodeId = payload;
      const index = state.selectedData.indexOf(selectedNodeId);
      const relatedNodeIds = tierListSelection(selectedNodeId, state.planarTree);

      let selectionCopy = [...state.selectedData];
      let lastAction = '';

      if (index > -1) {
        selectionCopy = selectionCopy.filter(val => val !== selectedNodeId);
        lastAction = 'REMOVE';
      } else if (index === -1) {
        selectionCopy = [
          ...selectionCopy.filter(val => relatedNodeIds.includes(val)),
          selectedNodeId,
        ];
        lastAction = 'ADD';
      } else {
        lastAction = 'Selected Node Error';
      }

      return {
        ...state,
        selectedData: selectionCopy,
        lastAction: lastAction,
        isActive: checkForActiveChildren(selectedNodeId, selectionCopy),
        eventCounts: countEvents(
          state.lineChart,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
        changeOverPreviousPeriod: calcDifference(
          state.lineChart,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
      };

    case 'COMPONENT_HISTORICAL_COMPARISON_DATERANGE':
      const dateRangeOptions = ['one_week', 'one_month', 'three_months', 'six_months', 'one_year'];
      const date = new Date();
      let days = 1;

      switch (payload) {
        case 'today':
          days = 1;
          break;
        case 'one_week':
          days = 7;
          break;
        case 'two_weeks':
          days = 14;
          break;
        case 'one_month':
          days = 30;
          break;
        case 'three_months':
          days = 90;
          break;
        case 'six_months':
          days = 180;
          break;
        case 'one_year':
          days = 365;
          break;
        default:
      }

      const dateRange = {
        startDate: new Date(date.getTime() - days * 24 * 60 * 60 * 1000).getTime(),
        endDate: new Date().getTime(),
      };

      return {
        ...state,
        epochDateRange: dateRangeOptions.includes(payload) ? dateRange : payload,
        dateSelection: dateRangeOptions.includes(payload) ? payload : 'custom',
        friendlyDateRange: getFriendlyDateRange(payload),
        eventCounts: countEvents(
          state.lineChart,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
        changeOverPreviousPeriod: calcDifference(
          state.lineChart,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
      };

    case 'COMPONENT_HISTORICAL_COMPARISON_SET_LINE_CHART_DATA':
      return {
        ...state,
        lineChart: payload,
        eventCounts: countEvents(
          payload,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
        changeOverPreviousPeriod: calcDifference(
          payload,
          state.epochDateRange.startDate,
          state.epochDateRange.endDate
        ),
      };

    case 'FETCH_TREE_MENU_DATA_PENDING':
      return {
        ...state,
        treeMenuLoaded: false,
      };
    case 'FETCH_TREE_MENU_DATA_SUCCESS':
      return {
        ...state,
        treeMenu: addIDFromTree(action.data),
        treeMenuLoaded: true,
      };
    case 'FETCH_TREE_MENU_DATA_ERROR':
      return {
        ...state,
        treeMenuError: action.error,
        treeMenuLoaded: false,
      };

    case 'FETCH_TOP_EVENTS_PENDING':
      return {
        ...state,
        topEventsLoaded: false,
      };
    case 'FETCH_TOP_EVENTS_SUCCESS':
      return {
        ...state,
        topEvents: action.data,
        topEventsLoaded: true,
      };
    case 'FETCH_TABLE_EVENTS_ERROR':
      return {
        ...state,
        tableEventsError: action.error,
        tableEventsLoaded: false,
      };
    case 'FETCH_TABLE_EVENTS_PENDING':
      return {
        ...state,
        tableEventsLoaded: false,
      };
    case 'FETCH_TABLE_EVENTS_SUCCESS':
      return {
        ...state,
        tableEvents: action.data,
        tableEventsLoaded: true,
      };
    case 'FETCH_TABLE_EVENTS_ERROR':
      return {
        ...state,
        tableEventsError: action.error,
        tableEventsLoaded: false,
      };

    default:
      return state;
  }
};

export default componentHistoricalComparisonReducer;

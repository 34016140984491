import {getStoreServicesPending, getStoreServicesSuccess, getStoreServicesError} from './index';
import config from '../../oktaConfig';

function getStoreServices(accessToken) {
  return dispatch => {
    dispatch(getStoreServicesPending());
    const controller = new AbortController();
    fetch(config.resourceServer.insightsApiUrlStoreServices, {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getStoreServicesSuccess(data));
      })
      .catch(err => {
        dispatch(getStoreServicesError(err));
        /* eslint-disable no-console */
        console.error(err);
      });

    return () => {
      controller.abort();
    };
  };
}

export default getStoreServices;

import React from 'react';
import styled from 'styled-components';
import StyledPaper from '../../Paper';
import SGSubtitle from '../../Typography/SGSubtitle';
import SGTitle from '../../Typography/SGTitle';
import Tooltip from '../../SGToolTip';

interface TradeItemProps {
  variant: 'down' | 'risk' | 'dimished' | 'trading';
  storeCount: number;
}

const StyledItem = styled(StyledPaper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #ffffff;
  height: calc(100% - 32px);
  &.down {
    border-color: ${props => props.theme.red};
  }
  &.risk {
    border-color: ${props => props.theme.orange};
  }
  &.dimished {
    border-color: ${props => props.theme.yellow};
  }
  &.trading {
    border-color: ${props => props.theme.green};
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCountContainer = styled.div`
  vertical-align: baseline;
  p {
    margin-right: 0.5rem;
    display: inline;
  }
`;

const renderSubTitleContent = function renderSubTitleContent(variantProp: string) {
  let subtitleContent;
  let tooltipContent;
  let color = '';

  switch (variantProp) {
    case 'down':
      color = 'red';
      subtitleContent = 'Unable to Trade';
      tooltipContent =
        'Insights considers a store to be Unable To Trade when at least one of following criteria is met: All Network Devices are in Critical Conditions. Server is Down. All Registers are in Critical Conditions (Insights is currently not taking Athlete Devices into account for the Trade Status). All PCs are in Critical Conditions and SOD has not been completed. (SOD: Operating Status = “Closed”).';
      break;
    case 'risk':
      color = 'orange';
      subtitleContent = 'At Risk';
      tooltipContent =
        'A store is considered to be At Risk: There is at least 1 Critical Network Device and the number of Critical Network Devices does not equal to the Total Network Devices. The number of Major or Critical Server devices is at least 1. The number of Critical Registers is greater than 50% of the Total Number of Registers. All PCs are Critical when SOD has been completed. (SOD: Operating Status is “Open”). The device health of Servers, Networks, Registers, and PCs do not fall under the Unable to Trade categories.';
      break;
    case 'dimished':
      color = 'yellow';
      subtitleContent = 'Diminished Capabilites';
      tooltipContent =
        'A store is considered to be Diminished: The number of Registers that are in Critical status is less than 50% of the stores Total Registers. The device health of Servers, Networks, Registers, and PCs do not fall under the At Risk or Unable to Trade categories.';
      break;
    case 'trading':
      color = 'green';
      subtitleContent = 'Fully Trading';
      tooltipContent = 'Stores that do not fall under any of the other categories.';
      break;
    default:
      subtitleContent = 'Invalid Store Status Type';
      tooltipContent = 'Invalid Store Status Type';
  }
  return (
    <StyledTitleContainer>
      <SGSubtitle variant={'s1'}>{subtitleContent}</SGSubtitle>
      <Tooltip title={tooltipContent} color={color} />
    </StyledTitleContainer>
  );
};

function TradeItem({variant, storeCount}: TradeItemProps) {
  return (
    <StyledItem className={variant}>
      {renderSubTitleContent(variant)}

      <StyledCountContainer>
        <SGTitle variant={'t1'} className={'text-color'}>
          {storeCount}
        </SGTitle>
        <SGSubtitle>Stores</SGSubtitle>
      </StyledCountContainer>
    </StyledItem>
  );
}

export default TradeItem;

import React from 'react';
import styled from 'styled-components';
import {Dialog, DialogTitle} from '@material-ui/core';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';
import {Services, getServiceDisplayName} from './interfaces/Services';
import {StoreAction} from './types/StoreAction';
import SGButton from 'components/CTA/SGButton';

// checking white space

//#region styled components
const StandardTextDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
`;

const StyledStoreOrServiceDiv = styled.div`
  font-style: italic;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  padding-top: 28px;
  padding-bottom: 4px;
  flex-direction: row;
  justify-content: center;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-style: bold;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(SGButton)`
  height: 30px;
  display: flex;
  align-items: center;
  margin: 8px;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${props => props.theme.cardBg};
    color: ${props => props.theme.text};
  }
`;
//#endregion styled components

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleNext: () => void;
  stores: Stores;
  services: Services;
  storeAction: StoreAction;
}

function ConfirmationDialog({
  isOpen,
  onClose,
  handleNext,
  stores,
  services,
  storeAction,
}: ConfirmationDialogProps) {
  return (
    <StyledDialog onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <StyledDialogTitle id="simple-dialog-title">Are you sure?</StyledDialogTitle>
      <StandardTextDiv>
        Are you sure you want to {storeAction === 'remove' ? 'remove' : 'add'} the following
        service(s)
      </StandardTextDiv>
      {services.map(service => {
        return (
          <StyledStoreOrServiceDiv key={service.id}>
            {getServiceDisplayName(service)}
          </StyledStoreOrServiceDiv>
        );
      })}
      <StandardTextDiv>{storeAction === 'remove' ? 'from' : 'to'} the store(s)</StandardTextDiv>
      {stores.map(store => {
        return (
          <StyledStoreOrServiceDiv key={store.SiteId}>
            {getStoreDisplayName(store)}
          </StyledStoreOrServiceDiv>
        );
      })}
      <StyledButtonContainerDiv>
        <StyledButton variant={'contained'} color={'default'} onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton onClick={handleNext}>Confirm</StyledButton>
      </StyledButtonContainerDiv>
    </StyledDialog>
  );
}

export default ConfirmationDialog;

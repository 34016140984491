import React from 'react';
import styled from 'styled-components';
import SwitchPort from './SwitchPort';
import {Port} from '../interfaces/SwitchConnectivityState';
import {PortHistorySwitch} from '../interfaces/PortHistoryState';

const StyledPortGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 8px;
`;
interface SwitchPortGroupProps {
  ports: Port[];
  portHistorySwitch: PortHistorySwitch | null;
}
export default function SwitchPortGroup({ports, portHistorySwitch}: SwitchPortGroupProps) {
  const switchPorts = ports.map(port => {
    return (
      <SwitchPort
        key={port.portNumber}
        port={port}
        portHistory={portHistorySwitch?.ports[port.portNumber] ?? null}
      />
    );
  });
  return <StyledPortGroupContainer>{switchPorts}</StyledPortGroupContainer>;
}

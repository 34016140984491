import {
  postStoreServiceConfigurationSuccess,
  postStoreServiceConfigurationError,
  postStoreServiceConfigurationPending,
  setUserPayload,
} from './index';
import config from '../../oktaConfig';

function postStoreServiceConfigurationAction(accessToken, data) {
  return dispatch => {
    dispatch(postStoreServiceConfigurationPending());

    const controller = new AbortController();
    fetch(config.resourceServer.insightsApiUrlPayloadIngestion, {
      mode: 'cors',
      method: 'post',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response;
      })
      .then(() => {
        dispatch(postStoreServiceConfigurationSuccess());
        dispatch(
          setUserPayload({
            status: 'ok',
            data,
          })
        );
      })
      .catch(err => {
        dispatch(postStoreServiceConfigurationError(err));
        dispatch(
          setUserPayload({
            status: 'error',
            data,
          })
        );
        /* eslint-disable no-console */
        console.error(err);
      });

    return () => {
      controller.abort();
    };
  };
}

export default postStoreServiceConfigurationAction;

import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {
  mdiAlertOctagon,
  mdiAlert,
  mdiAlertBox,
  mdiAlertCircleOutline,
  mdiCheckCircle,
} from '@mdi/js';

const StyledIcon = styled(Icon)`
  /* margin: 0rem 0.25rem; */
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  &.green {
    color: ${props => props.theme.green};
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.orange {
    color: ${props => props.theme.orange};
  }
  &.red {
    color: ${props => props.theme.red};
  }
  &.grey {
    color: ${props => props.theme.grey};
  }
`;

const getIconInfo = function getIconInfo(alertValue, showSuccess) {
  switch (alertValue) {
    case 0: {
      return {
        icon: showSuccess ? mdiCheckCircle : '',
        color: 'green',
      };
    }
    case 1: {
      return {
        icon: mdiAlert,
        color: 'yellow',
      };
    }
    case 2: {
      return {
        icon: mdiAlertBox,
        color: 'orange',
      };
    }
    case 3: {
      return {
        icon: mdiAlertOctagon,
        color: 'red',
      };
    }
    default:
      return {
        icon: mdiAlertCircleOutline,
        color: 'grey',
      };
  }
};

function AlertIcon(props) {
  const {alertValue, showSuccess, className} = props;
  const {icon, color} = getIconInfo(alertValue, showSuccess);
  return <StyledIcon path={icon} className={`${color} ${className}`} />;
}

export default AlertIcon;

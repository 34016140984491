import React from 'react';
import StoreNameCell from './StoreNameCell';
import AlertCell from './AlertCell';
import TableToolTipCell from './TableToolTipCell';

const RPTableColumns = [
  {
    id: 'storeName',
    label: 'Store Name',
    width: '18.75rem',
    sectionId: 'store',
    customHeader: 'StorePicker',
    format: (value, row, {removeStore, isGeoView}) => (
      <StoreNameCell
        storeName={value}
        removeStore={removeStore}
        store={row}
        isGeoView={isGeoView}
      />
    ),
  },
  {
    id: 'internetAProvisioned',
    label: (
      <TableToolTipCell
        value={'Transport A Provisioned'}
        tooltip={
          'Shows how the carrier has provisioned the first WAN transport (i.e. circuit) which connects the store to the outside world. Shows the available bandwidth and transport type as; download bps | upload bps (type)'
        }
        textWidth={'5.25rem'}
      />
    ),
    width: '9rem',
    sectionId: 'wanTransport',
    format: (value, row) => <AlertCell value={value} alert={row.internetAProvisionedAlertValue} />,
  },
  {
    id: 'internetAUtilization',
    label: (
      <TableToolTipCell
        value={'Transport A Utilization'}
        tooltip={
          'Shows how much of the carrier provisioned WAN Transport A bandwidth is being used by the store. Shown as 95th percentile; download % | upload %.'
        }
        textWidth={'5.25rem'}
      />
    ),
    width: '9rem',
    sectionId: 'wanTransport',
    format: (value, row) => <AlertCell value={value} alert={row.internetAUtilizationAlertValue} />,
  },
  {
    id: 'internetBProvisioned',
    label: (
      <TableToolTipCell
        value={'Transport B Provisioned'}
        tooltip={
          'Shows how the carrier has provisioned the second WAN transport (i.e. circuit) which connects the store to the outside world. Shows the available bandwidth and transport type as; download bps | upload bps (type)'
        }
        textWidth={'5.25rem'}
      />
    ),
    width: '11rem',
    sectionId: 'wanTransport',
    format: (value, row) => <AlertCell value={value} alert={row.internetBProvisionedAlertValue} />,
  },
  {
    id: 'internetBUtilization',
    label: (
      <TableToolTipCell
        value={'Transport B Utilization'}
        tooltip={
          'Shows how much of the carrier provisioned WAN Transport B bandwidth is being used by the store. Shown as 95th percentile; download % | upload %.'
        }
        textWidth={'5.25rem'}
      />
    ),
    width: '10rem',
    sectionId: 'wanTransport',
    format: (value, row) => <AlertCell value={value} alert={row.internetBUtilizationAlertValue} />,
  },
  {
    id: 'cellularBackup',
    label: (
      <TableToolTipCell
        value={'Cellular Backup'}
        tooltip={
          'Shows if cellular 4G / 5G backup Is installed, which acts as a Path of Last Resort to maintain limited connectivity if WAN Transports A & B both fail.'
        }
        textWidth={'4rem'}
      />
    ),
    width: '7rem',
    sectionId: 'wanTransport',
    format: (value, row) => <AlertCell value={value} alert={row.cellularBackupAlertValue} />,
  },
  {
    id: 'SDWANHardwareProvisioned',
    label: (
      <TableToolTipCell
        value={'Hardware Provisioned'}
        tooltip={'Shows the model of SDWAN Hardware currently installed at the store.'}
        textWidth={'5rem'}
      />
    ),
    width: '9rem',
    sectionId: 'sdWan',
    format: (value, row) => (
      <AlertCell value={value} alert={row.SDWANHardwareProvisionedAlertValue} />
    ),
  },
  // {
  //   id: 'SDWANHardwareUtilization',
  //   label: 'Hardware Utilization',
  //   width: '7rem',
  //   sectionId: 'sdWan',
  // },
  {
    id: 'SDWANEffectivenessQoE',
    label: (
      <TableToolTipCell
        value={'Effectiveness QoE'}
        tooltip={
          'Shows the quality of experience (QoE) for network connections over the WAN Transports, after SDWAN data corrections have been applied. Calculated through metrics such as packet loss, latency, and jitter.'
        }
        textWidth={'6rem'}
      />
    ),
    width: '7.5rem',
    sectionId: 'sdWan',
    format: (value, row) => <AlertCell value={value} alert={row.SDWANEffectivenessQoEAlertValue} />,
  },
  {
    id: 'switchingHardwareProvisioned',
    label: (
      <TableToolTipCell
        value={'Hardware Provisioned'}
        tooltip={'Shows the model of ethernet Switching Hardware currently installed at the store.'}
        textWidth={'5rem'}
      />
    ),
    width: '11rem',
    sectionId: 'switching',
    format: (value, row) => (
      <AlertCell value={value} alert={row.switchingHardwareProvisionedAlertValue} />
    ),
  },
  {
    id: 'switchingHardwarePortUtilization',
    label: (
      <TableToolTipCell
        value={'Hardware Port Utilization'}
        tooltip={
          'Shows the total count of switch ports currently in use at the store, compared to the total count offered by the Switching Hardware. Displayed as; in use / hardware total.'
        }
        textWidth={'6.5rem'}
      />
    ),
    width: '10rem',
    sectionId: 'switching',
    format: (value, row) => (
      <AlertCell value={value} alert={row.switchingHardwarePortUtilizationAlertValue} />
    ),
  },
  // {
  //   id: 'switchingHardwareAPPortUtilization',
  //   label: 'Hardware AP Port Utilization',
  //   width: '10rem',
  //   sectionId: 'switching',
  //   format: (value, row) => (
  //     <AlertCell value={value} alert={row.switchingHardwareAPPortUtilizationAlertValue} />
  //   ),
  // },
  {
    id: 'switchingHardwarePoEBudget',
    label: (
      <TableToolTipCell
        value={'Hardware PoE Budget'}
        tooltip={
          'Shows the total amount of Power-over-Ethernet watts used by devices connected to the network, compared to the total number of watts available across all store Switching Hardware. Displayed as; used watts / available watts.'
        }
        textWidth={'5rem'}
      />
    ),
    width: '10rem',
    sectionId: 'switching',
    format: (value, row) => (
      <AlertCell value={value} alert={row.switchingHardwarePoEBudgetAlertValue} />
    ),
  },
  {
    id: 'switchingHardwarePoEAtCapacity',
    label: (
      <TableToolTipCell
        value={'Hardware PoE At Capacity'}
        tooltip={
          'Shows the count of any single piece of Switching Hardware in the store is over 90% utilization.'
        }
        textWidth={'6rem'}
      />
    ),
    width: '9rem',
    sectionId: 'switching',
    format: (value, row) => (
      <AlertCell value={value} alert={row.switchingHardwarePoEAtCapacityAlertValue} />
    ),
  },
  {
    id: 'wirelessAPHardwareProvisioned',
    label: (
      <TableToolTipCell
        value={'AP Hardware Provisioned'}
        tooltip={
          'Shows the model of Wireless Access Point Hardware currently installed at the store.'
        }
        textWidth={'11rem'}
      />
    ),
    width: '18rem',
    sectionId: 'wireless',
    format: (value, row) => (
      <AlertCell value={value} alert={row.wirelessAPHardwareProvisionedAlertValue} />
    ),
  },
  {
    id: 'wirelessAPUpStatus',
    label: (
      <TableToolTipCell
        value={'AP Up Status'}
        tooltip={
          'Shows the count of Wireless APs in the store which are up and functional, compared to the total count of Wireless APs installed. Displayed as; up / total. '
        }
        textWidth={'3rem'}
      />
    ),
    width: '7rem',
    sectionId: 'wireless',
    format: (value, row) => <AlertCell value={value} alert={row.wirelessAPUpStatusAlertValue} />,
  },
  {
    id: 'wirelessClientHealthAvg',
    label: (
      <TableToolTipCell
        value={'Client Health Avg'}
        tooltip={
          'Shows the average health percentage for all wirelessly connected devices in the store, indicating the quality of wireless signal and coverage for clients.'
        }
        textWidth={'4.5rem'}
      />
    ),
    sectionId: 'wireless',
    width: '7rem',
    format: (value, row) => (
      <AlertCell value={value} alert={row.wirelessClientHealthAvgAlertValue} />
    ),
  },
  {
    id: 'wirelessAPHealth',
    label: (
      <TableToolTipCell
        value={'AP Health'}
        tooltip={
          'Shows the average health percentage for all Wireless APs in the store, indicating the health of Wireless AP hardware (e.g. CPU, memory), as well as the wireless environment health from the AP standpoint (e.g. interference). '
        }
        textWidth={'4.25rem'}
      />
    ),
    width: '5rem',
    sectionId: 'wireless',
    format: (value, row) => <AlertCell value={value} alert={row.wirelessAPHealthAlertValue} />,
  },
];

export default RPTableColumns;

import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiServerNetwork} from '@mdi/js';
import SGTitle from 'components/Typography/SGTitle';
import SGBody from 'components/Typography/SGBody';

const SwitchSelectionPromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.disabledStateBlue};
`;

const StyledSGBody = styled(SGBody)`
  color: ${props => props.theme.grey};
`;

function SwitchSelectionPrompt() {
  return (
    <SwitchSelectionPromptContainer>
      <StyledIcon path={mdiServerNetwork} size={4.25} />
      <SGTitle variant={'t3'} className={'text-color'}>
        NO DATA SHOWN
      </SGTitle>
      <StyledSGBody variant={'b2'}>
        Select a site in search bar to view Switch Connectivity
      </StyledSGBody>
    </SwitchSelectionPromptContainer>
  );
}

export default SwitchSelectionPrompt;

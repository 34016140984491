import {patchVersion} from '../../constants/releaseConfig';

function getLocalFirstTimeMsgViewed() {
  // firstTimeMsgViewed
  const localStorageValue = localStorage.getItem('firstTimeMsgViewed');

  if (localStorageValue === null) {
    return false;
  } else if (localStorageValue === 'true') {
    return true;
  } else {
    return false;
  }
}

function getPatchNotesViewed(patchVersion = null) {
  // patchNotesVersion
  const localStoragePatchVersion = localStorage.getItem('patchNotesVersion');

  if (localStoragePatchVersion === null) {
    return false;
  } else if (localStoragePatchVersion >= patchVersion) {
    return true;
  } else {
    return false;
  }
}

function getLocalSettings(settingType) {
  // settingTableScroll
  const localStorageValue = localStorage.getItem(settingType);

  if (localStorageValue === null) {
    return false;
  } else if (localStorageValue === 'true') {
    return true;
  } else {
    return false;
  }
}

export const initialState = {
  firstTimeMsgViewed: getLocalFirstTimeMsgViewed(),
  patchNotesViewed: getPatchNotesViewed(patchVersion.patchNotesNumber),
  tableScroll: getLocalSettings('settingTableScroll'),
  debugView: getLocalSettings('settingDebugView'),
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case 'UI_POPUP_VIEWED':
      localStorage.setItem('firstTimeMsgViewed', true);
      return {
        ...state,
        firstTimeMsgViewed: true,
      };
    case 'UI_POPUP_RESET':
      localStorage.setItem('firstTimeMsgViewed', false);
      return {
        ...state,
        firstTimeMsgViewed: false,
      };
    case 'UI_PATCHNOTES_VIEWED':
      localStorage.setItem('patchNotesVersion', action.patchVersion);
      return {
        ...state,
        patchNotesViewed: true,
        patchVersion: action.patchVersion,
      };
    case 'UI_PATCHNOTES_RESET':
      return {
        ...state,
        patchNotesViewed: false,
      };
    case 'UI_TOGGLE_TABLE':
      localStorage.setItem('settingTableScroll', !state.tableScroll);
      return {
        ...state,
        tableScroll: !state.tableScroll,
      };
    case 'UI_TOGGLE_DEBUG':
      localStorage.setItem('settingDebugView', !state.debugView);
      return {
        ...state,
        debugView: !state.debugView,
      };
    default:
      return state;
  }
};

export default ui;

import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchMinimumSiteInfo, fetchDistinctIdfSearchItems, mergeStoreSite} from 'redux/actions';
import {
  rdxMinimumSiteInfoDataRetrieved,
  rdxMinimumSiteInfoData,
} from 'redux/reducers/minimumSiteInfoReducer';
import {useHistory} from 'react-router-dom';
import {getSiteTypeFromSiteId} from 'utils/utils';
import {debounce} from 'lodash';
import SiteType from 'constants/SiteType';
import Site from '../../interfaces/Site';
import {Store} from '../../interfaces/Stores';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SiteIdSearchSuggestions from './components/SiteIdSearchSuggestions';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';
import searchAndSort from './functions/searchAndSort';
import SiteIdSettings from './components/SiteIdSettings';

//#region styled-components
const StyledSiteIdSearchContainer = styled.div`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  z-index: 11;
`;

const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: ${props => props.theme.blue2};
    color: ${props => props.theme.text};
    border-radius: 10px;
  }
  &.search-active .MuiFilledInput-root {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 1px;
  }
  .MuiFilledInput-input {
    padding: 8px 10px 8px;
    font-size: 0.8rem;
    width: 186px;
  }
  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0px 0px 0px;
  width: 24px;
`;

const StyledSearchIcon = styled(SearchRoundedIcon)`
  color: ${props => props.theme.subtleText};
`;

const StyledClearRoundedIcon = styled(ClearRoundedIcon)`
  color: ${props => props.theme.subtleText};
`;

const StyledCircleLoading = styled(CircularProgress)`
  height: 24px;
  width: 24px;
  svg {
    color: ${props => props.theme.subtleText};
  }
`;
//#endregion styled-components

export interface SearchItem {
  brand: string;
  geo: string;
  idf?: string;
  displayName: string;
  siteCode: string;
  siteId: string;
  siteSearch: string;
  siteType: string;
  status?: string;
}

export function getSwitchConnectivityParamsPartialUrlFromSearchItem(
  searchItem: SearchItem,
  setSwitchError: (error: string) => void
): string {
  const siteType: SiteType = getSiteTypeFromSiteId(searchItem.siteId);
  const baseParamsUrl: string = `/${searchItem.siteId}`;
  if (siteType !== 'Distribution Center') {
    return baseParamsUrl;
  } else {
    if (searchItem.idf && searchItem.idf.length > 0) {
      return baseParamsUrl.concat(`/${searchItem.idf}`);
    } else {
      setSwitchError(
        `Distribution Center search item for site ${searchItem.siteId} must include an idf.`
      );
      return '';
    }
  }
}

interface SiteIdSearchProps {
  urlType: string;
  setSwitchError: (error: string) => void;
  brands?: {
    converse: boolean;
    nike: boolean;
  };
  siteTypes?: {
    distributionCenter: boolean;
    retail: boolean;
    showroom: boolean;
  };
}

// TODO: Enable these in the future
// const defaultBrands = {
//   converse: false,
//   nike: true,
// }
// const defaultSiteTypes = {
//   distributionCenter: true,
//   retail: true,
//   showroom: true,
// }

function SiteIDSearch({
  urlType,
  setSwitchError,
}: // TODO: Enable these in the future
// brands = defaultBrands,
// siteTypes = defaultSiteTypes
SiteIdSearchProps) {
  const {authState, oktaAuth} = useOktaAuth();
  const dispatch = useDispatch();

  // TODO: Enable these in the future
  // const { converse, nike } = brands;
  // const { distributionCenter, retail, showroom } = siteTypes;

  //#region redux
  const isSitesLoaded: boolean = useSelector((state: any) =>
    rdxMinimumSiteInfoDataRetrieved(state)
  );
  const sites: Site[] = useSelector((state: any) => rdxMinimumSiteInfoData(state));

  const isIdfSearchItemsLoaded: boolean = useSelector(
    (state: any) => state.distinctIdfSearchItems.dataRetrieved
  );
  const idfSearchItems: IdfSearchItem[] = useSelector(
    (state: any) => state.distinctIdfSearchItems.data
  );

  const isStoresLoaded: boolean = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo.dataRetrieved
  );
  const stores: Store[] = useSelector((state: any) => state.data.app.searchMinimumStoreInfo.data);
  //#endRegion redux

  //#region localState
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [suggestedSearchItems, setSuggestedSearchItems] = useState<SearchItem[]>([]);
  const [filteredSearchValue, setFilteredSearchValue] = useState<string>('');
  //#endregion localState

  let history = useHistory();

  //#region handles
  const runSearch = (
    filteredSearchValue: string,
    sites: Site[],
    idfSearchItems: IdfSearchItem[]
  ) => {
    const filteredSites = searchAndSort({filteredSearchValue, sites, idfSearchItems});
    setSuggestedSearchItems(filteredSites);
  };

  const clearSearch = () => {
    setSearchValue('');
    setSuggestedSearchItems([]);
  };

  const debouncedSetFilteredSearchValue = useCallback(
    debounce((searchValue: string) => {
      const filteredSearchValue = searchValue.toLowerCase();
      setFilteredSearchValue(filteredSearchValue);
    }, 150),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onKeyUp = (event: React.KeyboardEvent) => {
    const eventKey = event.key;
    const enterKey = 'Enter';
    if (eventKey === enterKey && suggestedSearchItems.length > 0) {
      history.push(
        `/switch${getSwitchConnectivityParamsPartialUrlFromSearchItem(
          suggestedSearchItems[0],
          setSwitchError
        )}`
      );
      clearSearch();
    }
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };
  //#endregion handles

  //#region useEffect
  useEffect(() => {
    if (authState && authState.isAuthenticated && !isSitesLoaded) {
      const accessToken = oktaAuth.getAccessToken();
      dispatch(fetchMinimumSiteInfo(accessToken));
      dispatch(fetchDistinctIdfSearchItems(accessToken));
    }
    if (isSitesLoaded && isStoresLoaded) {
      dispatch(mergeStoreSite(stores));
    }
  }, [oktaAuth, authState, dispatch, isSitesLoaded, isStoresLoaded, stores]);

  useEffect(() => {
    debouncedSetFilteredSearchValue(searchValue);
  }, [searchValue, debouncedSetFilteredSearchValue]);

  useEffect(() => {
    if (
      filteredSearchValue.length > 0 &&
      isSitesLoaded &&
      sites.length > 0 &&
      isIdfSearchItemsLoaded &&
      idfSearchItems.length > 0
    ) {
      runSearch(filteredSearchValue, sites, idfSearchItems);
    } else {
      clearSearch();
    }
  }, [
    isSitesLoaded,
    sites,
    isIdfSearchItemsLoaded,
    idfSearchItems,
    filteredSearchValue,
    debouncedSetFilteredSearchValue,
  ]);
  //#endregion useEffect

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledSiteIdSearchContainer>
        <>
          <StyledTextField
            disabled={!isSitesLoaded && !isIdfSearchItemsLoaded}
            id="siteid-search"
            name="siteid-search"
            aria-describedby="site-id-search"
            variant="filled"
            onFocus={handleFocus}
            className={isOpen && suggestedSearchItems.length > 0 ? 'search-active' : ''}
            onChange={handleChange}
            placeholder={'Store #, site name, location etc.'}
            autoComplete={'off'}
            value={searchValue}
            onKeyUp={onKeyUp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isSitesLoaded && isIdfSearchItemsLoaded ? (
                    <StyledSearchIcon />
                  ) : (
                    <StyledCircleLoading size={24} />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton aria-label="clear search field" onClick={clearSearch}>
                    {searchValue.length > 0 ? <StyledClearRoundedIcon /> : ''}
                  </StyledIconButton>
                  <SiteIdSettings urlType={urlType} />
                </InputAdornment>
              ),
            }}
          />
          {isOpen && (
            <SiteIdSearchSuggestions
              urlType={urlType}
              suggestedSearchItems={suggestedSearchItems}
              clearUserInput={clearSearch}
              setSwitchError={setSwitchError}
            />
          )}
        </>
      </StyledSiteIdSearchContainer>
    </ClickAwayListener>
  );
}

export default SiteIDSearch;

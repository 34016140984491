import {FlatSwitchData} from '../interfaces/SwitchConnectivityState';
import SwitchResponseData from '../interfaces/response/SwitchResponseData';

// Copilot used to simplify the code

const initialize = (apiData: SwitchResponseData) => {
  const flattenedSwitchData: FlatSwitchData[] = [];

  apiData.switchStacks.forEach(({switchStackName, memberSwitches}) => {
    memberSwitches.forEach(({switchNumber, mainPorts: ports}) => {
      ports.forEach(switchPort => {
        const {
          portNumber,
          portStatus,
          portDescription,
          vlanNumber: portVlanNumber,
          connectedDevices,
        } = switchPort;

        const searchValues: string[] = [portDescription];
        const searchData = searchValues.join('|').toLowerCase();

        if (connectedDevices.length === 0) {
          const flattenedDevice: FlatSwitchData = {
            id: `${switchStackName}-${switchNumber}-${portNumber}-0`,
            switchStackName,
            switchNumber,
            portNumber,
            portStatus,
            portDescription,
            macAddress: '',
            deviceName: '',
            deviceType: '',
            vlanNumber: portVlanNumber ?? 'unknown',
            manufacturer: '',
            searchData,
          };

          flattenedSwitchData.push(flattenedDevice);
        }

        connectedDevices?.forEach((device, index) => {
          const {
            macAddress,
            deviceName,
            deviceType,
            vlanNumber: deviceVlanNumber,
            manufacturer,
          } = device;

          const searchValues: string[] = [
            macAddress?.replace(/[.|:]/g, ''),
            deviceName,
            deviceType,
            manufacturer,
            portDescription,
          ];
          const searchData = searchValues.join('|').toLowerCase();

          const flattenedDevice: FlatSwitchData = {
            id: `${switchStackName}-${switchNumber}-${portNumber}-${index}`,
            switchStackName,
            switchNumber,
            portNumber,
            portStatus,
            portDescription,
            macAddress: macAddress ?? '',
            deviceName: deviceName ?? '',
            deviceType: deviceType ?? '',
            vlanNumber: deviceVlanNumber ?? 'unknown',
            manufacturer: manufacturer ?? '',
            searchData,
          };

          flattenedSwitchData.push(flattenedDevice);
        });
      });
    });
  });

  return flattenedSwitchData;
};

export default initialize;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Dialog, DialogTitle} from '@material-ui/core';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';
import {StoreAction} from './types/StoreAction';
import SGButton from 'components/CTA/SGButton';

//#region styled-components

const StandardTextDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
`;

const StyledStoreOrServiceDiv = styled.div`
  font-style: italic;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  padding-top: 28px;
  padding-bottom: 4px;
  flex-direction: row;
  justify-content: center;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-style: bold;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(SGButton)`
  height: 30px;
  display: flex;
  align-items: center;
  margin: 8px;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${props => props.theme.cardBg};
    color: ${props => props.theme.text};
  }
`;
//#endregion styled-components

interface NoServicesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  stores: Stores;
  storeAction: StoreAction;
}

function NoServicesDialog({isOpen, onClose, stores, storeAction}: NoServicesDialogProps) {
  const [isStoreActionAdd, setIsStoreActionAdd] = useState<boolean>(storeAction === 'add');

  useEffect(() => {
    setIsStoreActionAdd(storeAction === 'add');
  }, [storeAction]);
  return (
    <StyledDialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <StyledDialogTitle id="simple-dialog-title">
        Missing or Empty Store Configurations
      </StyledDialogTitle>
      <StandardTextDiv>
        {isStoreActionAdd
          ? 'The selected store(s) currently have no services associated with them in the database. They should be updated with their full list of services.'
          : 'The selected store(s) currently have no services associated with them in the database so no services can be removed.'}
      </StandardTextDiv>
      {isStoreActionAdd && <StandardTextDiv>Selected stores: </StandardTextDiv>}
      {isStoreActionAdd &&
        stores.map(store => {
          return (
            <StyledStoreOrServiceDiv key={store.SiteId}>
              {getStoreDisplayName(store)}
            </StyledStoreOrServiceDiv>
          );
        })}
      <StyledButtonContainerDiv>
        <StyledButton onClick={onClose}>
          {isStoreActionAdd ? 'I Understand' : 'Go back'}
        </StyledButton>
      </StyledButtonContainerDiv>
    </StyledDialog>
  );
}

export default NoServicesDialog;

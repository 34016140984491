import React, {MouseEvent, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import Paper from 'components/Paper';
import Table from 'components/Tables/TableStructure/Table';
import Brand from '../../interfaces/Brand';
import Geo from '../../interfaces/SiteGeoSelection';
import SiteType from '../../interfaces/SiteType';
import Status from '../../interfaces/Status';
import SiteWithIdf from './interfaces/SiteWithIdf';
import SiteSearchTableColumns from './SiteSearchTableColumns';
import {getSiteTypeFromSiteId} from 'utils/utils';
import Snackbar from 'components/Snackbar';

const StyledTable = styled(Table)`
  .top-alignment.body-cell {
    vertical-align: top;
  }
  .body {
    background-color: transparent;
  }
  .mouseHover:hover {
    background-color: ${props => props.theme.activeStateBlue}0A;
  }
  .disable-hover:hover {
    background-color: transparent;
    cursor: auto;
  }
`;

interface Filters {
  brands: Brand[];
  geos: Geo[];
  siteTypes: SiteType[];
  statuses: Status[];
  [key: string]: string[]; // index signature
}

interface SiteSearchTableProps {
  urlKey: string;
  filters: Filters;
  data: SiteWithIdf[];
  loaded: boolean;
}

interface FormattedFilters {
  brands: string[];
  geos: string[];
  siteTypes: string[];
  statuses: string[];
  [key: string]: string[]; // index signature
}

function formatFilters(filters: Filters): FormattedFilters {
  // generalize the filter data to work with incoming data
  let results = {...filters};
  for (const property in filters) {
    results[property] = filters[property].map((value: string) => value.toLowerCase()) as string[];
  }
  return results;
}

const customRowsPerPage = {
  defaultRowsPerPage: 20,
  rowsPerPageOptions: [20, 50, 100, {label: 'All', value: -1}],
};

const customRowClass = (row: SiteWithIdf) => {
  const siteTypeFromId = getSiteTypeFromSiteId(row.siteId);
  if (siteTypeFromId === 'Distribution Center') {
    return 'disable-hover';
  } else {
    return '';
  }
};

function SiteSearchTable({urlKey, filters, data, loaded}: SiteSearchTableProps) {
  const [snackMsg, setSnackMsg] = useState<string>('');
  const formattedFilters = formatFilters(filters);
  const filteredData: SiteWithIdf[] = useMemo(() => {
    return data.filter(site => {
      const siteBrand = site.brand.replace(/\s+/g, '').toLowerCase();
      const siteGeo = site.geo.replace(/\s+/g, '').toLowerCase();
      const siteType = site.siteType.replace(/\s+/g, '').toLowerCase();
      const siteStatus = site.status?.replace(/\s+/g, '').toLowerCase();

      return (
        formattedFilters.brands.includes(siteBrand) &&
        formattedFilters.geos.includes(siteGeo) &&
        formattedFilters.siteTypes.includes(siteType) &&
        (siteStatus ? formattedFilters.statuses.includes(siteStatus) : true)
      );
    });
  }, [formattedFilters, data]);

  let history = useHistory();

  const goToSite = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const siteId = event.currentTarget.getAttribute('clickkey');

    // Once Table is converted to TSX the siteId check can be removed
    if (siteId?.length === 7) {
      const siteTypeFromId = getSiteTypeFromSiteId(siteId);
      if (siteTypeFromId !== 'Distribution Center') {
        const path = `/${urlKey}/${siteId}`;
        history.push(path);
      }
    } else {
      setSnackMsg('Invalid Link, please try another site.');
    }
  };

  return (
    <Paper>
      <StyledTable
        data={filteredData}
        columns={SiteSearchTableColumns(urlKey)}
        loaded={loaded}
        paginationText={'Sites'}
        clickAction={goToSite}
        clickActionType={'siteId'}
        customRowsPerPage={customRowsPerPage}
        customRowClass={customRowClass}
      />
      {snackMsg && <Snackbar>{snackMsg}</Snackbar>}
    </Paper>
  );
}
export default SiteSearchTable;

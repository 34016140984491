import React from 'react';
import styled from 'styled-components';
import SGHeadline from 'components/Typography/SGHeadline';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import AlertIcon from '../RRPTable/TableStructure/AlertIcon';

export interface CardHeaderProps {
  title: string;
  alert: number;
}

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledHeader = styled(SGHeadline)`
  margin-right: 0.5rem;
`;

const StyledStoreRoundedIcon = styled(StoreRoundedIcon)`
  margin-right: 0.5rem;
`;

const StyledAlertIcon = styled(AlertIcon)`
  margin-left: auto;
  margin-right: 0.75rem;
`;

export default function CardHeader({title, alert}: CardHeaderProps) {
  return (
    <StyledCardHeader>
      <StyledStoreRoundedIcon />
      <StyledHeader variant={'h5'} className={'text-color'}>
        {title}
      </StyledHeader>
      <StyledAlertIcon alertValue={alert} />
    </StyledCardHeader>
  );
}

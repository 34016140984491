import React from 'react';
import Grid from '@material-ui/core/Grid';
import IStoreData from 'features/RRP/interfaces/IStoreData';
import Card from '../Card';
import StoreTransferList from 'components/StoreTransferList';
import {Stores} from 'interfaces/Stores';

// check white space

interface CardViewProps {
  stores: Array<IStoreData>;
  handleStores: (value: Stores) => void;
  selectedStores: Stores;
}

const renderStoreCards = (stores: Array<IStoreData>) => {
  if (stores.length === 0 || stores.length === null) {
    return 'nothing to return';
  }

  return stores.map(store => {
    return (
      <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
        <Card storeData={store} />
      </Grid>
    );
  });
};

export default function CardView({stores, handleStores, selectedStores}: CardViewProps) {
  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={12}>
        <StoreTransferList
          handleStores={handleStores}
          selectedStores={selectedStores}
          variant={'light'}
        />
      </Grid>
      {renderStoreCards(stores)}
    </Grid>
  );
}

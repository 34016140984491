export const initialState = {
  diminishedStoreData: [],
  deviceCategoryData: [],
  deviceTypeData: [],
  deviceTypeReady: false,
  noMatchingStoreData: 0,
  // storeData: [],
};

const dataMerge = (state = initialState, action) => {
  switch (action.type) {
    case 'MERGE_EVENT_STORE':
      const storeData = action.store;
      let eventData = action.event;

      eventData.forEach(event => {
        let matchedStore = storeData.find(store => store.StoreNumber === event.storeNumber);
        if (matchedStore !== undefined) {
          // event.geo = matchedStore.Geo;
          // event.storeName = matchedStore.StoreName;
          event.isKeyMarket = matchedStore.IsKeyMarket;
          // event.tradingStatus = matchedStore.TradingStatus;
        } else {
          // console.log('no match', item);
          console.log('no match', matchedStore);
          state.noMatchingStoreData += 1;
        }
      });

      return {
        ...state,
        // storeData: storeData,
        diminishedStoreData: eventData,
      };
    case 'MERGE_DEVICE_CATEGORY_GEO':
      const activeEvent = action.activeEvent;
      const storeDataMerge = action.store;

      let sortedData = activeEvent.sort(function (a, b) {
        return a.StoreNumber - b.StoreNumber;
      });

      function getGeo(number) {
        let matchedIndex = storeDataMerge.map(store => store.StoreNumber).indexOf(number);
        // console.log('matchedIndex', matchedIndex);
        if (matchedIndex === -1) {
          return 'noGeo';
        } else {
          return storeDataMerge[matchedIndex].Geo;
        }
      }

      sortedData.forEach((event, index) => {
        // console.log('event',event)
        sortedData[index].geo = getGeo(event.StoreNumber);
      });

      // let deviceCategory = {
      //     na: [],
      //     emea: [],
      //     apla: [],
      //     gc: [],
      //     noGeo: [],
      // };

      // sortedData.forEach((event, index)=> {
      //     const eventGeo = event.geo.toLowerCase();
      //     // console.log('eventGeo',eventGeo);
      //     if (eventGeo === 'nogeo') {
      //         deviceCategory.noGeo.push(event);
      //     } else {
      //         const categoryIndex = deviceCategory[eventGeo].map((category) => category.category).indexOf(event.Category);
      //         if (deviceCategory[eventGeo].map((category) => category.category).indexOf(event.Category) > -1) {
      //             deviceCategory[eventGeo][categoryIndex].count += 1;
      //         } else if (deviceCategory[eventGeo].indexOf(event.Category) === -1) {
      //             deviceCategory[eventGeo].push({
      //                 category: event.Category,
      //                 count: 1,
      //             });
      //         }
      //     }

      // });

      let deviceType = {
        na: {
          series: [],
          labels: [],
        },
        emea: {
          series: [],
          labels: [],
        },
        apla: {
          series: [],
          labels: [],
        },
        gc: {
          series: [],
          labels: [],
        },
        noGeo: [],
      };

      let deviceCategorySubCategory = {
        na: [],
        emea: [],
        apla: [],
        gc: [],
      };

      //counting and formatting to what the front-end needs
      sortedData.forEach((event, index) => {
        const eventGeo = event.geo.toLowerCase();
        // console.log('eventGeo',eventGeo);
        if (eventGeo === 'nogeo') {
          // sometimes the data recieved does not include the correct geo information, we will store it to troubleshoot
          deviceType.noGeo.push(event);
        } else {
          const deviceIndex = deviceType[eventGeo].labels.indexOf(event.DeviceType);

          // sort active events into series and labels
          if (deviceIndex > -1) {
            // if label in deviceType exists, find index then add to series
            deviceType[eventGeo].series[deviceIndex] += 1;
          } else if (deviceIndex === -1) {
            // if label does not exist in deviceType, push count of 1 and Device Type into series and labels
            deviceType[eventGeo].series.push(1);
            deviceType[eventGeo].labels.push(event.DeviceType);
          }

          // sort active events into device-type, category, and sub-category
          const deviceTypeIndex = deviceCategorySubCategory[eventGeo]
            .map(device => device.name)
            .indexOf(event.DeviceType);
          if (deviceTypeIndex > -1) {
            // if deviceType in deviceCategory exists, find index then add to count
            deviceCategorySubCategory[eventGeo][deviceTypeIndex].count += 1;

            // check to see if category exists within deviceType, if it does then add to count
            const deviceCategoryIndex = deviceCategorySubCategory[eventGeo][
              deviceTypeIndex
            ].children
              .map(category => category.name)
              .indexOf(event.Category);
            if (deviceCategoryIndex > -1) {
              deviceCategorySubCategory[eventGeo][deviceTypeIndex].children[
                deviceCategoryIndex
              ].count += 1;

              // check to see if subCategory exists within category, if it does then add to count
              const subCategoryIndex = deviceCategorySubCategory[eventGeo][
                deviceTypeIndex
              ].children[deviceCategoryIndex].children
                .map(subCategory => subCategory.name)
                .indexOf(event.SubCategory);
              if (subCategoryIndex > -1) {
                deviceCategorySubCategory[eventGeo][deviceTypeIndex].children[
                  deviceCategoryIndex
                ].children[subCategoryIndex].count += 1;
              } else {
                deviceCategorySubCategory[eventGeo][deviceTypeIndex].children[
                  deviceCategoryIndex
                ].children.push({
                  name: event.SubCategory,
                  id: event.DeviceType + event.Category + event.SubCategory,
                  count: 1,
                });
              }
            } else {
              // if category doesnt exist in deviceType push into category, assumes subCategory needs to be added too
              deviceCategorySubCategory[eventGeo][deviceTypeIndex].children.push({
                name: event.Category,
                id: event.DeviceType + event.Category,
                count: 1,
                children: [
                  {
                    name: event.SubCategory,
                    id: event.DeviceType + event.Category + event.SubCategory,
                    count: 1,
                  },
                ],
              });
            }
          } else if (deviceTypeIndex === -1) {
            // if label does not exist in deviceType, push count of 1 and Device Type into series and labels
            deviceCategorySubCategory[eventGeo].push({
              name: event.DeviceType,
              id: event.DeviceType,
              count: 1,
              children: [
                {
                  name: event.Category,
                  id: event.DeviceType + event.Category,
                  count: 1,
                  children: [
                    {
                      name: event.SubCategory,
                      id: event.DeviceType + event.Category + event.SubCategory,
                      count: 1,
                    },
                  ],
                },
              ],
            });
          }
        }
      });

      //sorting the data
      function sortDeviceTypeData(deviceType) {
        let sortedDeviceSeries = {
          na: {
            series: [],
            labels: [],
          },
          emea: {
            series: [],
            labels: [],
          },
          apla: {
            series: [],
            labels: [],
          },
          gc: {
            series: [],
            labels: [],
          },
          noGeo: deviceType.noGeo,
        };
        const geoList = ['na', 'emea', 'apla', 'gc'];
        for (let i = 0; i < geoList.length; i++) {
          //1) combine the arrays:
          let list = [];
          for (let j = 0; j < deviceType[geoList[i]].labels.length; j++) {
            list.push({
              labels: deviceType[geoList[i]].labels[j],
              series: deviceType[geoList[i]].series[j],
            });
          }

          //2) sort with in descending count:
          list.sort(function (a, b) {
            return b.series - a.series;
          });

          //3) separate them back out:
          for (let k = 0; k < list.length; k++) {
            sortedDeviceSeries[geoList[i]].labels[k] = list[k].labels;
            sortedDeviceSeries[geoList[i]].series[k] = list[k].series;
          }
        }
        return sortedDeviceSeries;
      }

      const sortedDeviceTypeData = sortDeviceTypeData(deviceType);

      function sortDeviceCatSubData(deviceType) {
        let sorted = deviceType;
        sorted.sort(function (a, b) {
          if (Array.isArray(a.children)) {
            sortDeviceCatSubData(a.children);
          }
          return b.count - a.count;
        });

        return sorted;
      }

      const sortedDeviceCatSubData = {
        na: sortDeviceCatSubData(deviceCategorySubCategory.na),
        emea: sortDeviceCatSubData(deviceCategorySubCategory.emea),
        gc: sortDeviceCatSubData(deviceCategorySubCategory.gc),
        apla: sortDeviceCatSubData(deviceCategorySubCategory.apla),
      };

      return {
        ...state,
        // sortedEventData: sortedData,
        // deviceCategoryData: deviceCategory,
        deviceCategorySubCategory: sortedDeviceCatSubData,
        deviceTypeData: sortedDeviceTypeData,
        deviceTypeReady: true,
      };
    default:
      return state;
  }
};

export default dataMerge;

import React from 'react';
import styled from 'styled-components';
import SGTitle from 'components/Typography/SGTitle';
import AlertCount from './AlertCount';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import {IStatusItem} from 'features/RRP/interfaces/IStatusSummary';
import StatusSummaryFilterEnum from 'features/RRP/enums/StatusSummaryFilterEnum';

interface DeviceButtonProps {
  deviceName: string;
  statusCounts: IStatusItem;
  filters: StatusSummaryFilterEnum;
  value: StatusSummaryFilterEnum;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledSGTitle = styled(SGTitle)`
  transition: 200ms;
  &.green {
    color: ${props => props.theme.green};
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.orange {
    color: ${props => props.theme.orange};
  }
  &.red {
    color: ${props => props.theme.red};
  }
  &.grey {
    color: ${props => props.theme.grey};
  }
`;

const StyledAlertCount = styled(AlertCount)`
  transition: 200ms;
  .text-color {
    transition: 200ms;
    color: ${props => props.theme.text};
  }
`;

const StyledDeviceButton = styled.button`
  border: 2px solid ${props => props.theme.disabledStateBlue}00;
  background-color: ${props => props.theme.disabledStateBlue}64;
  padding: 8px;
  border-radius: 10px;
  min-width: 78px;
  min-height: 69px;
  margin: 4px;
  transition: 200ms;

  &:first-of-type {
    margin-left: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &.isActive {
    border: 2px solid ${props => props.theme.activeStateBlue};
    background-color: ${props => props.theme.activeStateBlue};
    & ${StyledSGTitle} {
      color: ${props => props.theme.black};
    }
    & ${StyledAlertCount} .text-color {
      color: ${props => props.theme.black};
    }
  }

  &.disabled {
    opacity: 0.4;
    &:hover {
      opacity: 1;
      border: 2px solid ${props => props.theme.disabledStateBlue}00;
      background-color: ${props => props.theme.disabledStateBlue}64;
      & ${StyledSGTitle} {
        color: ${props => props.theme.text};
      }
      & ${StyledAlertCount} .text-color {
        color: ${props => props.theme.text};
      }
    }
  }
`;

const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledCountContainer = styled(StyledNameContainer)`
  margin-top: 0.25rem;
`;

const generateActiveClassName = function generateActiveClassName(
  currentFilter: StatusSummaryFilterEnum,
  componentValue: StatusSummaryFilterEnum
) {
  let displayStateClass: string = '';
  if (currentFilter === StatusSummaryFilterEnum.All) {
    displayStateClass = '';
  } else if (currentFilter === componentValue) {
    displayStateClass = 'isActive';
  } else {
    displayStateClass = 'disabled';
  }
  return displayStateClass;
};

function DeviceButton({deviceName, statusCounts, filters, onClick, value}: DeviceButtonProps) {
  const activeClass = generateActiveClassName(filters, value);
  return (
    <StyledDeviceButton className={`${activeClass}`} onClick={onClick} value={value}>
      <StyledNameContainer>
        <StyledSGTitle variant={'t6'} className={'text-color'}>
          {deviceName}
        </StyledSGTitle>
      </StyledNameContainer>
      <StyledCountContainer>
        <StyledAlertCount alertType={AlertSeverityEnum.CRITICAL} count={statusCounts.CRITICAL} />
        <StyledAlertCount alertType={AlertSeverityEnum.MAJOR} count={statusCounts.MAJOR} />
        <StyledAlertCount alertType={AlertSeverityEnum.MINOR} count={statusCounts.MINOR} />
      </StyledCountContainer>
    </StyledDeviceButton>
  );
}

export default DeviceButton;

import {useState, useEffect} from 'react';
import config from '../../../oktaConfig';
import dataReducerRRP from '../components/dataReducerRRP';
import {useOktaAuth} from '@okta/okta-react';

function FetchSiteIdsHook(selectedStores) {
  const [isSiteIdLoaded, setIsSiteIdLoadedLoaded] = useState(false);
  const [siteIdData, setSiteIdData] = useState([]);
  const {authState, oktaAuth} = useOktaAuth();

  useEffect(() => {
    setIsSiteIdLoadedLoaded(false);
    if (authState && authState.isAuthenticated && selectedStores.length !== 0) {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      setIsSiteIdLoadedLoaded(false);
      let storeSiteId = selectedStores.map(store => store.SiteId);
      fetch(
        config.resourceServer.insightsApiUrlRetailRecurringPhysical +
          '?siteids=' +
          storeSiteId.join(','),
        {
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        }
      )
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(data => {
          // console.log('data', data)
          setSiteIdData(dataReducerRRP(data, selectedStores));
          setIsSiteIdLoadedLoaded(true);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          // setIsSiteIdLoadedLoaded(true);
        });

      return () => {
        controller.abort();
        setIsSiteIdLoadedLoaded(false);
      };
    } else if (authState.isAuthenticated && selectedStores.length === 0) {
      setSiteIdData([]);
      setIsSiteIdLoadedLoaded(true);
    } else {
      setSiteIdData([]);
      setIsSiteIdLoadedLoaded(false);
    }
  }, [selectedStores, authState, oktaAuth]);

  return {
    isSiteIdLoaded,
    siteIdData,
  };
}

export default FetchSiteIdsHook;

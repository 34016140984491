enum DeviceType {
  REGISTER = 'register',
  PC = 'pc',
  SERVER = 'server',
  ROUTER = 'router',
  SWITCH = 'switch',
  VELOCLOUD = 'velocloud',
  FIREWALL = 'firewall',
  ATHLETE = 'athlete',
  TRAFFIC = 'traffic',
  TIMECLOCK = 'timeclock',
  RETAILMAC = 'retailmac',
  BACKOFHOUSECACHINGSERVER = 'backofhousecachingserver',
  FRONTOFHOUSECACHINGSERVER = 'frontofhousecachingserver',
  BREAKROOMMAC = 'breakroommac',
  HYPERVISOR = 'hypervisor',
}

export default DeviceType;

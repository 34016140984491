import destructureIdString from './destructureIdString';

const flattenNestedObject = treeData => {
  let planarArray = [];
  let calcTotalEvents = 0;
  let allNodeIds = [];

  const nestedObjHammer = function flattenNestedTreeArray(nodes) {
    nodes.forEach(node => {
      const {deviceId, catId, subCatId} = destructureIdString(node.id);
      let planarNode = {
        name: node.name,
        id: node.id,
        count: node.count,
        checked: node.checked,
        deviceId: deviceId,
        catId: catId,
        subCatId: subCatId,
        stores: node.stores,
      };

      if (node.children?.length >= 1) {
        nestedObjHammer(node.children);
      } else {
        calcTotalEvents += node.count;
      }
      if (planarNode.count > 0) {
        allNodeIds.push(node.id);
        planarArray.push(planarNode);
      }
    });
  };

  nestedObjHammer(treeData);

  return {
    planarArray: planarArray,
    totalEvents: calcTotalEvents,
    allNodeIds: allNodeIds,
  };
};

export default flattenNestedObject;

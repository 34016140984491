import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import ToolTip from 'components/ToolTip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const StyledSGBody = styled(SGBody)`
  color: ${props => props.theme.black};
  &:first-of-type {
    margin-top: 4px;
  }
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  color: ${props => props.theme.text};
  height: 1.25rem;
  width: 1.25rem;
`;

function VLANLegendToolTip() {
  return (
    <ToolTip
      placement="bottom-end"
      title={
        <>
          <StyledSGBody variant={'b6'}>
            <strong>What is a trunk port?</strong>
          </StyledSGBody>
          <StyledSGBody variant={'b6'}>
            A <strong>Trunk Port</strong> is a type of connection on a switch that is used to
            connect a guest virtual machine that is VLAN aware. It allows data to flow across a
            network node for multiple VLANs.
          </StyledSGBody>
        </>
      }
      customIcon={<StyledInfoOutlinedIcon />}
    />
  );
}

export default VLANLegendToolTip;

import React, {MouseEvent} from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles, Theme} from '@material-ui/core/styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

const StyledCellContainer = styled.div`
  display: flex;
`;

const StyledHelpOutlineRoundedIcon = styled(HelpOutlineRoundedIcon)`
  color: ${props => props.theme.tableText};
  font-size: 1rem;
`;

const StyledCellText = styled.div<{$textWidth?: string}>`
  width: ${props => props.$textWidth || 'auto'};
  font-size: 16px;
  line-height: 16px;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.tableText};
  text-transform: uppercase;
`;

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#f5f5f9',
  },
}))(Tooltip);

const inhibitClick = (event: MouseEvent<HTMLHeadingElement>) => {
  event.stopPropagation();
  event.preventDefault();
};

interface TableToolTipCellProps {
  value: string;
  tooltip: string;
  textWidth: string;
}

export default function TableToolTipCell({value, tooltip, textWidth}: TableToolTipCellProps) {
  return (
    <StyledCellContainer>
      <StyledCellText $textWidth={textWidth}>{value}</StyledCellText>
      <HtmlTooltip
        interactive
        placement={'bottom'}
        title={<div onClick={inhibitClick}>{tooltip}</div>}
        arrow
      >
        <StyledHelpOutlineRoundedIcon />
      </HtmlTooltip>
    </StyledCellContainer>
  );
}

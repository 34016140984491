import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
} = AlertSeverityEnum;

export default function wirelessAPHealthAlertValue(wirelessAPHealth: number): IAlert {
  if (wirelessAPHealth === null || isNaN(wirelessAPHealth)) {
    return defaultNullAlert(['wirelessAPHealth']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  if (wirelessAPHealth < 90) {
    alert.value = MAJOR;
    alert.issue.push('Average Wireless AP health is below 90%.');
    alert.effect.push('Unhealthy Wireless AP hardware or an unhealthy wireless environment may result in show or intermittently failing wireless network connectivity.');
    alert.solution.push('Contact Wireless Network Engineering for further investigation.');
  } else if (wirelessAPHealth < 95) {
    alert.value = MINOR;
    alert.issue.push('Average Wireless AP health is below 95%.');
    alert.effect.push('Unhealthy Wireless AP hardware or an unhealthy wireless environment may result in show or intermittently failing wireless network connectivity.');
    alert.solution.push('Contact Wireless Network Engineering for further investigation.');
  }

  return alert;
}

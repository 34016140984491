import {useState, useEffect} from 'react';
// import config from '../../../oktaConfig';
import {useOktaAuth} from '@okta/okta-react';

interface CrowdstrikeHookProps {
  siteIds: string[];
}

function FetchCrowdstrikeHook({siteIds}: CrowdstrikeHookProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const {authState, oktaAuth} = useOktaAuth();
  // console.log('cd started')
  useEffect(() => {
    setIsLoaded(false);
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      setIsLoaded(false);
      fetch(
        // config.resourceServer.insightsApiUrlEvents +
        'https://n5-insights-apim-43.azure-api.net/n5-insights-API-fna-43/events?' +
          'category=crowdstrike&siteid=' +
          siteIds.join(),
        {
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        }
      )
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(data => {
          setData(data);
          setIsLoaded(true);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {});

      return () => {
        controller.abort();
        setIsLoaded(false);
      };
    } else if (authState?.isAuthenticated) {
      setData([]);
      setIsLoaded(true);
    } else {
      setData([]);
      setIsLoaded(false);
    }
  }, [authState, oktaAuth, siteIds]);

  return {
    isLoaded,
    data,
  };
}

export default FetchCrowdstrikeHook;

import React from 'react';
import styled from 'styled-components';

import Layout from './Layout';
import StyledContainer from '../components/Container';
import Grid from '@material-ui/core/Grid';
import CloudImg from '../assets/images/contact_cloud.svg';

const StyledContact = styled.div`
  h1.title {
    color: #ced5db;
    font-size: 4em;
    font-family: 'Futura C XBd';
    margin: 0;
  }
  p {
    font-size: 1rem;
  }
  img {
    max-width: 100%;
  }
  a:link {
    color: #ffffff;
  }
  a:hover {
    color: #ced5db;
  }
  a:visited {
    color: #ffffff;
  }
`;

const StyledTitle = styled.h1`
  color: #ced5db;
  font-size: 4em;
  font-family: 'Futura C XBd';
  margin: 0;
`;

function Contact(props) {
  const {match} = props;
  return (
    <Layout match={match}>
      <StyledContact>
        <StyledContainer>
          <Grid container spacing={3} direction="row" alignItems="stretch">
            <Grid item xs={12}>
              <StyledTitle>404</StyledTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <p>
                <strong>Page Not Found</strong>
              </p>
              <p>
                <a href="mailto:Lst-GT.TechOps.RSS.Insights@nike.com?subject=Retail%20Insight%20Support">
                  Lst-GT.TechOps.RSS.Insights@nike.com
                </a>
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={CloudImg} alt={'Contact'} />
            </Grid>
          </Grid>
        </StyledContainer>
      </StyledContact>
    </Layout>
  );
}

export default Contact;

import React from 'react';
import styled from 'styled-components';
import SGHeadline from 'components/Typography/SGHeadline';
import CloudIcon from '@material-ui/icons/Cloud';

interface RRPHeaderProps {
  children?: JSX.Element;
}

const StyledHeaderContainer = styled.div`
  margin-top: 1rem;
`;

const StyledGeoNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: ${props => props.theme.activeStateBlue}26;
  color: ${props => props.theme.activeStateBlue};
`;

const StyledSGHeadline = styled(SGHeadline)`
  color: ${props => props.theme.activeStateBlue};
`;

function RRPHeader({children}: RRPHeaderProps) {
  return (
    <StyledHeaderContainer>
      <StyledGeoNameContainer>
        <StyledIconContainer>
          <CloudIcon />
        </StyledIconContainer>
        <StyledSGHeadline variant={'h4'}>Reoccurring Retail Physical</StyledSGHeadline>
      </StyledGeoNameContainer>
      {children}
    </StyledHeaderContainer>
  );
}

export default RRPHeader;

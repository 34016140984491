import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  rdxMinimumSiteInfoDataRetrieved,
  rdxMinimumSiteInfoData,
} from 'redux/reducers/minimumSiteInfoReducer';
import Grid from '@material-ui/core/Grid';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';
import SiteWithIdf from 'features/SiteSearch/interfaces/SiteWithIdf';
import Brand from '../interfaces/Brand';
import Geo from '../interfaces/SiteGeoSelection';
import SiteType from '../interfaces/SiteType';
import Status from '../interfaces/Status';
import Layout from './Layout';
import LayoutHeader, {LayoutHeaderIconTypes} from 'components/LayoutHeader';
import Container from 'components/Container';
import SiteIDSearch from 'components/SiteIDSearch/SiteIDSearch';
import SiteSearchTable from 'features/SiteSearch/SiteSearchTable';
import SiteSearchHeader from 'features/SiteSearch/SiteSearchHeader';

const dictionary: Record<SearchParamsCategory, Record<string, string>> = {
  brand: {
    [Brand.NIKE]: Brand.NIKE,
    [Brand.CONVERSE]: Brand.CONVERSE,
  },
  geo: {
    [Geo.NA]: Geo.NA,
    [Geo.EMEA]: Geo.EMEA,
    [Geo.GC]: Geo.GC,
    [Geo.APLA]: Geo.APLA,
  },
  siteType: {
    [SiteType.DC]: SiteType.DC,
    [SiteType.RETAIL]: SiteType.RETAIL,
    [SiteType.SHOWROOM]: SiteType.SHOWROOM,
  },
  status: {
    [Status.CLOSED]: Status.CLOSED,
    [Status.OPEN]: Status.OPEN,
    [Status.REMODEL]: Status.REMODEL,
    [Status.PREOPEN]: Status.PREOPEN,
  },
};

type SearchParamsCategory = 'brand' | 'geo' | 'siteType' | 'status';

const parseSearchParams = (category: SearchParamsCategory, params: string) => {
  const array = params.split(',');

  const results = array.reduce((accumulator: string[], item) => {
    if (dictionary[category][item]) {
      return [...accumulator, dictionary[category][item]];
    }
    return accumulator;
  }, []);

  return results.length === 0 ? Object.values(dictionary[category]) : results;
};

function SiteSearch() {
  const {urlKey, brand, geo, siteType, status} = useParams<any>();

  const brands = parseSearchParams('brand', brand) as Brand[];
  const geos = parseSearchParams('geo', geo) as Geo[];
  const siteTypes = parseSearchParams('siteType', siteType) as SiteType[];
  const statuses = parseSearchParams('status', status) as Status[];

  const minimumSiteInfoData = useSelector((state: any) => rdxMinimumSiteInfoData(state));
  const minimumSiteInfoDataIsLoaded = useSelector((state: any) =>
    rdxMinimumSiteInfoDataRetrieved(state)
  );
  const isIdfSearchItemsLoaded: boolean = useSelector(
    (state: any) => state.distinctIdfSearchItems.dataRetrieved
  );
  const idfSearchItems: IdfSearchItem[] = useSelector(
    (state: any) => state.distinctIdfSearchItems.data
  );

  const [siteList, setSiteList] = useState<SiteWithIdf[]>([]);

  useEffect(() => {
    if (isIdfSearchItemsLoaded && minimumSiteInfoDataIsLoaded) {
      const combinedSiteAndIdf: SiteWithIdf[] = minimumSiteInfoData.map(site => {
        const idfItems = idfSearchItems.filter(idfItem => idfItem.siteId === site.siteId);
        if (idfItems.length > 0) {
          return {
            ...site,
            idfs: idfItems,
          };
        } else {
          return {...site};
        }
      });
      setSiteList(combinedSiteAndIdf);
    } else if (minimumSiteInfoDataIsLoaded) {
      setSiteList(minimumSiteInfoData);
    }
  }, [isIdfSearchItemsLoaded, idfSearchItems, minimumSiteInfoData, minimumSiteInfoDataIsLoaded]);

  return (
    <Layout
      headerContent={
        <>
          <LayoutHeader
            title={'Switch Connectivity'}
            icon={LayoutHeaderIconTypes.Switch}
            searchComponent={<SiteIDSearch urlType={urlKey} setSwitchError={() => {}} />}
          />
          <SiteSearchHeader
            urlKey={urlKey}
            brands={brands}
            geos={geos}
            siteTypes={siteTypes}
            statuses={statuses}
          />
        </>
      }
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SiteSearchTable
              urlKey={urlKey}
              data={siteList}
              loaded={minimumSiteInfoDataIsLoaded}
              filters={{brands, geos, siteTypes, statuses}}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default SiteSearch;

import 'date-fns';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ApexCharts from 'apexcharts';
import {historicalComparisonDateRange} from '../../../redux/actions';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import ReactDateRange from './ReactDateRange';
import Popover from '@material-ui/core/Popover';
import DateSelectorButton from '../../DateSelectorButton';

function CustomDateRange() {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.componentHistoricalComparison.dateSelection);

  const handleDateChange = (customStartDate, customEndDate) => {
    dispatch(
      historicalComparisonDateRange({
        startDate: new Date(customStartDate).getTime(),
        endDate: new Date(customEndDate).getTime(),
      })
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <DateSelectorButton
        aria-describedby={id}
        onClick={handleClick}
        selected={selected}
        value={'custom'}
      >
        <DateRangeRoundedIcon />
      </DateSelectorButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ReactDateRange handleDateChange={handleDateChange} />
      </Popover>
    </>
  );
}

export default CustomDateRange;

import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Table from './TableStructure/Table';
import SGBody from '../Typography/SGBody';
import TimeZoneDisplay from '../TimeZoneDisplay';
import {useSelector, useDispatch} from 'react-redux';
import {fetchStoreData, fetchDeviceHealthCount} from '../../redux/actions/index';
import {
  rdxStorePendingStatus,
  rdxStoreDataReady,
  rdxStoreData,
} from '../../redux/reducers/apiReducer';
import {
  rdxDeviceHealthCountDataPending,
  rdxDeviceHealthCountDataReady,
  rdxDeviceHealthCountData,
} from '../../redux/reducers/deviceHealthCountReducer';
import {userInfoAccessToken} from '../../redux/reducers/userInfoReducer';
import Icons from './Icons/Icons';

const columns = [
  {
    id: 'StoreNumber',
    label: 'Store',
    width: '25%',
    icon: 'none',
    format: (value, event) => (
      <SGBody variant={'b4'}>
        {value} {event.StoreName}
      </SGBody>
    ),
  },
  {
    id: 'TimeZone',
    label: 'Local Time',
    width: '15%',
    align: 'center',
    icon: 'none',
    format: value => <TimeZoneDisplay zone={value} />,
  },
  {
    id: 'network',
    label: 'Network',
    width: '15%',
    align: 'center',
    icon: 'none',
    format: (value, event) =>
      value ? (
        <Icons
          iconType={'warning'}
          text={'All Offline'}
          toolTipText={`Total Switches: ${event.totalSwitchs}, Critical Switchs: ${event.criticalSwitchs}, Total Velo Clouds: ${event.totalVeloClouds}, Critical Velo Clouds: ${event.criticalVeloClouds}`}
          color={'red'}
        />
      ) : (
        <Icons
          iconType={'checkWithCircle'}
          className={'circle-wrapper'}
          toolTipText={`Total Switches: ${event.totalSwitchs}, Total Velo Clouds: ${event.totalVeloClouds}`}
          color={'cardBg'}
        />
      ),
  },
  {
    id: 'server',
    label: 'Server',
    width: '15%',
    align: 'center',
    icon: 'none',
    format: (value, event) =>
      value ? (
        <Icons
          iconType={'warning'}
          text={'All Offline'}
          toolTipText={`Total Servers: ${event.totalServers}, Critical Servers: ${event.criticalServers}, `}
          color={'red'}
        />
      ) : (
        <Icons
          iconType={'checkWithCircle'}
          className={'circle-wrapper'}
          toolTipText={`Total Servers: ${event.totalServers}`}
          color={'cardBg'}
        />
      ),
  },
  {
    id: 'register',
    label: 'Register',
    width: '15%',
    align: 'center',
    icon: 'none',
    format: (value, event) =>
      value ? (
        <Icons
          iconType={'warning'}
          text={'All Offline'}
          toolTipText={`Total Register: ${event.totalRegisters}, Critical Register: ${event.criticalRegisters}`}
          color={'red'}
        />
      ) : (
        <Icons
          iconType={'checkWithCircle'}
          className={'circle-wrapper'}
          toolTipText={`Total Register: ${event.totalRegisters}`}
          color={'cardBg'}
        />
      ),
  },
  {
    id: 'pc',
    label: 'PC',
    width: '15%',
    align: 'center',
    icon: 'none',
    format: (value, event) =>
      value ? (
        <Icons
          iconType={'warning'}
          text={'All Offline'}
          toolTipText={`Total PCs: ${event.totalPCs}, Critical PCs: ${event.criticalPCs}`}
          color={'red'}
        />
      ) : (
        <Icons
          iconType={'checkWithCircle'}
          className={'circle-wrapper'}
          toolTipText={`Total PCs: ${event.totalPCs}`}
          color={'cardBg'}
        />
      ),
  },
];

const calcProgress = function calcProgress(
  storePendingStatus,
  storeDataReady,
  deviceHealthCountDataPending,
  deviceHealthCountDataReady
) {
  return 1;
};

function StoreEventsTable(props) {
  const {geo = 'na'} = props;

  const dispatch = useDispatch();

  const accessToken = useSelector(userInfoAccessToken);
  const storePendingStatus = useSelector(rdxStorePendingStatus);
  const storeDataReady = useSelector(rdxStoreDataReady);
  const storeData = useSelector(rdxStoreData);

  const deviceHealthCountDataPending = useSelector(rdxDeviceHealthCountDataPending);
  const deviceHealthCountDataReady = useSelector(rdxDeviceHealthCountDataReady);
  const deviceHealthCountData = useSelector(rdxDeviceHealthCountData);

  const [tableData, setTableData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataProgress, setDataProgress] = useState(0);

  useEffect(() => {
    if (accessToken !== null) {
      dispatch(fetchStoreData(accessToken));
      dispatch(fetchDeviceHealthCount(accessToken));
      setDataProgress(2);
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    const determineDeviceStatus = function determineDeviceStatus(storeDeviceHealth) {
      if (!storeDeviceHealth) {
        return {
          network: true,
          server: true,
          register: true,
          pc: true,
          totalVeloClouds: undefined,
          healthyVeloClouds: undefined,
          criticalVeloClouds: undefined,
          totalSwitchs: undefined,
          healthySwitchs: undefined,
          criticalSwitchs: undefined,
          totalServers: undefined,
          criticalServers: undefined,
          healthyServers: undefined,
          totalRegisters: undefined,
          healthyRegisters: undefined,
          criticalRegisters: undefined,
          totalPCs: undefined,
          healthyPCs: undefined,
          criticalPCs: undefined,
        };
      }

      const {
        totalServers,
        criticalServers,
        criticalRegisters,
        totalRegisters,
        criticalPCs,
        totalPCs,
        criticalSwitchs,
        totalSwitchs,
        criticalVeloClouds,
        totalVeloClouds,
        healthyRegisters,
        healthyServers,
        healthyPCs,
        healthySwitchs,
        healthyVeloClouds,
      } = storeDeviceHealth;

      let isDeviceCritical = {
        network: false,
        server: criticalServers >= totalServers ? true : false,
        register: criticalRegisters >= totalRegisters ? true : false,
        pc: criticalPCs >= totalPCs ? true : false,
        totalVeloClouds: totalVeloClouds,
        healthyVeloClouds: healthyVeloClouds,
        criticalVeloClouds: criticalVeloClouds,
        totalSwitchs: totalSwitchs,
        healthySwitchs: healthySwitchs,
        criticalSwitchs: criticalSwitchs,
        totalServers: totalServers,
        criticalServers: criticalServers,
        healthyServers: healthyServers,
        totalRegisters: totalRegisters,
        healthyRegisters: healthyRegisters,
        criticalRegisters: criticalRegisters,
        totalPCs: totalPCs,
        healthyPCs: healthyPCs,
        criticalPCs: criticalPCs,
      };

      if (criticalSwitchs >= totalSwitchs || criticalVeloClouds >= totalVeloClouds) {
        isDeviceCritical.network = true;
      }

      return isDeviceCritical;
    };

    if (storeDataReady && deviceHealthCountDataReady) {
      console.log(geo);
      let filteredStores = storeData.filter(
        data =>
          data.Geo.toUpperCase() === geo.toUpperCase() &&
          data.TradingStatus === 3 &&
          data.BusinessStatus === 'Open'
      );

      let mergedStoreDeviceHealth = [];
      filteredStores.forEach(store => {
        const storeDeviceCount = deviceHealthCountData.find(
          device => device.storeNumber === store.StoreNumber
        );
        mergedStoreDeviceHealth.push({...store, ...determineDeviceStatus(storeDeviceCount)});
        // if (storeDeviceCount === undefined) {
        //   console.log('store', store);
        // } else {
        //   mergedStoreDeviceHealth.push({...store, ...determineDeviceStatus(storeDeviceCount)});
        // }
      });

      if (mergedStoreDeviceHealth.length === 0) {
        setTableData([]);
      }
      setTableData(mergedStoreDeviceHealth);
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
  }, [
    geo,
    deviceHealthCountDataPending,
    deviceHealthCountDataReady,
    deviceHealthCountData,
    storeDataReady,
    storePendingStatus,
    storeData,
  ]);

  const history = useHistory();
  const goToStore = e => {
    e.preventDefault();
    const storeNumber = e.currentTarget.getAttribute('clickkey');
    let path = `/store/${storeNumber}/overview`;
    history.push(path);
  };

  return (
    <Table
      columns={columns}
      data={tableData}
      loaded={dataLoaded}
      progressCount={dataProgress}
      showAll={false}
      clickAction={goToStore}
      clickActionType={'StoreNumber'}
      paginationType={'Total Stores'}
      disablePagination={false}
      noDataText={'No Unable to Trade Stores to show at the moment.'}
    />
  );
}

export default StoreEventsTable;

export const mockCircleData = {
  circle: {
    series: [773, 142, 75, 34, 0],
    labels: ['Register', 'Server', 'PC', 'Network', 'Athlete Device'],
  },
};

export const mockTreeData = [
  {
    name: 'Register',
    id: 'Register',
    count: 838,
    checked: false,
    children: [
      {
        name: 'POS Peripheral',
        id: 'Register-POSPeripheral',
        count: 712,
        checked: false,
        children: [
          {
            name: 'Payment Terminal',
            id: 'Register-POSPeripheral-PaymentTerminal',
            count: 600,
            checked: false,
            children: null,
          },
          {
            name: 'Receipt Printer',
            id: 'Register-POSPeripheral-ReceiptPrinter',
            count: 47,
            checked: false,
            children: null,
          },
          {
            name: 'Barcode Scanner',
            id: 'Register-POSPeripheral-BarcodeScanner',
            count: 47,
            checked: false,
            children: null,
          },
          {
            name: 'Cash Drawer',
            id: 'Register-POSPeripheral-CashDrawer',
            count: 18,
            checked: false,
            children: null,
          },
        ],
      },
      {
        name: 'Application',
        id: 'Register-Application',
        count: 45,
        checked: false,
        children: [
          {
            name: 'Replication',
            id: 'Register-Application-Replication',
            count: 38,
            checked: false,
            children: null,
          },
          {
            name: 'Service Status',
            id: 'Register-Application-ServiceStatus',
            count: 7,
            checked: false,
            children: null,
          },
        ],
      },
      {
        name: 'Performance',
        id: 'Register-Performance',
        count: 44,
        checked: false,
        children: [
          {
            name: 'Memory',
            id: 'Register-Performance-Memory',
            count: 40,
            checked: false,
            children: null,
          },
          {
            name: 'Disk Space',
            id: 'Register-Performance-DiskSpace',
            count: 4,
            checked: false,
            children: null,
          },
        ],
      },
      {
        name: 'Connectivity',
        id: 'Register-Connectivity',
        count: 37,
        checked: false,
        children: [
          {
            name: 'Network Connection',
            id: 'Register-Connectivity-NetworkConnection',
            count: 37,
            checked: false,
            children: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Network',
    id: 'Network',
    count: 238,
    checked: false,
    children: [
      {
        name: 'Connectivity',
        id: 'Network-Connectivity',
        count: 238,
        checked: false,
        children: [
          {
            name: 'Network Connection',
            id: 'Network-Connectivity-NetworkConnection',
            count: 238,
            checked: false,
            children: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Server',
    id: 'Server',
    count: 165,
    checked: false,
    children: [
      {
        name: 'Performance',
        id: 'Server-Performance',
        count: 161,
        checked: false,
        children: [
          {
            name: 'Disk Space',
            id: 'Server-Performance-DiskSpace',
            count: 148,
            checked: false,
            children: null,
          },
          {
            name: 'Memory',
            id: 'Server-Performance-Memory',
            count: 8,
            checked: false,
            children: null,
          },
          {name: 'CPU', id: 'Server-Performance-CPU', count: 5, checked: false, children: null},
        ],
      },
      {
        name: 'Connectivity',
        id: 'Server-Connectivity',
        count: 4,
        checked: false,
        children: [
          {
            name: 'Network Connection',
            id: 'Server-Connectivity-NetworkConnection',
            count: 4,
            checked: false,
            children: null,
          },
        ],
      },
    ],
  },
  {
    name: 'PC',
    id: 'PC',
    count: 84,
    checked: false,
    children: [
      {
        name: 'Connectivity',
        id: 'PC-Connectivity',
        count: 54,
        checked: false,
        children: [
          {
            name: 'Network Connection',
            id: 'PC-Connectivity-NetworkConnection',
            count: 54,
            checked: false,
            children: null,
          },
        ],
      },
      {
        name: 'Application',
        id: 'PC-Application',
        count: 18,
        checked: false,
        children: [
          {
            name: 'Service Status',
            id: 'PC-Application-ServiceStatus',
            count: 18,
            checked: false,
            children: null,
          },
        ],
      },
      {
        name: 'Performance',
        id: 'PC-Performance',
        count: 12,
        checked: false,
        children: [
          {name: 'Memory', id: 'PC-Performance-Memory', count: 7, checked: false, children: null},
          {
            name: 'Disk Space',
            id: 'PC-Performance-DiskSpace',
            count: 5,
            checked: false,
            children: null,
          },
        ],
      },
    ],
  },
  {name: 'Athlete Device', id: 'AthleteDevice', count: 0, checked: false, children: null},
];

export const mockTopEvents = [
  {
    device: 'PC',
    eventCount: 37,
    date: '01/02/2022',
    storesCount: 8,
    url: '#',
  },
  {
    device: 'Register',
    eventCount: 87,
    date: '01/22/2022',
    storesCount: 18,
    url: '#',
  },
  {
    device: 'Server',
    eventCount: 5,
    date: '01/05/2022',
    storesCount: 2,
    url: '#',
  },
];

export const mockTableEvents = [
  {
    category: 'POS Peripheral',
    subCategory: 'Payment Terminal',
    subject: '1Terminal API',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Payment Terminal',
    subject: 'API Association',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Connection',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Connection',
    store: '41 Williamsburg VA',
    date: '11/18',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Configuration',
    store: '325 Soho',
    date: '12/04',
    options: 'view more',
  },
  {
    category: 'Connectivity',
    subCategory: 'Network Connection',
    subject: 'Network Connection',
    store: '325 Soho',
    date: '12/04',
    options: 'view more',
  },
  {
    category: 'Connectivity',
    subCategory: 'Network Connection',
    subject: 'Network Connection',
    store: '325 Soho',
    date: '01/05',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Payment Terminal',
    subject: 'Terminal API',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Payment Terminal',
    subject: 'API Association',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Connection',
    store: '41 Williamsburg VA',
    date: '01/22',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Connection',
    store: '41 Williamsburg VA',
    date: '11/18',
    options: 'view more',
  },
  {
    category: 'POS Peripheral',
    subCategory: 'Receipt Printer',
    subject: 'Configuration',
    store: '325 Soho',
    date: '12/04',
    options: 'view more',
  },
  {
    category: 'Connectivity',
    subCategory: 'Network Connection',
    subject: 'Network Connection',
    store: '325 Soho',
    date: '12/04',
    options: 'view more',
  },
  {
    category: 'Connectivity',
    subCategory: 'Network Connection',
    subject: 'Network Connection',
    store: '325 Soho',
    date: '01/05',
    options: 'view more',
  },
];

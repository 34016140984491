import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {NavLink} from 'react-router-dom';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import TextSkeleton from '../Skeletons/TextSkeleton';
import {useSelector} from 'react-redux';
import {getFullNameFromGeo} from '../../utils/utils';

const StyledNavCrumb = styled.div``;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  li {
    padding-left: 6px;
    margin-bottom: 0px;
  }
  li:last-of-type > a {
    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${props => props.theme.navText};
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSeparator = styled(ChevronRightRoundedIcon)`
  color: ${props => props.theme.navText};
`;

const initialPath = [
  {
    url: '/',
    name: 'Tech Insights',
  },
];

function NavCrumb(props) {
  const {className, match, breadCrumb} = props;

  const storeInfo = useSelector(state => state.data.app.storeInfo.data);

  const [crumbs, setCrumbs] = useState(initialPath);

  useEffect(() => {
    if (match !== undefined) {
      const urlArray = match.url.split('/');

      if (urlArray[1] === 'dashboard' && urlArray[2] !== undefined) {
        setCrumbs([
          ...initialPath,
          {
            url: match.url,
            name: getFullNameFromGeo(urlArray[2]),
          },
        ]);
      } else if (urlArray[1] === 'store') {
        //store page uses a custom url generator since it needs to display the stores geo in the crumbs rather then the url path
        setCrumbs([
          ...initialPath,
          {
            url: '/dashboard/' + storeInfo.geo.toLowerCase(),
            name: getFullNameFromGeo(storeInfo.geo),
          },
          {
            url: '/store/' + urlArray[2],
            name: 'Store ' + urlArray[2],
          },
        ]);
      } else if (urlArray[1] === 'nst') {
        //store page uses a custom url generator since it needs to display the stores geo in the crumbs rather then the url path
        setCrumbs([
          ...initialPath,
          {
            url: '/dashboard/' + storeInfo.geo.toLowerCase(),
            name: getFullNameFromGeo(storeInfo.geo),
          },
          {
            url: '/nst/' + urlArray[2],
            name: 'Store ' + urlArray[2],
          },
        ]);
      } else if (urlArray[1] === 'rrp') {
        setCrumbs([
          ...initialPath,
          {
            url: '/rrp',
            name: 'RRP',
          },
        ]);
      } else {
        setCrumbs([
          ...initialPath,
          {
            url: match.url,
            name: urlArray[1] ? urlArray[1] : 'Error',
          },
        ]);
      }
    } else {
      setCrumbs([
        ...initialPath,
        {
          url: '/settings',
          name: 'Not Set',
        },
      ]);
    }
  }, [match, storeInfo]);

  return (
    <StyledNavCrumb className={className}>
      {breadCrumb ? (
        <StyledBreadcrumbs separator={<StyledSeparator fontSize="small" />} aria-label="breadcrumb">
          {crumbs.map((crumb, index) => {
            return crumbs.length === index + 1 ? (
              <StyledNavLink key={crumb.url} to={crumb.url} disabled>
                {crumb.name}
              </StyledNavLink>
            ) : crumb.name === 'fetching geo' ? (
              <TextSkeleton key="skeleton">
                <StyledNavLink key={crumb.url} to={crumb.url}>
                  {crumb.name}
                </StyledNavLink>
              </TextSkeleton>
            ) : (
              <StyledNavLink key={crumb.url} to={crumb.url}>
                {crumb.name}
              </StyledNavLink>
            );
          })}
        </StyledBreadcrumbs>
      ) : (
        ''
      )}
    </StyledNavCrumb>
  );
}

export default NavCrumb;

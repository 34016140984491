import React, {useState} from 'react';
import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/TextField';

const StyledOutlinedInput = styled(OutlinedInput)`
  transition: 200ms;
  .MuiOutlinedInput-root {
    background-color: ${props => props.theme.cardBg};
    color: ${props => props.theme.text};
    border-radius: 4px;
  }
  .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.indicatorBg};
  }
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.indicatorBg};
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiFormLabel-root {
    color: rgba(255, 255, 255, 0.5);
  }
  .MuiFormHelperText-root {
    color: rgba(255, 255, 255, 0.5);
  }
  .MuiFormHelperText-contained {
    margin-left: 0px;
  }
`;

export default function SwitchSearch(props) {
  return (
    <StyledOutlinedInput
      id="switchSearch"
      label="Switch Filter"
      type="search"
      variant="outlined"
      helperText="Mac Address, Device Name, Device Type, Manufacturer"
      value={props.value}
      onChange={e => {
        props.setSearchValue(e.target.value);
      }}
      autoComplete={'off'}
    />
  );
}

import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as SwooshLogo} from '../../assets/images/swoosh.svg';
import {NavLink} from 'react-router-dom';

const StyledNavHeader = styled(NavLink)`
  display: none;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 22px;
  }
`;

const SyledTitle = styled(Typography)`
  font-family: 'Futura C XBd';
  font-size: 1.25rem;
  color: ${props => props.theme.navText};
`;

const StyledLogo = styled(SwooshLogo)`
  width: 50px;
  height: 50px;
  fill: ${props => props.theme.primary};
`;

function NavHeader() {
  return (
    <StyledNavHeader to={'/'}>
      <StyledLogo />
      <SyledTitle>INSIGHTS</SyledTitle>
    </StyledNavHeader>
  );
}

export default NavHeader;

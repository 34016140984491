import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import SGChip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import {FilterValues} from 'features/Inventories/interfaces/FilterOptions';

const StyledChipContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const StyledChip = styled(SGChip)`
  background-color: ${props => props.theme.disabledStateBlue};
  color: ${props => props.theme.activeStateBlue};
  text-transform: capitalize;
  font-family: 'Text Regular';
  font-size: 0.875rem;

  &:focus.MuiChip-deletable {
    background-color: ${props => props.theme.disabledStateBlue};
  }
  &.uppercase {
    text-transform: uppercase;
  }
  .MuiSvgIcon-root {
    color: ${props => props.theme.activeStateBlue};
    margin-right: 8px;
  }
  .MuiChip-deleteIcon {
    height: 1.125rem;
    width: 1.125rem;
  }

  &:hover .MuiSvgIcon-root {
    opacity: 0.5;
  }
`;

const renderChips = (
  filterValues: FilterValues,
  selectedFilters: string[],
  handleFilterChange: (value: string[]) => void
) => {
  const allKeys = Object.keys(filterValues);
  const filteredKeys = allKeys.filter(key => selectedFilters.includes(key));

  return filteredKeys.map(key => (
    <StyledChip
      key={key}
      label={filterValues[key].label}
      id={key}
      onDelete={() => {
        handleFilterChange([key]);
      }}
      deleteIcon={<CloseIcon />}
    />
  ));
};

interface ChipProps {
  category: string;
  selectedFilters: string[];
  filterValues: FilterValues;
  handleFilterChange: (value: string[]) => void;
  className?: string;
}

function Chip({category, selectedFilters, filterValues, className, handleFilterChange}: ChipProps) {
  return (
    <StyledChipContainer className={className}>
      <SGBody variant="b4" noWrap>
        {category}
      </SGBody>
      {renderChips(filterValues, selectedFilters, handleFilterChange)}
    </StyledChipContainer>
  );
}

export default Chip;

const nodeIds = [
  'Register-POSPeripheral-PaymentTerminal',
  'Register-POSPeripheral-ReceiptPrinter',
  'Register-POSPeripheral-BarcodeScanner',
  'Register-POSPeripheral-CashDrawer',
  'Register-POSPeripheral',
  'Register-Application-Replication',
  'Register-Application-ServiceStatus',
  'Register-Application',
  'Register-Performance-Memory',
  'Register-Performance-DiskSpace',
  'Register-Performance',
  'Register-Connectivity-NetworkConnection',
  'Register-Connectivity',
  'Register',
  'Network-Connectivity-NetworkConnection',
  'Network-Connectivity',
  'Network',
  'Server-Performance-DiskSpace',
  'Server-Performance-Memory',
  'Server-Performance-CPU',
  'Server-Performance',
  'Server-Connectivity-NetworkConnection',
  'Server-Connectivity',
  'Server',
  'PC-Connectivity-NetworkConnection',
  'PC-Connectivity',
  'PC-Application-ServiceStatus',
  'PC-Application',
  'PC-Performance-Memory',
  'PC-Performance-DiskSpace',
  'PC-Performance',
  'PC',
  'AthleteDevice',
];

const deviceIds = ['Register', 'Network', 'Server', 'PC', 'Athlete Device'];

const randomNumber = function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const generateTrendLineCounts = function generateStockLikeCounts(
  min,
  max,
  volatility,
  preCount = 0
) {
  const rnd = Math.random() - 0.5; // generate number, 0 <= x < 1.0
  const changePercent = (volatility / 100) * rnd;

  const changeAmount = preCount * changePercent;
  const newCount = preCount + changeAmount;

  if (newCount <= 0) {
    return randomNumber(min, volatility);
  } else if (newCount > max) {
    return preCount;
  } else {
    return Math.ceil(newCount);
  }
};

const generateArray = function generateArrayFromAllNodes(days, nodeNames) {
  const dateNow = Date.now();
  const epochDay = 86400000;

  let results = [];

  nodeNames.map(item => {
    let objectbuild = {
      name: item,
      data: [],
    };

    for (let i = 0; i < days; i++) {
      const dateTime = dateNow - epochDay * i;
      let count;
      if (i === 0) {
        count = randomNumber(0, 200);
      } else if (i > 0) {
        count = generateTrendLineCounts(0, 200, 40, objectbuild.data[i - 1][1]);
      } else {
        count = 999;
      }
      objectbuild.data.push([dateTime, count]);
    }

    results.push(objectbuild);
  });
  // 1644192000000 - mock epoch
  // 1519211809934

  return results;
};

export default generateArray(180, nodeIds);

import SwitchResponseData from '../interfaces/response/SwitchResponseData';
import {VlanLegendData} from '../interfaces/SwitchConnectivityState';
import distributionCenterLegendColors from '../VLANLegend/DCLegendColors';

interface FormatLegedData {
  legendColorsInUse: Record<string, string>;
  legendData: VlanLegendData[];
}

function formatLegedData(apiData: SwitchResponseData): FormatLegedData {
  let legendColorsInUse: Record<string, string> = {};

  const legendData: VlanLegendData[] = apiData.vlanLegendItems.map(legendItem => {
    if (legendItem.color !== null) return {...legendItem, isSelected: false};

    let color: string | null = legendItem.color;

    if (legendColorsInUse[legendItem.vlanNumber] === undefined) {
      const legendColorSize: number = Object.keys(legendColorsInUse).length;
      color = distributionCenterLegendColors[legendColorSize];
      legendColorsInUse = {...legendColorsInUse, [legendItem.vlanNumber]: color};
    } else if (legendColorsInUse[legendItem.vlanNumber] !== undefined) {
      color = legendColorsInUse[legendItem.vlanNumber];
    }

    return {
      ...legendItem,
      color,
      isSelected: false,
    };
  });

  let defaultLegendPorts: VlanLegendData[] = [
    {
      vlanNumber: 'unknown',
      shortDescription: 'Unknown, not in DB',
      color: 'outline',
      isSelected: false,
    },
    {
      vlanNumber: 'Plug Icon',
      shortDescription: 'Device connected to port',
      color: '#ffffff',
      isSelected: false,
    },
  ];

  return {
    legendColorsInUse,
    legendData: [...legendData, ...defaultLegendPorts],
  };
}

export default formatLegedData;

import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiServerNetwork, mdiFileExportOutline} from '@mdi/js';

export enum HeaderIconTypes {
  Switch = 'SWITCH',
  Inventory = 'INVENTORY',
}

export enum HeaderColorTypes {
  Default = 'default',
  Red = 'red',
}

interface HeaderIconProps {
  color: HeaderColorTypes;
  icon: HeaderIconTypes;
}

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 10px;
  margin-right: 1rem;
  background-color: ${props => props.theme.activeStateBlue}33;

  svg {
    color: ${props => props.theme.activeStateBlue};
  }

  &.red {
    background-color: ${props => props.theme.red}33;
    svg {
      color: ${props => props.theme.red};
    }
  }
`;

const iconSelected = (icon: HeaderIconTypes) => {
  if (icon === HeaderIconTypes.Switch) {
    return <Icon path={mdiServerNetwork} size={1} />;
  } else if (icon === HeaderIconTypes.Inventory) {
    return <Icon path={mdiFileExportOutline} size={1} />;
  } else {
    return <Icon path={mdiServerNetwork} size={1} />;
  }
};

const colorSelected = (color: HeaderColorTypes) => {
  return color;
};

function HeaderIcon({color, icon}: HeaderIconProps) {
  const selectedIcon = iconSelected(icon);
  const selectedColor = colorSelected(color);
  return <StyledIconContainer className={selectedColor}>{selectedIcon}</StyledIconContainer>;
}

export default HeaderIcon;

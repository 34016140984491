import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  UNKNOWN,
} = AlertSeverityEnum;

export default function switchingHardwareProvisionedAlertValue(switchProvisioned: string[]): IAlert {
  if (switchProvisioned === null) {
    return defaultNullAlert(['switchProvisioned']);
  }
  try {
    const hardwareModelNumberToMatch = 9300;
    let hardwareMatched: boolean = false;

    switchProvisioned.forEach(hardware => {
      const modelNumber = hardware.match(/\d{4}/);
      if (modelNumber !== null && parseInt(modelNumber[0]) >= hardwareModelNumberToMatch) {
        hardwareMatched = true;
      }
    })

    if (!hardwareMatched) {
      return {
        value: MINOR,
        issue: ['Switching Hardware does not meet standard.'],
        effect: ['Hardware may be unable to meet store functional requirements, and may be at risk for end-of-life issues such as limited functional support or limited security vulnerability support.'],
        solution: [`Consider upgrading to meet Switching Hardware standard of ${hardwareModelNumberToMatch}.`],
      };
    }

    return {
      value: HEALTHY,
      issue: [],
      effect: [],
      solution: [],
    };
  } catch {
    return {
      value: UNKNOWN,
      issue: ['There was an error in switchingHardwareProvisionedAlertValue.js'],
      effect: ['When parsing through the returned api data the catch block was intitiated.'],
      solution: ['Check the file switchingHardwareProvisionedAlertValue.js for values the function is not expecting.'],
    };
  }
}

import React, {useEffect, useReducer} from 'react';
import styled from 'styled-components';
import {
  reducer,
  initialState,
  actionSelectTab,
  actionUpdateSelectedFilters,
  actionUpdateDeviceData,
} from '../features/Inventories/reducer/InventoryReducer';
import Layout from './Layout';
import LayoutHeader, {LayoutHeaderIconTypes} from 'components/LayoutHeader';
import Container from 'components/Container';
import Grid from '@material-ui/core/Grid';
import Inventories, {useParamsInvetory, useInventories} from 'features/Inventories';
import useSiteInfo from 'components/SiteIDSearch/hooks/useSiteInfo';
import HeaderTabs from 'features/Inventories/components/HeaderTabs';

const StyledLayout = styled(Layout)`
  .header-root {
    margin-bottom: 24px;
  }
  @media (min-width: 600px) {
    .header-root {
      margin-bottom: 24px;
    }
  }
`;

function Inventory() {
  const {
    tab,
    filters,
    handleFilterChange,
    handleClearFilters,
    handleTabChange,
  } = useParamsInvetory();

  const [state, dispatch] = useReducer(reducer, initialState);

  const {sites, isSiteStoreMerged} = useSiteInfo();

  const {data, pending} = useInventories({
    geo: state.geoToFetch,
  });

  useEffect(() => {
    dispatch(actionSelectTab(tab));
  }, [tab]);

  useEffect(() => {
    dispatch(actionUpdateSelectedFilters(filters));
  }, [filters]);

  useEffect(() => {
    if (!pending && data !== null && isSiteStoreMerged) {
      dispatch(actionUpdateDeviceData(data, sites));
    }
  }, [data, pending, sites, isSiteStoreMerged]);

  return (
    <StyledLayout
      headerContent={
        <>
          <LayoutHeader title={'Inventories'} icon={LayoutHeaderIconTypes.Inventory} />
          <HeaderTabs selectedTab={tab} handleTabChange={handleTabChange} />
        </>
      }
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Inventories
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
              selectedTab={tab}
              invState={state}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledLayout>
  );
}

export default Inventory;

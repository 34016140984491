import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import IAlert from 'features/RRP/interfaces/IAlert';
import RRPAlertModal from './RRPAlertModal';

interface AlertCellProps {
  value: string | number | boolean;
  alert: IAlert;
}

const StyledRPCell = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  line-height: 0;
`;

const StyledBody = styled(SGBody)`
  margin-left: 0.25rem;
`;

export default function AlertCell({value, alert}: AlertCellProps) {
  return (
    <StyledRPCell>
      {alert.value > 0 && <RRPAlertModal alert={alert} />}
      <StyledBody>{value}</StyledBody>
    </StyledRPCell>
  );
}

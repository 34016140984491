import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchServicesByStore, fetchStoreServices} from 'redux/actions';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListContainer from '../StoreTransferList/components/ListContainer';
import ListItem from '../StoreTransferList/components/ListItem';
import ClearAll from '../StoreTransferList/components/ClearAll';
import TransferListSearch from '../Search/TransferListSearch';
import filterServicesByInput from './ServiceSearchFilter';
import {Stores} from 'interfaces/Stores';
import {
  Services,
  ServiceDictionary,
  Service,
  serviceArraysAreEqual,
  getServiceDisplayName,
} from './interfaces/Services';
import {StoreAction} from './types/StoreAction';
import {StoreConfigStatus} from './types/StoreConfigStatus';
import SGButton from 'components/CTA/SGButton';
import RectSkeleton from 'components/Skeletons/RectSkeleton';
import {isDeviceType} from './types/DeviceType';
import {useOktaAuth} from '@okta/okta-react';
import getStoreConfigStatus from './getStoreConfigStatus';
import getFilterableServices from './getFilterableServices';
import {getUniqueServices} from './getUniqueServices';

// check white space

//#region styled components
const StyledServiceTransferList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
`;

const StyledSkeletonContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
`;

const StyledSkeleton = styled(RectSkeleton)`
  height: 220px;
  width: 100%;
  margin-top: 8px;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 12px;
`;
//#endregion styled components

//#region interface
export interface ServiceTransferListProps {
  storeAction: StoreAction;
  selectedStores: Stores;
  selectedServices: Services;
  setSelectedServices: (value: Services) => void;
  setStoreConfigStatus: (value: StoreConfigStatus) => void;
  setSelectedStoresWithoutServices: (value: Stores) => void;
  setUpdatedServicesByStore: (value: ServiceDictionary) => void;
}
//#endregion interface

//#region functions
const logServiceWithoutValidDeviceType: (service: Service) => void = (service: Service) => {
  if (!isDeviceType(service.deviceType)) {
    console.error(
      `Service ${service.id} does not have a valid device type. Device type: ${service.deviceType}`
    );
  }
};
//#endregion functions

function ServiceTransferList({
  storeAction,
  selectedStores,
  selectedServices,
  setSelectedServices,
  setStoreConfigStatus,
  setSelectedStoresWithoutServices,
  setUpdatedServicesByStore,
}: ServiceTransferListProps) {
  const dispatch = useDispatch();
  const {oktaAuth} = useOktaAuth();
  //#region selectors
  const allServices: Services = useSelector(
    (state: any) => state.storeService.api.storeServices.data
  );
  const isAllServicesLoaded: boolean = useSelector(
    (state: any) => state.storeService.api.storeServices.dataRetrieved
  );
  const servicesByStore: ServiceDictionary = useSelector(
    (state: any) => state.storeService.api.servicesByStore.data
  );
  const isServicesByStoreLoaded: boolean = useSelector(
    (state: any) => state.storeService.api.servicesByStore.dataRetrieved
  );
  //#endregion selectors

  const [filterableServices, setFilterableServices] = useState<Services>(selectedServices);
  const [filteredServices, setFilteredServices] = useState<Services>([]);

  //#region useEffect
  // Set statuses and filterable services as allServices and servicesByStore are loaded.
  useEffect(() => {
    if (isServicesByStoreLoaded) {
      const selectedStoresWithoutServices: Stores = selectedStores.filter(
        store => servicesByStore[store.SiteId].length === 0
      );

      const storeConfigStatus: StoreConfigStatus = getStoreConfigStatus(
        selectedStoresWithoutServices,
        selectedStores
      );

      setStoreConfigStatus(storeConfigStatus);
      setSelectedStoresWithoutServices(selectedStoresWithoutServices);

      if (isAllServicesLoaded || storeAction === 'remove') {
        const filterableServices = getFilterableServices(
          storeAction,
          servicesByStore,
          allServices,
          storeConfigStatus,
          selectedStores,
          selectedStoresWithoutServices
        );
        setFilterableServices(filterableServices);
        setFilteredServices(filterableServices);
        setUpdatedServicesByStore(servicesByStore);
      }
    }
  }, [
    storeAction,
    selectedStores,
    servicesByStore,
    allServices,
    isServicesByStoreLoaded,
    isAllServicesLoaded,
    setSelectedStoresWithoutServices,
    setStoreConfigStatus,
    setUpdatedServicesByStore,
  ]);

  // Refilter selected services when filterable services changes.
  useEffect(() => {
    const _filteredSelectedServices: Services = selectedServices.filter(selectedService =>
      filterableServices.map(service => service.id).includes(selectedService.id)
    );
    if (!serviceArraysAreEqual(_filteredSelectedServices, selectedServices)) {
      setSelectedServices(_filteredSelectedServices);
    }
  }, [selectedServices, filterableServices, setSelectedServices]);

  // Log services with invalid device types
  useEffect(() => {
    if (isAllServicesLoaded && isServicesByStoreLoaded) {
      allServices.forEach(service => {
        logServiceWithoutValidDeviceType(service);
      });
      getUniqueServices(servicesByStore).forEach(service => {
        logServiceWithoutValidDeviceType(service);
      });
    }
  }, [isAllServicesLoaded, isServicesByStoreLoaded, allServices, servicesByStore]);

  // Initialize and update updatedStoreServices
  useEffect(() => {
    if (isServicesByStoreLoaded) {
      let updatedServicesByStore: ServiceDictionary = {};
      if (storeAction === 'add') {
        for (const [siteId, currentServices] of Object.entries(servicesByStore)) {
          const currentServiceIds = currentServices.map(service => service.id);
          const nonDuplicateSelectedServices: Services = selectedServices.filter(
            selectedService => !currentServiceIds.includes(selectedService.id)
          );
          updatedServicesByStore[siteId] = [...currentServices, ...nonDuplicateSelectedServices];
        }
      } else {
        for (const [siteId, currentServices] of Object.entries(servicesByStore)) {
          const selectedServiceIds = selectedServices.map(service => service.id);
          updatedServicesByStore[siteId] = currentServices.filter(
            service => !selectedServiceIds.includes(service.id)
          );
        }
      }
      setUpdatedServicesByStore(updatedServicesByStore);
    }
  }, [
    isServicesByStoreLoaded,
    servicesByStore,
    storeAction,
    selectedServices,
    setUpdatedServicesByStore,
  ]);
  //#endregion useEffect

  //#region handles
  const handleUserInput = (input: string) => {
    if (isServicesByStoreLoaded && (isAllServicesLoaded || storeAction === 'remove')) {
      if (input.length > 0) {
        setFilteredServices(filterServicesByInput(input, filterableServices));
      } else if (input.length === 0) {
        setFilteredServices(filterableServices);
      }
    }
  };

  const handleAddService = (serviceId: string) => {
    const matchByServiceId = filteredServices.filter(service => service.id === serviceId);
    setSelectedServices([...selectedServices, ...matchByServiceId]);
  };

  const handleRemoveService = (serviceId: string) => {
    setSelectedServices(selectedServices.filter(service => service.id !== serviceId));
  };

  const handleClearServices = () => {
    setSelectedServices([]);
  };
  //#endregion handles
  return (
    <StyledServiceTransferList>
      <ListContainer variant={'left'} isServiceManager={true}>
        <TransferListSearch
          id="service-transfer-list-search"
          handleUserInput={handleUserInput}
          placeholderText="Search Service Name or Device Type"
          className="service-manager"
        />
        {isServicesByStoreLoaded && (isAllServicesLoaded || storeAction === 'remove') ? (
          <List>
            {filteredServices.map(
              service =>
                !selectedServices.includes(service) && (
                  <ListItem
                    icon={'add'}
                    handleClick={() => handleAddService(service.id)}
                    text={getServiceDisplayName(service)}
                    key={`${service.id}`}
                  />
                )
            )}
          </List>
        ) : (
          <StyledSkeletonContainerDiv>
            <StyledSkeleton />
            <StyledButtonContainerDiv>
              <div>Services are not loaded yet.</div>
              <SGButton
                onClick={() => {
                  const accessToken = oktaAuth.getAccessToken();
                  dispatch(fetchStoreServices(accessToken));
                  dispatch(
                    fetchServicesByStore(
                      accessToken,
                      selectedStores.map(store => store.SiteId)
                    )
                  );
                }}
              >
                Try again
              </SGButton>
            </StyledButtonContainerDiv>
          </StyledSkeletonContainerDiv>
        )}
      </ListContainer>
      <ListContainer variant={'right'} isServiceManager={true}>
        <ClearAll handleClick={handleClearServices} subtitle={`Services to ${storeAction}`} />
        <List>
          {selectedServices.map(service => (
            <ListItem
              icon={'minus'}
              handleClick={() => handleRemoveService(service.id)}
              text={getServiceDisplayName(service)}
              key={`${service.id}`}
            />
          ))}
        </List>
      </ListContainer>
    </StyledServiceTransferList>
  );
}

export default ServiceTransferList;

import React from 'react';
import Tab from './components/Tab';
import type Tabs from '../../interfaces/Tabs';

interface HeaderTabsProps {
  selectedTab: string;
  handleTabChange: (tab: Tabs) => void;
}

function HeaderTabs({selectedTab, handleTabChange}: HeaderTabsProps) {
  return <Tab selectedTab={selectedTab} handleTabChange={handleTabChange} />;
}

export default HeaderTabs;

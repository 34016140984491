import React from 'react';
import styled from 'styled-components';

interface HeaderTabsProps {
  setTabSelected: (tab: string) => void;
  tabSelected: string;
  options?: JSX.Element;
}

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTabContainer = styled.div``;

const StyledTab = styled.button`
  font-size: 24px;
  color: ${props => props.theme.navText};
  text-decoration: none;
  font-family: 'Trade Gothic Bold Condensed';
  font-weight: 300;
  background-color: transparent;
  border: none;
  padding: 0;
  border-bottom: 3px solid transparent;
  margin-right: 3rem;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.navTextActive};
    border-bottom: 3px solid ${props => props.theme.navTextActive};
  }
  &.active {
    color: ${props => props.theme.navTextActive};
    border-bottom: 3px solid ${props => props.theme.navTextActive};
  }
  &.disabled {
    color: ${props => props.theme.navDisabled};
    &:hover {
      cursor: default;
    }
  }
`;

const StyledOptions = styled.div``;

function HeaderTabs({setTabSelected, tabSelected, options}: HeaderTabsProps) {
  const handleClick = (value: string) => {
    setTabSelected(value);
  };

  return (
    <StyledHeaderContainer>
      <StyledTabContainer>
        <StyledTab
          onClick={() => handleClick('custom')}
          className={tabSelected === 'custom' ? 'active' : ''}
        >
          CUSTOM VIEW
        </StyledTab>
        <StyledTab
          onClick={() => handleClick('na')}
          className={tabSelected === 'na' ? 'active' : ''}
        >
          NA
        </StyledTab>
        <StyledTab
          onClick={() => handleClick('emea')}
          className={tabSelected === 'emea' ? 'active' : ''}
        >
          EMEA
        </StyledTab>
        <StyledTab
          onClick={() => handleClick('gc')}
          className={tabSelected === 'gc' ? 'active' : ''}
        >
          GC
        </StyledTab>
        <StyledTab
          onClick={() => handleClick('apla')}
          className={tabSelected === 'apla' ? 'active' : ''}
        >
          APLA
        </StyledTab>
      </StyledTabContainer>
      <StyledOptions>{options}</StyledOptions>
    </StyledHeaderContainer>
  );
}

export default HeaderTabs;

import React, {ChangeEvent} from 'react';
import styled, {useTheme} from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import SGBody from 'components/Typography/SGBody';

const StyledFormControl = styled(FormControl)`
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-template-rows: auto;
  align-items: center;
  padding: 0px;

  &.disabled {
    pointer-events: none;
    opacity: 0.35;
  }
`;

const StyledInput = styled(InputBase)`
  background-color: transparent;
  color: ${props => props.theme.grey};

  padding: 0px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 18px;
  width: 214px;

  &.search-active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .MuiSelect-icon {
    color: ${props => props.theme.grey};
  }
  .MuiSelect-root {
    border-radius: 10px;
    border: 1px solid ${props => props.theme.grey};
    padding: 6px 10px;
  }
  &.Mui-focused .MuiSelect-root {
    border: 1px solid ${props => props.theme.focusText};
  }
  &.Mui-disabled {
    color: ${props => props.theme.grey};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  display: none;
  color: ${props => props.theme.text};
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.875rem;
  color: ${props => props.theme.disabledStateBlue};
  background-color: transparent;
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;

  &.Mui-selected {
    opacity: 0.7;
  }

  &.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${props => props.theme.disabledStateBlue};
    color: ${props => props.theme.activeStateBlue};
  }

  &:hover {
    opacity: 1;
  }
`;

interface MenuItem {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export interface SelectItemsProps {
  selectLabel: string;
  value: string;
  id: string;
  onChange: (event: ChangeEvent<{value: unknown}>) => void;
  menuItems: MenuItem[];
  isDisabled?: boolean;
}

function SelectItems({selectLabel, value, id, onChange, menuItems, isDisabled}: SelectItemsProps) {
  const theme: any = useTheme();

  return (
    <StyledFormControl className={isDisabled ? 'disabled' : ''}>
      <SGBody variant="b6">{selectLabel}</SGBody>
      <StyledInputLabel htmlFor="site-id-setting-brand-label">{selectLabel}</StyledInputLabel>
      <Select
        labelId="site-id-setting-brand-label"
        id={id}
        value={value}
        onChange={onChange}
        input={<StyledInput />}
        defaultValue={menuItems[0].value}
        disabled={isDisabled}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: theme.cardBg,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
      >
        {menuItems.map((item: MenuItem) => (
          <StyledMenuItem key={item.value} value={item.value} disabled={item.isDisabled}>
            {item.label}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default SelectItems;

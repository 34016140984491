import IAlert from "features/RRP/interfaces/IAlert";
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import defaultNullAlert from "./utility/defaultNullAlert";

const {
  HEALTHY,
  MINOR,
  UNKNOWN,
} = AlertSeverityEnum;

export default function SDWANHardwareProvisionedAlertValue(SDWANHardwareProvisioned: string): IAlert {
  if (SDWANHardwareProvisioned === null || SDWANHardwareProvisioned === undefined || typeof SDWANHardwareProvisioned !== 'string') {
    return defaultNullAlert(['SDWANHardwareProvisioned']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  const hardwareToMatch = 'VCE-620N';
  const { name, model, version } = {
    name: 'vce',
    model: 620,
    version: 'n',
  };
  // const text = 'edge520-v';
  const hardwareModelNumber = SDWANHardwareProvisioned.match(/\d+/g);
  if (hardwareModelNumber?.length === 0 || hardwareModelNumber === null) {
    return {
      value: UNKNOWN,
      issue: ['The SDWANHardwareProvisioned returned value does not contain a model number of the device.'],
      effect: [`Unable to compare the returned value: ${SDWANHardwareProvisioned} to determine if it meets the SDWAN Hardware standard of VCE-620N or higher.`],
      solution: ['Check to see if the returned value is correct. If the value is correct please contact us to implement a new comparison solution.'],
    }
  } else if (hardwareModelNumber.length > 1) {
    return {
      value: UNKNOWN,
      issue: ['The SDWANHardwareProvisioned returned value contains multiple number sets.'],
      effect: [`Unable to compare the returned value: ${SDWANHardwareProvisioned} to determine if it meets the SDWAN Hardware standard of VCE-620N or higher.`],
      solution: ['Check to see if the returned value is correct. If the value is correct please contact us to implement a new comparison solution.'],
    }
  } else if (parseInt(hardwareModelNumber[0]) < model) {
    alert.value = MINOR;
    alert.issue.push('SDWAN Hardware does not meet standard model of VCE-620N or higher.');
    alert.effect.push('May cause slow or intermittently failing network connections for whole store, when paired with high SDWAN Hardware utilization.');
    alert.solution.push('Consider upgrading to meet SDWAN Hardware standard of VCE-620N or higher.');
  }
  return alert;
}

import React, {useState, useLayoutEffect} from 'react';
import styled from 'styled-components';
import Container from './Container';
import NavCrumb from './Navigation/NavCrumb';
import Search from './Search/Search';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';

const StyledHeader = styled.header`
  transition: 0.25s;
  position: absolute;
  top: 0;
  z-index: 4;
  width: calc(100%);
  overflow: visible;
  background-color: ${props => props.theme.navBg};
  margin-bottom: 24px;
  min-height: 4rem;
  &.show-bg {
    background-color: ${props => props.theme.navBg};
  }
  @media (min-width: 600px) {
    position: sticky;
    width: calc((100vw) - (100vw - 100%));
    max-width: calc((100vw) - 13.125rem);
    left: 13.125rem;
    margin-bottom: 48px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .flex-end {
    justify-content: flex-end;
    overflow: visible;
    position: relative;
  }
`;

function Header(props) {
  const {match, breadCrumb, disableMaxWidth, headerContent} = props;

  const navExpanded = useSelector(state => state.navigation);

  const [showBg, setShowBg] = useState(false);

  useLayoutEffect(() => {
    const handleScroll = () => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 20) {
        if (showBg !== true) {
          setShowBg(true);
        }
      } else {
        setShowBg(false);
      }
    };
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showBg]);

  return (
    <StyledHeader id={'nike'} className={(showBg ? 'show-bg ' : '') + 'header-root'}>
      <StyledContainer
        disableMaxWidth={disableMaxWidth}
        className={navExpanded ? 'nav-expanded ' : ''}
      >
        <Grid container spacing={0}>
          <Grid item xs className={'flex-row'}>
            <NavCrumb breadCrumb={breadCrumb} match={match} />
          </Grid>
          <Grid item xs className={'flex-row flex-end'}>
            <Search />
          </Grid>

          <Grid item xs={12}>
            {headerContent}
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledHeader>
  );
}

export default Header;

import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '../Button';
import Paper from '../Paper';
import Title from '../Typography/SGTitle';
import SubTitle from '../Typography/SGSubtitle';

const ContentBox = styled(Paper)`
  max-width: 50vw;
  position: relative;
  top: 25vh;
  left: 25vw;
`;

const StyledButtonContainer = styled.div`
  button {
    display: inline;
    margin-left: 0rem;
    margin-right: 0.5rem;
  }
`;

const AuthRequiredModal = ({authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin}) => {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal
      onClose={closeModal}
      open={authRequiredModalOpen}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ContentBox>
        <Title>Auth required</Title>
        <SubTitle>
          <p>Do you want to re-authenticate?</p>
        </SubTitle>

        <StyledButtonContainer>
          <Button onClick={closeModal}>No</Button>
          <Button onClick={confirmModal}>Yes</Button>
        </StyledButtonContainer>
      </ContentBox>
    </Modal>
  );
};
export default AuthRequiredModal;

import 'date-fns';
import React from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {historicalComparisonDateRange} from '../../../redux/actions';
import DateSelectorButton from '../../DateSelectorButton';
import ApexCharts from 'apexcharts';
import CustomDateRange from './CustomDateRange';

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function updateData(timeline) {
  const date = new Date();
  let days = 1;
  switch (timeline) {
    case 'today':
      days = 1;
      break;
    case 'one_week':
      days = 7;
      break;
    case 'two_weeks':
      days = 14;
      break;
    case 'one_month':
      days = 30;
      break;
    case 'three_months':
      days = 90;
      break;
    case 'six_months':
      days = 180;
      break;
    case 'one_year':
      days = 365;
      break;
    default:
  }

  const dateRange = {
    start: new Date(date.getTime() - days * 24 * 60 * 60 * 1000).getTime(),
    end: new Date().getTime(),
  };

  ApexCharts.exec('line-datetime', 'zoomX', dateRange.start, dateRange.end);

  return dateRange;
}

function DateTimeSelector() {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.componentHistoricalComparison.dateSelection);
  const handleClick = timeline => {
    const epochRange = updateData(timeline);
    dispatch(historicalComparisonDateRange(timeline));
  };

  return (
    <StyledButtonContainer>
      <DateSelectorButton
        selected={selected}
        value={'one_week'}
        onClick={() => handleClick('one_week')}
      >
        1w
      </DateSelectorButton>
      <DateSelectorButton
        selected={selected}
        value={'one_month'}
        onClick={() => handleClick('one_month')}
      >
        1m
      </DateSelectorButton>
      <DateSelectorButton
        selected={selected}
        value={'three_months'}
        onClick={() => handleClick('three_months')}
      >
        3m
      </DateSelectorButton>
      <DateSelectorButton
        selected={selected}
        value={'six_months'}
        onClick={() => handleClick('six_months')}
      >
        6m
      </DateSelectorButton>
      <DateSelectorButton
        selected={selected}
        value={'one_year'}
        onClick={() => handleClick('one_year')}
      >
        1y
      </DateSelectorButton>
      <CustomDateRange />
    </StyledButtonContainer>
  );
}

export default DateTimeSelector;

import React, {ReactNode} from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import TrunkPortTooltip from './TrunkPortTooltip';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';

const StyledLegendItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 1.5rem;
  column-gap: 6px;
`;

const StyledLegendItemButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 5px;
  &:hover {
    background: ${props => props.theme.disabledStateBlue}33;
    cursor: pointer;
    transition: 200ms;
  }
  &.selected {
    background: ${props => props.theme.disabledStateBlue}66;
  }
`;

const StyledSGBody = styled(SGBody)`
  && {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const StyledIcon = styled.div<{$color?: string}>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #f2f2f2;
  background: ${props => props.$color};
`;

const StyledRainbowIcon = styled(StyledIcon)`
  background: linear-gradient(
    rgb(251, 42, 42) 0%,
    rgb(255, 129, 25) 10%,
    rgb(239, 39, 150) 20%,
    rgb(201, 51, 223) 30%,
    rgb(125, 62, 225) 41%,
    rgb(55, 188, 191) 52%,
    rgb(95, 231, 163) 63%,
    rgb(126, 227, 74) 73%,
    rgb(58, 76, 153) 86%,
    rgb(0, 212, 255) 100%
  );
`;

const StyledUnknownIcon = styled(StyledIcon)`
  box-shadow: inset 0 0 0 2px ${props => props.theme.text};
  background: transparent;
`;

const StyledSettingsInputHdmiIcon = styled(SettingsInputHdmiIcon)`
  color: ${props => props.theme.text};
  width: 1rem;
  height: 1rem;
  margin-left: -2px;
`;

const portLegend = (vlanNumber: string, shortDescription: string, color: string) => {
  const trunkPort: string[] = ['trunk port', 'trunk'];
  const useRainbowIcon: boolean = trunkPort.includes(vlanNumber.toLowerCase()) ? true : false;
  let portType = <StyledIcon $color={color} />;
  let toolTip: ReactNode | null = null;
  let vlanNumberDisplayText = vlanNumber;

  if (useRainbowIcon) {
    portType = <StyledRainbowIcon />;
    toolTip = <TrunkPortTooltip />;
  } else if (vlanNumber.toLowerCase() === 'unknown') {
    portType = <StyledUnknownIcon />;
    vlanNumberDisplayText = 'VLAN';
  } else if (vlanNumber.toLowerCase() === 'plug icon') {
    portType = <StyledSettingsInputHdmiIcon />;
  } else {
    portType = <StyledIcon $color={color} />;
  }

  return (
    <StyledLegendItemContainer>
      {portType}
      <StyledSGBody>
        <b>{vlanNumberDisplayText}</b> - {shortDescription}
      </StyledSGBody>
      {toolTip && toolTip}
    </StyledLegendItemContainer>
  );
};

interface VLANLegendItemProps {
  vlanNumber: string;
  shortDescription: string;
  color: string;
  isSelected: boolean;
  onLegendItemSelectionChanged: (vlanNumber: string, isSelected: boolean) => void;
}

export default function VLANLegendItem({
  vlanNumber,
  shortDescription,
  color,
  isSelected,
  onLegendItemSelectionChanged,
}: VLANLegendItemProps) {
  const legendItem = portLegend(vlanNumber, shortDescription, color);

  return (
    <StyledLegendItemButton
      onClick={() => onLegendItemSelectionChanged(vlanNumber, !isSelected)}
      className={isSelected ? 'selected' : ''}
    >
      {legendItem}
    </StyledLegendItemButton>
  );
}

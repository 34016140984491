import React, {useEffect, useCallback} from 'react';
import styled from 'styled-components';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import SGSubtitle from '../Typography/SGSubtitle';
import Grid from '@material-ui/core/Grid';
import {useOktaAuth} from '@okta/okta-react';
import Button from '@material-ui/core/Button';
import {userPageView} from '../../redux/actions';
import useUserLogs from './hooks/useUserLogs';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

//#region styled-components
const StyledPaper = styled(Paper)`
  &.hidden {
    display: none;
  }
  code {
    width: 100%;
    word-wrap: break-word;
    display: inline-block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  min-width: 190px;
  margin-left: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  color: ${props => props.theme.altText};
  background-color: ${props => props.theme.tableRow};
  text-decoration: none;
  text-transform: capitalize;
  display: block;
`;
//#endregion styled-components

function UserLog(props) {
  const {isSettings} = props;

  const dispatch = useDispatch();
  const {oktaAuth} = useOktaAuth();

  const themeSelected = useSelector(state => state.theme.select);
  const userInfo = useSelector(state => state.userInfo);
  const userPageHistory = useSelector(state => state.userInfo.pages);
  const payload = useSelector(state => state.userInfo.payload);
  const sessionId = useSelector(state => state.userInfo.sessionId);
  const admin = useSelector(state => state.userInfo.admin);

  let location = useLocation();
  const pathname = location.pathname;

  useUserLogs({
    pathname,
    sessionId,
    themeSelected,
    payload,
    isSettings,
  });

  //#region useEffect
  useEffect(() => {
    if (!isSettings) {
      dispatch(userPageView(pathname));
    }
  }, [isSettings, pathname, dispatch]);
  //#endregion useEffect

  return (
    <>
      {isSettings && (
        <StyledPaper className={isSettings ? '' : 'hidden'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PaperTitle>Log User Info</PaperTitle>
              <SGSubtitle className={'grey'}>ADMIN</SGSubtitle>
            </Grid>
            <Grid item xs={12}>
              {admin && (
                <StyledButton onClick={() => oktaAuth.handleAuthentication()}>
                  Handle Auth
                </StyledButton>
              )}
            </Grid>
            {admin && (
              <Grid item xs={12}>
                <PaperTitle>User Groups</PaperTitle>
                <code>{userInfo && userInfo?.user?.groups}</code>
              </Grid>
            )}
            {admin && (
              <Grid item xs={12}>
                <PaperTitle>History</PaperTitle>
                {userPageHistory.map((page, index) => (
                  <code key={index}>{page}</code>
                ))}
              </Grid>
            )}
          </Grid>
        </StyledPaper>
      )}
    </>
  );
}

export default UserLog;

import Geo from 'interfaces/Geo';
import filterOptions from 'features/Inventories/constants/FilterOptions';

function parseFiltersForGeos(selectedFilters: string | string[]): Geo[] {
  try {
    const allGeosKeys = Object.keys(filterOptions.geo.values);
    const filteredGeos = allGeosKeys.filter(geoKey => selectedFilters.includes(geoKey));
    return filteredGeos as Geo[];
  } catch (error) {
    return [] as Geo[];
  }
}

export default parseFiltersForGeos;

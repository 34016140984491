export const initialState = {
  dataRetrieved: false,
  pending: false,
  data: [],
  error: null,
  diminishedCapabilities: [],
  formatError: 0,
  totalEvents: 0,
};

const apiActiveEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ACTIVE_EVENT_DATA_PENDING':
      return {
        ...state,
        pending: true,
      };
    case 'GET_ACTIVE_EVENT_DATA_SUCCESS':
      return {
        ...state,
        pending: false,
        data: action.data,
        dataRetrieved: true,
      };
    case 'GET_ACTIVE_EVENT_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case 'FORMAT_ACTIVE_EVENT_DATA':
      let sortedData = state.data.sort(function (a, b) {
        return a.StoreNumber - b.StoreNumber;
      });

      let diminishedCapabilities = [];
      let riskCapabilities = [];

      let storeObj = {
        storeNumber: null,
        tradingStatus: null,
        network: 0,
        server: 0,
        register: 0,
        pc: 0,
        athleteDevice: 0,
        total: 0,
        storeName: '',
        geo: '',
        isKeyMarket: '',
      };

      sortedData.forEach(event => {
        if (storeObj.storeNumber === event.StoreNumber || storeObj.storeNumber === null) {
          storeObj.storeNumber = event.StoreNumber;
          storeObj.tradingStatus = event.TradingStatus;
          storeObj.storeName = event.StoreName;
          storeObj.geo = event.Geo;
        } else {
          // solves js pointing to same memory issue
          let pushedArray = JSON.parse(JSON.stringify(storeObj));
          diminishedCapabilities.push(pushedArray);

          storeObj = {
            storeNumber: event.StoreNumber,
            tradingStatus: event.TradingStatus,
            network: 0,
            server: 0,
            register: 0,
            pc: 0,
            athleteDevice: 0,
            total: 0,
            storeName: '',
            geo: '',
            isKeyMarket: '',
          };
        }

        if (event.DeviceType === 'Network') {
          storeObj.network += 1;
          storeObj.total += 1;
          state.totalEvents += 1;
        } else if (event.DeviceType === 'Server') {
          storeObj.server += 1;
          storeObj.total += 1;
          state.totalEvents += 1;
        } else if (event.DeviceType === 'Register') {
          storeObj.register += 1;
          storeObj.total += 1;
          state.totalEvents += 1;
        } else if (event.DeviceType === 'PC') {
          storeObj.pc += 1;
          storeObj.total += 1;
          state.totalEvents += 1;
        } else if (event.DeviceType === 'Athlete Device') {
          storeObj.athleteDevice += 1;
          storeObj.total += 1;
          state.totalEvents += 1;
        } else {
          state.formatError += 1;
        }
      });

      if (storeObj.total > 0) {
        // solves js pointing to same memory issue
        let pushedArray = JSON.parse(JSON.stringify(storeObj));
        diminishedCapabilities.push(pushedArray);
      }

      return {
        ...state,
        diminishedCapabilities: diminishedCapabilities,
        riskCapabilities: riskCapabilities,
      };
    case 'FORMAT_DEVICE_CATEGORY':
      let mockData = [
        {
          type: 'Category 1',
          count: 60,
        },
        {
          type: 'Category 2',
          count: 26,
        },
        {
          type: 'Category 3',
          count: 20,
        },
        {
          type: 'Category 4',
          count: 17,
        },
        {
          type: 'Category 5',
          count: 15,
        },
        {
          type: 'Category 6',
          count: 12,
        },
        {
          type: 'Category 7',
          count: 8,
        },
      ];
      return {
        ...state,
        topDeviceCategory: mockData,
      };
    case 'FORMAT_GEO_CATEGORY':
      return state;
    default:
      return state;
  }
};

export default apiActiveEventReducer;

export const getActiveEvents = state => state.data;
export const getActiveEventsPendingStatus = state => state.pending;
export const getActiveEventsError = state => state.error;

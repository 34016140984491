import {useState, useEffect} from 'react';
import config from '../../../oktaConfig';
import {useSelector} from 'react-redux';
import monthlyReportReducerRRP from '../components/monthlyReportReducerRRP';
import {useOktaAuth} from '@okta/okta-react';

function FetchMonthlyHook(date, selectedStores, geo) {
  const [isMonthlyDataLoaded, setIsMonthlyDataLoaded] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  const {authState, oktaAuth} = useOktaAuth();
  const storeInfoList = useSelector(state => state.data.app.searchMinimumStoreInfo?.data);

  useEffect(() => {
    const createParam = function createParam(date, selectedStores, geo) {
      // builds parameters for fetch
      let paramArray = [`date=${date}`];

      if (selectedStores.length > 0 && geo === 'custom') {
        let storeSiteId = selectedStores.map(store => store.SiteId);
        paramArray.push('siteids=' + storeSiteId.join(','));
      } else if (geo !== 'custom') {
        paramArray.push(`geo=${geo}`);
      }

      return '?' + paramArray.join('&');
    };

    setIsMonthlyDataLoaded(false);

    if (authState && authState.isAuthenticated && date !== null) {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      setIsMonthlyDataLoaded(false);
      fetch(
        config.resourceServer.insightsApiUrlRetailRecurringPhysicalReport +
          createParam(date, selectedStores, geo),
        {
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        }
      )
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(data => {
          setMonthlyData(monthlyReportReducerRRP(data, storeInfoList));
          setIsMonthlyDataLoaded(true);
        })
        .catch(err => {
          console.error(err);
        });

      return () => {
        controller.abort();
        setIsMonthlyDataLoaded(false);
      };
    } else if (authState.isAuthenticated && selectedStores.length === 0) {
      setMonthlyData([]);
      setIsMonthlyDataLoaded(true);
    } else {
      setMonthlyData([]);
      setIsMonthlyDataLoaded(false);
    }
  }, [date, selectedStores, geo, oktaAuth, storeInfoList, authState]);

  return {
    isMonthlyDataLoaded,
    monthlyData,
  };
}

export default FetchMonthlyHook;

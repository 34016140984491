import React from 'react';
import styled from 'styled-components';
import {uiToggleDebug} from '../../redux/actions';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import SGSubtitle from '../Typography/SGSubtitle';
import SGSwitch from '../CTA/SGSwitch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const StyledPaper = styled(Paper)`
  code {
    width: 100%;
    word-wrap: break-word;
    display: inline-block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

function DebugView() {
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.userInfo.admin);
  const debugViewState = useSelector(state => state.ui.debugView);

  return isAdmin ? (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperTitle>Debug Setting</PaperTitle>
          <SGSubtitle className={'grey'}>ADMIN</SGSubtitle>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={
                  <SGSwitch onClick={() => dispatch(uiToggleDebug())} checked={debugViewState} />
                }
                label={`Debug View ${debugViewState ? 'Enabled' : 'Disabled'}`}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SGSubtitle className={'grey'}>Build Type</SGSubtitle>
          {process.env.REACT_APP_BUILD}
          <SGSubtitle className={'grey'}>API Type</SGSubtitle>
          {process.env.REACT_APP_API}
          <SGSubtitle className={'grey'}>OKTA Type</SGSubtitle>
          {process.env.REACT_APP_OKTA}
        </Grid>
      </Grid>
    </StyledPaper>
  ) : null;
}

export default DebugView;

import React from 'react';
import styled from 'styled-components';

const StyledItemIndicator = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: auto;
  svg {
    font-size: 1.6rem;
    color: ${props => props.theme.tableText};
  }
  /* &.green, &.green svg {
        color: ${props => props.theme.green};
    }
    &.yellow, .yellow, &.yellow svg {
        color: ${props => props.theme.yellow};
    }
    &.orange, &.orange svg {
        color: ${props => props.theme.orange};
    }
    &.red, &.red svg {
        color: ${props => props.theme.red};
    } */
`;

const StyledCount = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 20px;
  border-radius: 10px;
  color: ${props => props.theme.indicatorText};
  background-color: ${props => props.theme.indicatorBg};
  font-size: 0.8rem;
  font-family: 'Trade Gothic Bold Condensed';

  ${StyledItemIndicator}.red & {
    background-color: ${props => props.theme.red};
  }
  ${StyledItemIndicator}.orange & {
    background-color: ${props => props.theme.orange};
  }
  ${StyledItemIndicator}.yellow & {
    background-color: ${props => props.theme.yellow};
  }
  ${StyledItemIndicator}.green & {
    background-color: ${props => props.theme.green};
  }
`;

function ItemIndicator(props) {
  const {className, children, count} = props;
  return (
    <StyledItemIndicator className={className}>
      {children}
      <StyledCount>{count}</StyledCount>
    </StyledItemIndicator>
  );
}

export default ItemIndicator;

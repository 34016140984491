import React, {useState} from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiVideoInputHdmi} from '@mdi/js';
import SwitchPortModal from './SwitchPortModal';
import {Port} from '../interfaces/SwitchConnectivityState';
import {fade} from '@material-ui/core/styles/colorManipulator'; // todo: change to alpha when updating MUI
import {PortHistoryPort} from '../interfaces/PortHistoryState';

const StyledPortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.text};
`;

const StyledPortNumber = styled.div`
  width: 32px;
  text-align: center;
  font-size: 0.75rem;
`;

const StyledPort = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: rgb(251, 42, 42);
  display: flex;
  justify-content: center;
  align-items: center;

  &.unknown-port {
    border: 2px solid #ffffff;
  }

  &.unknown-port.not-selected {
    border: 2px solid #ffffff33;
  }

  &.not-selected {
    opacity: 0.2;
  }
  &:hover {
    cursor: pointer;
  }
`;

interface SwitchPortProps {
  port: Port;
  portHistory: PortHistoryPort | null;
}
export default function SwitchPort({port, portHistory}: SwitchPortProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = (event: any) => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  const handleModalOpen = (event: any) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const portNumberLocation = port.portNumber % 2 === 0 ? 'bottom' : 'top';

  function getPortColor() {
    const trunkPort = ['trunk port', 'trunk'];
    const vlanNumber: string = port.vlanNumber ? port.vlanNumber.toLowerCase() : 'unknown';
    const useRainbowColor: boolean = trunkPort.includes(vlanNumber) ? true : false;
    let portColor: string = '';
    let portClass: string = '';

    if (useRainbowColor) {
      if (port.isSelected) {
        portColor = `linear-gradient(
          180deg,
          rgba(251, 42, 42, 1) 0%,
          rgba(255, 129, 25, 1) 10%,
          rgba(239, 39, 150, 1) 20%,
          rgba(201, 51, 223, 1) 30%,
          rgba(125, 62, 225, 1) 41%,
          rgba(55, 188, 191, 1) 52%,
          rgba(95, 231, 163, 1) 63%,
          rgba(126, 227, 74, 1) 73%,
          rgba(58, 76, 153, 1) 86%,
          rgba(0, 212, 255, 1) 100%
        )`;
      } else {
        portColor = `linear-gradient(
          180deg,
          rgba(251, 42, 42, .25) 0%,
          rgba(255, 129, 25, .25) 10%,
          rgba(239, 39, 150, .25) 20%,
          rgba(201, 51, 223, .25) 30%,
          rgba(125, 62, 225, .25) 41%,
          rgba(55, 188, 191, .25) 52%,
          rgba(95, 231, 163, .25) 63%,
          rgba(126, 227, 74, .25) 73%,
          rgba(58, 76, 153, .25) 86%,
          rgba(0, 212, 255, .25) 100%
        )`;
      }
    } else if (port.portColor === 'transparent') {
      portColor = 'transparent';
      if (port.isSelected) {
        portClass = 'unknown-port';
      } else {
        portClass = 'unknown-port not-selected';
      }
    } else {
      const transparentColor = fade(port.portColor, 0.25);
      portColor = port.isSelected ? port.portColor : transparentColor;
    }

    return {portColor, portClass};
  }

  const {portColor, portClass} = getPortColor();

  const iconColor: string = port.isSelected ? 'white' : 'grey';

  return (
    <StyledPortContainer onClick={handleModalOpen}>
      {portNumberLocation === 'top' && <StyledPortNumber>{port.portNumber}</StyledPortNumber>}
      <StyledPort style={{background: portColor}} className={portClass}>
        {port.portStatus === 'connected' && (
          <Icon path={mdiVideoInputHdmi} size={0.75} color={iconColor} />
        )}
        <SwitchPortModal
          data={port}
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          portHistory={portHistory}
        />
      </StyledPort>
      {portNumberLocation === 'bottom' && <StyledPortNumber>{port.portNumber}</StyledPortNumber>}
    </StyledPortContainer>
  );
}

import {
  getMinimumSiteInfoPending,
  getMinimumSiteInfoSuccess,
  getMinimumSiteInfoError,
} from './index';
import config from '../../oktaConfig';

function getMinimumSiteInfo(accessToken) {
  return dispatch => {
    dispatch(getMinimumSiteInfoPending());

    fetch(config.resourceServer.insightsApiUrlMinimumSiteInfo, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getMinimumSiteInfoSuccess(data));
      })
      .catch(err => {
        dispatch(getMinimumSiteInfoError(err));
        /* eslint-disable no-console */
        console.error(err);
      });
  };
}

export default getMinimumSiteInfo;

import {Stores, getStoreDisplayName} from 'interfaces/Stores';
const escapeSpecialRegexChars = (str: string) => {
  //eslint-disable-next-line
  const specialRegexChars: RegExp = /[\.\+\*\?\^\$\(\)\[\]\{\}\|\\]/g;
  return str.replace(specialRegexChars, '\\$&');
};

function filterStoresByInput(rawInput: string, stores: Stores) {
  const input = escapeSpecialRegexChars(rawInput);

  // Prioritize matching StoreNumbers
  const matchByStoreNumber = stores.filter(store => store.StoreNumber.toString() === input);

  // StoresNumbers and StoreNames that contain user input
  let otherMatchingStores = stores.filter(store => {
    return (
      getStoreDisplayName(store).toLowerCase().indexOf(input.toLowerCase()) > -1 ||
      store.StoreNumber.toString().match(input) ||
      (store.StoreName !== null
        ? store.StoreName.toLowerCase().indexOf(input.toLowerCase()) > -1
        : false)
    );
  });

  // If there is a exact match based on StoreNumber,
  // remove it from the rest of the list
  if (matchByStoreNumber.length > 0) {
    const index = otherMatchingStores.indexOf(matchByStoreNumber[0]);
    if (index !== -1) {
      otherMatchingStores.splice(index, 1);
    }
  }

  // Limits the number of returned stores for performance
  const limitedMatchingStores = otherMatchingStores.slice(0, 10);

  return [...matchByStoreNumber, ...limitedMatchingStores];
}

export default filterStoresByInput;

import {isAdmin} from '../../utils/insightUsers';

export const initialState = {
  admin: false,
  pending: false,
  posted: false,
  pages: [],
  payload: null,
  user: undefined,
  accessToken: null,
  sessionId: new Date().getTime(),
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'POST_USER_INFO_PENDING':
      return {
        ...state,
        pending: true,
        posted: false,
      };
    case 'POST_USER_INFO_SUCESS':
      return {
        ...state,
        pending: false,
        posted: true,
      };
    case 'POST_USER_INFO_ERROR':
      return {
        ...state,
        pending: false,
        posted: false,
        error: action.error,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        admin: isAdmin(action?.user?.email),
        user: {
          ...action.user,
          isAuthorizedForStoreServiceManager: action?.user?.groups?.includes(
            'App.TechInsights.StoreServiceManager'
          ),
        },
      };
    case 'UNSET_USER_INFO':
      return initialState;
    case 'USER_INFO_PAGE_VIEW':
      return {
        ...state,
        pages: [...state.pages, action.page],
      };
    case 'SET_USER_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case 'SET_USER_PAYLOAD':
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export default userInfoReducer;

export const userInfoAccessToken = state => state.userInfo.accessToken;

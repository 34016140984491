import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.subtleText};
`;

const StyledPageInfo = styled.div`
  text-align: center;
  width: 200px;
`;

function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onChangePage} = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  return (
    <>
      <StyledPageInfo>Total Stores: {count}</StyledPageInfo>
      <StyledIconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </StyledIconButton>
      <StyledPageInfo>
        {page >= Math.ceil(count / rowsPerPage) - 1 || rowsPerPage === 'All'
          ? rowsPerPage === 'All'
            ? 1
            : Math.ceil(page * rowsPerPage) + 1
          : Math.ceil(page * rowsPerPage) + 1}
        -
        {page * rowsPerPage + rowsPerPage > count || rowsPerPage === 'All'
          ? count
          : page * rowsPerPage + rowsPerPage}{' '}
        of {count}
      </StyledPageInfo>
      <StyledIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </StyledIconButton>
    </>
  );
}

export default TablePaginationActions;

import React from 'react';
import styled from 'styled-components';
import StatusIndicator from '../components/StatusIndicator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const StyledModalHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.grey};
  margin: -14px -24px 8px -24px;
  padding: 14px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  && p {
    font-size: 1rem;
    margin-top: 2px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.text};
  padding: 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.text};
`;

interface SwitchModalHeaderProps {
  portStatus: string;
  handleModalClose: () => void;
}

function SwitchModalHeader({portStatus, handleModalClose}: SwitchModalHeaderProps) {
  return (
    <StyledModalHeader>
      <StyledStatusIndicator portStatus={portStatus} />
      <StyledIconButton aria-label="close modal" onClick={handleModalClose}>
        <StyledCloseIcon />
      </StyledIconButton>
    </StyledModalHeader>
  );
}

export default SwitchModalHeader;

import React from 'react';
import styled from 'styled-components';
import MuiTableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from './TableCell';
import SGBody from '../../Typography/SGBody';

const StyledTableBody = styled(MuiTableBody)``;

const StyledTableRow = styled(TableRow)`
  &.mouseHover {
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
  background-color: ${props => props.theme.cardBg};
  padding: 8px 6px;
  &.extra-padding {
    //visually center table contents with sort label
    padding-right: 26px;
  }
`;

function descendingComparator(a, b, orderBy) {
  if (a[orderBy] === undefined) return 0;

  const A = a[orderBy].toString();
  const B = b[orderBy].toString();

  if (isNaN(Number(A)) && isNaN(Number(B))) {
    // if both are strings
    if (A < B) {
      return -1;
    }
    if (A > B) {
      return 1;
    }
    return 0;
  } else if (isNaN(Number(A))) {
    // if A is a string
    return 1;
  } else if (isNaN(Number(B))) {
    // if B is a string
    return -1;
  } else {
    // if both are numbers
    return Number(A) - Number(B);
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function TableBody(props) {
  const {
    className,
    columns,
    rows,
    rowsPerPage,
    page,
    order,
    orderBy,
    clickAction,
    clickActionType,
    customRowClass,
  } = props;

  return (
    <StyledTableBody className={className}>
      {(rowsPerPage > 0
        ? stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : stableSort(rows, getComparator(order, orderBy))
      ).map(row => {
        return (
          <>
            <StyledTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.id}
              onClick={clickAction}
              clickkey={row[clickActionType]}
              className={
                (clickAction ? 'mouseHover ' : ' ') + (customRowClass ? customRowClass(row) : '')
              }
            >
              {columns.map(column => {
                const value = row[column.id];
                return (
                  <StyledTableCell
                    body
                    key={column.id + row.id}
                    style={{width: column.width}}
                    align={column.align}
                    className={`body-cell ${column.align === 'center' ? 'extra-padding ' : ' '} ${
                      column.customClass
                    } `}
                  >
                    {column.format ? (
                      column.format(value, row)
                    ) : (
                      <SGBody variant={'b4'}>{value}</SGBody>
                    )}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            {row.extraContent && (
              <StyledTableRow key={row.id + 'extraContent'}>{row.extraContent}</StyledTableRow>
            )}
          </>
        );
      })}
    </StyledTableBody>
  );
}

export default TableBody;

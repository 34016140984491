import React, {ReactElement} from 'react';
import styled from 'styled-components';
import SGHeadline from 'components/Typography/SGHeadline';
import Icon from '@mdi/react';
import {mdiServerNetwork} from '@mdi/js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  border: 1px solid ${props => props.theme.grey};
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 0.5rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0;
  }
  .anchor {
    scroll-margin-top: 7.5em;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  row-gap: 16px;
  color: ${props => props.theme.text};
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.text};
`;

const StyledHeadlineContainer = styled.div`
  color: ${props => props.theme.text};
  display: inline-flex;
  column-gap: 0.5rem;
`;

interface SwitchAccordianProps {
  anchorId: string;
  header: string;
  isExpanded: boolean;
  handleAccordianChange: (switchId: string) => void;
  children?: ReactElement[] | ReactElement;
}

function SwitchAccordian({
  header,
  anchorId,
  isExpanded,
  handleAccordianChange,
  children,
}: SwitchAccordianProps) {
  return (
    <StyledAccordionContainer>
      <StyledAccordion expanded={isExpanded} onChange={() => handleAccordianChange(header)}>
        <AccordionSummary
          expandIcon={<StyledExpandMoreIcon />}
          aria-controls={anchorId}
          id={anchorId}
          className={'anchor'}
        >
          <StyledHeadlineContainer>
            <Icon path={mdiServerNetwork} size={1} />
            <SGHeadline variant={'h5'} className={'text-color'}>
              {header}
            </SGHeadline>
          </StyledHeadlineContainer>
        </AccordionSummary>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    </StyledAccordionContainer>
  );
}

export default SwitchAccordian;

import {
  getDashboardTrendingPending,
  getDashboardTrendingSuccess,
  getDashboardTrendingError,
} from './index';
import config from '../../oktaConfig';

function getTrendingIssueData(accessToken, param) {
  return dispatch => {
    dispatch(getDashboardTrendingPending());

    fetch(config.resourceServer.insightsApiUrlTrendingIssueCount + `${param}`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getDashboardTrendingSuccess(data));
      })
      .catch(err => {
        dispatch(getDashboardTrendingError(err));
        /* eslint-disable no-console */
        console.error(err);
      });
  };
}

export default getTrendingIssueData;

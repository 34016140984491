export const initialState = {
  data: {
    na: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
    },
    emea: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
    },
    gc: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
    },
    apla: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
    },
  },
  pending: false,
  dataRetrieved: false,
  error: null,
};

const geoTradeStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GEO_TRADE_STATUS_PENDING':
      return {
        ...state,
        pending: true,
      };
    case 'GET_GEO_TRADE_STATUS_SUCCESS':
      return {
        ...state,
        data: action.data,
        pending: false,
        dataRetrieved: false,
      };
    case 'GET_GEO_TRADE_STATUS_ERROR':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default geoTradeStatusReducer;

const CLIENT_ID = process.env.CLIENT_ID || 'nike.techops.retail-store-insights';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const OKTA_ISSUER_DEV = 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/';
const OKTA_ISSUER_PROD = 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/';
const API_SERVER_DEV = 'https://n0-insights-apim-01.azure-api.net/n0-insights-API-fna-01/';
const API_SERVER_PROD = 'https://p0-insights-apim-01.azure-api.net/p0-insights-API-fna-01/';

export const ENVIRONMENT = process.env.REACT_APP_BUILD || 'prod';
const BASENAME = '/';
const REDIRECT_URI = `${window.location.origin}${BASENAME}implicit/callback`;

let apiEndPoint;
let oktaEnvironment;

switch (ENVIRONMENT) {
  case 'local':
    apiEndPoint = API_SERVER_DEV;
    oktaEnvironment = OKTA_ISSUER_DEV;
    console.log(ENVIRONMENT, {apiEndPoint, oktaEnvironment});
    break;
  case 'dev':
    apiEndPoint = API_SERVER_DEV;
    oktaEnvironment = OKTA_ISSUER_DEV;
    console.log(ENVIRONMENT);
    break;
  case 'prod':
    apiEndPoint = API_SERVER_PROD;
    oktaEnvironment = OKTA_ISSUER_PROD;
    break;
  default:
    apiEndPoint = API_SERVER_DEV;
    oktaEnvironment = OKTA_ISSUER_DEV;
    console.log('default');
    break;
}

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: oktaEnvironment,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'techops.rss.read'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    insightsApiUrlStores: apiEndPoint + 'stores',
    insightsApiUrlActiveEvents: apiEndPoint + 'events?isactive=true',
    insightsApiUrlEvents: apiEndPoint + 'events?',
    insightsApiUrlLandingPage: apiEndPoint + 'landingpagecounts',
    insightsApiUrlLandingPageKeyMarket: apiEndPoint + 'landingpagecounts?IsKeyMarket=true',
    insightsApiUrlTrendingChart: apiEndPoint + 'trendingchartdata?',
    insightsApiUrlCoordinates: apiEndPoint + 'coordinates?',
    insightsApiUrlStoreIssues: apiEndPoint + 'storeissuecount?',
    insightsApiUrlTrendingIssueCount: apiEndPoint + 'trendingissuecount?',
    insightsApiUrlMinimumStoreInfo: apiEndPoint + 'MinimumStoreInfo',
    insightsApiUrlLogUserAction: apiEndPoint + 'LogUserAction',
    insightsApiUrlTreeMenuData: apiEndPoint + 'TreeMenuData',
    insightsApiUrlTopEvents: apiEndPoint + 'TopEvents',
    insightsApiUrlTableEvents: apiEndPoint + 'TableEvents',
    insightsApiUrlDeviceHealthCount: apiEndPoint + 'DeviceHealthCount',
    insightsApiUrlRetailRecurringPhysical: apiEndPoint + 'RetailRecurringPhysical',
    insightsApiUrlRetailRecurringPhysicalReport: apiEndPoint + 'RetailRecurringPhysicalReport',
    insightsApiUrlStoreServices: apiEndPoint + 'StoreServices',
    insightsApiUrlDistinctIdfSearchItems: apiEndPoint + 'DistinctIdfSearchItems',
    insightsApiUrlServicesByStore: apiEndPoint + 'ServicesByStore',
    insightsApiUrlPayloadIngestion: apiEndPoint + 'PayloadIngestion',
    insightsApiUrlSwitchData: apiEndPoint + 'SwitchConnectivity',
    insightsApiUrlSwitchPortHistory: apiEndPoint + 'SwitchPortHistory',
    insightsApiUrlMinimumSiteInfo: apiEndPoint + 'MinimumSiteInfo',
    insightsApiUrlDevices: apiEndPoint + 'Devices',
  },
  app: {
    basename: BASENAME,
  },
};

export default config;

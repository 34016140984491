import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from "./utility/defaultNullAlert";
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
} = AlertSeverityEnum;

export default function switchingHardwareAPPortUtilizationAlertValue(
  switchAPPortUtilization_Used: number,
  switchAPPortUtilization_Total: number,
): IAlert {
  if (switchAPPortUtilization_Used === null || switchAPPortUtilization_Total === null) {
    return defaultNullAlert(['switchAPPortUtilization_Used', 'switchAPPortUtilization_Total']);
  }

  const percentUsed = switchAPPortUtilization_Used / switchAPPortUtilization_Total;

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  if (percentUsed >= 1) {
    alert.value = MINOR;
    alert.issue.push('Store is using 100% of the ports allocated for Wireless AP use on the Switching Hardware, so has no additional Wireless AP ports. ');
    alert.effect.push('The store has run out of Wireless AP ports, and is not able to connect any new Wireless AP the network. Only critical if new Wireless APs are planned to be installed.');
    alert.solution.push('Consider adding additional Switching Hardware to the store. If new Wireless APs are planned to be installed, contact Network Engineering for temporary over-allocation.');
  }

  return alert;
}

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from './TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SGTitle from '../../Typography/SGTitle';
import Linear from '../../Skeletons/Loading/Linear';
interface TreeMenuItem {
  name: string;
  count: number;
  geoStore: string;
  stores: number;
  checked: boolean;
  id: string;
  children?: TreeMenuItem[];
  index?: string;
}

export interface TreeMenuArray extends Array<TreeMenuItem> {}

interface Props {
  node: TreeMenuArray;
  geoStore: string;
  loaded: boolean;
}

const StyledTreeView = styled(TreeView)`
  .MuiTreeItem-iconContainer {
    color: ${props => props.theme.subtleText};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  padding: 0px;
  padding-left: 1.5rem;
  margin-bottom: 0px;
  .text-block {
    display: block;
    width: 50%;
  }
  .count-block {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    min-width: 240px;
  }
  .count-text {
    text-align: center;
    width: 120px;
  }
`;

function TreeMenu({node, loaded, geoStore}: Props) {
  const [ready, setReady] = useState<boolean>(false);
  const [expandedBranches] = useState<Array<string>>([]);

  const renderTree = function renderTree(node: TreeMenuItem, index: number, hidden?: boolean) {
    return (
      <TreeItem
        key={node.name + index}
        nodeId={node.id}
        label={node.name}
        geoStore={geoStore}
        count={node.count}
        stores={node.stores}
        hidden={hidden}
      >
        {Array.isArray(node.children)
          ? node.children.map((x, index) => renderTree(x, index))
          : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (loaded === true) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [loaded, node]);

  return (
    <>
      <StyledHeader>
        <div className={'text-block'}>
          <SGTitle variant={'t5'}>DEVICES AND EVENTS</SGTitle>
        </div>
        <div className={'count-block'}>
          <SGTitle variant={'t5'} className={'count-text'}>
            TOTAL EVENTS
          </SGTitle>
          <SGTitle variant={'t5'} className={'count-text'}>
            TOTAL STORES
          </SGTitle>
        </div>
      </StyledHeader>
      {ready ? (
        <StyledTreeView
          defaultExpanded={expandedBranches}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {node.map((x, index) => renderTree(x, index, true))}
        </StyledTreeView>
      ) : (
        <Linear />
      )}
    </>
  );
}

export default TreeMenu;

import React, {MouseEvent} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from 'components/Tables/TableStructure/Table';
import SGBody from 'components/Typography/SGBody';

const StyledAccordion = styled(Accordion)`
  background-color: ${props => props.theme.barBg};
  &:hover {
    cursor: default;
  }
  .MuiIconButton-root {
    color: ${props => props.theme.subtleText};
  }
  .MuiAccordionDetails-root {
    display: block;
  }
  .end-table-spacer {
    display: none;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0px;
  }
  & .MuiTableCell-head {
    padding-top: 4px;
  }
`;

const StyledIDFCount = styled.span`
  color: ${props => props.theme.subtleText};
`;

const StyledTable = styled(Table)`
  .body {
    background-color: transparent;
  }
`;

const columns = [
  {
    id: 'building',
    label: 'Building',
    width: '2rem',
  },
  {
    id: 'operationalArea',
    label: 'Operational Area',
    width: '3rem',
  },
  {
    id: 'idf',
    label: 'IDF',
    width: '2rem',
  },
];

interface IdfTableProps {
  data: IdfSearchItem[];
  siteId: string;
  urlKey: string;
}

function IDFTable({data, siteId, urlKey}: IdfTableProps) {
  const handleAccordianClick = (event: MouseEvent<HTMLHeadingElement>) => {
    event.stopPropagation();
  };

  let history = useHistory();

  const goToSite = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const idf = event.currentTarget.getAttribute('clickkey');
    const path = `/${urlKey}/${siteId}/${idf}`;
    history.push(path);
  };

  return (
    <StyledAccordion onClick={handleAccordianClick} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <SGBody variant="b4">
          View IDFs <StyledIDFCount>({data.length})</StyledIDFCount>
        </SGBody>
      </AccordionSummary>
      <AccordionDetails>
        <StyledTable
          columns={columns}
          clickAction={goToSite}
          clickActionType={'idf'}
          data={data}
          loaded
          showAll
          disablePagination
        />
      </AccordionDetails>
    </StyledAccordion>
  );
}

export default IDFTable;

import {useEffect} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchMinimumSiteInfo, fetchDistinctIdfSearchItems, mergeStoreSite} from 'redux/actions';
import {
  rdxMinimumSiteInfoDataRetrieved,
  rdxMinimumSiteInfoData,
  rdxIsSiteStoreMerged,
} from 'redux/reducers/minimumSiteInfoReducer';
import Site from 'interfaces/Site';
import {Store} from 'interfaces/Stores';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';

function useSiteInfo() {
  const {authState, oktaAuth} = useOktaAuth();
  const dispatch = useDispatch();

  //#region redux
  const isSitesLoaded: boolean = useSelector((state: any) =>
    rdxMinimumSiteInfoDataRetrieved(state)
  );
  const sites: Site[] = useSelector((state: any) => rdxMinimumSiteInfoData(state));
  const isIdfSearchItemsLoaded: boolean = useSelector(
    (state: any) => state.distinctIdfSearchItems.dataRetrieved
  );
  const idfs: IdfSearchItem[] = useSelector((state: any) => state.distinctIdfSearchItems.data);
  const isStoresLoaded: boolean = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo.dataRetrieved
  );
  const stores: Store[] = useSelector((state: any) => state.data.app.searchMinimumStoreInfo.data);
  const isSiteStoreMerged: boolean = useSelector((state: any) => rdxIsSiteStoreMerged(state));
  //#endRegion redux

  //#region useEffect
  useEffect(() => {
    if (authState && authState.isAuthenticated && !isSitesLoaded) {
      const accessToken = oktaAuth.getAccessToken();
      dispatch(fetchMinimumSiteInfo(accessToken));
      dispatch(fetchDistinctIdfSearchItems(accessToken));
    }
    if (isSitesLoaded && isStoresLoaded && !isSiteStoreMerged) {
      dispatch(mergeStoreSite(stores));
    }
  }, [oktaAuth, authState, dispatch, isSitesLoaded, isStoresLoaded, stores, isSiteStoreMerged]);
  //#endRegion useEffect

  return {sites, isSitesLoaded, isIdfSearchItemsLoaded, idfs, isSiteStoreMerged};
}

export default useSiteInfo;

import {Services, getServiceDisplayName} from './interfaces/Services';
function filterServicesByInput(input: string, serviceList: Services): Services {
  // ServiceNames that contain user input
  const matchingServices: Services = serviceList.filter(service => {
    const serviceName = getServiceDisplayName(service);
    return serviceName.match(input) || serviceName.toLowerCase().indexOf(input.toLowerCase()) > -1;
  });

  // Limits the number of returned services for performance
  return matchingServices.slice(0, 10);
}

export default filterServicesByInput;

import React from 'react';
import styled from 'styled-components';
import HeaderIcon, {HeaderIconTypes, HeaderColorTypes} from './components/HeaderIcon';
import SGHeadline from 'components/Typography/SGHeadline';

interface LayoutHeaderProps {
  title: string;
  icon: HeaderIconTypes;
  iconColor?: HeaderColorTypes;
  searchComponent?: JSX.Element;
}

const StyledLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: 1100px) {
    flex-direction: row;
  }
`;

const StyledIconTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSGHeadline = styled(SGHeadline)`
  && {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

function LayoutHeader({
  title,
  icon,
  iconColor = HeaderColorTypes.Default,
  searchComponent,
}: LayoutHeaderProps) {
  return (
    <StyledLayoutHeader>
      <StyledIconTextContainer>
        <HeaderIcon color={iconColor} icon={icon} />
        <StyledSGHeadline variant={'h4'} className={'active-color'}>
          {title}
        </StyledSGHeadline>
      </StyledIconTextContainer>
      {searchComponent}
    </StyledLayoutHeader>
  );
}

export {HeaderIconTypes as LayoutHeaderIconTypes};
export {HeaderColorTypes as LayoutHeaderColorTypes};
export default LayoutHeader;

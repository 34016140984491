import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

// component built with TypeScript and the ABnB Style guide

type SGSubtitleProps = {
  children: React.ReactNode;
  variant?: 's1' | 's2' | 's3';
  className?: string;
  rest?: any;
};

const StyledSubtitle = styled(Typography)`
  font-family: 'Text Regular';
  color: ${props => props.theme.text};
  font-size: 1rem;

  &.s1 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &.s2 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &.s3 {
    font-size: 0.75rem;
    line-height: 1.1875rem;
  }

  &.grey {
    color: ${props => props.theme.grey};
  }
`;

function SGSubtitle({children, variant = 's2', className, ...rest}: SGSubtitleProps) {
  return (
    <StyledSubtitle className={`${variant} ${className}`} {...rest}>
      {children}
    </StyledSubtitle>
  );
}

export default SGSubtitle;

import React, {ReactElement} from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiHistory} from '@mdi/js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  margin: 0.5rem 0px;
`;

const StyledHeader = styled.p`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.text};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  margin: 0;
`;

const StyledAccordion = styled(Accordion)`
  background: ${props => props.theme.blue1};
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0;
  }
  .anchor {
    scroll-margin-top: 7.5em;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  row-gap: 16px;
  color: ${props => props.theme.text};
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.text};
`;

const StyledHeadlineContainer = styled.div`
  color: ${props => props.theme.text};
  display: inline-flex;
  column-gap: 0.5rem;
  align-items: center;
`;

interface SwitchPortHistoryAccordianProps {
  anchorId: string;
  header: string;
  isExpanded: boolean;
  children?: ReactElement[] | ReactElement;
}

function SwitchPortHistoryAccordian({
  header,
  anchorId,
  isExpanded,
  children,
}: SwitchPortHistoryAccordianProps) {
  return (
    <StyledAccordionContainer>
      <StyledAccordion expanded={isExpanded} onChange={undefined}>
        <AccordionSummary
          expandIcon={<StyledExpandMoreIcon />}
          aria-controls={anchorId}
          id={anchorId}
          className={'anchor'}
        >
          <StyledHeadlineContainer>
            <Icon path={mdiHistory} size={1} />
            <StyledHeader>{header}</StyledHeader>
          </StyledHeadlineContainer>
        </AccordionSummary>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    </StyledAccordionContainer>
  );
}

export default SwitchPortHistoryAccordian;

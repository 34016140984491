import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h1`
  color: ${props => props.theme.text};
  font-size: 2rem;
  font-family: 'Futura C XBd';
  margin: 0;
  padding-left: 5px;
  @media (min-width: 600px) {
    font-size: 4rem;
  }
`;

function Title(props) {
  const {children, className} = props;

  return <StyledTitle className={className}>{children}</StyledTitle>;
}

export default Title;

import React from 'react';
import styled from 'styled-components';

const StyledPaperTitle = styled.span`
  font-weight: normal;
  text-transform: uppercase;
  font-family: 'Futura C XBd';
  color: ${props => props.theme.titleText};
  font-size: 1rem;
`;

function PaperTitle(props) {
  const {children, className} = props;

  return <StyledPaperTitle className={className}>{children}</StyledPaperTitle>;
}

export default PaperTitle;

import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
  UNKNOWN
} = AlertSeverityEnum;

export default function wirelessAPHardwareProvisionedAlertValue(wirelessAPHardwareProvisioned: string[]): IAlert {
  if (wirelessAPHardwareProvisioned === null || !Array.isArray(wirelessAPHardwareProvisioned)) {
    return defaultNullAlert(['wirelessAPHardwareProvisioned']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  const idealHardware = 'Cisco Catalyst 9120AXI Unified Access Point';
  const hardware1562I = 'Cisco 1562I Unified Access Point';
  const hardware2700I = 'Cisco 2700I Unified Access Point';
  const hardware2800I = 'Cisco 2800I Unified Access Point';
  const hardware3800I = 'Cisco 3800I Unified Access Point';

  let allAlerts: AlertSeverityEnum[] = [];

  wirelessAPHardwareProvisioned.forEach(hardware => {
    if (hardware.toLowerCase() === hardware3800I.toLowerCase()) {
      allAlerts.push(MINOR);
    } else if (hardware.toLowerCase() === hardware2800I.toLowerCase()) {
      allAlerts.push(MINOR);
    } else if (hardware.toLowerCase() === hardware2700I.toLowerCase()) {
      allAlerts.push(MAJOR);
    } else if (hardware.toLowerCase() === hardware1562I.toLowerCase()) {
      allAlerts.push(MAJOR);
    } else if (hardware.toLowerCase() === idealHardware.toLowerCase()) {
      allAlerts.push(HEALTHY);
    } else {
      allAlerts.push(UNKNOWN);
    }
  });

  const lowestAlert = Math.min(...allAlerts);
  alert.value = lowestAlert;

  switch (alert.value) {
    case MINOR:
      alert.issue.push('Store Wireless AP Hardware does not meet standard. Vendor has stopped developing general maintenance software releases.');
      alert.effect.push('Hardware may be unable to meet future store functional requirements, and is at risk for future software bugs not being remedated.');
      alert.solution.push('Consider upgrading to meet Wireless AP Hardware standard of Cisco Catalyst 9120AXi.');
      break;
    case MAJOR:
      alert.issue.push('Store Wireless AP Hardware does not meet standard. Vendor has stopped developing security vulnerability software releases. ');
      alert.effect.push('Hardware may be unable to meet future store functional requirements, and is at risk for future software security vulnerabilities not being remedated.');
      alert.solution.push('Consider upgrading to meet Wireless AP Hardware standard of Cisco Catalyst 9120AXi.');
      break;
  }

  return alert;
}

const destructureIdString = function destructureIdString(idString) {
  const splitId = idString.split('-');

  return {
    deviceId: splitId.length >= 1 ? splitId[0] : undefined,
    catId: splitId.length >= 2 ? splitId[1] : undefined,
    subCatId: splitId.length >= 3 ? splitId[2] : undefined,
  };
};

export default destructureIdString;

export const initialState = {
  dataRetrieved: false,
  pending: false,
  data: null,
  error: null,
};

const distinctIdfSearchItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DISTINCT_IDF_SEARCH_ITEMS_PENDING':
      return {
        ...state,
        dataRetrieved: false,
        pending: true,
        data: null,
        error: null,
      };
    case 'GET_DISTINCT_IDF_SEARCH_ITEMS_SUCCESS':
      const data = action.data.map((item, index) => {
        return {
          ...item,
          id: index.toString(),
        };
      });
      return {
        ...state,
        dataRetrieved: true,
        pending: false,
        data: data,
        error: null,
      };
    case 'GET_DISTINCT_IDF_SEARCH_ITEMS_ERROR':
      return {
        ...state,
        dataRetrieved: false,
        pending: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default distinctIdfSearchItemsReducer;

import React from 'react';
import styled from 'styled-components';
import StoreTransferList, {StoreTransferListProps} from 'components/StoreTransferList';

const StyledFilterContainer = styled.div`
  position: relative;
  z-index: 5;
`;

function SiteFilter({handleStores, selectedStores}: StoreTransferListProps) {
  return (
    <StyledFilterContainer>
      <StoreTransferList handleStores={handleStores} selectedStores={selectedStores} />
    </StyledFilterContainer>
  );
}

export default SiteFilter;

import React from 'react';
import styled from 'styled-components';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import Grid from '@material-ui/core/Grid';
import packageJson from '../../../package.json';
import {getBuildDate} from '../../utils/utils';
import {patchVersion} from '../../constants/releaseConfig';

const StyledPaper = styled(Paper)`
  code {
    width: 100%;
    word-wrap: break-word;
    display: inline-block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

function BuildVersion() {
  const {version} = patchVersion;
  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperTitle>Version</PaperTitle>
        </Grid>
        <Grid item xs={12}>
          <code>{version}</code>
        </Grid>
        <Grid item xs={12}>
          <PaperTitle>Build</PaperTitle>
        </Grid>
        <Grid item xs={12}>
          <code>{getBuildDate(packageJson.buildDate)}</code>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default BuildVersion;

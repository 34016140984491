import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import StyledPaperTitle from '../../PaperTitle';
import StyledPaper from '../../Paper';
import TreeMenu from './TreeMenu';
import ApexLineChart from './ApexLineChart';
import {mockTreeData} from './mockData';
import {
  historicalComparisonDefaultSelectionLoad,
  fetchTreeMenuData,
  fetchTopEvents,
  fetchTableEvents,
} from '../../../redux/actions';
import TopEvents from './TopEvents';
import EventsByType from './EventsByType';
import EventDetailsTable from './EventDetailsTable';

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.disabledStateBlue};
`;

const StyledVerticalDivider = styled(StyledDivider)`
  width: 100%;
  height: 1px;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    height: 100%;
    width: 1px;
  }
`;

function HistoricalComparisonChart({debugLoaded, geo}) {
  const dispatch = useDispatch();
  const [dataReady, setDataReady] = useState(false);
  const {
    friendlyDateRange,
    treeMenu,
    treeMenuLoaded,
    topEvents,
    topEventsLoaded,
    tableEvents,
    tableEventsLoaded,
  } = useSelector(state => state.componentHistoricalComparison);
  const {accessToken} = useSelector(state => state.userInfo);

  useEffect(() => {
    // Dev Overide
    debugLoaded ? setDataReady(true) : setDataReady(false);
  }, [debugLoaded]);

  useEffect(() => {
    if (dataReady) {
      dispatch(historicalComparisonDefaultSelectionLoad(mockTreeData));
    }
  }, [dataReady, dispatch]);

  useEffect(() => {
    if (accessToken !== null) {
      dispatch(fetchTreeMenuData(accessToken));
      dispatch(fetchTopEvents(accessToken));
      dispatch(fetchTableEvents(accessToken));
    }
  }, [geo, accessToken, dispatch]);

  return (
    <StyledPaper>
      <Grid container spacing={6} direction="row">
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <StyledPaperTitle>Historical - Trending Events</StyledPaperTitle>
          <TreeMenu debugLoaded={debugLoaded} loaded={treeMenuLoaded} node={treeMenu} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={12} md={12}>
              <ApexLineChart debugLoaded={debugLoaded} loaded={dataReady} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
              <EventsByType debugLoaded={debugLoaded} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={1}>
              <StyledVerticalDivider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <TopEvents
                debugLoaded={debugLoaded}
                loaded={topEventsLoaded}
                topEventItems={topEvents}
                friendlyDateRange={friendlyDateRange}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EventDetailsTable
                debugLoaded={debugLoaded}
                loaded={tableEventsLoaded}
                events={tableEvents}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default HistoricalComparisonChart;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@okta/okta-auth-js/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router} from 'react-router-dom';
import config from './oktaConfig';
import withClearCache from './ClearCache';

const ClearCacheComponent = withClearCache(MainApp);

function MainApp() {
  return (
    <Router basename={config.app.basename}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ClearCacheComponent />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

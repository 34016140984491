import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

const StyledCard = styled(Card)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 15px;
  position: relative;
  overflow: visible;
  height: calc(100% - 48px);
`;

const StyledTypography = styled(Typography)`
  color: ${props => props.themesubtleText};
`;

const StyledIconContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -2.5em;
  width: calc(100% - 48px);
`;

const StyledIconCircle = styled.div`
  display: flexbox;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  -webkit-box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.16);
  svg {
    font-size: 3rem;
    color: blue;
  }
  img {
    max-width: 3rem;
    max-height: 3rem;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 48px 12px 68px 12px;
`;

const StyledCardActions = styled(CardActions)`
  background-color: #edf8fa;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: calc(100% - 16px);
  margin: 0px -24px 0 -24px;
  text-align: center;
  display: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 1rem;
  font-family: 'Trade Gothic';
  color: ${props => props.theme.subtleText};
  span {
    color: ${props => props.theme.subtleText};
  }
  &:active span,
  &:hover span,
  &:focus span {
    color: ${props => props.theme.altSubtleText};
  }
`;

function InfoCard(props) {
  const {children, className, icon, link, linkText, ...rest} = props;

  return (
    <StyledCard elevation={1} className={className} {...rest}>
      <StyledIconContainer>
        <StyledIconCircle>
          <img src={icon} alt={linkText} />
        </StyledIconCircle>
      </StyledIconContainer>
      <StyledCardContent>
        <StyledTypography align={'center'}>
          {children}
          <br></br>
        </StyledTypography>
      </StyledCardContent>
      <StyledCardActions>
        <StyledButton size="small" fullWidth={true} href={link} target="_blank">
          {linkText} <ChevronRightRoundedIcon />
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  );
}

export default InfoCard;

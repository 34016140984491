import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import SGHeadline from '../../Typography/SGTitle';
import SGBody from '../../Typography/SGBody';
import RectSkeleton from '../../Skeletons/RectSkeleton';

interface DataSummaryProps {
  percent: number;
  deviceCount: number;
  storeCount: number;
  loaded?: boolean;
}

const DataSummaryGrid = styled(Grid)``;

function DataSummary({percent, deviceCount, storeCount, loaded = false}: DataSummaryProps) {
  return (
    <DataSummaryGrid container spacing={1} direction="row">
      <Grid item xs={12}>
        <SGHeadline variant={'t3'}>Percent Of Events Selected</SGHeadline>
        {loaded ? (
          <SGBody variant={'b2'}>{percent}%</SGBody>
        ) : (
          <RectSkeleton>
            <SGBody variant={'b2'}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </SGBody>
          </RectSkeleton>
        )}
      </Grid>
      {/* <Grid item xs={6}>
        <SGHeadline variant={'t3'}>Affected Devices</SGHeadline>
        {loaded ? (
          <SGBody>{deviceCount}</SGBody>
        ) : (
          <RectSkeleton>
            <SGBody variant={'b2'}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </SGBody>
          </RectSkeleton>
        )}
      </Grid>
      <Grid item xs={6}>
        <SGHeadline variant={'t3'}>Affected Stores</SGHeadline>
        {loaded ? (
          <SGBody variant={'b2'}>{storeCount}</SGBody>
        ) : (
          <RectSkeleton>
            <SGBody variant={'b2'}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </SGBody>
          </RectSkeleton>
        )}
      </Grid> */}
    </DataSummaryGrid>
  );
}

export default DataSummary;

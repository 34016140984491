import React, {useState} from 'react';
import styled from 'styled-components';
import Table from 'components/Tables/TableStructure/Table';
import StatusIndicator from '../components/StatusIndicator';
import Icon from '@mdi/react';
import IconButton from '@material-ui/core/IconButton';
import {mdiHistory} from '@mdi/js';
import SwitchPortHistoryTable from './SwitchPortHistoryTable';
import ToolTip from 'components/ToolTip';

const StyledTable = styled(Table)`
  .word-wrap-break-word {
    word-wrap: break-word;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.disabledStateBlue};
  cursor: pointer;
  padding: 0;
  &:hover {
    color: ${props => props.theme.activeStateBlue};
  }
  &:active {
    color: ${props => props.theme.disabledStateBlue};
  }
`;

function SwitchTable(props) {
  const {
    className, //react specific
    data,
    loaded,
    noDataText,
    portHistoryData,
  } = props;

  const [expandedSwitchHistory, setExpandedSwitchHistory] = useState([]);

  const toggleExpandedSwitchHistory = id => {
    if (expandedSwitchHistory.includes(id)) {
      setExpandedSwitchHistory(previous => previous.filter(x => x != id));
    } else {
      setExpandedSwitchHistory(previous => [...previous, id]);
    }
  };

  // columns are defined manually
  const columns = [
    {
      id: 'switchNumber',
      label: 'Switch',
      align: 'center',
      width: '2.8rem',
    },
    {
      id: 'portNumber',
      label: 'Port',
      align: 'center',
      width: '2.8rem',
    },
    {
      id: 'portStatus',
      label: 'Status',
      width: '7rem',
      format: value => <StatusIndicator portStatus={value} />,
    },
    {
      id: 'portDescription',
      label: 'Port Description',
      width: '10rem',
    },
    {
      id: 'macAddress',
      label: 'MAC Address',
      width: '8rem',
    },
    {
      id: 'deviceType',
      label: 'Device Type',
      width: '6.5rem',
      customClass: 'word-wrap-break-word',
    },
    {
      id: 'deviceName',
      label: 'Device Name',
      width: '6.75rem',
    },
    {
      id: 'manufacturer',
      label: 'Manufacturer',
      width: '11rem',
    },
    {
      id: 'vlanNumber',
      label: 'VLAN',
      width: '4.75rem',
      align: 'center',
    },
    {
      id: 'portHistoryIcon',
      width: '1rem',
      format: (_, row) => (
        <ToolTip
          placement="top-end"
          title="Port History"
          customIcon={
            <StyledIconButton onClick={() => toggleExpandedSwitchHistory(row.id)}>
              <Icon path={mdiHistory} size={1} />
            </StyledIconButton>
          }
        />
      ),
    },
  ];

  for (let i = 0; i < data.length; i++) {
    let switchNumber = data[i].switchNumber;
    let portNumber = data[i].portNumber;
    let id = data[i].id;
    let portHistoryPort = portHistoryData?.switches[switchNumber]?.ports[portNumber];
    let isOpen = expandedSwitchHistory.includes(id);
    data[i].extraContent = (
      <td colSpan={10}>
        <SwitchPortHistoryTable key={id} isOpen={isOpen} portHistoryPort={portHistoryPort} />
      </td>
    );
  }

  return (
    <StyledTable
      showAll
      disablePagination
      className={className}
      columns={columns}
      data={data}
      loaded={loaded}
      noDataText={noDataText}
    />
  );
}

export default SwitchTable;

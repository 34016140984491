import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import Grid from '@material-ui/core/Grid';
import {useOktaAuth} from '@okta/okta-react';
import Button from 'components/CTA/SGButton';
import {useDispatch, useSelector} from 'react-redux';
import {unsetUserInfo} from 'redux/actions';

const StyledPaper = styled(Paper)`
  code {
    width: 100%;
    word-wrap: break-word;
    display: inline-block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

function OktaKey() {
  const dispatch = useDispatch();
  const {authState, oktaAuth} = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const isAdmin = useSelector(state => state.userInfo.admin);
  const [oktaKey, setOktaKey] = useState('');

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else if (authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      setOktaKey(accessToken);
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperTitle>User Info</PaperTitle>
        </Grid>
        <Grid item xs={12}>
          <code>{userInfo ? userInfo.name : ''}</code>
          <code>{userInfo ? userInfo.email : ''}</code>
          <code>{userInfo ? userInfo.locale : ''}</code>
          <code>{userInfo ? userInfo.zoneinfo : ''}</code>
        </Grid>
        {isAdmin && (
          <>
            <Grid item xs={12}>
              <PaperTitle>Access Key</PaperTitle>
            </Grid>
            <Grid item xs={12}>
              <code>{oktaKey}</code>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            onClick={() => {
              dispatch(unsetUserInfo());
              return oktaAuth.signOut();
            }}
          >
            Sign Out of Okta
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default OktaKey;

import React from 'react';
import styled from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '../Paper';
import Title from '../Typography/SGTitle';
import SubTitle from '../Typography/SGSubtitle';

interface ModalProps {
  modalState: boolean;
  modalToggle: (value: boolean) => void;
  title: string;
  subTitle: string;
  children: React.ReactNode;
}

const ContentBox = styled(Paper)`
  max-width: 50vw;
  position: relative;
  top: 25vh;
  left: 25vw;
`;

const StyledGrid = styled(Grid)`
  &.align-right {
    text-align: right;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 1rem;
  }
  p:nth-child(2) {
    margin-top: 3px;
  }
`;

const StyledButton = styled(MuiButton)`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  font-style: italic;
  padding: 0;
  text-transform: capitalize;
  .MuiButton-label {
    justify-content: flex-end;
  }
  &:hover {
    color: ${props => props.theme.focusText};
  }
`;

function Modal({modalState, modalToggle, title, subTitle, children}: ModalProps) {
  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalState}
      onClose={() => modalToggle(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalState}>
        <ContentBox>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <StyledHeader>
                <Title className={'text-color'}>{title}</Title>
                <SubTitle>{subTitle}</SubTitle>
                <SubTitle>37 Events</SubTitle>
                <SubTitle>8 Stores Affected</SubTitle>
                <SubTitle>March 28th, 2022</SubTitle>
              </StyledHeader>
            </Grid>
            <StyledGrid item xs={4} className={'align-right'}>
              <StyledButton onClick={() => modalToggle(false)}>
                <CloseIcon />
              </StyledButton>
            </StyledGrid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </ContentBox>
      </Fade>
    </MuiModal>
  );
}

export default Modal;

import React from 'react';
import styled from 'styled-components';
import SGButton from 'components/CTA/SGButton';
import ToolTip from 'components/SGToolTip';

const StyledBackButton = styled(SGButton)`
  margin-right: 8px;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface NavigationButtonsProps {
  isBackButtonVisible?: boolean;
  handleBack: () => void;
  handleNext: () => void;
  nextButtonLabel?: string;
  nextButtonTooltip?: string;
  isDisabled: boolean;
}

function NavigationButtons({
  isBackButtonVisible = true,
  handleBack,
  handleNext,
  nextButtonLabel = 'Next',
  nextButtonTooltip = '',
  isDisabled,
}: NavigationButtonsProps) {
  const nextButton = (
    <SGButton disabled={isDisabled} onClick={handleNext}>
      {nextButtonLabel}
    </SGButton>
  );

  return (
    <StyledButtonContainerDiv>
      {isBackButtonVisible && (
        <StyledBackButton variant={'contained'} color={'default'} onClick={handleBack}>
          Back
        </StyledBackButton>
      )}
      {nextButtonTooltip === '' ? (
        nextButton
      ) : (
        <ToolTip title={nextButtonTooltip}>{nextButton}</ToolTip>
      )}
    </StyledButtonContainerDiv>
  );
}

export default NavigationButtons;

import Brand from 'interfaces/Brand';
import Geo from 'interfaces/Geo';
import Status from 'interfaces/Status';
import DeviceType from 'interfaces/DeviceType';
import FilterOptions from '../interfaces/FilterOptions';
import SiteType from 'interfaces/SiteType';
import NVSNSO from 'interfaces/NVSNSO';
import Concept from 'interfaces/Concept';

const filterOptions: FilterOptions = {
  brand: {
    label: 'Brand',
    values: {
      [Brand.NIKE]: {
        label: 'Nike',
        isSelected: false,
      },
      [Brand.CONVERSE]: {
        label: 'Converse',
        isSelected: false,
      },
    },
  },
  geo: {
    label: 'Geo',
    values: {
      [Geo.NA]: {
        label: 'NA',
        isSelected: false,
      },
      [Geo.EMEA]: {
        label: 'EMEA',
        isSelected: false,
      },
      [Geo.GC]: {
        label: 'GC',
        isSelected: false,
      },
      [Geo.APLA]: {
        label: 'APLA',
        isSelected: false,
      },
    },
  },
  siteType: {
    label: 'Site Type',
    values: {
      [SiteType.RETAIL]: {
        label: 'Retail',
        isSelected: false,
      },
      [SiteType.DC]: {
        label: 'Distribution Center',
        isSelected: false,
      },
      [SiteType.SHOWROOM]: {
        label: 'Showroom',
        isSelected: false,
      },
      [SiteType.CORPORATE]: {
        label: 'Office',
        isSelected: false,
      },
    },
  },
  nvsnso: {
    label: 'NVS/NSO',
    excludedValues: [Brand.CONVERSE],
    values: {
      [NVSNSO.NVS]: {
        label: 'NVS',
        isSelected: false,
        includedValues: [Concept.UNITE, Concept.CLEARANCE],
      },
      [NVSNSO.NSO]: {
        label: 'NSO',
        isSelected: false,
        includedValues: [
          Concept.EMPLOYEESTORE,
          Concept.KICKSLOUNGE,
          Concept.STYLE,
          Concept.WELLCOLLECTIVE,
          Concept.RISE,
          Concept.INNOVATION,
        ],
      },
    },
  },
  storeConcept: {
    label: 'Concept',
    values: {
      [Concept.UNITE]: {
        label: 'Unite',
        isSelected: false,
        excludedValues: [NVSNSO.NSO, Brand.CONVERSE],
      },
      [Concept.BEACON]: {
        label: 'Beacon',
        isSelected: false,
        excludedValues: [Brand.NIKE],
      },
      [Concept.FACTORY]: {
        label: 'Factory',
        isSelected: false,
        excludedValues: [Brand.NIKE],
      },
      [Concept.CLEARANCE]: {
        label: 'Clearance',
        isSelected: false,
        excludedValues: [NVSNSO.NSO],
      },
      [Concept.RISE]: {
        label: 'Rise',
        isSelected: false,
        excludedValues: [NVSNSO.NVS, Brand.CONVERSE],
      },
      [Concept.STYLE]: {
        label: 'Style',
        isSelected: false,
        excludedValues: [NVSNSO.NVS, Brand.CONVERSE],
      },
      [Concept.INNOVATION]: {
        label: 'Innovation',
        isSelected: false,
        excludedValues: [NVSNSO.NVS, Brand.CONVERSE],
      },
      [Concept.WELLCOLLECTIVE]: {
        label: 'Well Collective',
        isSelected: false,
        excludedValues: [NVSNSO.NVS, Brand.CONVERSE],
      },
      [Concept.EMPLOYEESTORE]: {
        label: 'Employee Store',
        isSelected: false,
        excludedValues: [Brand.CONVERSE],
      },
      [Concept.KICKSLOUNGE]: {
        label: 'Kicks Lounge',
        isSelected: false,
        excludedValues: [Brand.CONVERSE],
      },
    },
  },
  siteStatus: {
    label: 'Status',
    values: {
      [Status.CLOSED]: {
        label: 'Closed',
        isSelected: false,
      },
      [Status.OPEN]: {
        label: 'Open',
        isSelected: false,
      },
      [Status.REMODEL]: {
        label: 'Remodel',
        isSelected: false,
      },
      [Status.PREOPEN]: {
        label: 'Preopen',
        isSelected: false,
      },
    },
  },
  deviceType: {
    label: 'Device',
    values: {
      [DeviceType.REGISTER]: {
        label: 'Register',
        isSelected: false,
      },
      [DeviceType.PC]: {
        label: 'PC',
        isSelected: false,
      },
      [DeviceType.SERVER]: {
        label: 'Server',
        isSelected: false,
      },
      [DeviceType.ROUTER]: {
        label: 'Router',
        isSelected: false,
      },
      [DeviceType.SWITCH]: {
        label: 'Switch',
        isSelected: false,
      },
      [DeviceType.VELOCLOUD]: {
        label: 'Velocloud',
        isSelected: false,
      },
      [DeviceType.FIREWALL]: {
        label: 'Firewall',
        isSelected: false,
      },
      [DeviceType.ATHLETE]: {
        label: 'Athlete',
        isSelected: false,
      },
      [DeviceType.TRAFFIC]: {
        label: 'Traffic',
        isSelected: false,
      },
      [DeviceType.TIMECLOCK]: {
        label: 'Timeclock',
        isSelected: false,
      },
      [DeviceType.RETAILMAC]: {
        label: 'Retailmac',
        isSelected: false,
      },
      [DeviceType.BACKOFHOUSECACHINGSERVER]: {
        label: 'Back Of House Caching Server',
        isSelected: false,
      },
      [DeviceType.FRONTOFHOUSECACHINGSERVER]: {
        label: 'Front Of House Caching Server',
        isSelected: false,
      },
      [DeviceType.BREAKROOMMAC]: {
        label: 'Breakroom Mac',
        isSelected: false,
      },
      [DeviceType.HYPERVISOR]: {
        label: 'Hypervisor',
        isSelected: false,
      },
    },
  },
};

export default filterOptions;

import React, {useState, useEffect} from 'react';
import {subDays} from 'date-fns';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange} from 'react-date-range';

const StyledDateRange = styled(DateRange)`
  background-color: ${props => props.theme.cardBg};
  .rdrDayNumber span {
    color: ${props => props.theme.text};
  }
  .rdrDayDisabled {
    background-color: ${props => props.theme.cardBg};
  }
  .rdrDayPassive {
    color: ${props => props.theme.disabled};
    opacity: 0;
  }
  .rdrDayDisabled .rdrDayNumber span {
    color: ${props => props.theme.disabled};
  }
  .rdrDayPassive .rdrDayNumber span {
    color: ${props => props.theme.grey};
  }
  .rdrMonthAndYearPickers select {
    color: ${props => props.theme.disabledStateText};
  }
  .rdrMonthAndYearPickers select option {
    background-color: ${props => props.theme.tableRow};
  }
  .rdrNextPrevButton {
    background-color: ${props => props.theme.cardBg};
  }
  .rdrPprevButton i {
    border-color: transparent ${props => props.theme.text} transparent transparent;
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent ${props => props.theme.text};
  }
`;

function ReactDateRange(props) {
  const {handleDateChange} = props;
  const theme = useSelector(state => state.theme);
  const {startDate, endDate} = useSelector(
    state => state.componentHistoricalComparison.epochDateRange
  );
  const themeSelected = theme[theme.select];
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleChange = item => {
    setState([item.selection]);
    handleDateChange(item.selection.startDate, item.selection.endDate);
  };

  useEffect(() => {
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  return (
    <StyledDateRange
      editableDateInputs={true}
      onChange={item => handleChange(item)}
      moveRangeOnFirstSelection={false}
      ranges={state}
      color={themeSelected.disabledStateText}
      rangeColors={[themeSelected.disabledStateBlue]}
      months={2}
      minDate={subDays(new Date(), 180)}
      maxDate={new Date()}
      direction="horizontal"
      showDateDisplay={false}
      showMonthAndYearPickers={true}
    />
  );
}

export default ReactDateRange;

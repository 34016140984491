import Action from '../../Action';
import Site from 'interfaces/Site';
import siteInfoFormat from './siteInfoFormat';
import mergeStatus from './mergeStatus';

interface rdxStore {
  minimumSiteInfo: MinimumSiteInfoState;
}

export interface MinimumSiteInfoApi {
  SiteId: string;
  SiteName: string;
  Brand: string;
  Geo: string;
  Address: {
    address1: string;
    address2: string;
    address3: string;
    area: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    iso2Country: string;
    county: string;
  };
}

export interface MinimumSiteInfoState {
  dataRetrieved: boolean;
  pending: boolean;
  data: Site[];
  error: any | null;
  siteStoreMerged: boolean;
}

export const initialState: MinimumSiteInfoState = {
  dataRetrieved: false,
  pending: false,
  data: [],
  error: null,
  siteStoreMerged: false,
};

const minimumSiteInfoReducer = (
  state: MinimumSiteInfoState = initialState,
  action: Action
): MinimumSiteInfoState => {
  switch (action.type) {
    case 'GET_MINIMUM_SITE_INFO_PENDING':
      return {
        ...state,
        dataRetrieved: false,
        pending: true,
      };

    case 'GET_MINIMUM_SITE_INFO_SUCCESS':
      if (state.siteStoreMerged) {
        return state;
      } else {
        const data = siteInfoFormat(action.payload);
        return {
          ...state,
          dataRetrieved: true,
          pending: false,
          data,
        };
      }

    case 'GET_MINIMUM_SITE_INFO_ERROR':
      return {
        ...state,
        dataRetrieved: false,
        pending: false,
        error: action.payload,
      };

    case 'MERGE_STORE_AND_SITE':
      const mergedSites = mergeStatus({sites: state.data, stores: action.payload});

      return {
        ...state,
        data: mergedSites,
        siteStoreMerged: true,
      };
    default:
      return state;
  }
};

export default minimumSiteInfoReducer;

export const rdxMinimumSiteInfoDataRetrieved = (state: rdxStore) =>
  state.minimumSiteInfo.dataRetrieved;
export const rdxMinimumSiteInfoPending = (state: rdxStore) => state.minimumSiteInfo.pending;
export const rdxMinimumSiteInfoData = (state: rdxStore) => state.minimumSiteInfo.data;
export const rdxMinimumSiteInfoError = (state: rdxStore) => state.minimumSiteInfo.error;
export const rdxIsSiteStoreMerged = (state: rdxStore) => state.minimumSiteInfo.siteStoreMerged;

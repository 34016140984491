import {css} from 'styled-components';

import TradeGothic from '../../assets/fonts/TradeGothicforNike-BdCn.ttf';
import TradeGothicLight from '../../assets/fonts/TradeGothicforNike365-Light.ttf';
import Futura from '../../assets/fonts/FuturaStd-Condensed.otf';
import FuturaCnXBd from '../../assets/fonts/NFUNCXBD.ttf';
import FuturaCnXBdObl from '../../assets/fonts/NFUNCXBI.ttf';
import TradeGothicBoldCondensed from '../../assets/fonts/TradeGothicforNike-BdCn.ttf';
import TradeGothicLT from '../../assets/fonts/TradeGothic-LT.ttf';
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/fonts/Roboto-Medium.ttf';

const fontsFace = css`
  @font-face {
    font-family: 'Text Regular';
    src: url(${RobotoRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Text Bold';
    src: url(${RobotoMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Futura';
    src: url(${Futura}) format('otf');
  }
  @font-face {
    font-family: 'Futura C XBd';
    src: url(${FuturaCnXBd}) format('truetype');
  }
  @font-face {
    font-family: 'Futura C XBI';
    src: url(${FuturaCnXBdObl}) format('truetype');
  }
  @font-face {
    font-family: 'Trade Gothic';
    src: url(${TradeGothic}) format('truetype');
  }
  @font-face {
    font-family: 'Trade Gothic Light';
    src: url(${TradeGothicLight}) format('truetype');
  }
  @font-face {
    font-family: 'Trade Gothic Bold Condensed';
    src: url(${TradeGothicBoldCondensed}) format('truetype');
  }
  @font-face {
    font-family: 'Trade Gothic LT';
    src: url(${TradeGothicLT}) format('truetype');
  }
`;

export default fontsFace;

import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import AlertIcon from '../RRPTable/TableStructure/AlertIcon';
import IAlert from 'features/RRP/interfaces/IAlert';
interface CardInfoItemProps {
  title: string;
  data: any;
  alert?: IAlert;
}

const StyledGridItem = styled.div`
  padding: 0.5rem 0.5rem;
`;

const StyledDataContainer = styled.div`
  display: flex;
  justify-items: center;
`;

const StyledHeader = styled.div`
  font-family: 'Trade Gothic Bold Condensed';
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${props => props.theme.disabledStateBlue};
`;

const StyledSGBody = styled(SGBody)`
  display: inline-block;
  margin-right: 0.25rem;
`;

export default function CardInfoItem({title, data, alert}: CardInfoItemProps) {
  return (
    <StyledGridItem>
      <StyledHeader>{title}</StyledHeader>
      <StyledDataContainer>
        <StyledSGBody>{data}</StyledSGBody>
        {alert?.value ? <AlertIcon alertValue={alert.value} /> : ''}
      </StyledDataContainer>
    </StyledGridItem>
  );
}

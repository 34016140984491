import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ListContainer from './components/ListContainer';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from './components/ListItem';
import TransferListSearch from 'components/Search/TransferListSearch';
import filterStoresByInput from './components/storeSearchFilter';
import ClearAll from './components/ClearAll';
import SGButton from '../CTA/SGButton';
import RectSkeleton from 'components/Skeletons/RectSkeleton';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';

// check white space

//#region styled components
const StyledContainer = styled.div`
  position: relative;
`;

const StyledStoreTransferList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 630px;
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  left: -0.5rem;
  &.hidden {
    display: none;
  }
`;

const StyledSpacer = styled.div`
  height: 40px;
  width: 100%;
`;

const StyledButton = styled(SGButton)`
  justify-self: flex-end;
`;

export const StyledButtonContainerDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

//#endregion styled components

//#region interface
export interface StoreTransferListProps {
  handleStores: (value: Stores) => void;
  selectedStores: Stores;
  variant?: 'default' | 'light';
}
//#endregion interface

function StoreTransferList({
  handleStores,
  selectedStores,
  variant = 'default',
}: StoreTransferListProps) {
  const [inputFilteredStores, setInputFilteredStores] = useState<Stores>([]);
  const [selectedRightStores, setSelectedRightStores] = useState<Stores>(selectedStores);
  const [isFieldActive, setIsFieldActive] = useState(false);

  const isStoreInfoListReady: boolean = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo.dataRetrieved
  );
  const storeInfoList: Stores = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo?.data
  );

  useEffect(() => {
    setSelectedRightStores(selectedStores);
  }, [selectedStores]);

  //#region handles
  const handleUserInput = (input: string) => {
    if (isStoreInfoListReady && input.length > 0) {
      const filteredStoreList = filterStoresByInput(input, storeInfoList);
      setInputFilteredStores(filteredStoreList);
      setIsFieldActive(true);
    } else {
      setInputFilteredStores([]);
      setIsFieldActive(false);
    }
  };

  const handleAddStore = (storeNumber: string) => {
    const matchByStoreNumber = storeInfoList.filter(
      store => store.StoreNumber.toString() === storeNumber
    );
    setSelectedRightStores(prev => [...prev, ...matchByStoreNumber]);
  };

  const handleRemoveStore = (storeNumber: string) => {
    const filteredStoreList = selectedRightStores.filter(
      store => store.StoreNumber.toString() !== storeNumber
    );
    setSelectedRightStores(filteredStoreList);
  };

  const handleClearStore = () => {
    setSelectedRightStores([]);
  };

  const handleHideListPanel = () => {
    setIsFieldActive(false);
  };

  const handleAddSelectedStoresButton = (selectedRightStores: Stores) => {
    handleStores(selectedRightStores);
    handleHideListPanel();
  };
  //#endregion handles

  return (
    <StyledContainer>
      {isStoreInfoListReady ? (
        <TransferListSearch
          id="store-transfer-field"
          handleUserInput={handleUserInput}
          className={variant}
        />
      ) : (
        <RectSkeleton>
          <TransferListSearch
            id="store-transfer-field"
            handleUserInput={handleUserInput}
            className={variant}
          />
        </RectSkeleton>
      )}
      <StyledStoreTransferList className={isFieldActive ? 'visible' : 'hidden'}>
        <ListContainer variant={'left'}>
          <StyledSpacer />
          <List>
            {inputFilteredStores.map(
              store =>
                !selectedRightStores.includes(store) && (
                  <ListItem
                    icon={'add'}
                    handleClick={() => handleAddStore(store.StoreNumber.toString())}
                    text={getStoreDisplayName(store)}
                    key={store.SiteId}
                  />
                )
            )}
          </List>
        </ListContainer>
        <ListContainer variant={'right'}>
          <ClearAll handleClick={handleClearStore} />
          <List>
            {selectedRightStores.map(store => (
              <ListItem
                icon={'minus'}
                handleClick={() => handleRemoveStore(store.StoreNumber.toString())}
                text={getStoreDisplayName(store)}
                key={store.SiteId}
              />
            ))}
          </List>
          <StyledButton onClick={() => handleAddSelectedStoresButton(selectedRightStores)}>
            Add Selected Stores
          </StyledButton>
        </ListContainer>
      </StyledStoreTransferList>
    </StyledContainer>
  );
}

export default StoreTransferList;
export type {Stores as Stores};

import React from 'react';
import styled from 'styled-components';
import TableCell from '../../../../../components/Tables/TableStructure/TableCell';
import MUITableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SectionHeader from '../../SectionHeader';
import StorePicker from '../../StorePicker';

const StyledTableHead = styled(MUITableHead)``;

const baseHeightPosition = 6.35;
const headerPosition = baseHeightPosition + 'rem';
const secondHeaderPosition = baseHeightPosition + 2.5625 + 'rem';

const StyledTableRow = styled(TableRow)`
  position: sticky;
  top: ${headerPosition};
  z-index: 4;
`;

const StyledLabelHead = styled(StyledTableRow)`
  top: ${secondHeaderPosition};
`;

const StyledHeaderSpan = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.tableText};
  text-transform: uppercase;
  &.disable {
    color: ${props => props.theme.tableText};
  }
`;

const StyledTableCell = styled(TableCell)`
  background-color: ${props => props.theme.cardBg};
  border-top: 1px solid ${props => props.theme.cardBg};
  border-bottom: 1px solid ${props => props.theme.cardBg};
  &.MuiTableCell-head {
    /* padding: 0px; */
    padding-top: 0px;
    padding-bottom: 0px;
  }
  &.Section-Headers {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  &.Cell-Header {
    padding-top: 0.25rem;
    padding-bottom: 0px;
  }
`;

const StyledSectionHeaders = styled(StyledTableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    color: ${props => props.theme.tableText};
  }
  &&&.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: ${props => props.theme.subtleText};
  }
`;

const GenerateHeaderSections = function GenerateHeaderSections(
  visibleColumns,
  handleTableColumns,
  handleStores,
  filters
) {
  function checkColSpan(sectionId, visibleColumns) {
    return visibleColumns.includes(sectionId);
  }
  const columnMap = [
    {
      colspan: 1,
      title: 'Store',
      icon: 'store',
      sectionId: 'store',
    },
    {
      colspan: checkColSpan('wanTransport', visibleColumns) ? 5 : 1,
      title: 'WAN Transport',
      icon: 'wanTransport',
      sectionId: 'wanTransport',
    },
    {
      colspan: checkColSpan('sdWan', visibleColumns) ? 2 : 1,
      title: 'SDWAN',
      icon: 'sdWan',
      sectionId: 'sdWan',
    },
    {
      colspan: checkColSpan('switching', visibleColumns) ? 4 : 1,
      title: 'Switching',
      icon: 'switching',
      sectionId: 'switching',
    },
    {
      colspan: checkColSpan('wireless', visibleColumns) ? 4 : 1,
      title: 'Wireless',
      icon: 'wireless',
      sectionId: 'wireless',
    },
  ];

  let headersToGenerate = [];

  if (
    filters === 'wanTransport' ||
    filters === 'sdWan' ||
    filters === 'switching' ||
    filters === 'wireless'
  ) {
    let filterColumn = [{...columnMap[0]}];
    let matched = columnMap.find(column => column.sectionId === filters);
    filterColumn.push(matched);
    headersToGenerate = filterColumn;
  } else {
    headersToGenerate = columnMap;
  }

  return headersToGenerate.map(column => {
    return (
      <StyledSectionHeaders colSpan={column.colspan} key={column.sectionId}>
        <SectionHeader
          icon={column.icon}
          title={column.title}
          sectionId={column.sectionId}
          handleTableColumns={handleTableColumns}
          handleStores={handleStores}
          visibleColumns={visibleColumns}
        />
      </StyledSectionHeaders>
    );
  });
};

function TableHead(props) {
  const {
    columns,
    order,
    orderBy,
    onRequestSort,
    visibleColumns,
    handleTableColumns,
    handleStores,
    selectedStores,
    isGeoView,
    filters,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const renderHeader = column => {
    if (column.customHeader && !isGeoView) {
      return <StorePicker handleStores={handleStores} selectedStores={selectedStores} />;
    }
    if (column.disableSort) {
      return <StyledHeaderSpan>{column.label}</StyledHeaderSpan>;
    } else {
      return (
        <StyledTableSortLabel
          active={orderBy === column.id}
          direction={orderBy === column.id ? order : 'asc'}
          onClick={createSortHandler(column.id)}
          IconComponent={ArrowDropDownIcon}
        >
          {column.label}
        </StyledTableSortLabel>
      );
    }
  };

  return (
    <>
      <StyledTableHead>
        <StyledTableRow>
          {columns.map(column => (
            <StyledTableCell style={{width: column.width}} key={column.id}></StyledTableCell>
          ))}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableHead>
        <StyledTableRow>
          {GenerateHeaderSections(visibleColumns, handleTableColumns, handleStores, filters)}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableHead>
        <StyledLabelHead>
          {columns.map(column => (
            <StyledTableCell
              key={column.id}
              align={column.align}
              sortDirection={orderBy === column.id ? order : false}
              className={`${column.customClass} Cell-Header`}
            >
              {renderHeader(column)}
            </StyledTableCell>
          ))}
        </StyledLabelHead>
      </StyledTableHead>
    </>
  );
}

export default TableHead;

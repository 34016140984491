import React from 'react';
import styled from 'styled-components';
import {RadioGroup, Radio} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from 'components/Paper';
import {StoreAction, isStoreAction} from './types/StoreAction';

const StyledRadio = styled(Radio)`
  color: ${props => props.theme.subtleText};
`;

interface AddOrRemoveRadioButtonsProps {
  storeAction: StoreAction;
  setStoreAction: (storeAction: StoreAction) => void;
}

function AddOrRemoveRadioButtons({storeAction, setStoreAction}: AddOrRemoveRadioButtonsProps) {
  return (
    <Paper>
      <RadioGroup
        name="store-action"
        value={storeAction}
        onChange={(_event, value) => {
          if (isStoreAction(value)) {
            setStoreAction(value);
          } else {
            setStoreAction('default');
          }
        }}
      >
        <FormControlLabel value="add" control={<StyledRadio />} label="Add" />
        <FormControlLabel value="remove" control={<StyledRadio />} label="Remove" />
      </RadioGroup>
    </Paper>
  );
}

export default AddOrRemoveRadioButtons;

import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

export interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  href?: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
  rest?: any;
}

const StyledButton = styled(MuiButton)`
  font-family: 'Text Regular';
  text-transform: capitalize;
  color: ${props => props.theme.defaultNavy};
  transition: 200ms;

  &.MuiButton-textPrimary {
    background-color: ${props => props.theme.navBlue};
  }
  &.MuiButton-textPrimary:hover {
    background-color: ${props => props.theme.navBlue}77;
  }
  &.MuiButton-textPrimary.Mui-disabled {
    color: ${props => props.theme.grey}77;
    background-color: ${props => props.theme.grey}77;
  }

  &.MuiButton-textSecondary {
    color: ${props => props.theme.disabledStateBlue};
  }
  &.MuiButton-textSecondary:hover {
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.navBlue}77;
  }
  &.MuiButton-textSecondary.active {
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.navBlue}77;
  }
  &.MuiButton-textSecondary.Mui-disabled {
    color: ${props => props.theme.grey}77;
    background-color: ${props => props.theme.grey}77;
  }

  &.MuiButton-outlinedPrimary {
    color: ${props => props.theme.navBlue};
    border: 2px solid ${props => props.theme.navBlue};
  }
  &.MuiButton-outlinedPrimary.active {
    color: ${props => props.theme.navDisabled};
    background-color: ${props => props.theme.navBlue};
  }
  &.MuiButton-outlinedPrimary:hover {
    color: ${props => props.theme.navBlue}77;
    border-color: ${props => props.theme.navBlue}77;
  }
  &.MuiButton-outlinedPrimary.active:hover {
    color: ${props => props.theme.navDisabled};
    background-color: ${props => props.theme.navBlue}77;
    border-color: ${props => props.theme.navBlue}77;
  }
  &.MuiButton-outlinedPrimary.Mui-disabled {
    color: ${props => props.theme.grey}77;
    border-color: ${props => props.theme.grey}77;
  }
`;

function Button({
  children,
  onClick,
  active,
  href,
  variant,
  size = 'medium',
  color = 'primary',
  disabled,
  className,
  ...rest
}: ButtonProps) {
  return (
    <StyledButton
      size={size}
      variant={variant}
      onClick={onClick}
      href={href}
      color={color}
      disabled={disabled}
      className={`${className} ${active ? 'active' : ''}`}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

export default Button;

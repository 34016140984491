import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';
import ToolTip from 'components/ToolTip';

const StyledToolTip = styled(ToolTip)`
  margin-top: 2px;
`;

const StyledSGBody = styled(SGBody)`
  color: ${props => props.theme.black};
  &:first-of-type {
    margin-top: 4px;
  }
`;

const StyledListItem = styled.li`
  padding-left: 0px;
`;

function VLANLegendToolTip() {
  return (
    <StyledToolTip
      title={
        <>
          <StyledSGBody variant={'b6'}>
            <strong>What is a VLAN?</strong>
          </StyledSGBody>
          <StyledSGBody variant={'b6'}>
            A <strong>Virtual Local Area Network</strong> is a group of network devices (computers,
            servers, registers, and other resources) that behave as if they are connected to a
            single network segment, even though they might not be.
          </StyledSGBody>
          <br />
          <StyledSGBody variant={'b6'}>VLANs have a number of advantages:</StyledSGBody>
          <ul>
            <StyledListItem>
              <StyledSGBody variant={'b6'}>
                <strong>VLANs let you easily segment your network.</strong>
              </StyledSGBody>
              <StyledSGBody variant={'b6'}>
                You can group users who communicate most frequently with each other in a common
                VLAN, regardless of physical location. Each group's traffic is contained largely
                within the VLAN, reducing extraneous traffic and impromving the efficiency of the
                whole network.
              </StyledSGBody>
            </StyledListItem>
            <StyledListItem>
              <StyledSGBody variant={'b6'}>
                <strong>VLANs are easy to manage.</strong>
              </StyledSGBody>
              <StyledSGBody variant={'b6'}>
                You can quickly add or change network nodes and make the other network changes
                through the switch web management interface rather than from the wiring closet
              </StyledSGBody>
            </StyledListItem>
            <StyledListItem>
              <StyledSGBody variant={'b6'}>
                <strong>VLANs provide increased performance.</strong>
              </StyledSGBody>
              <StyledSGBody variant={'b6'}>
                VLANs free up bandwith by limiting node-to-node and broadcast traffic throughout the
                network.
              </StyledSGBody>
            </StyledListItem>
            <StyledListItem>
              <StyledSGBody variant={'b6'}>
                <strong>VLANs enhance network security.</strong>
              </StyledSGBody>
              <StyledSGBody variant={'b6'}>
                VLANs create virtual boundaries that can be crossed only through a router.
              </StyledSGBody>
            </StyledListItem>
          </ul>
        </>
      }
    />
  );
}

export default VLANLegendToolTip;

import UserMessageItem from '../interface/UserMessageItem';

const contactRSSInstructions = 'Please reach out to the RSS team.';

const fetchResponseDictionary: Record<string, UserMessageItem> = {
  '400': {
    statusKey: 400,
    statusText: 'Bad Request',
    userText: `It looks like the selected site isn't returning any valid data. Try a different site.`,
    alertType: 'error',
    debugOnly: false,
  },
  '404': {
    statusKey: 404,
    statusText: 'Not Found',
    userText: `It looks like the API is not returning any valid data. ${contactRSSInstructions}`,
    alertType: 'error',
    debugOnly: false,
  },
  '408': {
    statusKey: 408,
    statusText: 'Request Timeout',
    userText: `The Cisco API timed out during the request for device:`,
    alertType: 'error',
    debugOnly: false,
  },
  '429': {
    statusKey: 429,
    statusText: 'Too Many Requests',
    userText:
      'Looks like too many requests have been sent in a row, please try again in a few minutes.',
    alertType: 'error',
    debugOnly: false,
  },
  '500': {
    statusKey: 500,
    statusText: 'Internal Server Error',
    userText: 'There was a problem completing your request please try again later.',
    alertType: 'error',
    debugOnly: false,
  },
  '504': {
    statusKey: 504,
    statusText: 'GatewayTimeout status code received from Switch feature cisco API!',
    userText: 'One of the switch devices in this store did not respond to the request.',
    alertType: 'error',
    debugOnly: false,
  },
  userAbortedRequest: {
    statusKey: 'userAbortedRequest',
    statusText: 'DOMException',
    userText: 'The user aborted a request.',
    alertType: 'info',
    debugOnly: true,
  },
  NO_SWITCH_FOUND: {
    statusKey: 'NO_SWITCH_FOUND',
    statusText: 'NO_SWITCH_FOUND status code received from SwitchData API.',
    userText: 'There was no active switches found for this site.',
    alertType: 'info',
    debugOnly: false,
  },
};

export default fetchResponseDictionary;

export const initialState = {
  storeStatusCountsPending: false,
  storeStatusCountsDataReady: false,
  storeStatusCounts: {
    na: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    emea: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    apla: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    gc: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
  },
  keyStoreStatusCountsPending: false,
  keyStatusCountsDataReady: false,
  keyStatusCounts: {
    na: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    emea: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    apla: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    gc: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
  },
};

const formatStoreCounts = apiData => {
  const geos = ['na', 'emea', 'gc', 'apla'];
  const nodes = ['up', 'issue', 'risk', 'down'];
  let results = {
    na: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    emea: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    apla: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
    gc: {
      up: 0,
      issue: 0,
      risk: 0,
      down: 0,
      total: 0,
    },
  };

  geos.forEach(geo => {
    nodes.forEach(node => {
      const matchGeoApiProperty = geo.toUpperCase();
      const matchNodeApiProperty = node.toUpperCase();
      if (apiData[matchGeoApiProperty]?.[matchNodeApiProperty] !== undefined) {
        results[geo][node] = apiData[matchGeoApiProperty][matchNodeApiProperty];
        results[geo].total += apiData[matchGeoApiProperty][matchNodeApiProperty];
      } else {
        results[geo][node] = 0;
      }
    });
  });

  return results;
};

const storeStatusCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LANDING_PAGE_COUNTS_PENDING':
      return {
        ...state,
        storeStatusCountsPending: true,
      };

    case 'GET_LANDING_PAGE_COUNTS_SUCCESS':
      let formatError = null;
      let landingPageCountsData;

      try {
        landingPageCountsData = formatStoreCounts(action.data);
      } catch {
        landingPageCountsData = state.landingPageCounts;
        formatError = 'API data mismatch';
      }

      return {
        ...state,
        storeStatusCountsPending: false,
        storeStatusCountsDataReady: true,
        storeStatusCounts: landingPageCountsData,
        StoreStatusError: formatError,
      };

    case 'GET_LANDING_PAGE_COUNTS_ERROR':
      return {
        ...state,
        error: action.error,
      };

    case 'GET_LANDING_PAGE_KEY_COUNTS_PENDING':
      return {
        ...state,
        keyStoreStatusCountsPending: true,
      };

    case 'GET_LANDING_PAGE_KEY_COUNTS_SUCCESS':
      let formatKeyError = null;
      let landingPageKeyCountsData;

      try {
        landingPageKeyCountsData = formatStoreCounts(action.data);
      } catch {
        landingPageKeyCountsData = state.landingPageCounts;
        formatKeyError = 'API data mismatch';
      }

      return {
        ...state,
        keyStoreStatusCountsPending: false,
        keyStatusCountsDataReady: true,
        keyStatusCounts: landingPageKeyCountsData,
        keyStatusError: formatKeyError,
      };

    case 'GET_LANDING_PAGE_KEY_COUNTS_ERROR':
      return {
        ...state,
        keyStatusError: action.error,
      };

    default:
      return state;
  }
};

export default storeStatusCountReducer;

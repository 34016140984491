import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiPlusBoxOutline} from '@mdi/js';
import {mdiMinusBoxOutline} from '@mdi/js';

export interface AddRemoveIconProps {
  variant: 'add' | 'minus';
}

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.text};
`;

function AddRemoveIcon({variant}: AddRemoveIconProps) {
  if (variant === 'add') {
    return <StyledIcon path={mdiPlusBoxOutline} size={0.7} />;
  } else {
    return <StyledIcon path={mdiMinusBoxOutline} size={0.7} />;
  }
}

export default AddRemoveIcon;

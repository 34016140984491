import React, {useState, ChangeEvent} from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Paper from '@material-ui/core/Paper';
import {FilterValues} from 'features/Inventories/interfaces/FilterOptions';

const StyledFormControl = styled(FormControl)`
  grid-area: 'filter';
  display: grid;
  grid-template-columns: fit-content(50%) fit-content(50%);
  grid-gap: 12px;
`;

const StyledGridItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    border: 1px solid ${props => props.theme.grey};
    top: 0px;
    transition: 100ms ease-in-out;
    transition-property: border;
  }
  &:hover .MuiOutlinedInput-notchedOutline,
  &:active .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${props => props.theme.text};
    color: ${props => props.theme.text};
  }

  .MuiOutlinedInput-input {
    font-family: Roboto;
    padding: 7px 34px 7px 12px;
    font-size: 12px;
    color: ${props => props.theme.grey};
    display: flex;
    align-items: center;
    transition: 100ms ease-in-out;
  }
  &:hover .MuiOutlinedInput-input,
  &.Mui-focused .MuiOutlinedInput-input {
    color: ${props => props.theme.text};
  }

  svg {
    color: ${props => props.theme.grey};
    width: 16px;
    transition: 100ms ease-in-out;
    transition-property: color;
  }
  &:hover svg,
  &.Mui-focused svg {
    color: ${props => props.theme.text};
  }

  em {
    font-style: normal;
    margin-top: 2px;
  }

  legend {
    display: none;
  }
`;

const StlyedInputLabel = styled.label`
  font-family: 'Trade Gothic Bold Condensed';
  font-size: 20px;
  text-wrap: nowrap;
  text-transform: uppercase;
`;

const StyledPaper = styled(Paper)`
  background-color: ${props => props.theme.cardBg};
  color: ${props => props.theme.text};
  border-radius: 10px;
  margin-top: 45px;
  max-height: 55vh;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
  font-family: Roboto;
  padding-left: 45px;
  transition: 100ms ease-in-out;
  transition-property: color, background-color;
  &.Mui-selected,
  &:hover {
    color: ${props => props.theme.navTextActive};
    background-color: ${props => props.theme.disabledStateBlue};
  }
  &.Mui-selected:hover {
    color: ${props => props.theme.navTextActive};
    background-color: ${props => props.theme.disabledStateBlue}B3;
  }
`;

const renderValue = (value: unknown) => {
  const valuesSelected = value as string[];
  if (valuesSelected === undefined || valuesSelected === null || valuesSelected.length === 0) {
    return 'All';
  } else {
    return valuesSelected.length + ' selected';
  }
};

type FilterValueKeys = keyof FilterValues;

interface FilterDropDownProps {
  label: string;
  selectedValues: string[];
  options: FilterValues;
  handleFilterChange: (value: string[]) => void;
  filteredValueKeys: FilterValueKeys[];
  className?: string;
}

function FilterDropDown({
  label,
  selectedValues,
  options,
  handleFilterChange,
  filteredValueKeys,
  className,
}: FilterDropDownProps) {
  const [open, setOpen] = useState(false);

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    const values = event.target.value as string[];
    let updateValue: string[] = [];

    if (selectedValues.length > values.length) {
      //remove
      updateValue = selectedValues.filter(filter => !values.includes(filter));
    } else {
      //add
      updateValue = values.filter(filter => !selectedValues.includes(filter));
    }

    handleFilterChange(updateValue);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledFormControl variant="outlined" className={className}>
      <StyledGridItems>
        <StlyedInputLabel>{label}</StlyedInputLabel>
      </StyledGridItems>
      <StyledGridItems>
        <StyledSelect
          labelId={label + 'select'}
          id={label + 'select'}
          value={selectedValues}
          onChange={handleChange}
          onClose={handleClose}
          open={open}
          label={label}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={renderValue}
          onOpen={handleOpen}
          MenuProps={{
            getContentAnchorEl: null,
            PaperProps: {
              component: StyledPaper,
            },
            transformOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          }}
          multiple
          displayEmpty
        >
          {filteredValueKeys.map(key => {
            return (
              <StyledMenuItem key={key} value={key}>
                {options[key].label}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </StyledGridItems>
    </StyledFormControl>
  );
}

export default FilterDropDown;

import fetchResponseDictionary from './dictionary/fetchResponseDictionary';
import UserMessageItem from './interface/UserMessageItem';

interface ErrorMessageDictionaryProps {
  msgKey: string | number;
}

function ErrorMessageDictionary({msgKey}: ErrorMessageDictionaryProps) {
  const statusKey = String(msgKey);
  const fetchResponseItem: UserMessageItem = fetchResponseDictionary[statusKey];
  const messages: UserMessageItem[] = [fetchResponseItem];
  const foundMessages: UserMessageItem[] = messages.filter(
    userMessageItem => userMessageItem !== undefined
  );

  const dictionaryItemNotFound: UserMessageItem = {
    statusKey: 'dictionaryItemNotFound',
    statusText: `Status key was not found in the dictionary: ${statusKey}`,
    userText: 'An error occured that we were not expecting. Please reach out to the RSS team.',
    alertType: 'info',
    debugOnly: true,
  };

  const multipleItemsFound: UserMessageItem = {
    statusKey: 'multipleDictionaryItemsFound',
    statusText: `More than 1 status key found in dictionary : ${statusKey}`,
    userText: 'An error occured that we were not expecting. Please reach out to the RSS team.',
    alertType: 'info',
    debugOnly: true,
  };

  // default message set as dictionaryItemNotFound
  let dictionaryItemResult: UserMessageItem = dictionaryItemNotFound;

  if (foundMessages.length > 1) {
    dictionaryItemResult = multipleItemsFound;
  } else if (foundMessages.length === 0) {
    dictionaryItemResult = dictionaryItemNotFound;
  } else if (foundMessages.length === 1) {
    dictionaryItemResult = foundMessages[0];
  }

  return dictionaryItemResult;
}

export default ErrorMessageDictionary;

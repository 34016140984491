import convertToRRPData from './convertToRRPData';

// todo: https://jira.nike.com/browse/GRSTI-1682
// Create returned data validation check

export default function dataReducerRRP(data, selectedStores) {
  // initialize an empty array to store the flattened and converted data
  let results = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const matchingSelectedStore = selectedStores.find(
        selectedStore => selectedStore.SiteId === key
      );
      if (matchingSelectedStore === undefined || matchingSelectedStore === null) {
        console.log('Missing SiteId', key);
      } else {
        results.push(convertToRRPData(data[key], matchingSelectedStore));
      }
    }
  }

  // return the resulting array of converted data for all stores
  return results;
}

import {Stores} from 'interfaces/Stores';
import {StoreConfigStatus} from './types/StoreConfigStatus';

const getStoreConfigStatus: (s1: Stores, s2: Stores) => StoreConfigStatus = (
  selectedStoresWithoutServices: Stores,
  selectedStores: Stores
) => {
  let storeConfigStatus: StoreConfigStatus;

  if (selectedStoresWithoutServices.length === 0) {
    storeConfigStatus = 'hasServices';
  } else if (selectedStoresWithoutServices.length === selectedStores.length) {
    storeConfigStatus = 'hasNoServices';
  } else {
    storeConfigStatus = 'mixed';
  }

  return storeConfigStatus;
};

export default getStoreConfigStatus;

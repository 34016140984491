import convertToRRPData from './convertToRRPData';

// todo: https://jira.nike.com/browse/GRSTI-1682
// Create returned data validation check

const formatStoreObj = function formatStoreObj(apiData) {
  const {
    internetAProvisioned_Upload,
    internetAProvisioned_Download,
    internetAProvisioned_Type,
    internetBProvisioned_Upload,
    internetBProvisioned_Download,
    internetBProvisioned_Type,
    internetAPeakUtilization_Upload,
    internetAPeakUtilization_Download,
    internetBPeakUtilization_Upload,
    internetBPeakUtilization_Download,
    switchProvisioned,
    switchPortUtilization_Total,
    switchPortUtilization_Used,
    switchAPPortUtilization_Total,
    switchAPPortUtilization_Used,
    switchPoEBudget_Total,
    switchPoEBudget_Used,
    switchPoEStackMembers_AtCapacity,
    wirelessAPHardwareProvisioned,
    wirelessAPUpStatus_Total,
    wirelessAPUpStatus_Up,
    wirelessClientsTotal,
    wirelessClientGood,
    wirelessAPHealth,
    sdwanHardwareProvisioned,
    sdwanEffectivenessQoE,
    cellularBackupProvisioned_POLR,
  } = apiData;

  let storeObj = {
    provisioned: {
      internetAProvisioned_Upload,
      internetAProvisioned_Download,
      internetAProvisioned_Type,
      internetBProvisioned_Upload,
      internetBProvisioned_Download,
      internetBProvisioned_Type,
    },
    utilization: {
      internetAPeakUtilization_Upload,
      internetAPeakUtilization_Download,
      internetBPeakUtilization_Upload,
      internetBPeakUtilization_Download,
    },
    storeSwitch: {
      switchProvisioned,
      switchPortUtilization_Total,
      switchPortUtilization_Used,
      switchAPPortUtilization_Total,
      switchAPPortUtilization_Used,
      switchPoEBudget_Total,
      switchPoEBudget_Used,
      switchPoEStackMembers_AtCapacity,
    },
    wirelessAP: {
      wirelessAPHardwareProvisioned,
      wirelessAPUpStatus_Total,
      wirelessAPUpStatus_Up,
    },
    wireless: {
      wirelessClientsTotal,
      wirelessClientGood,
      wirelessAPHealth,
    },
    vmWareSDWAN: {
      sdwanHardwareProvisioned,
      sdwanEffectivenessQoE,
      cellularBackupProvisioned_POLR,
    },
  };
  return storeObj;
};

export default function monthlyReportReducerRRP(data, selectedStores) {
  // initialize an empty array to store the flattened and converted data
  let results = [];
  for (let i = 0; i < data.length; i++) {
    const matchingSelectedStore = selectedStores.find(
      selectedStore => selectedStore.SiteId === data[i].siteId
    );
    if (matchingSelectedStore === undefined || matchingSelectedStore === null) {
      console.log('Missing SiteId', data[i].siteId);
    } else {
      const formattedStoreObj = formatStoreObj(data[i]);
      results.push(convertToRRPData(formattedStoreObj, matchingSelectedStore));
    }
  }

  // return the resulting array of converted data for all stores
  return results;
}

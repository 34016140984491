import Site from 'interfaces/Site';
import {Store} from 'interfaces/Stores';
import Status from '../../../interfaces/Status';

interface mergeStatusProps {
  sites: Site[];
  stores: Store[];
}

const statusDictionary: Record<string, Status> = {
  preopen: Status.PREOPEN,
  open: Status.OPEN,
  remodel: Status.REMODEL,
  closed: Status.CLOSED,
};

function mergeStatus({sites, stores}: mergeStatusProps): Site[] {
  const sitesWithStatus: Site[] = sites.map(site => {
    const store = stores.find(store => store.SiteId === site.siteId);

    if (store) {
      const status = statusDictionary[store.BusinessStatus.toLowerCase()]
        ? statusDictionary[store.BusinessStatus.toLowerCase()]
        : store.BusinessStatus;
      const storeConcept = store.StoreConcept;
      return {
        ...site,
        storeConcept,
        status,
      };
    }

    return site;
  });

  return sitesWithStatus;
}

export default mergeStatus;

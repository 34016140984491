import {createGlobalStyle} from 'styled-components';
import Fonts from './fonts';

export default createGlobalStyle`
    ${Fonts}

    html {
        scroll-behavior: smooth;
    }
    
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: ${props => props.theme.disabled};
    }

    ::-webkit-scrollbar:vertical {
        width: 10px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 1px solid ${props => props.theme.navText}66;
        background-color: ${props => props.theme.navText}66;

        &:hover {
            border: 1px solid ${props => props.theme.navText}99;
            background-color: ${props => props.theme.navText}99;
        }
    }

    ::-webkit-resizer {
        display: none;
    }
`;

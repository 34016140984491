enum StatusSummaryFilterEnum {
  All = 'all',
  Critical = 'critical',
  Major = 'major',
  Minor = 'minor',
  Healthy = 'healthy',
  Unknown = 'unknown',
  WANTransport = 'wanTransport',
  SDWAN = 'sdWan',
  switching = 'switching',
  wireless = 'wireless',
}
export default StatusSummaryFilterEnum;
function addIDFromTree(treeMenu) {
  const generateID = (nodes, parentNodeId) => {
    let results = [];
    nodes.forEach(node => {
      let id = (parentNodeId ? parentNodeId + '-' : '') + node.name.replace(/\s+/g, '');
      let nodeWithId = {
        ...node,
        id: id,
        children: Array.isArray(node.children) ? generateID(node.children, id) : null,
      };
      results.push(nodeWithId);
    });
    return results;
  };

  return generateID(treeMenu);
}

export default addIDFromTree;

export const getMockGeoKeyData = (state = false, action) => {
  switch (action.type) {
    case 'GET_MOCKDATA_GEOKEY':
      return state;
    case 'GET_MTRADE':
      return state.down;
    default:
      return state;
  }
};

export const getMockStoreStatusData = (state = false, action) => {
  switch (action.type) {
    case 'GET_MOCKDATA_STORE':
      return state;
    default:
      return state;
  }
};

export const getmockGeoData = (state = false, action) => {
  switch (action.type) {
    case 'GET_MOCKDATA_GEO':
      return state;
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';
import Paper from './Paper';
import PaperTitle from './PaperTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {maintenance} from '../constants/releaseConfig';
import Button from './CTA/SGButton';

const StyledMaintenance = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #33333388;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const StyledPaper = styled(Paper)`
  width: 25%;
  min-width: 500px;
`;

function Maintenance(props) {
  const {maintenanceWindow, setMaintenanceWindow} = props;
  const {messageDate, messageContent} = maintenance;

  return (
    <StyledMaintenance>
      <StyledPaper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperTitle id="transition-modal-title">
              Retail Insights (In Development) - Data Warning
            </PaperTitle>
          </Grid>
          <Grid item xs={12}>
            <code>{messageDate}</code>
          </Grid>
          <Grid item xs={12}>
            <Typography>{messageContent}</Typography>
          </Grid>
          {/* {admin ? (
            <Grid item xs={12}>
              <Button onClick={() => setMaintenanceWindow(!maintenanceWindow)}>
                Admin: Dismiss Window
              </Button>
            </Grid>
          ) : (
            ''
          )} */}
          <Grid item xs={12}>
            <Button onClick={() => setMaintenanceWindow(!maintenanceWindow)}>Continue</Button>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledMaintenance>
  );
}

export default Maintenance;

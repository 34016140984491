import React from 'react';
import styled from 'styled-components';
import MUIPaper from '@material-ui/core/Paper';

const StyledPaper = styled(MUIPaper)`
  background-color: ${props => props.theme.cardBg};
  padding: 14px 24px;
  color: ${props => props.theme.text};
  border-radius: 12px;
`;

function Paper(props) {
  const {children, className, ...rest} = props;

  return (
    <StyledPaper elevation={1} className={className} {...rest}>
      {children}
    </StyledPaper>
  );
}

export default Paper;

import React, {useState, ReactElement, MouseEvent} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SGBody from 'components/Typography/SGBody';
import {useSelector} from 'react-redux';

const StyledControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  color: ${props => props.theme.text};
  text-transform: none;
  text-decoration: underline;
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin-left: 0.25rem;
  color: ${props => props.theme.text};
`;

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    min-width: 10rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  &.MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledLink = styled.a`
  background-color: ${props => props.theme.cardBg};
  color: ${props => props.theme.text};
  padding: 4px;
  transition: 200ms;
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.disabledStateBlue}33;
  }
`;

interface SwitchDiagramControlsProps {
  switchStackNames: string[];
  anchorIdPrefix: string;
  expandedSwitches: string[];
  handleCollapseExpandAll: () => void;
}

function SwitchDiagramControls({
  switchStackNames,
  anchorIdPrefix,
  expandedSwitches,
  handleCollapseExpandAll,
}: SwitchDiagramControlsProps): ReactElement {
  // This theming method is the only way I can figure out how to
  // style the popover generated from the Menu
  const theme = useSelector((state: any) => state.theme);
  const themeSelected = theme[theme.select];

  // Prevents the anchor links from double stacking the id
  const windowLocation: string = window.location.href;
  const baseUrl: string = windowLocation.split('#')[0];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <StyledControlContainer>
      <StyledButton aria-controls="switch-stack-jump" aria-haspopup="true" onClick={handleClick}>
        <SGBody variant={'b4'} className={'text-color'}>
          Jump to switch stack
        </SGBody>
        <StyledExpandMoreIcon />
      </StyledButton>
      <StyledMenu
        id="switch-stack-jump"
        anchorEl={anchorEl}
        keepMounted
        // Boolean() Explained: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Boolean#boolean_coercion
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: `${themeSelected.cardBg}`,
            padding: '.5rem',
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {switchStackNames.map(switchStack => (
          <StyledMenuItem key={switchStack}>
            <StyledLink href={`${baseUrl}#${anchorIdPrefix}${switchStack}`} onClick={handleClose}>
              {switchStack}
            </StyledLink>
          </StyledMenuItem>
        ))}
      </StyledMenu>
      <StyledButton onClick={handleCollapseExpandAll}>
        {expandedSwitches.length > 0 ? (
          <SGBody variant={'b4'} className={'text-color'}>
            Collapse all switch stacks
          </SGBody>
        ) : (
          <SGBody variant={'b4'} className={'text-color'}>
            Expand all switch stacks
          </SGBody>
        )}
      </StyledButton>
    </StyledControlContainer>
  );
}

export default SwitchDiagramControls;

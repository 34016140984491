import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  CRITICAL,
  UNKNOWN,
} = AlertSeverityEnum;

export default function wirelessAPUpStatusAlertValue(
  wirelessAPUpStatus_Up: number,
  wirelessAPUpStatus_Total: number
): IAlert {
  if (
    wirelessAPUpStatus_Up === null ||
    wirelessAPUpStatus_Total === null ||
    isNaN(wirelessAPUpStatus_Up) ||
    isNaN(wirelessAPUpStatus_Total)
  ) {
    return defaultNullAlert(['wirelessAPUpStatus_Up', 'wirelessAPUpStatus_Total']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  const percentUp = wirelessAPUpStatus_Up / wirelessAPUpStatus_Total;
  if (percentUp < 1) {
    alert.value = CRITICAL;
    alert.issue.push('One or more Wireless APs is down and not currently functional.');
    alert.effect.push('Wireless signal quality is degraded in some areas of the store, resulting in slow or intermittently failing network connectivity for any wirelessly connected devices in those areas');
    alert.solution.push('Contact Wireless Network Engineering urgently to remediate down Wireless AP.');
  } else if (percentUp > 1) {
    alert.value = UNKNOWN;
    alert.issue.push('There are more Wireless AP up than there are Wireless AP total');
    alert.effect.push('Data coming in is potentially incorrect.');
    alert.solution.push('Please check the return values of wirelessAPUpStatus_Up and wirelessAPUpStatus_Total');
  }

  return alert;
}

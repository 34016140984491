import React, {ReactNode, useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import SGTitle from 'components/Typography/SGTitle';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  flex-direction: column;
`;

const StyledLoadingText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 0.5rem;
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 80%;
  border-radius: 5px;
  &.MuiLinearProgress-root {
    height: 10px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${props => props.theme.text}0D;
  }
  .MuiLinearProgress-bar1Buffer {
    background-color: ${props => props.theme.text}19;
  }
  .MuiLinearProgress-bar2Buffer {
    background-color: ${props => props.theme.text}22;
  }
  .MuiLinearProgress-dashedColorPrimary {
    background-size: 500px 10px;
    background-image: linear-gradient(
      90deg,
      transparent,
      ${props => props.theme.text}22,
      transparent
    );
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: 'MuiSkeleton-keyframes-wave' 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, ${props => props.theme.text}22, transparent);
  }
`;

const renderLoadingSteps = function renderLoadingSteps(percentage: number) {
  const x = Math.round(percentage);

  if (x < 70) {
    return 'Loading... Thank you for your patience.';
  } else if (x >= 70 && x < 96) {
    return 'Optimizing Switch Data...';
  } else {
    return 'Loading Complete';
  }
};

const progressGenerator = (
  currentProgress: number,
  currentBuffer: number,
  maxValue: number,
  minValue: number
) => {
  const diff = Math.random() * (maxValue - minValue) + minValue;
  const diff2 = Math.random() * (maxValue - minValue) + minValue;

  if (currentProgress < 96 && currentProgress + diff >= 95) {
    const newBuffer = currentBuffer + diff + diff2 <= 100 ? currentBuffer + diff + diff2 : 100;
    return {
      newProgress: 95,
      newBuffer: newBuffer,
    };
  } else if (currentProgress >= 96) {
    return {
      newProgress: 100,
      newBuffer: 100,
    };
  } else {
    return {
      newProgress: currentProgress + diff,
      newBuffer: currentProgress + diff + diff2,
    };
  }
};

interface SwitchLoadingIndicatorProps {
  isLoaded: boolean;
  switchFetchingError: boolean;
  siteId?: string;
  idfName?: string;
  children?: ReactNode;
}

function SwitchLoadingIndicator({
  isLoaded,
  siteId,
  switchFetchingError,
  idfName,
  children,
}: SwitchLoadingIndicatorProps) {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});

  useEffect(() => {
    progressRef.current = () => {
      let maxValue = 5;
      let minValue = 1;
      if (isLoaded) {
        maxValue = 50;
        minValue = 25;
      } else if (progress < 50) {
        maxValue = 10;
      } else if (progress < 70) {
        maxValue = 5;
      } else if (progress < 90) {
        maxValue = 2;
      } else if (progress < 96) {
        maxValue = 1;
      }

      const {newProgress, newBuffer} = progressGenerator(progress, buffer, maxValue, minValue);

      if (!isLoaded && progress < 96) {
        setProgress(newProgress);
        setBuffer(newBuffer);
      } else if (progress >= 99) {
        setProgress(100);
      } else {
        setBuffer(newBuffer);
      }
    };
  });

  useEffect(() => {
    if (isLoaded) {
      setProgress(99);
      setBuffer(100);
    }
  }, [isLoaded]);

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    if (switchFetchingError || progress >= 100) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress, siteId, idfName, switchFetchingError]);

  return (
    <>
      {progress < 100 && (
        <>
          <StyledContainer id={'progress-container'}>
            <StyledLoadingText id={'progress-container-text'}>
              <SGTitle variant={'t4'} className={'text-color'}>
                {switchFetchingError
                  ? `Something wen't wrong, please refresh the page or try another site.`
                  : renderLoadingSteps(progress)}
              </SGTitle>
            </StyledLoadingText>
            <StyledLinearProgress
              id={'progress-container-bar'}
              variant="buffer"
              value={progress}
              valueBuffer={buffer}
            />
          </StyledContainer>
        </>
      )}
      {progress >= 100 && children}
    </>
  );
}

export default SwitchLoadingIndicator;

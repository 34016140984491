import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

const StyledCircleChart = styled.div`
  max-width: 16rem;
  .apexcharts-datalabel-label {
    font-style: italic;
    text-transform: uppercase;
  }
  .flex-grid {
    display: flex;
    align-items: center;
  }
`;

function CircleChart(props) {
  const {data, loaded} = props;

  const theme = useSelector(state => state.theme);
  const themeSelected = theme[theme.select];
  const {colors, altColors, tableText} = themeSelected;
  const [chartColors] = useState(colors);

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    if (loaded && data?.circle?.series && data?.circle?.labels && data?.circle?.labels) {
      setChartData(data.circle.series);
      setChartLabels(data.circle.labels);
      setFilter(data.circle.labels);
      ApexCharts.exec('circle-events', 'updateOptions', {
        labels: data.circle.labels,
      });
    }
  }, [data, loaded]);

  // Color matching based on series selected
  useEffect(() => {
    const matchColors = (seriesSelected, labels) => {
      let chartColors = [...altColors];
      for (let i = 0; i < labels.length; i++) {
        if (seriesSelected.indexOf(labels[i]) === -1) {
          chartColors[i] = tableText;
        }
      }
      return chartColors;
    };

    if (loaded) {
      ApexCharts.exec('circle-events', 'updateOptions', {
        colors: matchColors(filter, chartLabels),
        labels: chartLabels,
      });
    }
  }, [filter, loaded, chartLabels, tableText, colors, altColors]);

  const [chartOptions] = useState({
    options: {
      colors: chartColors,
      labels: chartLabels,
      chart: {
        id: 'circle-events',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '80%',
            labels: {
              show: true,
              name: {
                color: themeSelected.subtleText,
                fontFamily: 'Trade Gothic Bold Condensed',
                fontSize: '14px',
                offsetY: 0,
              },
              value: {
                fontFamily: 'Futura C XBI',
                fontSize: '14px',
                color: themeSelected.text,
                offsetY: 0,
              },
              total: {
                label: 'Active Events',
                showAlways: true,
                fontFamily: 'Trade Gothic Bold Condensed',
                fontSize: '14px',
                show: true,
                color: themeSelected.subtleText,
              },
            },
          },
        },
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        colors: themeSelected.cardBg,
        width: 3,
      },
      noData: {
        text: 'Loading...',
      },
      dataLabels: {
        enabled: false,
      },
    },
  });

  return (
    <StyledCircleChart>
      <Chart options={chartOptions.options} series={chartData} type="donut" />
    </StyledCircleChart>
  );
}

export default CircleChart;

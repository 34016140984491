import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';

interface ModalItemProps {
  subTitle?: string;
  value?: string | number;
  big: boolean;
  key: number;
}

const StyledInfoItem = styled.div`
  word-wrap: break-word;
  flex: 1 0 0;
`;

const StyledSubTitle = styled.p<{big: boolean}>`
  font-family: 'Trade Gothic Light';
  font-family: 'Trade Gothic Bold Condensed';
  margin: 0 0 4px 0;
  text-transform: uppercase;
  color: ${props => props.theme.disabledStateBlue};
  font-size: ${props => (props.big ? 1 : 0.9)}rem;
`;

const StyledPortDetail = styled(SGBody)`
  min-height: 1.5rem;
`;

function ModalItem({subTitle, value, big = false}: ModalItemProps) {
  return (
    <StyledInfoItem>
      {subTitle && <StyledSubTitle big={big}>{subTitle}</StyledSubTitle>}
      {value && <StyledPortDetail variant={big ? 'b1' : 'b2'}>{value}</StyledPortDetail>}
    </StyledInfoItem>
  );
}

export default ModalItem;

import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  UNKNOWN,
} = AlertSeverityEnum;

export default function cellularBackupAlertValue(cellularBackupProvisioned_POLR: boolean): IAlert {
  if (cellularBackupProvisioned_POLR === null) return defaultNullAlert(['cellularBackupProvisioned_POLR']);

  try {
    let alert: IAlert = {
      value: HEALTHY,
      issue: [],
      effect: [],
      solution: [],
    }

    if (cellularBackupProvisioned_POLR === false) {
      alert.value = MINOR;
      alert.issue.push('Cellular Backup is not installed.');
      alert.effect.push('Will result in full network outage for the store if WAN Transport A & B both fail.');
      alert.solution.push('Consider installing cellular backup.');
    }
    return alert;
  } catch (err) {
    return {
      value: UNKNOWN,
      issue: ['There was an error in internetAProvisionedAlertValue.js'],
      effect: ['When parsing through the returned api data the catch block was intitiated.'],
      solution: ['Check the file internetAProvisionedAlertValue.js for values the function is not expecting.'],
    };
  }
}

import React, {MouseEvent, useEffect, useState} from 'react';
import styled from 'styled-components';
import MuiTreeItem from '@material-ui/lab/TreeItem';
import SGBody from '../../Typography/SGBody';
import SGTitle from '../../Typography/SGTitle';
import SGSwitch from '../../CTA/SGSwitch';
import {useSelector, useDispatch} from 'react-redux';
import {historicalComparisonAddRemoveSelectedData} from '../../../redux/actions';

export interface TreeItemProp {
  nodeId: string;
  label: string;
  colorIndex?: number;
  className?: string;
  children?: React.ReactNode;
}

const StyledTreeItem = styled(MuiTreeItem)`
  .MuiTreeItem-content {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  &.hidden {
    display: none;
  }

  &.active > .MuiTreeItem-content {
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.text};
    }
  }

  &.ci0 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[0]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[0]};
    }
  }
  &.ci1 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[1]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[1]};
    }
  }
  &.ci2 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[2]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[2]};
    }
  }
  &.ci3 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[3]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[3]};
    }
  }
  &.ci4 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[4]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[4]};
    }
  }
  &.ci5 > .MuiTreeItem-content {
    background-color: ${props => props.theme.sgColors[5]}66;
    .text-color,
    .MuiTreeItem-iconContainer {
      color: ${props => props.theme.sgColors[5]};
    }
  }
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBody = styled(SGBody)`
  color: ${props => props.theme.disabledStateBlue};
`;

const StyledTitle = styled(SGTitle)`
  &.text-color {
    color: ${props => props.theme.disabledStateBlue};
  }
`;

function TreeItem({nodeId, label, colorIndex, className, children}: TreeItemProp) {
  const dispatch = useDispatch();
  const handleLabelClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const handleSwitchClick = (id: string) => {
    dispatch(historicalComparisonAddRemoveSelectedData(id));
  };

  interface HistoricalComparison {
    componentHistoricalComparison: {
      selectedData: Array<string>;
      isActive: Array<string>;
    };
  }

  interface HistoricalComparisonActiveList {
    componentHistoricalComparison: {
      isActive: Array<string>;
    };
  }

  const selectedData = useSelector(
    (state: HistoricalComparison) => state.componentHistoricalComparison.selectedData
  );

  const isActive = useSelector(
    (state: HistoricalComparisonActiveList) => state.componentHistoricalComparison.isActive
  );

  const [isChecked, setIsChecked] = useState(false);
  const [activeChildren, setActiveChildren] = useState(false);

  useEffect(() => {
    if (selectedData.indexOf(nodeId) > -1) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedData, nodeId]);

  useEffect(() => {
    if (isActive.indexOf(nodeId) > -1) {
      setActiveChildren(true);
    } else {
      setActiveChildren(false);
    }
  }, [isActive, nodeId]);

  return (
    <StyledTreeItem
      nodeId={nodeId}
      label={
        <StyledLabel>
          {nodeId.split('-').length > 1 ? (
            <StyledBody className={'text-color'}>{label}</StyledBody>
          ) : (
            <StyledTitle variant={'t4'} className={'text-color'}>
              {label}
            </StyledTitle>
          )}
          <SGSwitch
            onClick={() => handleSwitchClick(nodeId)}
            colorIndex={colorIndex}
            checked={isChecked}
          />
        </StyledLabel>
      }
      onLabelClick={handleLabelClick}
      className={`${className} ${isChecked ? 'ci' + colorIndex : ''} ${
        activeChildren ? 'active' : ''
      } `}
    >
      {children}
    </StyledTreeItem>
  );
}

export default TreeItem;

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MUITooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

interface ToolTipProps {
  title: string;
  color?: string;
  children?: ReactNode;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
  },
  arrow: {
    color: '#ffffff',
  },
}));

const StyledToolTip = styled(MUITooltip)`
  .MuiTooltip-tooltip {
    background-color: ${props => props.theme.text};
    color: ${props => props.theme.primary};
  }
`;

const StyledIcon = styled(HelpOutlineOutlinedIcon)`
  color: ${props => props.theme.titleText};
  font-size: 1rem;
  margin-left: 6px;
  &.red {
    color: ${props => props.theme.red};
  }
  &.orange {
    color: ${props => props.theme.orange};
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.green {
    color: ${props => props.theme.green};
  }
`;

function SGToolTip({title, color, children}: ToolTipProps) {
  const classes = useStyles();

  return (
    <StyledToolTip
      title={title}
      arrow
      interactive
      className={'test'}
      classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
    >
      <StyledIcon className={color} />
    </StyledToolTip>
  );
}

export default SGToolTip;

import React from 'react';
import styled from 'styled-components';

// import Layout from './Layout';
import StyledContainer from '../components/Container';
import Grid from '@material-ui/core/Grid';
import CloudImg from '../assets/images/contact_cloud.svg';

const StyledBrowserWarning = styled.div`
  h1.title {
    color: #282828;
    font-size: 4em;
    font-family: 'Futura C XBd';
    margin: 0;
  }
  p {
    font-size: 1rem;
    color: #282828;
  }
  img {
    max-width: 100%;
  }
  a:link {
    color: #282828;
  }
  a:hover {
    color: #ced5db;
  }
  a:visited {
    color: #282828;
  }
`;

const StyledTitle = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  color: #282828;
  font-size: 4em;
  margin: 0;
`;

function BrowserWarning() {
  return (
    <StyledBrowserWarning>
      <StyledContainer>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <StyledTitle>Welcome to Insights, but...</StyledTitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              <strong>
                Unfortunately Trade Insights is not supporting this browser. May we recommend that
                you use the latest version of Chrome, Safari, Firefox, or Edge?
              </strong>
            </p>
            <p>Please contact us if you have any questions.</p>
            <p>
              <a href="mailto:Lst-GT.TechOps.RSS.Insights@nike.com?subject=Retail%20Insight%20Support">
                Lst-GT.TechOps.RSS.Insights@nike.com
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={CloudImg} alt={'Contact'} />
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledBrowserWarning>
  );
}

export default BrowserWarning;

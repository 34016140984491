import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import TradeItem from './TradeItem';
import {useSelector} from 'react-redux';
import {geoCodes} from '../../../interfaces/IGeoAcronym';
import IStoreStatusState from '../../../interfaces/IStoreStatusState';
import Skeleton from '../../Skeletons/RectSkeleton';

interface GeoTradeStatusProps {
  geo: geoCodes;
}

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
`;

function GeoTradeStatus({geo}: GeoTradeStatusProps) {
  const {storeStatusCountsDataReady, storeStatusCounts} = useSelector(
    (state: IStoreStatusState) => state.storeStatusCount
  );
  const [down, setDown] = useState(0);
  const [risk, setRisk] = useState(0);
  const [issue, seIssue] = useState(0);
  const [up, setUp] = useState(0);

  useEffect(() => {
    if (!storeStatusCountsDataReady) {
      setDown(0);
      setRisk(0);
      seIssue(0);
      setUp(0);
    } else if (geo === 'na') {
      const {up, issue, risk, down} = storeStatusCounts.na;
      setDown(down);
      setRisk(risk);
      seIssue(issue);
      setUp(up);
    } else if (geo === 'emea') {
      const {up, issue, risk, down} = storeStatusCounts.emea;
      setDown(down);
      setRisk(risk);
      seIssue(issue);
      setUp(up);
    } else if (geo === 'gc') {
      const {up, issue, risk, down} = storeStatusCounts.gc;
      setDown(down);
      setRisk(risk);
      seIssue(issue);
      setUp(up);
    } else if (geo === 'apla') {
      const {up, issue, risk, down} = storeStatusCounts.apla;
      setDown(down);
      setRisk(risk);
      seIssue(issue);
      setUp(up);
    } else {
      setDown(0);
      setRisk(0);
      seIssue(0);
      setUp(0);
    }
  }, [geo, storeStatusCountsDataReady, storeStatusCounts]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {storeStatusCountsDataReady ? (
          <TradeItem variant={'down'} storeCount={down} />
        ) : (
          <StyledSkeleton>
            <TradeItem variant={'down'} storeCount={0} />
          </StyledSkeleton>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {storeStatusCountsDataReady ? (
          <TradeItem variant={'risk'} storeCount={risk} />
        ) : (
          <StyledSkeleton>
            <TradeItem variant={'down'} storeCount={0} />
          </StyledSkeleton>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {storeStatusCountsDataReady ? (
          <TradeItem variant={'dimished'} storeCount={issue} />
        ) : (
          <StyledSkeleton>
            <TradeItem variant={'down'} storeCount={0} />
          </StyledSkeleton>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {storeStatusCountsDataReady ? (
          <TradeItem variant={'trading'} storeCount={up} />
        ) : (
          <StyledSkeleton>
            <TradeItem variant={'down'} storeCount={0} />
          </StyledSkeleton>
        )}
      </Grid>
    </Grid>
  );
}

export default GeoTradeStatus;

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListContainer from '../StoreTransferList/components/ListContainer';
import ListItem from '../StoreTransferList/components/ListItem';
import ClearAll from '../StoreTransferList/components/ClearAll';
import TransferListSearch from '../Search/TransferListSearch';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';
import filterStoresByInput from '../StoreTransferList/components/storeSearchFilter';
import {StoreAction} from './types/StoreAction';
import RectSkeleton from 'components/Skeletons/RectSkeleton';

// check white space

//#region interface
interface StoreTransferListProps {
  setSelectedStores: (value: Stores) => void;
  selectedStores: Stores;
  storeAction: StoreAction;
}
//#endregion interface

//#region styled components
const StyledStoreTransferList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
`;

const StyledSkeleton = styled(RectSkeleton)`
  margin-top: 12px;
  height: calc(100% - 48px - 8px);
  width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
`;
//#endregion styled components

function StoreTransferList({
  setSelectedStores,
  selectedStores,
  storeAction,
}: StoreTransferListProps) {
  const storeInfoList: Stores = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo?.data
  );
  const isStoreInfoListReady: boolean = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo.dataRetrieved
  );

  const [storeList, setStoreList] = useState<Stores>([]);
  const [clearAllSubtitle, setClearAllSubtitle] = useState<string>('Selected stores');

  //#region useEffect
  useEffect(() => {
    switch (storeAction) {
      case 'default':
        setClearAllSubtitle('Selected stores');
        break;
      case 'add':
        setClearAllSubtitle('Stores to add services to');
        break;
      case 'remove':
        setClearAllSubtitle('Stores to remove services from');
        break;
    }
  }, [storeAction]);

  useEffect(() => {
    if (isStoreInfoListReady) {
      setStoreList(storeInfoList.slice(0, 10));
    }
  }, [storeInfoList, isStoreInfoListReady]);
  //#endregion useEffect

  //#region handles
  const handleUserInput = (input: string) => {
    if (isStoreInfoListReady && input.length > 0) {
      const filteredServiceList = filterStoresByInput(input, storeInfoList);
      setStoreList(filteredServiceList);
    } else {
      setStoreList([]);
    }
  };

  const handleAddStore = (storeName: string) => {
    const matchByStoreName: Stores = storeInfoList.filter(store => store.StoreName === storeName);
    setSelectedStores([...selectedStores, ...matchByStoreName]);
  };

  const handleRemoveService = (storeName: string) => {
    const filteredStoreList = selectedStores.filter(store => store.StoreName !== storeName);
    setSelectedStores(filteredStoreList);
  };

  const handleClearStores = () => {
    setSelectedStores([]);
  };
  //#endregion handles

  return (
    <StyledStoreTransferList>
      <ListContainer variant={'left'} isServiceManager={true}>
        <TransferListSearch
          id="service-manager-store-transfer-list-search"
          handleUserInput={handleUserInput}
          className="service-manager"
        />
        {isStoreInfoListReady ? (
          <List>
            {storeList.map(
              store =>
                !selectedStores.includes(store) && (
                  <ListItem
                    icon={'add'}
                    handleClick={() => handleAddStore(store.StoreName)}
                    text={getStoreDisplayName(store)}
                    key={store.SiteId}
                  />
                )
            )}
          </List>
        ) : (
          <StyledSkeleton />
        )}
      </ListContainer>
      <ListContainer variant={'right'} isServiceManager={true}>
        <ClearAll handleClick={handleClearStores} subtitle={clearAllSubtitle} />
        <List>
          {selectedStores.map(store => (
            <ListItem
              icon={'minus'}
              handleClick={() => handleRemoveService(store.StoreName)}
              text={getStoreDisplayName(store)}
              key={store.SiteId}
            />
          ))}
        </List>
      </ListContainer>
    </StyledStoreTransferList>
  );
}

export default StoreTransferList;

import React, {useState} from 'react';
import Layout from './Layout';
import StyledContainer from '../components/Container';
import StyledPaper from '../components/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../components/Typography/Title';
import StyledPaperTitle from '../components/PaperTitle';
import ActiveEvents from '../components/Charts/ActiveEvents';
import HistoricalComparison from '../components/Charts/HistoricalComparisonChart';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GeoTradeStatus from '../components/Charts/GeoTradeStatus';
import StoreEvents from '../components/StoreEvents';

function Development(props) {
  const {match} = props;
  const [dataLoadStatus, setDataLoadStatus] = useState(true);
  const handleChange = () => {
    setDataLoadStatus(!dataLoadStatus);
  };

  return (
    <Layout match={match}>
      <StyledContainer>
        <Grid container spacing={5} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Title>IN DEVELOPMENT</Title>
          </Grid>
          <Grid item xs={12}>
            <StyledPaper>
              <StyledPaperTitle>Admin Test</StyledPaperTitle>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!dataLoadStatus}
                      onChange={handleChange}
                      name="checkedA"
                      inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                  }
                  label="Display Loading Indicator"
                />
              </FormGroup>
            </StyledPaper>
          </Grid>
          <Grid item xs={12}>
            <GeoTradeStatus />
          </Grid>
          <Grid item xs={12}>
            <StoreEvents />
          </Grid>
          <Grid item xs={12}>
            <HistoricalComparison debugLoaded={dataLoadStatus} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
            <StyledPaper>
              <StyledPaperTitle>Filler Component</StyledPaperTitle>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StyledPaper>
              <ActiveEvents debugLoaded={dataLoadStatus} />
            </StyledPaper>
          </Grid>
        </Grid>
      </StyledContainer>
    </Layout>
  );
}

export default Development;

enum Concept {
  UNITE = 'unite',
  BEACON = 'beacon',
  FACTORY = 'factory',
  CLEARANCE = 'clearance',
  RISE = 'rise',
  STYLE = 'style',
  INNOVATION = 'innovation',
  WELLCOLLECTIVE = 'wellcollective',
  EMPLOYEESTORE = 'employeestore',
  KICKSLOUNGE = 'kickslounge',
}

export default Concept;

import {fetchTopEventsPending, fetchTopEventsSuccess, fetchTopEventsError} from './index';
import config from '../../oktaConfig';

function getTopEvents(accessToken) {
  return dispatch => {
    dispatch(fetchTopEventsPending());
    fetch(config.resourceServer.insightsApiUrlTopEvents, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        dispatch(fetchTopEventsSuccess(data));
      })
      .catch(error => {
        console.log('error', error);
        dispatch(fetchTopEventsError(error));
      });
  };
}

export default getTopEvents;

import {Action} from 'redux';
import Geo from 'interfaces/Geo';
import Site from 'interfaces/Site';
import Tabs from '../interfaces/Tabs';
import DevicesResponse from '../interfaces/DevicesResponse';
import InventoryDevice from '../interfaces/InventoryDevice';
import parseFiltersForGeos from './functions/parseFiltersForGeos';
import mergeSiteAndDevice from './functions/mergeSiteAndDevice';
import filterData from './functions/filterData';

export interface InventoryAction extends Action {
  type: string;
  payload?: any;
}

export interface InventoryState {
  selectedTab: Tabs;
  selectedGeos: Geo[];
  selectedFilters: string[];
  geoToFetch: Geo | null;
  loadedGeos: {
    [Geo.NA]: boolean;
    [Geo.EMEA]: boolean;
    [Geo.GC]: boolean;
    [Geo.APLA]: boolean;
  };
  parsedFilters: Record<string, string[]>;
  deviceData: InventoryDevice[];
  tableDeviceData: InventoryDevice[];
  missingFilterOptions: Record<string, string[]>;
}

const DEFAULT_GEOS = [Geo.NA, Geo.EMEA, Geo.GC, Geo.APLA];

//#region State
export const initialState: InventoryState = {
  selectedTab: Tabs.CUSTOM,
  selectedGeos: [],
  selectedFilters: [],
  geoToFetch: null,
  loadedGeos: {
    [Geo.NA]: false,
    [Geo.EMEA]: false,
    [Geo.GC]: false,
    [Geo.APLA]: false,
  },
  parsedFilters: {},
  deviceData: [],
  tableDeviceData: [],
  missingFilterOptions: {},
};
//#endregion State

//#region Reducer
export const reducer = (state: InventoryState, action: InventoryAction): InventoryState => {
  switch (action.type) {
    case 'SELECT_TAB': {
      const selectedTab = action.payload;
      let selectedGeos = parseFiltersForGeos(state.selectedFilters);
      let geoToFetch: Geo | null = null;
      if (selectedTab === Tabs.CUSTOM && selectedGeos.length === 0) {
        selectedGeos = DEFAULT_GEOS;
      } else if (selectedTab !== Tabs.CUSTOM) {
        selectedGeos = [selectedTab as Geo];
      }

      geoToFetch = getNextGeoToFetch(selectedGeos, state.loadedGeos);

      const tableDeviceData = filterData({
        selectedGeos,
        selectedFilters: state.selectedFilters,
        deviceData: state.deviceData,
      });

      return {
        ...state,
        selectedGeos,
        geoToFetch,
        selectedTab: selectedTab,
        tableDeviceData,
      };
    }

    case 'UPDATE_SELECTED_FILTERS': {
      const selectedFilters = action.payload;

      let selectedGeos = parseFiltersForGeos(selectedFilters);
      let geoToFetch: Geo | null = null;

      if (state.selectedTab === Tabs.CUSTOM && selectedGeos.length === 0) {
        selectedGeos = DEFAULT_GEOS;
      } else if (state.selectedTab !== Tabs.CUSTOM) {
        const tabAsGeo = [(state.selectedTab as unknown) as Geo];
        selectedGeos = tabAsGeo;
      }

      geoToFetch = getNextGeoToFetch(selectedGeos, state.loadedGeos);
      const tableDeviceData = filterData({
        selectedGeos,
        selectedFilters: selectedFilters,
        deviceData: state.deviceData,
      });

      return {
        ...state,
        selectedGeos,
        geoToFetch,
        tableDeviceData,
        selectedFilters: action.payload,
      };
    }

    case 'UPDATE_DEVICE_DATA': {
      const {deviceData, sites} = action.payload;
      const loadedGeos = {
        ...state.loadedGeos,
        [state.geoToFetch as Geo]: true,
      };
      const geoToFetch: Geo | null = getNextGeoToFetch(state.selectedGeos, loadedGeos);
      const mergedDeviceData: InventoryDevice[] = mergeSiteAndDevice({
        devicesResponse: deviceData,
        sites: sites,
      });

      const sortedMergedData = [...state.deviceData, ...mergedDeviceData].sort((a, b) => {
        const siteCodeA = a.siteCode.toString();
        const siteCodeB = b.siteCode.toString();

        if (isNaN(Number(siteCodeA)) && isNaN(Number(siteCodeB))) {
          if (siteCodeA < siteCodeB) {
            return -1;
          }
          if (siteCodeA > siteCodeB) {
            return 1;
          }
          return 0;
        } else if (isNaN(Number(siteCodeA))) {
          return 1;
        } else if (isNaN(Number(siteCodeB))) {
          return -1;
        } else {
          return Number(siteCodeA) - Number(siteCodeB);
        }
      });

      const tableDeviceData = filterData({
        selectedGeos: state.selectedGeos,
        selectedFilters: state.selectedFilters,
        deviceData: sortedMergedData,
      });

      return {
        ...state,
        loadedGeos,
        tableDeviceData,
        geoToFetch: geoToFetch,
        deviceData: sortedMergedData,
      };
    }

    default: {
      return state;
    }
  }
};
//#endregion Reducer

//#region functions
function getNextGeoToFetch(selectedGeos: Geo[], loadedGeos: Record<Geo, boolean>): Geo | null {
  const nextGeoToLoad = selectedGeos.filter(geo => loadedGeos[geo] === false)[0];
  return nextGeoToLoad?.length > 0 ? (nextGeoToLoad as Geo) : null;
}
//#endregion functions

//#region actions
export function actionSelectTab(tab: Tabs): InventoryAction {
  return {
    type: 'SELECT_TAB',
    payload: tab,
  };
}

export function actionUpdateSelectedFilters(filters: string[]): InventoryAction {
  return {
    type: 'UPDATE_SELECTED_FILTERS',
    payload: [...filters],
  };
}

export function actionUpdateDeviceData(
  deviceData: DevicesResponse[],
  sites: Site[]
): InventoryAction {
  return {
    type: 'UPDATE_DEVICE_DATA',
    payload: {deviceData, sites},
  };
}
//#endregion actions

import React from 'react';
import styled from 'styled-components';
import SGBody from '../../Typography/SGBody';
import TrendingDownRoundedIcon from '@material-ui/icons/TrendingDownRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import RectSkeleton from '../../Skeletons/RectSkeleton';
interface TypeButtonProps {
  debugLoaded?: boolean;
  count: number;
  deviceType: string;
  trend: boolean;
}

const StyledTrendCount = styled.div`
  padding: 0.25rem 0.625rem;
  border-radius: 10px;
`;

const StyledLabel = styled.div`
  width: 5rem;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  align-items: flex-start;
`;

const StyledNumber = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.text};
  font-size: 1.125rem;
  &.red {
    color: ${props => props.theme.red};
  }
  &.green {
    color: ${props => props.theme.green};
  }
`;

const StyledTrendingUpRoundedIcon = styled(TrendingUpRoundedIcon)`
  font-size: 1.125rem;
  margin-left: 0.375rem;
  color: ${props => props.theme.red};
`;

const StyledTrendingDownRoundedIcon = styled(TrendingDownRoundedIcon)`
  font-size: 1.125rem;
  margin-left: 0.375rem;
  color: ${props => props.theme.green};
`;

const StyledRectSkeleton = styled(RectSkeleton)`
  margin-bottom: 4px;
`;

function TrendCount({debugLoaded, count, deviceType, trend}: TypeButtonProps) {
  const absCount = Math.abs(count);
  if (debugLoaded) {
    return (
      <StyledTrendCount>
        <StyledLabel>
          {trend ? (
            <StyledNumber className={'green'}>
              {absCount}% <StyledTrendingDownRoundedIcon />
            </StyledNumber>
          ) : (
            <StyledNumber className={'red'}>
              {absCount}% <StyledTrendingUpRoundedIcon />
            </StyledNumber>
          )}
          <SGBody variant={'b5'} className={'subtle'}>
            {deviceType}
          </SGBody>
        </StyledLabel>
      </StyledTrendCount>
    );
  } else {
    return (
      <StyledTrendCount>
        <StyledRectSkeleton width={'4rem'} height={'1.375rem'} />
        <StyledRectSkeleton width={'3.5rem'} height={'1.25rem'} />
      </StyledTrendCount>
    );
  }
}

export default TrendCount;

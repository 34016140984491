import {useState, useEffect} from 'react';
import config from '../../../oktaConfig';
import {useOktaAuth} from '@okta/okta-react';
import Geo from 'interfaces/Geo';

interface useInvetoriesProps {
  geo: Geo | null;
}

function useInvetories({geo}: useInvetoriesProps) {
  const {authState, oktaAuth} = useOktaAuth();
  const [data, setData] = useState<any | null>(null);
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    async function startFetch({controller, geo}: {controller: AbortController; geo: Geo}) {
      setData(null);
      setError(null);
      setPending(true);

      const accessToken = oktaAuth.getAccessToken();
      const results = await fetch(config.resourceServer.insightsApiUrlDevices + `?geo=${geo}`, {
        mode: 'cors',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal: controller.signal,
      })
        .then(response => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .catch(err => {
          console.log('error', err);
          setError(err);
        })
        .finally(() => {
          setPending(false);
        });

      if (!ignore) {
        setData(results);
      }
    }

    let ignore = false;
    if (authState && authState.isAuthenticated && geo !== null) {
      startFetch({controller, geo});
    }

    return () => {
      ignore = true;
      controller.abort();
    };
  }, [authState, oktaAuth, geo]);

  return {data, pending, error};
}

export default useInvetories;

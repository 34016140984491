import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import Paper from '../Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PaperTitle from '../PaperTitle';
import Typography from '@material-ui/core/Typography';
// import ButtonLink from '../ButtonLink';
import Button from '../Button';
import {useSelector, useDispatch} from 'react-redux';
import {setUiPatchNotesViewed, setUiPatchNotesReset} from '../../redux/actions';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {patchVersion} from '../../constants/releaseConfig';
import allPatches from '../../constants/allPatches';
import {ReactComponent as SlackIconMono} from '../../assets/icons/Slack_Mark_Monochrome_Black.svg';

const StyledPopup = styled.div`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const StyledModal = styled(Modal)`
  overflow: scroll;
  height: 100%;
  display: block;
  position: absolute;
`;

const MessageBox = styled(Paper)`
  max-width: 50vw;
  position: relative;
  top: 100px;
  left: 25vw;
  margin-bottom: 200px;
`;

const StyledButton = styled(MuiButton)`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  font-style: italic;
  padding: 0;
  text-transform: capitalize;
  position: relative;
  right: -25px;
  &:hover {
    color: ${props => props.theme.focusText};
  }
`;

const StyledGrid = styled(Grid)`
  &.align-right {
    text-align: right;
  }
`;

const StyledSlackLink = styled.a`
  margin: 0px 0px 0px 0px;
  position: relative;
  left: -8px;
  text-decoration: none;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
  margin-top: auto;
  display: block;

  &:visited {
    color: ${props => props.theme.navText};
    fill: ${props => props.theme.navText};
  }

  &:active,
  &:hover,
  &:focus,
  &.active {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
  }

  &:hover .hoverClass {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
  }
`;

const StyledButtonSlack = styled(MuiButton)`
  height: 4rem;
  border-radius: 10px;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
  text-decoration: none;
  text-transform: none;

  height: 40px;
  border-radius: 10px;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
    background-color: ${props => props.theme.navBg};
  }

  ${StyledSlackLink}:active &, ${StyledSlackLink}:hover &, ${StyledSlackLink}:focus &, ${StyledSlackLink}.active & {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
    background-color: ${props => props.theme.navBg};
  }

  span {
    flex-direction: row;
    justify-content: flex-start;
  }
  p {
    margin: 0 0 3px 8px;
    margin-left: 8px;
    margin-bottom: 3px;
    font-size: 1rem;
    font-family: 'Trade Gothic';
  }

  .noPointer .MuiButton-root {
    cursor: context-menu;
  }
`;

const StyledSlackMono = styled(SlackIconMono)`
  position: relative;
  top: -40%;
  left: -45%;
  width: 42px;
  height: 42px;
`;

const SlackIconContainer = styled.div`
  overflow: hidden;
  object-fit: cover;
  width: 24px;
  height: 24px;
`;

function PopupPatchNotes(props) {
  const {settings} = props;

  const {patchNotesNumber} = patchVersion;

  const renderLatestPatch = () => {
    const [latestPatch] = allPatches;
    return (
      <>
        <Typography>{latestPatch.version}</Typography>
        <br />
        <Typography>{latestPatch.overview}</Typography>
        <br />

        {latestPatch.changes.map((change, index) => {
          return (
            <Fragment key={index}>
              <Typography key={'title' + index}>{change.title}</Typography>
              <ul key={'ul' + index}>
                {change.list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Fragment>
          );
        })}

        {/* <ButtonLink to={'/about-patch'}>View all patches</ButtonLink> */}
      </>
    );
  };

  const dispatch = useDispatch();
  const msgViewed = useSelector(state => state.ui.patchNotesViewed);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!msgViewed) {
    setOpen(true);
    dispatch(setUiPatchNotesViewed(patchNotesNumber));
  }

  return (
    <StyledPopup>
      {/* for the settings page display testing buttons */}
      {settings ? (
        <>
          <br></br>
          <Button variant="outlined" onClick={handleOpen}>
            Trigger Patch Notes
          </Button>
          <Button variant="outlined" onClick={() => dispatch(setUiPatchNotesReset())}>
            Reset Patch Notes
          </Button>
        </>
      ) : (
        ''
      )}

      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MessageBox>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <PaperTitle id="transition-modal-title">Patch Notes</PaperTitle>
              </Grid>
              <StyledGrid item xs={6} className={'align-right position'}>
                <StyledButton onClick={handleClose}>
                  <CloseIcon />
                </StyledButton>
              </StyledGrid>
              <Grid item xs={12}>
                {renderLatestPatch()}
              </Grid>
              <Grid item xs={12}>
                <StyledSlackLink
                  href="https://nikedigital.slack.com/archives/G75ESC0RJ"
                  target="_blank"
                >
                  <StyledButtonSlack color="inherit" aria-label="slack">
                    <SlackIconContainer>
                      <StyledSlackMono />
                    </SlackIconContainer>
                    <p>Connect with us at #retailsupport-tools</p>
                  </StyledButtonSlack>
                </StyledSlackLink>
              </Grid>
            </Grid>
          </MessageBox>
        </Fade>
      </StyledModal>
    </StyledPopup>
  );
}

export default PopupPatchNotes;

import React from 'react';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IAlert from 'features/RRP/interfaces/IAlert';
import SGTitle from 'components/Typography/SGTitle';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

interface CardAlertAccordianProps {
  alerts: {
    title: string;
    alert: IAlert;
  }[];
}

const {HEALTHY} = AlertSeverityEnum;

const StyledAccordian = styled(Accordion)`
  grid-column: 1 / span 5;
  color: ${props => props.theme.text};
  .MuiAccordionSummary-root {
    padding: 0 12px;
  }
  &.MuiAccordion-root:before {
    background-color: transparent;
  }
  &.MuiPaper-elevation1 {
    background-color: transparent;
    box-shadow: none;
  }
  .MuiIconButton-root {
    color: ${props => props.theme.text};
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.grey};
  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledSGTitle = styled(SGTitle)`
  color: ${props => props.theme.grey};
`;

const StyledSubHeader = styled(Typography)`
  font-family: 'Trade Gothic Bold Condensed';
  margin: 0.5rem 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${props => props.theme.disabledStateBlue};
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &&&& .MuiAccordionSummary-expandIcon {
    display: block;
  }
`;

export default function CardAlertAccordian({alerts}: CardAlertAccordianProps) {
  return (
    <StyledAccordian>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Alert details</Typography>
      </StyledAccordionSummary>
      {Array.isArray(alerts) &&
        alerts.map(item => {
          const {title, alert} = item;
          if (alert.value > HEALTHY) {
            return (
              <StyledAccordionDetails>
                <StyledSGTitle variant={'t4'}>{title}</StyledSGTitle>
                <StyledSubHeader>Issue</StyledSubHeader>
                {alert.issue.map(issue => (
                  <Typography>{issue}</Typography>
                ))}
                <StyledSubHeader>Effect</StyledSubHeader>
                {alert.effect.map(effect => (
                  <Typography>{effect}</Typography>
                ))}
                <StyledSubHeader>Solution</StyledSubHeader>
                {alert.solution.map(solution => (
                  <Typography>{solution}</Typography>
                ))}
              </StyledAccordionDetails>
            );
          } else {
            return '';
          }
        })}
    </StyledAccordian>
  );
}

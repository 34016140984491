import React from 'react';
import styled from 'styled-components';
import Button from './CTA/SGButton';

const StyledButton = styled(Button)`
  border: 1px solid transparent;
  color: ${props => props.theme.subtleText};
  text-transform: none;
  &:hover,
  &.active {
    border: 1px solid ${props => props.theme.subtleText};
    color: ${props => props.theme.text};
  }

  &.disabled {
    color: ${props => props.theme.disabled};
    border: none;
    &:hover {
      border: none;
      cursor: default;
    }
  }
`;

function DateSelectorButton(props) {
  const {className, children, value, onClick, selected, ...rest} = props;

  return (
    <StyledButton
      variant={'text'}
      color={'secondary'}
      onClick={onClick}
      className={(selected === value ? 'active ' : '') + className}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

export default DateSelectorButton;

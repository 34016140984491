import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import ToolTip from './ToolTip';

const StyledTime = styled.span`
  font-family: 'Text Regular';
  font-size: 1rem;
  color: ${props => props.theme.text};
  &.subtle {
    color: ${props => props.theme.tableText};
  }
  &.red {
    color: ${props => props.theme.red};
  }
  &.orange {
    color: ${props => props.theme.orange};
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.green {
    color: ${props => props.theme.green};
  }
`;

function TimeZoneDisplay(props) {
  const {className, zone} = props;

  function renderTime(zone) {
    const d = new Date();
    const utc = d.getTime();
    const nd = new Date(utc + zone.UTCOffset * 60 * 60 * 1000);

    const timeFormatted = nd.toLocaleTimeString([], {
      timeZone: 'UTC',
      hour: '2-digit',
      minute: '2-digit',
    });

    return timeFormatted;
  }

  useEffect(() => {
    function tick() {
      setTime(renderTime(zone));
    }
    const interval = setInterval(tick, 5000);

    return () => clearInterval(interval);
  }, [zone]);

  const [time, setTime] = useState(renderTime(zone));

  return (
    <ToolTip title={zone.JSZone}>
      <StyledTime className={className}>
        {time} {zone.ZoneShort}
      </StyledTime>
    </ToolTip>
  );
}

export default TimeZoneDisplay;

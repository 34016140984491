const {detect} = require('detect-browser');

const convertTimeToISOString = epoch => {
  const event = new Date(epoch);
  const isoString = event.toISOString();
  const array = isoString.split('');
  array.splice(23, 0, '0000');

  return array.join('');
};

const checkBrowser = () => {
  const browser = detect();

  if (browser) {
    return `${browser.name} ${browser.version} ${browser.os}`;
  } else {
    return 'Unknown Browser';
  }
};
// userName, email, dateTime, id, source
export const buildUserLog = (
  name,
  email,
  time,
  sessionId,
  themeSelected,
  action,
  payload,
  insightsVersion
) => {
  const userLog = {
    email: email ? email : 'undefined',
    source: 'Insights',
    userName: email ? email.replace(/\s/g, '') : 'noEmailGiven',
    id: (email ? email.replace(/\s/g, '') : 'noEmailGiven') + '+T' + time,
    fullName: name ? name : 'undefined',
    insightsVersion: insightsVersion,
    browser: checkBrowser(),
    theme: themeSelected,
    dateTime: convertTimeToISOString(time),
    action: action,
    payload: payload,
    sessionId: (email ? email.replace(/\s/g, '') : 'noEmailGiven') + '+T' + sessionId,
  };

  return userLog;
};

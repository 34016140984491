import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {getStoreDisplayName} from 'interfaces/Stores';

const StyledSearchSuggestion = styled.ul`
  padding-left: 0px;
  margin: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const StyledSearchItems = styled.li`
  background-color: ${props => props.theme.cardBg};
  color: ${props => props.theme.altText};
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
  overflow: hidden;
  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  font-size: 0.8rem;
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  width: calc(100% - 63px);
  padding: 8px 10px 8px 55px;
  &:visited {
    color: ${props => props.theme.subtleText};
    text-decoration: none;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.altText};
    text-decoration: none;
    background-color: ${props => props.theme.tableRow};
  }
  ${StyledSearchItems}:first-of-type > & {
    color: ${props => props.theme.altText};
    background-color: ${props => props.theme.tableRow};
  }
`;

function SearchSuggestion(props) {
  const {stores} = props;

  const numberOfStoresToDisplay = 8;

  return (
    <StyledSearchSuggestion>
      {stores.slice(0, numberOfStoresToDisplay).map((store, index) => (
        <StyledSearchItems key={index}>
          <StyledNavLink
            id={'s' + store.StoreNumber}
            to={`/store/${store.StoreNumber}/overview`}
            exact
          >
            {getStoreDisplayName(store)}
          </StyledNavLink>
        </StyledSearchItems>
      ))}
    </StyledSearchSuggestion>
  );
}

export default SearchSuggestion;

import React from 'react';
import styled from 'styled-components';

interface TabPaneProps {
  tabId: number;
  activeId: number;
  children?: React.ReactNode;
}

const StyledPane = styled.div`
  display: none;
  &.active {
    display: block;
  }
`;

function TabPane({activeId, tabId, children}: TabPaneProps) {
  return <StyledPane className={activeId === tabId ? 'active ' : ' '}>{children}</StyledPane>;
}

export default TabPane;

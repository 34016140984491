import React from 'react';
import styled from 'styled-components';
import {format} from 'date-fns';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

interface ChipProps {
  dateTime: string | null;
  closeMonthlyReport: () => void;
}

const StyledChip = styled(Chip)`
  color: ${props => props.theme.defaultNavy};
  background-color: ${props => props.theme.activeStateBlue};
  border-radius: 18px;
  min-height: 36px;
  &.MuiChip-deletable:focus {
    background-color: ${props => props.theme.white};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  color: ${props => props.theme.defaultNavy};
  opacity: 0.7;
`;

function DateChip({dateTime, closeMonthlyReport}: ChipProps) {
  if (dateTime == null) {
    return <div></div>;
  }
  const date = format(new Date(dateTime), 'PPP');

  const handleDelete = () => {
    closeMonthlyReport();
  };

  return (
    <StyledChip
      label={`Report date ending ${date}`}
      onDelete={handleDelete}
      deleteIcon={<StyledCloseIcon />}
    />
  );
}

export default DateChip;

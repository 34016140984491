import React, {useState, useEffect, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import {getSiteTypeFromSiteId} from 'utils/utils';
import {debounce} from 'lodash';
import {useParams} from 'react-router-dom';
import Container from 'components/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@mdi/react';
import {mdiServerNetwork} from '@mdi/js';
import {mdiTable} from '@mdi/js';
import useSwitchConnectivity from 'features/SwitchConnectivity/hooks/useSwitchConnectivity';
import {
  reducer,
  initialState,
} from 'features/SwitchConnectivity/reducers/switchConnectivityReducer';
import SwitchDiagramContainer from 'features/SwitchConnectivity/SwitchDiagram/SwitchDiagramContainer';
import VLANLegend from 'features/SwitchConnectivity/VLANLegend/VLANLegend';
import SwitchSearch from 'features/SwitchConnectivity/Search/SwitchSearch';
import SwitchHeaderSubtitle from 'features/SwitchConnectivity/components/SwitchHeaderSubtitle';
import SwitchSelectionPrompt from 'features/SwitchConnectivity/components/SwitchSelectionPrompt';
import SwitchLoadingIndicator from 'features/SwitchConnectivity/components/SwitchLoadingIndicator';
import SwitchSnackbar from 'features/SwitchConnectivity/components/SwitchSnackbar';
import SwitchTableContainer from 'features/SwitchConnectivity/SwitchDiagram/SwitchTableContainer';
import TabPanel from 'components/Tabs/TabPanel';
import Paper from 'components/Paper';
import LayoutHeader, {LayoutHeaderIconTypes} from 'components/LayoutHeader';
import SiteIDSearch from 'components/SiteIDSearch';
import Snackbar from 'components/Snackbar';
import SwitchResponseData from 'features/SwitchConnectivity/interfaces/response/SwitchResponseData';
import PortHistoryResponseData from 'features/SwitchConnectivity/interfaces/response/PortHistoryResponseData';
import SwitchSearchCheckboxes, {
  SwitchSearchOptions,
} from 'features/SwitchConnectivity/Search/SwitchSearchCheckboxes';

//#region styled-components
const StyledGridItemAlignRight = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledVlanLegendGrid = styled(Grid)`
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
`;

const StyledTabs = styled(Tabs)`
  margin-top: 8px;
  .MuiTabs-indicator {
    height: 6px;
    background-color: ${props => props.theme.activeStateBlue};
  }
`;

const StyledTab = styled(Tab)`
  margin-bottom: 15px;
  min-width: 217px;
  padding: 0px 16px;
  transition: 200ms;
  margin-right: 1.5rem;
  &.MuiTab-textColorInherit {
    color: ${props => props.theme.text};
    border-radius: 10px;
    border: 4px solid ${props => props.theme.grey};
  }
  &.MuiTab-textColorInherit.Mui-selected {
    background-color: ${props => props.theme.activeStateBlue};
    color: ${props => props.theme.black};
    border: 4px solid ${props => props.theme.activeStateBlue};
  }
  &.MuiTab-labelIcon {
    min-height: 56px;
  }
  .MuiTab-wrapper {
    flex-direction: row;
    column-gap: 0.5rem;
  }
  .MuiTab-wrapper > *:first-child {
    margin-bottom: 4px;
  }
`;

const StyledSearchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

//#endregion styled-components
interface SwitchParams {
  siteId: string;
  idfName: string | undefined;
}

export default function SwitchConnectivity() {
  const {siteId, idfName} = useParams<SwitchParams>();
  const {
    switchData,
    portHistoryData,
    allDataLoaded,
    error: switchFetchingError,
  } = useSwitchConnectivity(siteId, idfName);
  const [state, reactDispatch] = useReducer(reducer, initialState);
  const [value, setValue] = useState(0);
  const [error, setError] = useState<string>('');
  const [canGetSwitchData, setCanGetSwitchData] = useState<boolean>(siteId?.length > 0);
  const [isLegendPinned, setIsLegendPinned] = useState<boolean>(false);
  const [switchSearchValue, setSwitchSearchValue] = useState<string>('');
  const [switchSearchOptions, setSwitchSearchOptions] = useState<SwitchSearchOptions>({
    includeCurrentData: true,
    includeHistoricalData: false,
  });

  useEffect(() => {
    setSwitchSearchValue('');

    if (siteId) {
      const siteType = getSiteTypeFromSiteId(siteId);
      setCanGetSwitchData(
        siteType === 'Retail' ||
          siteType === 'Showroom' ||
          (siteType === 'Distribution Center' && idfName !== undefined && idfName.length > 0)
      );
    } else {
      setCanGetSwitchData(false);
    }

    const siteType = getSiteTypeFromSiteId(siteId);

    if (siteType === 'Distribution Center' && !idfName) {
      setError(
        'Invalid URL: The site id in the URL is for a distribution center, but an idf name is not specified. Please use the searchbar to specify an idf.'
      );
    } else {
      setError('');
    }
  }, [siteId, idfName]);

  // #region functions

  function handleLegendItemSelectionChanged(vlanNumber: any, isSelected: any) {
    setSwitchSearchValue('');
    setSwitchSearchOptions({
      includeCurrentData: true,
      includeHistoricalData: false,
    });
    reactDispatch({
      type: 'LEGEND_ITEM_SELECTION_CHANGED',
      payload: {vlanNumber, isSelected},
    });
  }

  function handleClearLegendItems() {
    reactDispatch({
      type: 'LEGEND_ITEM_CLEAR',
    });
  }

  const dispatchUpdateSwitchData = useCallback((data: SwitchResponseData) => {
    reactDispatch({
      type: 'UPDATE_SWITCH_DATA',
      payload: data,
    });
  }, []);

  const dispatchUpdatePortHistoryData = useCallback((data: PortHistoryResponseData[]) => {
    reactDispatch({
      type: 'UPDATE_PORT_HISTORY_DATA',
      payload: data,
    });
  }, []);

  const dispatchSearchTextChanged = (searchText: any, searchOptions: SwitchSearchOptions) =>
    reactDispatch({
      type: 'SEARCH_TEXT_CHANGED',
      payload: {searchText, searchOptions},
    });

  const dispatchResetSwitchConnectivity = useCallback(() => {
    reactDispatch({
      type: 'RESET_SWITCH_CONNECTIVITY',
    });
  }, []);

  const debouncedHandleSearchTextChanged = useCallback(
    debounce(dispatchSearchTextChanged, 500),
    []
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handlePinLegend = () => {
    setIsLegendPinned(!isLegendPinned);
  };

  const handleUpdateSearchValue = (newValue: string) => {
    setSwitchSearchValue(newValue);
    debouncedHandleSearchTextChanged(newValue, switchSearchOptions);
  };

  const handleUpdateSearchOptions = (newOptions: SwitchSearchOptions) => {
    setSwitchSearchOptions(newOptions);
    debouncedHandleSearchTextChanged(switchSearchValue, newOptions);
  };

  // #endregion functions

  // #region useEffect
  useEffect(() => {
    if (allDataLoaded) {
      if (switchData !== null) {
        dispatchUpdateSwitchData(switchData);
        setValue(0);
        setSwitchSearchOptions({
          includeCurrentData: true,
          includeHistoricalData: false,
        });
      }
      if (portHistoryData !== null) {
        dispatchUpdatePortHistoryData(portHistoryData);
      }
    }
  }, [
    allDataLoaded,
    switchData,
    portHistoryData,
    dispatchUpdateSwitchData,
    dispatchUpdatePortHistoryData,
  ]);

  useEffect(() => {
    if (state.isSwitchDataReady) {
      dispatchResetSwitchConnectivity();
    }
  }, [siteId, idfName, dispatchResetSwitchConnectivity, state]);

  // #endregion

  return (
    <Layout
      headerContent={
        <>
          <LayoutHeader
            title={'Switch Connectivity'}
            icon={LayoutHeaderIconTypes.Switch}
            searchComponent={<SiteIDSearch urlType={'switch'} setSwitchError={setError} />}
          />
          <SwitchHeaderSubtitle siteId={siteId} idfName={idfName} />
        </>
      }
    >
      <Container>
        <Grid container spacing={2}>
          {!canGetSwitchData && (
            <Grid item xs={12}>
              <SwitchSelectionPrompt />
            </Grid>
          )}

          {canGetSwitchData && (
            <SwitchLoadingIndicator
              key={(siteId ? siteId.toString() : '') + (idfName ? idfName.toString() : '')}
              switchFetchingError={switchFetchingError ? true : false}
              isLoaded={state.isSwitchDataReady}
            >
              <StyledVlanLegendGrid item xs={12} className={isLegendPinned ? 'sticky' : ' '}>
                <VLANLegend
                  handlePinLegend={handlePinLegend}
                  isLegendPinned={isLegendPinned}
                  switchState={state}
                  handleClearLegendItems={handleClearLegendItems}
                  onLegendItemSelectionChanged={handleLegendItemSelectionChanged}
                />
              </StyledVlanLegendGrid>
              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <StyledTabs value={value} onChange={handleChange}>
                        <StyledTab
                          icon={<Icon path={mdiServerNetwork} size={1} />}
                          label="DIAGRAM"
                        />
                        <StyledTab icon={<Icon path={mdiTable} size={1} />} label="TABLE" />
                      </StyledTabs>
                    </Grid>
                    <StyledGridItemAlignRight item xs={6}>
                      <StyledSearchBoxContainer>
                        <SwitchSearch
                          value={switchSearchValue}
                          setSearchValue={handleUpdateSearchValue}
                        />
                        <SwitchSearchCheckboxes
                          options={switchSearchOptions}
                          setOptions={handleUpdateSearchOptions}
                        />
                      </StyledSearchBoxContainer>
                    </StyledGridItemAlignRight>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <SwitchDiagramContainer
                          switchStacks={state.switchStacks}
                          switchStackNames={state.switchStackNames}
                          portHistoryData={state.portHistoryState}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <SwitchTableContainer
                          switchStackNames={state.switchStackNames}
                          filteredFlattenedSwitchData={state.filteredFlattenedSwitchData}
                          portHistoryData={state.portHistoryState}
                        />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </SwitchLoadingIndicator>
          )}

          {canGetSwitchData &&
            switchData?.errors.map(function (error, key) {
              return <SwitchSnackbar error={error} key={key} />;
            })}
          {switchFetchingError && <SwitchSnackbar error={switchFetchingError} />}
          {error && <Snackbar alertType={'error'}>{error}</Snackbar>}
        </Grid>
      </Container>
    </Layout>
  );
}

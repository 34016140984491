import React, {Fragment, useState} from 'react';
import {Collapse} from '@material-ui/core';
import {PortHistoryPort} from '../interfaces/PortHistoryState';
import styled from 'styled-components';

interface SwitchPortHistoryTableProps {
  isOpen: boolean;
  portHistoryPort: PortHistoryPort;
}

const StyledCollapsable = styled(Collapse)`
  background: ${props => props.theme.blue1};
`;

const StyledHeader = styled.p`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.text};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  margin: 10px;
`;

const StyledSubtitle = styled.p`
  font-family: 'Trade Gothic Light';
  font-family: 'Trade Gothic Bold Condensed';
  text-transform: uppercase;
  color: ${props => props.theme.disabledStateBlue};
  font-size: 1rem;
  margin: 0px 15px;
`;

const StyledInfoItem = styled.div`
  word-wrap: break-word;
  flex: 1 0 0;
  margin: 0px 15px;
`;

const StyledPortHistoryContainer = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  margin-bottom: 15px;
`;

const StyledSeparator = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  height: 3px;
  background-color: ${props => props.theme.grey}33;
`;

const StyledGridMessage = styled.p`
  text-align: center;
  grid-column: 1 / 6;
`;

const formatDateString = (date: Date) => {
  if (date == null) return 'Unknown Date';

  let stringDate = date.toISOString().split('T')[0];
  let options: Intl.DateTimeFormatOptions = {hour: 'numeric', minute: 'numeric', second: 'numeric'};
  let time = date.toLocaleString('de-DE', options);
  return stringDate + ' ' + time;
};

const renderPortHistory = (portHistory: PortHistoryPort) => {
  if (portHistory?.snapshots == null || portHistory.snapshots.length < 1) {
    return <StyledGridMessage>No history found for this port.</StyledGridMessage>;
  }

  return portHistory.snapshots.map((snapshot, index) => {
    return (
      <Fragment key={index}>
        <StyledInfoItem>{formatDateString(snapshot.date!!)}</StyledInfoItem>
        <StyledInfoItem>{snapshot.macAddress}</StyledInfoItem>
        <StyledInfoItem>{snapshot.deviceType}</StyledInfoItem>
        <StyledInfoItem>{snapshot.deviceName}</StyledInfoItem>
        <StyledInfoItem>{snapshot.manufacturer}</StyledInfoItem>
      </Fragment>
    );
  });
};

export default function SwitchPortHistoryTable({
  isOpen,
  portHistoryPort,
}: SwitchPortHistoryTableProps) {
  return (
    <StyledCollapsable in={isOpen} timeout="auto" unmountOnExit>
      <StyledHeader>Port History</StyledHeader>
      <StyledPortHistoryContainer>
        <StyledSubtitle>Check-in Date</StyledSubtitle>
        <StyledSubtitle>Mac Address</StyledSubtitle>
        <StyledSubtitle>Device Type</StyledSubtitle>
        <StyledSubtitle>Device Name</StyledSubtitle>
        <StyledSubtitle>Manufacturer</StyledSubtitle>
        <StyledSeparator />
        {renderPortHistory(portHistoryPort)}
      </StyledPortHistoryContainer>
    </StyledCollapsable>
  );
}

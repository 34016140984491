function sortArrays(series, labels) {
  if (series.length !== labels.length) {
    console.log(
      'dashboardTrendingReducer',
      `Series: ${series.length}  !==  Labels: ${labels.length}`
    );

    return {
      series: [0],
      labels: ['series and labels length mismatch'],
    };
  }

  let list = [];
  // 1) combine arrays
  for (let i = 0; i < labels.length; i++) {
    list.push({
      labels: labels[i],
      series: series[i],
    });
  }

  // 2) sort with in descending count:
  list.sort(function (a, b) {
    return b.series - a.series;
  });

  //3) separate them back out:
  let sortedList = {
    series: [],
    labels: [],
  };

  for (let k = 0; k < list.length; k++) {
    sortedList.labels.push(list[k].labels);
    sortedList.series.push(list[k].series);
  }

  return sortedList;
}

function sortDeviceCatSubData(deviceType) {
  let sorted = deviceType;
  sorted.sort(function (a, b) {
    if (Array.isArray(a.children)) {
      sortDeviceCatSubData(a.children);
    }
    return b.count - a.count;
  });

  return sorted;
}

function formatTreeData(data, parentKey) {
  let tree = [];

  const objectKeys = Object.keys(data);
  objectKeys.forEach(id => {
    let node;
    if (id !== 'count' || id !== 'stores') {
      node = {
        name: id,
        id: (parentKey ? parentKey.replace(/\s/g, '') + '-' : '') + id.replace(/\s/g, ''),
        count: data[id].count,
        stores: data[id].stores,
        checked: true,
        children:
          Object.keys(data[id]).length > 1
            ? formatTreeData(data[id], (parentKey ? parentKey + '-' : '') + id)
            : null,
      };
      if (data[id].count > 0) {
        tree.push(node);
      }
    }
  });

  return sortDeviceCatSubData(tree);
}

const dashboardTrendingReducer = data => {
  let dataObj = {
    data: data,
    tree: [],
    treeNodeIds: [],
    circle: {
      series: [],
      labels: [],
    },
  };
  const objectKeys = Object.keys(data);

  // tree
  // name: event.DeviceType,
  // id: event.DeviceType,
  // count: 1,
  // children: []
  const formattedTreeData = formatTreeData(data);
  dataObj.tree = formattedTreeData;

  // circle data
  let series = [];
  let labels = [];

  objectKeys.forEach(key => {
    if (data[key].count > 0) {
      series.push(data[key].count);
      labels.push(key);
    }
  });

  dataObj.circle = sortArrays(series, labels);

  return dataObj;
};

export default dashboardTrendingReducer;

import React from 'react';
import styled from 'styled-components';
import Status from '../../../interfaces/Status';

const StyledStatusIndicator = styled.div`
  background-color: transparent;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 12px;

  &.open {
    background-color: ${props => props.theme.green};
  }
  &.preopen {
    background-color: ${props => props.theme.blue};
  }
  &.closed {
    background-color: ${props => props.theme.grey};
  }
  &.remodel {
    background-color: ${props => props.theme.blue};
  }
`;

const statusClass: Record<string, string> = {
  [Status.ALL]: '',
  [Status.OPEN]: 'open',
  [Status.PREOPEN]: 'preopen',
  [Status.CLOSED]: 'closed',
  [Status.REMODEL]: 'remodel',
};

function StatusIndicator({status}: {status: string}) {
  const indicatorClass: string | undefined = statusClass[status];

  return <StyledStatusIndicator className={indicatorClass ? indicatorClass : ''} />;
}

export default StatusIndicator;

import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AlertIcon from 'features/RRP/components/RRPTable/TableStructure/AlertIcon';
import IAlert from 'features/RRP/interfaces/IAlert';
import Paper from 'components/Paper';
import SGBody from 'components/Typography/SGBody';
import SGSubtitle from 'components/Typography/SGSubtitle';
import CloseIcon from '@material-ui/icons/Close';

interface RRPAlertModalProps {
  alert: IAlert;
  className?: string;
}

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  max-width: 400px;
  padding: 0%;
`;

const StyledSection = styled.div`
  padding: 0 24px;
  margin-bottom: 1.5rem;
`;

const StyledButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    opacity: 0.7;
  }
`;

const StyledHeader = styled.div`
  font-family: 'Trade Gothic Bold Condensed';
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${props => props.theme.disabledStateBlue};
  margin-bottom: 0.5rem;
`;

const StyledTitleSection = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid ${props => props.theme.grey};
  margin-bottom: 24px;
`;

const StyledSGSubtitle = styled(SGSubtitle)`
  margin-left: 0.5rem;
`;

const StyledCloseButton = styled(StyledButton)`
  margin-left: auto;
  color: ${props => props.theme.text};
`;

export default function RRPAlertModal({alert, className}: RRPAlertModalProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={className}>
      <StyledButton type="button" onClick={handleOpen}>
        <AlertIcon alertValue={alert.value} />
      </StyledButton>
      <StyledModal
        aria-labelledby="rrp-alert-modal"
        aria-describedby="rrp-alert-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <StyledPaper>
            <StyledTitleSection>
              <AlertIcon alertValue={alert.value} />
              <StyledSGSubtitle variant={'s1'}>Alert</StyledSGSubtitle>
              <StyledCloseButton onClick={handleClose}>
                <CloseIcon />
              </StyledCloseButton>
            </StyledTitleSection>
            <StyledSection>
              <StyledHeader>Issue</StyledHeader>
              {alert.issue.map((issue, index) => (
                <SGBody variant={'b4'} key={issue + index}>
                  {issue}
                </SGBody>
              ))}
            </StyledSection>
            <StyledSection>
              <StyledHeader>Effect</StyledHeader>
              {alert.effect.map((effect, index) => (
                <SGBody variant={'b4'} key={effect + index}>
                  {effect}
                </SGBody>
              ))}
            </StyledSection>
            <StyledSection>
              <StyledHeader>Solution</StyledHeader>
              {alert.solution.map((solution, index) => (
                <SGBody variant={'b4'} key={solution + index}>
                  {solution}
                </SGBody>
              ))}
            </StyledSection>
          </StyledPaper>
        </Fade>
      </StyledModal>
    </div>
  );
}

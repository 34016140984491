import React from 'react';
import styled from 'styled-components';
import RPTableColumns from './TableStructure/RPTableColumns';
import Table from './TableStructure/Table';
import Paper from '../../../../components/Paper';

const StyledPaper = styled(Paper)`
  /* padding-left: 14px; */
  width: fit-content;
  /* max-width: calc(100% - 48px); */
`;

function RRPTable(props) {
  const {
    tabSelected,
    loaded,
    isAlertsOnly,
    data,
    handleStores,
    removeStore,
    selectedStores,
    isGeoView,
    filters,
  } = props;

  return (
    <StyledPaper>
      <Table
        columns={RPTableColumns}
        data={data}
        loaded={loaded}
        tabSelected={tabSelected}
        isAlertsOnly={isAlertsOnly}
        handleStores={handleStores}
        removeStore={removeStore}
        selectedStores={selectedStores}
        isGeoView={isGeoView}
        filters={filters}
        showAll
      />
    </StyledPaper>
  );
}

export default RRPTable;

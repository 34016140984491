import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

// component built with TypeScript and the ABnB Style guide

interface SGHeadlineProps {
  children: React.ReactNode;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  rest?: any;
}

const StyledHeadline = styled(Typography)`
  font-family: 'Futura C XBd';
  color: ${props => props.theme.subtleText};
  text-transform: uppercase;
  font-size: 1rem;

  &.h1 {
    font-size: 4.5rem;
    line-height: 3.875rem;
  }
  &.h2 {
    font-size: 3.5rem;
    line-height: 3rem;
  }
  &.h3 {
    font-size: 3rem;
    line-height: 2.5rem;
  }
  &.h4 {
    font-size: 2.5rem;
    line-height: 2.125rem;
  }
  &.h5 {
    font-size: 1.5rem;
    line-height: 1.375rem;
  }
  &.h6 {
    font-size: 1.25rem;
    line-height: 1.125rem;
  }
  &.underline {
    text-decoration: underline;
  }

  &.text-color {
    color: ${props => props.theme.text};
  }
  &.active-color {
    color: ${props => props.theme.activeStateBlue};
  }
`;

function SGHeadline({children, variant = 'h3', className, ...rest}: SGHeadlineProps) {
  return (
    <StyledHeadline className={`${variant} ${className}`} {...rest}>
      {children}
    </StyledHeadline>
  );
}

export default SGHeadline;

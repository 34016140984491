import React, {MouseEvent} from 'react';
import styled from 'styled-components';
import MuiTreeItem from '@material-ui/lab/TreeItem';
import SGBody from '../../Typography/SGBody';
import {NavLink} from 'react-router-dom';

export interface TreeItemProp {
  nodeId: string;
  label: string;
  geoStore: string;
  count?: number;
  stores?: number;
  hidden?: boolean;
  children?: React.ReactNode;
}

const StyledTreeItem = styled(MuiTreeItem)`
  .MuiTreeItem-group {
    border-left: 1px solid ${props => props.theme.tableRow};
    padding-left: 13px;
    margin-left: 7px;
  }
  &.hidden {
    display: none;
  }
`;

const StyledLine = styled.div`
  position: relative;
  height: 1px;
  width: 12px;
  left: -36px;
  background-color: ${props => props.theme.tableRow};
  border: none;
  &.hidden {
    background-color: transparent;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${props => props.theme.text};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .text-block {
    position: relative;
    left: -16px;
    display: block;
    width: 50%;
    align-items: center;
  }
  .count-block {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    min-width: 240px;
    align-items: center;
  }
  .count-text {
    text-align: center;
    width: 120px;
  }
  .MuiFormControlLabel-root {
    margin-left: 0px;
  }
`;

const generateURL = function generateURL(nodeId: string, geoStore: string) {
  const properties = ['deviceType', 'category', 'subCategory'];
  const nodeIDArray = nodeId.split('-');
  const urlArray: string[] = [];

  // if geoStore is a geo, push geo as a param
  if (geoStore === 'na' || geoStore === 'emea' || geoStore === 'gc' || geoStore === 'apla') {
    urlArray.push(`geo=${geoStore}`);
  } else {
    // assumes geoStore is not a geo then it should be storeNumber
    urlArray.push(`storeNumber=${geoStore}`);
  }

  nodeIDArray.forEach((node, index) => {
    urlArray.push(`${properties[index]}=${node}`);
  });

  return `?` + urlArray.join('&');
};

function TreeItem({
  nodeId,
  label,
  count = 0,
  stores = 0,
  hidden,
  geoStore,
  children,
}: TreeItemProp) {
  const handleLabelClick = (event: MouseEvent<HTMLInputElement>) => {
    // const clickedId = event.target.id;
    // event.preventDefault();
    event.stopPropagation();
  };

  return (
    <StyledTreeItem
      nodeId={nodeId}
      label={
        <StyledLabel>
          <StyledLine className={hidden ? 'hidden' : ''} />
          <div className={'text-block'}>
            <StyledNavLink to={`/events${generateURL(nodeId, geoStore)}`}>{label}</StyledNavLink>
          </div>
          <div className={'count-block'}>
            <SGBody className={'count-text'}>{count}</SGBody>
            <SGBody className={'count-text'}>{stores}</SGBody>
          </div>
        </StyledLabel>
      }
      onLabelClick={handleLabelClick}
    >
      {children}
    </StyledTreeItem>
  );
}

export default TreeItem;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from './TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Linear from '../../Skeletons/Loading/Linear';

interface TreeMenuItem {
  name: string;
  checked: boolean;
  id: string;
  children?: TreeMenuItem[];
  index?: string;
}

export interface TreeMenuArray extends Array<TreeMenuItem> {}

interface Props {
  node: TreeMenuArray;
  loaded: boolean;
  debugLoaded?: boolean;
}

const StyledTreeView = styled(TreeView)`
  margin-top: 1rem;
  .MuiTreeItem-iconContainer {
    color: ${props => props.theme.subtleText};
  }
`;

function TreeMenu({node, loaded, debugLoaded}: Props) {
  const [ready, setReady] = useState<boolean>(false);
  const [expandedBranches] = useState<Array<string>>([]);

  const renderTree = function renderTree(node: TreeMenuItem, index: number) {
    return (
      <TreeItem key={node.name + index} nodeId={node.id} label={node.name} colorIndex={index}>
        {Array.isArray(node.children)
          ? node.children.map((x, index) => renderTree(x, index))
          : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (debugLoaded === false) {
      setReady(false);
    } else if (loaded === true && debugLoaded === true) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [loaded, debugLoaded]);

  return (
    <>
      {ready ? (
        <StyledTreeView
          defaultExpanded={expandedBranches}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {node.map((x, index) => renderTree(x, index))}
        </StyledTreeView>
      ) : (
        <Linear />
      )}
    </>
  );
}

export default TreeMenu;

import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Icon from '@mdi/react';
import {mdiDownloadOutline} from '@mdi/js';
import {InventoryState} from '../reducer/InventoryReducer';
import InventoryDevice from '../interfaces/InventoryDevice';

const StyledButton = styled(Button)`
  grid-area: 'download';
  background-color: ${props => props.theme.activeStateBlue};
  text-transform: capitalize;
  font-family: 'Text Regular';
  border-radius: 10px;
  padding: 8px 12px;
  line-height: 18px;
  color: ${props => props.theme.defaultNavy};
  font-size: 0.75rem;
  &:hover {
    background-color: ${props => props.theme.activeStateBlue}77;
  }
  &.MuiButton-root.Mui-disabled {
    background-color: ${props => props.theme.grey}80;
    color: ${props => props.theme.defaultNavy};
  }
`;

function downloadCSV(tableDeviceData: InventoryDevice[]) {
  let csvContent = 'data:text/csv;charset=utf-8,';
  const header = [
    'NO/CODE',
    'Name',
    'Brand',
    'GEO',
    'Site Type',
    'Site Status',
    'Store Concept',
    'Device Type',
    'Device Name',
    'Model',
    'Manufacturer',
    'Serial Number',
  ];
  csvContent += header.join(',') + '\r\n';

  tableDeviceData.forEach(function (device) {
    const {
      siteCode,
      siteName,
      brand,
      geo,
      siteType,
      siteStatus,
      storeConcept,
      deviceType,
      deviceName,
      model,
      manufacturer,
      serialNumber,
    } = device;

    const row = [
      siteCode,
      siteName,
      brand,
      geo,
      siteType,
      siteStatus,
      storeConcept,
      deviceType,
      deviceName,
      model,
      manufacturer,
      serialNumber,
    ];

    csvContent += row.join(',') + '\r\n';
  });

  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}

interface DownloadCSVProps {
  invState: InventoryState;
}

function DownloadCSV({invState}: DownloadCSVProps) {
  const {tableDeviceData, loadedGeos, selectedGeos} = invState;
  const notLoadedGeos = selectedGeos.filter(geo => loadedGeos[geo] === false);
  const isAllSelectedGeosLoaded = notLoadedGeos.length === 0 ? true : false;
  const isDisabled = !isAllSelectedGeosLoaded;

  return (
    <StyledButton
      startIcon={<Icon path={mdiDownloadOutline} size={1} />}
      onClick={() => downloadCSV(tableDeviceData)}
      disabled={isDisabled}
    >
      Download
    </StyledButton>
  );
}

export default DownloadCSV;

import React from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {
  themeSelectDefault,
  themeSelectLight,
  themeSelectDark,
  themeSelectLightBlue,
  themeSelectVolt,
} from '../../redux/actions';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const StyledPaper = styled(Paper)`
  p {
    color: ${props => props.theme.text};
    margin-left: 12px;
    font-size: 15px;
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  min-width: 190px;
  margin-left: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  color: ${props => props.theme.altText};
  background-color: ${props => props.theme.tableRow};
  text-decoration: none;
  text-transform: capitalize;
  display: block;
`;

function ThemeSelect() {
  const dispatch = useDispatch();
  const themeSelected = useSelector(state => state.theme.select);

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperTitle>Theme Select (In Development)</PaperTitle>
        </Grid>
        <Grid item xs={12}>
          <p>Selected: {themeSelected}</p>
        </Grid>
        <Grid item xs={12}>
          <StyledButton onClick={() => dispatch(themeSelectDefault())}>Default</StyledButton>
          <StyledButton onClick={() => dispatch(themeSelectLight())}>Light</StyledButton>
          <StyledButton onClick={() => dispatch(themeSelectDark())}>Dark</StyledButton>
          <StyledButton onClick={() => dispatch(themeSelectLightBlue())}>Light Blue</StyledButton>
          <StyledButton onClick={() => dispatch(themeSelectVolt())}>Volt</StyledButton>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default ThemeSelect;

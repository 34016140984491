import React from 'react';
import styled from 'styled-components';

interface CardInfoGridProps {
  numItems: number;
  children?: React.ReactElement;
}
//#region styled-components
const StyledInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 24% 24% 17px 24% 24%;
`;

const StyledGridDivider = styled.div`
  width: 1px;
  background-color: ${props => props.theme.grey};
  grid-column: 3;
  grid-row: 1 / span 1;
  margin: 0 1rem 0 0;
  &.two {
    grid-row: 1 / span 2;
  }
  &.three {
    grid-row: 1 / span 3;
  }
`;
//#endregion styled-components

const calcRows = function calcRows(length: number) {
  if (length > 4) {
    return 'three';
  } else if (length > 2 && length <= 4) {
    return 'two';
  } else {
    return '';
  }
};

export default function CardInfoGrid({numItems, children}: CardInfoGridProps) {
  const dividerClass = calcRows(numItems);
  return (
    <StyledInfoGrid>
      <StyledGridDivider className={dividerClass} />
      {children}
    </StyledInfoGrid>
  );
}

import React from 'react';
import styled from 'styled-components';
import MuiTableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../../../../components/Tables/TableStructure/TableCell';
import SGBody from '../../../../../components/Typography/SGBody';

const StyledTableBody = styled(MuiTableBody)``;

const StyledTableRow = styled(TableRow)`
  &.mouseHover {
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
  /* border-bottom: 1px solid transparent; */
  &.MuiTableCell-root {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    /* padding-top: 0rem; */
    /* padding-bottom: 0rem; */
  }
  &.extra-padding {
    //visually center table contents with sort label
    padding-right: 34px;
  }

  &.no-border {
    border-bottom: none;
  }
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function TableBody(props) {
  const {
    className,
    columns,
    rows,
    rowsPerPage,
    page,
    order,
    orderBy,
    removeStore,
    clickAction,
    clickActionType,
    isGeoView,
  } = props;

  return (
    <StyledTableBody className={className}>
      {(rowsPerPage > 0
        ? stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : stableSort(rows, getComparator(order, orderBy))
      ).map(row => {
        return (
          <StyledTableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.storeNumber}
            onClick={clickAction}
            clickkey={row[clickActionType]}
            className={clickAction ? 'mouseHover ' : ' '}
          >
            {columns.map(column => {
              const value = row[column.id];
              return (
                <StyledTableCell
                  body
                  key={column.id}
                  style={{width: column.width}}
                  align={column.align}
                  className={
                    (column.align === 'center' ? 'extra-padding ' : ' ') + column.customClass
                  }
                >
                  {column.format ? (
                    column.format(value, row, {
                      removeStore,
                      isGeoView,
                    })
                  ) : (
                    <SGBody variant={'b4'}>{value}</SGBody>
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
}

export default TableBody;

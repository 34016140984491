import {Checkbox, FormControlLabel} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: ${props => props.theme.text};
  }
`;

export interface SwitchSearchOptions {
  includeCurrentData: boolean;
  includeHistoricalData: boolean;
}

interface SwitchSearchOptionsProps {
  options: SwitchSearchOptions;
  setOptions: (newOptions: SwitchSearchOptions) => void;
}

export default function SwitchSearchCheckboxes({options, setOptions}: SwitchSearchOptionsProps) {
  return (
    <div style={{flexDirection: 'row'}}>
      <FormControlLabel
        control={
          <StyledCheckbox
            defaultChecked
            size="small"
            checked={options.includeCurrentData}
            onChange={(_: any, checked: boolean) =>
              setOptions({...options, includeCurrentData: checked})
            }
          />
        }
        label="Current data"
      />
      <FormControlLabel
        control={
          <StyledCheckbox
            size="small"
            checked={options.includeHistoricalData}
            onChange={(_: any, checked: boolean) =>
              setOptions({...options, includeHistoricalData: checked})
            }
          />
        }
        label="Include historical data"
      />
    </div>
  );
}

import countEvents from './countEvents';

const calcDifference = function compareCurrentToPrevious(arrrayEpochCount, epochStart, epochEnd) {
  const previousStart = epochStart - (epochEnd - epochStart);
  const previousEnd = epochStart - 1;
  const previousEventCounts = countEvents(arrrayEpochCount, previousStart, previousEnd);
  const currentEventCounts = countEvents(arrrayEpochCount, epochStart, epochEnd);

  const results = currentEventCounts.map(event => {
    const {eventName: currentEventName, totalEvents: currentEventCount} = event;
    const previousEventCount = previousEventCounts.find(
      previousEvent => previousEvent.eventName === currentEventName
    ).totalEvents;

    const difference = ((currentEventCount - previousEventCount) / currentEventCount) * 100;

    return {
      eventName: currentEventName,
      percentDifference: Math.trunc(difference),
    };
  });

  return results;
};

export default calcDifference;

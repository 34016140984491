import SiteType from 'constants/SiteType';
interface Site {
  id: string;
  siteId: string;
  siteName: string;
  brand: string;
  geo: string;
  displayName: string;
  siteCode: string;
  siteType: SiteType;
  siteSearch: string;
  status?: string;
  storeConcept?: string;
}

export const getSiteNumber = (site: Site) => {
  const siteNumberFromId: string = site.siteId.substring(3, 7);
  const siteNumber: number = Number(siteNumberFromId);

  return siteNumber;
};

export const getSiteDisplayName: (site: Site) => string = (site: Site) => {
  const siteNumber = getSiteNumber(site);

  return `${siteNumber} ${site.siteName}`;
};

export default Site;

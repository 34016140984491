import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import RRPTable from './components/RRPTable';
import CardView from './components/CardView';
import useLocalStorage from './hooks/useLocalStorage';
import FetchSiteIdsHook from './hooks/FetchSiteIdsHook';
import FetchGeoHook from './hooks/FetchGeosHook';
import FetchMonthlyHook from './hooks/FetchMonthlyHook';
import DateChip from './components/DateChip';
import DateSelector from './components/DateSelector';
import StatusSummary from './components/StatusSummary';
import createStatusSummaryObj, {
  defaultStatusSummary,
} from './components/StatusSummary/utility/createStatusSummaryObj';

const StyledFilters = styled.div`
  display: flex;
  max-width: calc(100vw - 13.125rem - 96px);
  position: sticky;
  left: calc(13.125rem);
  align-items: center;
  justify-content: space-between;
`;

const defaultRRPStores = [
  {
    StoreNumber: 76,
    StoreName: 'NIKE FACTORY STORE - NORTH BEND',
    Geo: 'NA',
    SiteId: 'NNR0076',
  },
  {
    StoreNumber: 88,
    StoreName: 'NIKE FACTORY STORE - ORLANDO INTERNATIONAL',
    Geo: 'NA',
    SiteId: 'NNR0088',
  },
  {
    StoreNumber: 95,
    StoreName: 'Nike Factory Store - Jeffersonville',
    Geo: 'NA',
    SiteId: 'NNR0095',
  },
  {
    StoreNumber: 99,
    StoreName: 'Nike Factory Store - Oak Brook',
    Geo: 'NA',
    SiteId: 'NNR0099',
  },
  {
    StoreNumber: 128,
    StoreName: 'Nike Factory Store - Lake Buena Vista',
    Geo: 'NA',
    SiteId: 'NNR0128',
  },
  {
    StoreNumber: 129,
    StoreName: 'Nike Factory Store - Woodburn',
    Geo: 'NA',
    SiteId: 'NNR0129',
  },
  {
    StoreNumber: 234,
    StoreName: 'Nike Factory Store - Eagan',
    Geo: 'NA',
    SiteId: 'NNR0234',
  },
  {
    StoreNumber: 246,
    StoreName: 'Nike The Grove',
    Geo: 'NA',
    SiteId: 'NNR0246',
  },
  {
    StoreNumber: 250,
    StoreName: 'Nike Clearance Store- Santa Clarita',
    Geo: 'NA',
    SiteId: 'NNR0250',
  },
  {
    StoreNumber: 258,
    StoreName: 'Nike Factory Store - Staten Island',
    Geo: 'NA',
    SiteId: 'NNR0258',
  },
  {
    StoreNumber: 356,
    StoreName: 'Nike Factory Store - Livermore',
    Geo: 'NA',
    SiteId: 'NNR0356',
  },
  {
    StoreNumber: 358,
    StoreName: 'Nike Factory Store - Oceanside',
    Geo: 'NA',
    SiteId: 'NNR0358',
  },
];

function RRP(props) {
  const {tabSelected, isCardView, isAlertsOnly} = props;

  const [selectedStores, setSelectedStores] = useLocalStorage('rrp', defaultRRPStores);
  const [monthlyReportDate, setMonthlyReportDate] = useState(null);
  const {isSiteIdLoaded, siteIdData} = FetchSiteIdsHook(selectedStores);
  const {isGeoLoaded, geoData} = FetchGeoHook(tabSelected);
  const {isMonthlyDataLoaded, monthlyData} = FetchMonthlyHook(
    monthlyReportDate,
    selectedStores,
    tabSelected
  );

  const [filters, setFilters] = useState('all');

  let statusCounts = defaultStatusSummary;
  if (tabSelected === 'custom' && isSiteIdLoaded) {
    statusCounts = createStatusSummaryObj(siteIdData);
  } else if (tabSelected !== 'custom' && isMonthlyDataLoaded && monthlyReportDate !== null) {
    statusCounts = createStatusSummaryObj(monthlyData);
  } else if (tabSelected !== 'custom' && isGeoLoaded && monthlyReportDate === null) {
    statusCounts = createStatusSummaryObj(geoData);
  }

  const handleFilters = function handleFilters(option) {
    if (option === filters) {
      setFilters('all');
    } else {
      setFilters(option);
    }
  };

  const handleStores = function handleStores(selectedStores) {
    if (selectedStores.length === 0) {
      setSelectedStores([]);
    } else {
      setSelectedStores(selectedStores);
    }
  };

  const removeStore = function removeStore(storeNumber) {
    const updatedSelectedStores = selectedStores.filter(store => store.StoreNumber !== storeNumber);
    setSelectedStores(updatedSelectedStores);
  };

  const handleSetSelectedMonthlyDate = function handleSetSelectedMonthlyDate(date) {
    setMonthlyReportDate(date.toISOString());
  };

  const handeSetReportToNull = function handeSetReportToNull() {
    setMonthlyReportDate(null);
  };

  useEffect(() => {}, [tabSelected]);

  return (
    <>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12}>
          <StyledFilters>
            <DateChip dateTime={monthlyReportDate} closeMonthlyReport={handeSetReportToNull} />
            <DateSelector
              selectedDate={monthlyReportDate}
              setSelectedDate={handleSetSelectedMonthlyDate}
            />
          </StyledFilters>
        </Grid>

        <Grid item xs={12}>
          <StyledFilters>
            <StatusSummary
              filters={filters}
              handleFilters={handleFilters}
              statusCounts={statusCounts}
            />
          </StyledFilters>
        </Grid>
        <Grid item xs={12}>
          {isCardView && tabSelected === 'custom' && (
            <CardView
              stores={siteIdData}
              handleStores={handleStores}
              selectedStores={selectedStores}
            />
          )}

          {isCardView && tabSelected !== 'custom' && (
            <p>
              Due to performance limitations, the Scorecard view is not available for viewing stores
              by Geography.
            </p>
          )}
        </Grid>
      </Grid>

      {/* Custom View */}
      {!isCardView && tabSelected === 'custom' && monthlyReportDate === null && (
        <RRPTable
          tabSelected={tabSelected}
          isAlertsOnly={isAlertsOnly}
          loaded={isSiteIdLoaded}
          data={siteIdData}
          handleStores={handleStores}
          removeStore={removeStore}
          selectedStores={selectedStores}
          filters={filters}
        />
      )}

      {/* Geo Views */}
      {!isCardView && tabSelected !== 'custom' && monthlyReportDate === null && (
        <RRPTable
          tabSelected={tabSelected}
          isAlertsOnly={isAlertsOnly}
          loaded={isGeoLoaded}
          data={geoData}
          handleStores={handleStores}
          removeStore={removeStore}
          selectedStores={selectedStores}
          filters={filters}
          isGeoView
        />
      )}

      {/* Monthly Report View */}
      {!isCardView && monthlyReportDate !== null && (
        <RRPTable
          tabSelected={tabSelected}
          isAlertsOnly={isAlertsOnly}
          loaded={isMonthlyDataLoaded}
          data={monthlyData}
          handleStores={handleStores}
          removeStore={removeStore}
          selectedStores={selectedStores}
          filters={filters}
          isGeoView
        />
      )}
    </>
  );
}

export default RRP;

import React, {useState, useEffect} from 'react';
import styled, {useTheme} from 'styled-components';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TablePaginationActionsStore from './TablePaginationActionsStore';
import {useSelector} from 'react-redux';
import LoadingNoDataTable from './LoadingNoData';

const StyledTableContainer = styled(TableContainer)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: ${props => props.theme.disabled}22;
  }

  ::-webkit-scrollbar-thumb {
    border: 1px solid ${props => props.theme.navText}22;
    background-color: ${props => props.theme.navText}22;

    &:hover {
      border: 1px solid ${props => props.theme.navText}99;
      background-color: ${props => props.theme.navText}99;
    }
  }

  ::-webkit-resizer {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 8rem;

  &.scroll {
    max-height: 550px;
    overflow-y: auto;
  }
`;

const StyledNoData = styled.div`
  position: absolute;
  z-index: 10;
  left: 40%;
  margin-top: 5rem;
`;

const StyledTable = styled(Table)`
  /* border-collapse: separate; */
  /* border-spacing: 0 8px; */
  width: 100%;
  table-layout: fixed;
  &.scroll tbody {
    overflow-y: auto; /* Trigger vertical scroll    */
    overflow-x: hidden; /* Hide the horizontal scroll */
  }
`;

const StyledSpaceer = styled.div`
  width: 100%;
  height: 2rem;
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${props => props.theme.subtleText};
  .MuiIconButton-label {
    /* color: ${props => props.theme.subtleText}; */
  }
  .MuiSelect-icon {
    color: ${props => props.theme.subtleText};
  }
  .MuiIconButton-root.Mui-disabled {
    color: ${props => props.theme.disabled};
  }
  .MuiTablePagination-caption:nth-of-type(2) {
    display: none;
  }
`;

function TableStructure(props) {
  const {
    columns,
    data,
    loaded,
    noDataText,
    clickAction,
    clickActionType,
    disablePagination,
    paginationType,
    paginationText = 'Events',
    customRowsPerPage,
    customRowClass,
    showAll,
    className,
  } = props;

  const theme = useTheme();
  const selectProps = {
    MenuProps: {
      PaperProps: {
        style: {
          backgroundColor: theme.cardBg,
          color: theme.text,
          borderRadius: '10px',
          border: '1px solid',
          borderColor: theme.grey,
        },
      },
    },
  };

  const tableScroll = useSelector(state => state.ui.tableScroll);
  const defaultRowsPerPage = customRowsPerPage?.defaultRowsPerPage
    ? customRowsPerPage.defaultRowsPerPage
    : 5;

  const rowsPerPageOptions = customRowsPerPage?.rowsPerPageOptions
    ? customRowsPerPage.rowsPerPageOptions
    : [5, 10, 25, {label: 'All', value: -1}];

  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setRows(data);
    setPage(0);
  }, [data]);

  useEffect(() => {
    showAll || tableScroll ? setRowsPerPage('All') : setRowsPerPage(defaultRowsPerPage);
  }, [showAll, tableScroll, defaultRowsPerPage]);

  const customPagination = props => {
    return <TablePaginationActions {...props} paginationText={paginationText} />;
  };

  return (
    <>
      <StyledTableContainer className={(tableScroll ? 'scroll ' : ' ') + className}>
        <StyledTable
          stickyHeader
          aria-label="sticky table"
          className={tableScroll ? 'scroll ' : ' '}
        >
          <TableHead
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {loaded && (
            <TableBody
              columns={columns}
              rows={rows}
              order={order}
              orderBy={orderBy}
              rowsPerPage={rowsPerPage}
              page={page}
              clickAction={clickAction}
              clickActionType={clickActionType}
              customRowClass={customRowClass}
            />
          )}
        </StyledTable>
        <LoadingNoDataTable dataCount={rows.length} noDataText={noDataText} isLoaded={loaded} />
      </StyledTableContainer>
      {tableScroll || disablePagination ? (
        <StyledSpaceer className={'end-table-spacer'} />
      ) : (
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={paginationType ? TablePaginationActionsStore : customPagination}
          SelectProps={selectProps}
        />
      )}
    </>
  );
}

export default TableStructure;

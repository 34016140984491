function getLocalTheme() {
  if (localStorage.getItem('theme') === null) {
    return 'default';
  } else {
    return localStorage.getItem('theme');
  }
}

const theme = {
  select: getLocalTheme(),
  default: {
    primary: '#F2F4F5',
    secondary: '#9EDAE5',
    altPrimary: '#123D5C',
    text: '#EDEDED',
    focusText: '#F2F4F5',
    altText: '#9EDAE5',
    titleText: '#EDEDED',
    subtleText: '#527790',
    altSubtleText: '#9EDAE5',
    disabled: '#15495E',
    barBg: '#1A374B',
    cardBg: '#0B2539',
    tableRow: '#527790',
    tableText: '#527790',
    indicatorText: '#0E304B',
    indicatorBg: '#FFFFFF',
    navBg: '#0B2539',
    navText: '#527790',
    navTextActive: '#9EDAE5',
    navBlue: '#9EDAE5',
    navDisabled: '#15495E',
    mapColor: '#0B2539',
    mapOutline: '#155667',
    bgStart: '#155667',
    bgEnd: '#0E314F',
    red: '#F43E60',
    orange: '#F78223',
    yellow: '#FABC2B',
    green: '#37D1B6',
    blue: '#5694D9',
    focusIcon: '#40DBB6',
    colors: ['#38BECF', '#9882AC', '#98DF8A', '#40DBB6', '#2783F2'],
    altColors: ['#DE85FD', '#F7A072', '#CAFE8A', '#E85D75', '#1FC1C1', '#168DE7'],
    defaultNavy: '#0C2A41',
    black: '#111111',
    white: '#FFFFFF',
    blue1: '#0E314F',
    blue2: '#12465C',
    blue3: '#155667',
    disabledStateBlue: '#52779D',
    disabledStateText: '#CBD6E2',
    activeStateBlue: '#9EDAE5',
    navyGrey: '#354C64',
    grey: '#89959D',
    sgColors: ['#FBFF4F', '#F7A072', '#3EE6F4', '#DE85FD', '#94FF52', '#3EA7F4'],
  },
  dark: {
    primary: '#F2F4F5',
    secondary: '#737B80',
    altPrimary: '#111111',
    text: '#EDEDED',
    focusText: '#F2F4F5',
    altText: '#E6F5FF',
    titleText: '#D9D9D9',
    subtleText: '#666666',
    altSubtleText: '#666666',
    disabled: '#666666',
    barBg: '#111111',
    cardBg: '#222222',
    tableRow: '#111111',
    tableText: '#666666',
    indicatorText: '#111111',
    indicatorBg: '#FFFFFF',
    navBg: '#222222',
    navText: '#666666',
    navTextActive: '#F1f1f1',
    navBlue: '#F1f1f1',
    navDisabled: '#666666',
    mapColor: '#2E3133',
    mapOutline: '#111111',
    bgStart: '#111111',
    bgEnd: '#111111',
    red: '#F43E60',
    orange: '#F78223',
    yellow: '#FBBD49',
    green: '#75D15B',
    blue: '#9761C9',
    focusIcon: '#40DBB6',
    colors: ['#38BECF', '#9882AC', '#98DF8A', '#40DBB6', '#2783F2'],
    altColors: ['#DE85FD', '#F7A072', '#CAFE8A', '#E85D75', '#1FC1C1', '#168DE7'],
    defaultNavy: '#0C2A41',
    black: '#111111',
    white: '#FFFFFF',
    blue1: '#4F4F4F',
    blue2: '#858585',
    blue3: '#CFCFCF',
    disabledStateBlue: '#666666',
    disabledStateText: '#9C9C9C',
    activeStateBlue: '#F1f1f1',
    navyGrey: '#354C64',
    grey: '#89959D',
    sgColors: ['#3EA7F4', '#3EE6F4', '#FBFF4F', '#94FF52', '#DE85FD', '#F7A072'],
  },
  light: {
    primary: '#111111',
    secondary: '#f1f1f1',
    altPrimary: '#CCCCCC',
    text: '#222222',
    focusText: '#111111',
    altText: '#999999',
    titleText: '#222222',
    subtleText: '#AAAAAA',
    altSubtleText: '#666666',
    disabled: '#CCCCCC',
    barBg: '#F4F4F4',
    cardBg: '#FFFFFF',
    tableRow: '#F4F4F4',
    tableText: '#AAAAAA',
    indicatorText: '#FFFFFF',
    indicatorBg: '#111111',
    navBg: '#FFFFFF',
    navText: '#AAAAAA',
    navTextActive: '#666666',
    navBlue: '#9EDAE5',
    navDisabled: '#CCCCCC',
    mapColor: '#ffffff',
    mapOutline: '#666666',
    bgStart: '#F4F4F4',
    bgEnd: '#F4F4F4',
    red: '#F43E60',
    orange: '#F78223',
    yellow: '#FBBD49',
    green: '#75D15B',
    blue: '#5694D9',
    focusIcon: '#38BECF',
    colors: ['#38BECF', '#9882AC', '#98DF8A', '#40DBB6', '#2783F2'],
    altColors: ['#DE85FD', '#F7A072', '#CAFE8A', '#E85D75', '#1FC1C1', '#168DE7'],
    defaultNavy: '#0C2A41',
    black: '#111111',
    white: '#FFFFFF',
    blue1: '#4F4F4F',
    blue2: '#E3E3E3',
    blue3: '#FFFFFF',
    disabledStateBlue: '#666666',
    disabledStateText: '#CFCFCF',
    activeStateBlue: '#CCCCCC',
    navyGrey: '#354C64',
    grey: '#89959D',
    sgColors: ['#3EA7F4', '#3EE6F4', '#FBFF4F', '#94FF52', '#DE85FD', '#F7A072'],
  },
  lightBlue: {
    primary: '#9EDAE5',
    secondary: '#EDEDED',
    altPrimary: '#CCCCCC',
    text: '#EDEDED',
    focusText: '#9EDAE5',
    altText: '#999999',
    titleText: '#EDEDED',
    subtleText: '#84A0AA',
    altSubtleText: '#EDEDED',
    disabled: '#999999',
    barBg: '#1B5367',
    cardBg: '#3C6A7B',
    tableRow: '#1B5367',
    tableText: '#84A0AA',
    indicatorText: '#111111',
    indicatorBg: '#FFFFFF',
    navBg: '#3C6A7B',
    navText: '#84A0AA',
    navTextActive: '#9EDAE5',
    navBlue: '#9EDAE5',
    navDisabled: '#999999',
    mapColor: '#3C6A7B',
    mapOutline: '#246273',
    bgStart: '#246273',
    bgEnd: '#032B45',
    red: '#E8536F',
    orange: '#F78223',
    yellow: '#FBBD49',
    green: '#5BCB82',
    blue: '#5694D9',
    focusIcon: '#40DBB6',
    colors: ['#38BECF', '#9882AC', '#98DF8A', '#40DBB6', '#2783F2'],
    altColors: ['#DE85FD', '#F7A072', '#CAFE8A', '#E85D75', '#1FC1C1', '#168DE7'],
    defaultNavy: '#0C2A41',
    black: '#111111',
    white: '#FFFFFF',
    blue1: '#0E314F',
    blue2: '#12465C',
    blue3: '#155667',
    disabledStateBlue: '#52779D',
    disabledStateText: '#CBD6E2',
    activeStateBlue: '#9EDAE5',
    navyGrey: '#354C64',
    grey: '#89959D',
    sgColors: ['#3EA7F4', '#3EE6F4', '#FBFF4F', '#94FF52', '#DE85FD', '#F7A072'],
  },
  volt: {
    primary: '#CEFF00',
    secondary: '#00A1DF',
    altPrimary: '#00A1DF',
    text: '#282828',
    focusText: '#111111',
    altText: '#EDEDED',
    titleText: '#282828',
    subtleText: '#B5B5B5',
    altSubtleText: '#737373',
    disabled: '#DBDBDB',
    barBg: '#F1F1F1',
    cardBg: '#F5F5F6',
    tableRow: '#CCCCCC',
    tableText: '#737373',
    indicatorText: '#111111',
    indicatorBg: '#FFFFFF',
    navBg: '#333333',
    navText: '#EDEDED',
    navTextActive: '#CEFF00',
    navBlue: '#00A1DF',
    navDisabled: '#4A4A4A',
    mapColor: '#BFBFBF',
    mapOutline: '#FFFFFF',
    bgStart: '#FFFFFF',
    bgEnd: '#F1F1F1',
    red: '#F2385A',
    orange: '#F5A503',
    yellow: '#F5D60F',
    green: '#4AD9D9',
    blue: '#82A4FF',
    focusIcon: '#40DBB6',
    colors: ['#38BECF', '#9882AC', '#98DF8A', '#40DBB6', '#2783F2'],
    altColors: ['#757AD1', '#CEFF00', '#00A1DF', '#F78E1E', '#F2385A', '#4AD9D9'],
    defaultNavy: '#0C2A41',
    black: '#111111',
    white: '#FFFFFF',
    blue1: '#4F4F4F',
    blue2: '#E3E3E3',
    blue3: '#CFCFCF',
    disabledStateBlue: '#666666',
    disabledStateText: '#9C9C9C',
    activeStateBlue: '#F1f1f1',
    navyGrey: '#354C64',
    grey: '#89959D',
    sgColors: ['#3EA7F4', '#3EE6F4', '#FBFF4F', '#94FF52', '#DE85FD', '#F7A072'],
  },
};

export default theme;

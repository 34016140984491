import React from 'react';
import styled from 'styled-components';
import SGTitle from '../../Typography/SGTitle';

const StyledActiveLine = styled.div`
  height: 6px;
  background-color: ${props => props.theme.disabled};
`;

const StyledTab = styled.button`
  margin-right: 2rem;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0 0;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.disabled};
  background-color: unset;
  border: none;

  &.active .red {
    color: ${props => props.theme.red};
  }
  &.active .orange {
    color: ${props => props.theme.orange};
  }
  &.active .yellow {
    color: ${props => props.theme.yellow};
  }

  &.active ${StyledActiveLine}.red {
    background-color: ${props => props.theme.red};
  }
  &.active ${StyledActiveLine}.orange {
    background-color: ${props => props.theme.orange};
  }
  &.active ${StyledActiveLine}.yellow {
    background-color: ${props => props.theme.yellow};
  }
`;

interface TabProps {
  title: string;
  tabId: number;
  activeId: number;
  onClick: () => void;
}

const getIndexColor = function getIndexColor(index: number) {
  const colors = ['red', 'orange', 'yellow'];
  if (index > colors.length) {
    return 'navyGrey';
  }
  return colors[index];
};

function Tab({title, tabId, activeId, onClick}: TabProps) {
  const colorName = getIndexColor(tabId);
  return (
    <StyledTab onClick={onClick} className={activeId == tabId ? ' active' : ''}>
      <SGTitle className={colorName}>{title}</SGTitle>
      <StyledActiveLine className={colorName} />
    </StyledTab>
  );
}
export default Tab;

import IAlert from "features/RRP/interfaces/IAlert";
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import defaultNullAlert from "./utility/defaultNullAlert";

const {
  HEALTHY,
  MINOR,
  MAJOR,
} = AlertSeverityEnum;

export default function SDWANEffectivenessQoEAlertValue(sdwanEffectivenessQoE: number): IAlert {
  if (sdwanEffectivenessQoE === null || isNaN(sdwanEffectivenessQoE)) {
    return defaultNullAlert(['sdwanEffectivenessQoE']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  if (sdwanEffectivenessQoE < 9.0) {
    alert.value = MAJOR;
    alert.issue.push('QoE is less than 9.0, indicating a degraded experience.')
    alert.effect.push('May cause slow or intermittently failing network connections for whole store. Likely noticeable by users.');
    alert.solution.push('Investigate issues with WAN Transports, starting with the individual QoE scores ("Before SDWAN") for each Transport. Open tickets with transport carriers and apply remediations as needed.');
  } else if (sdwanEffectivenessQoE < 9.5) {
    alert.value = MINOR;
    alert.issue.push('QoE is less than 9.5, indicating a slightly degraded experience.');
    alert.effect.push('May cause slow or intermittently failing network connections for whole store. Potentially unnoticeable by users.');
    alert.solution.push('Investigate issues with WAN Transports, starting with the individual QoE scores ("Before SDWAN") for each Transport. Open tickets with transport carriers and apply remediations as needed.');
  }

  return alert;
}

function sortArrays(series, labels) {
  if (series.length !== labels.length) {
    console.log(
      'storeEventsDataReducer',
      `Series: ${series.length}  !==  Labels: ${labels.length}`
    );

    return 'Series and Labels have a mismatch length';
  }

  let list = [];
  // 1) combine arrays
  for (let i = 0; i < labels.length; i++) {
    list.push({
      labels: labels[i],
      series: series[i],
    });
  }

  // 2) sort with in descending count:
  list.sort(function (a, b) {
    return b.series - a.series;
  });

  //3) separate them back out:
  let sortedList = {
    series: [],
    labels: [],
  };

  for (let k = 0; k < list.length; k++) {
    sortedList.labels.push(list[k].labels);
    sortedList.series.push(list[k].series);
  }

  return sortedList;
}

function sortDeviceCatSubData(deviceType) {
  let sorted = deviceType;
  sorted.sort(function (a, b) {
    if (Array.isArray(a.children)) {
      sortDeviceCatSubData(a.children);
    }
    return b.count - a.count;
  });

  return sorted;
}

function formatTreeData(data) {
  let tree = [];

  data.forEach(event => {
    let node = {
      name: event.deviceType,
      id: event.deviceType,
      count: 1,
      children: [
        {
          name: event.category,
          id: event.category,
          count: 1,
          children: [
            {
              name: event.subCategory,
              id: event.subCategory,
              count: 1,
              children: [],
            },
          ],
        },
      ],
    };

    const nodeTypes = ['deviceType', 'category', 'subCategory'];
    const treeIndex = tree
      .map(function (event) {
        return event.name;
      })
      .indexOf(event[nodeTypes[0]]);
    if (treeIndex > -1) {
      tree[treeIndex].count++;
      const catIndex = tree[treeIndex].children
        .map(function (event) {
          return event.name;
        })
        .indexOf(event[nodeTypes[1]]);
      if (catIndex > -1) {
        tree[treeIndex].children[catIndex].count++;

        const subIndex = tree[treeIndex].children[catIndex].children
          .map(function (event) {
            return event.name;
          })
          .indexOf(event[nodeTypes[2]]);
        if (subIndex > -1) {
          tree[treeIndex].children[catIndex].children[subIndex].count++;
        } else {
          tree[treeIndex].children[catIndex].children.push({
            name: event.subCategory,
            id: event.subCategory,
            count: 1,
          });
        }
      } else {
        tree[treeIndex].children.push({
          name: event.category,
          id: event.category,
          count: 1,
          children: [
            {
              name: event.subCategory,
              id: event.subCategory,
              count: 1,
            },
          ],
        });
      }
    } else {
      tree.push(node);
    }
  });

  return sortDeviceCatSubData(tree);
}

const storeEventsDataReducer = data => {
  // console.log('rawData',data);
  // const data = JSON.parse(rawData);
  // const formatValues = (array) => {
  //     return array.join(', ');
  // };

  const formatTime = time => {
    //2020-09-01T22:48:21.178762+00:00
    // let timeArray = time.split(/[T.+]/g);

    //2020-09-01 , 22:48:21 , 178762+00:00
    //[     0   ,     1     ,       2    ]
    // return timeArray[0] + ' ' + timeArray[1];

    //updated format for safari and firefox issues
    // let timeArray = time.split(/[-T:.+]/g);
    //2020, 09, 01, 22, 48, 21, 178762, 00, 00
    //[ 0 ,  1,  2,  3,  4,  5,      6,  7,  8]
    // return {
    //     year: timeArray[0],
    //     month: parseInt(timeArray[1]) - 1,
    //     day: timeArray[2],
    //     hour: timeArray[3],
    //     min: timeArray[4],
    //     sec: timeArray[5],
    // };
    // moved the time formatting to the component level
    return time;
  };

  const getPropertyValue = (propertyName, event = undefined) => {
    if (event === undefined) {
      return '';
    } else if (
      propertyName === 'StartDateTime' &&
      event.hasOwnProperty(propertyName) &&
      event[propertyName] !== null
    ) {
      return formatTime(event[propertyName]);
    } else if (propertyName === 'OperationsImpactedDisplay') {
      const currentState = event.CurrentState;
      const operations = currentState.OperationsImpactedDisplay;
      return operations;
    } else if (propertyName === 'Description') {
      const currentState = event.CurrentState;
      const description = currentState.Description;
      return description;
    } else if (propertyName === 'LatestState') {
      return event.StateChanges.slice(-1);
    } else {
      return event.hasOwnProperty(propertyName) ? event[propertyName] : '';
    }
  };

  const defaultValues = {
    id: getPropertyValue('id'),
    storeNumber: getPropertyValue('StoreNumber'),
    type: getPropertyValue('Type'),
    deviceType: getPropertyValue('DeviceType'),
    deviceName: getPropertyValue('DeviceName'),
    category: getPropertyValue('Category'),
    timeOfIssue: getPropertyValue('StartDateTime'),
    operations: getPropertyValue('OperationsImpactedDisplay'),
    storeImpact: getPropertyValue('StoreImpact'),
    deviceImpact: getPropertyValue('DeviceImpact'),
    stateChanges: getPropertyValue('StateChanges'),
    latestState: getPropertyValue('LatestState'),
    description: getPropertyValue('Description'),
  };

  if (data === undefined) {
    // if no data is provided return default values
    return {
      critical: [defaultValues],
      issues: [defaultValues],
    };
  }

  let eventsArray = {
    critical: [],
    issues: [],
    allEvents: [],
    deviceCategory: [],
    tree: [],
    circle: {
      series: [],
      labels: [],
    },
  };

  data.forEach(event => {
    let eventApp = {
      id: getPropertyValue('id', event),
      storeNumber: getPropertyValue('StoreNumber', event),
      type: getPropertyValue('Type', event),
      deviceType: getPropertyValue('DeviceType', event),
      deviceName: getPropertyValue('DeviceName', event),
      category: getPropertyValue('Category', event),
      subCategory: getPropertyValue('SubCategory', event),
      timeOfIssue: getPropertyValue('StartDateTime', event),
      operations: getPropertyValue('OperationsImpactedDisplay', event),
      storeImpact: getPropertyValue('StoreImpact', event),
      deviceImpact: getPropertyValue('DeviceImpact', event),
      stateChanges: getPropertyValue('StateChanges', event),
      latestState: getPropertyValue('LatestState', event),
      description: getPropertyValue('Description', event),
    };

    if (eventApp.latestState[0].StoreImpact === 3) {
      eventsArray.critical.push(eventApp);
    } else {
      eventsArray.issues.push(eventApp);
    }
    eventsArray.allEvents.push(eventApp);

    const categoryIndex = eventsArray.deviceCategory
      .map(category => category.category)
      .indexOf(event.Category);
    if (
      eventsArray.deviceCategory.map(category => category.category).indexOf(event.Category) > -1
    ) {
      eventsArray.deviceCategory[categoryIndex].count += 1;
    } else if (eventsArray.deviceCategory.indexOf(event.Category) === -1) {
      eventsArray.deviceCategory.push({
        category: event.Category,
        count: 1,
      });
    }

    //generate circle data
    const deviceTypeIndex = eventsArray.circle.labels.indexOf(eventApp.deviceType);
    if (deviceTypeIndex > -1) {
      eventsArray.circle.series[deviceTypeIndex] += 1;
    } else {
      eventsArray.circle.series.push(1);
      eventsArray.circle.labels.push(eventApp.deviceType);
    }
  });

  eventsArray.circle = sortArrays(eventsArray.circle.series, eventsArray.circle.labels);
  eventsArray.tree = formatTreeData(eventsArray.allEvents);

  return eventsArray;
};

export default storeEventsDataReducer;

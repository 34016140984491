import React, {useState, ChangeEvent} from 'react';
import styled from 'styled-components';
import Brand from '../../../interfaces/Brand';
import Geo from '../../../interfaces/SiteGeoSelection';
import SiteType from '../../../interfaces/SiteType';
import Status from '../../../interfaces/Status';
import SGHeadline from 'components/Typography/SGHeadline';
import SelectItems, {SelectItemsProps} from './SelectItems';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from 'react-router-dom';
import SiteSearchButtons from './SiteSearchButtons';

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: 32px 32px 32px 32px 32px 32px;
  align-items: center;
  padding: 12px 24px;
  gap: 16px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSGHeadline = styled(SGHeadline)`
  &.h3 {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
`;

const StyledCloseButton = styled(IconButton)`
  color: ${props => props.theme.text};
  margin-right: -12px;
  transition: color 150ms ease-in-out;
  &:hover {
    color: ${props => props.theme.activeStateBlue};
  }

  &:hover {
    background-color: ${props => props.theme.activeStateBlue}0A;
  }
`;

interface SiteIdMenuProps {
  handleClose: () => void;
  urlType: string;
}

export default function SiteIdMenu({handleClose, urlType}: SiteIdMenuProps) {
  const [brand, setBrand] = useState<Brand>(Brand.ALL);
  const [geo, setGeo] = useState<Geo>(Geo.ALL);
  const [siteType, setSiteType] = useState<SiteType>(SiteType.ALL);
  const [status, setStatus] = useState<Status>(Status.ALL);
  let history = useHistory();

  const handleBrandChange = (event: ChangeEvent<{value: unknown}>) => {
    const value = event.target.value as Brand;
    setBrand(value);
  };

  const handleGeoChange = (event: ChangeEvent<{value: unknown}>) => {
    const value = event.target.value as Geo;
    setGeo(value);
  };

  const handleConceptChange = (event: ChangeEvent<{value: unknown}>) => {
    const value = event.target.value as SiteType;
    setSiteType(value);
    if (value !== SiteType.ALL) {
      setStatus(Status.ALL);
    }
  };

  const handleStatusChange = (event: ChangeEvent<{value: unknown}>) => {
    const value = event.target.value as Status;
    setStatus(value);
    if (value !== Status.ALL) {
      setSiteType(SiteType.RETAIL);
    }
  };

  const handleReset = () => {
    setBrand(Brand.ALL);
    setGeo(Geo.ALL);
    setSiteType(SiteType.ALL);
    setStatus(Status.ALL);
  };

  const handleSearch = () => {
    const brandParams = brand === Brand.ALL ? [Brand.NIKE, Brand.CONVERSE] : [brand];
    const geoParams = geo === Geo.ALL ? [Geo.NA, Geo.EMEA, Geo.GC, Geo.APLA] : [geo];
    const siteTypeParams =
      siteType === SiteType.ALL ? [SiteType.RETAIL, SiteType.DC, SiteType.SHOWROOM] : [siteType];
    const statusParams =
      status === Status.ALL
        ? [Status.OPEN, Status.PREOPEN, Status.CLOSED, Status.REMODEL]
        : [status];

    // history must be formatted as:
    // /site-search/:urlKey/:brand/:geo/:siteType/:status
    // source: App.jsx

    history.push(
      `/site-search/${urlType}/${brandParams.join(',')}/${geoParams.join(
        ','
      )}/${siteTypeParams.join(',')}/${statusParams.join(',')}`
    );

    handleClose();
  };

  const isDCShowroomDisabled = () => {
    return status !== Status.ALL ? true : false;
  };

  const isStatusDisabled = () => {
    return siteType === SiteType.ALL || siteType === SiteType.RETAIL ? false : true;
  };

  const selectItems: SelectItemsProps[] = [
    {
      selectLabel: 'Brand',
      value: brand,
      onChange: handleBrandChange,
      id: 'drl_brand',
      menuItems: [
        {value: Brand.ALL, label: 'All'},
        {value: Brand.NIKE, label: 'Nike'},
        {value: Brand.CONVERSE, label: 'Converse'},
      ],
    },
    {
      selectLabel: 'Geo',
      value: geo,
      onChange: handleGeoChange,
      id: 'drl_geo',
      menuItems: [
        {value: Geo.ALL, label: 'All'},
        {value: Geo.NA, label: 'NA'},
        {value: Geo.EMEA, label: 'EMEA'},
        {value: Geo.GC, label: 'GC'},
        {value: Geo.APLA, label: 'APLA'},
      ],
    },
    {
      selectLabel: 'Site Type',
      value: siteType,
      onChange: handleConceptChange,
      id: 'drl_sitetype',
      menuItems: [
        {value: SiteType.ALL, label: 'All'},
        {value: SiteType.RETAIL, label: 'Retail'},
        {value: SiteType.DC, label: 'Distribution Center', isDisabled: isDCShowroomDisabled()},
        {value: SiteType.SHOWROOM, label: 'Showroom', isDisabled: isDCShowroomDisabled()},
      ],
    },
    {
      selectLabel: 'Status',
      value: status,
      onChange: handleStatusChange,
      isDisabled: isStatusDisabled(),
      id: 'drl_status',
      menuItems: [
        {value: Status.ALL, label: 'All'},
        {value: Status.OPEN, label: 'Open'},
        {value: Status.PREOPEN, label: 'Pre-Open'},
        {value: Status.REMODEL, label: 'Remodel'},
        {value: Status.CLOSED, label: 'Closed'},
      ],
    },
  ];

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledSGHeadline className="text-color">FILTER BY</StyledSGHeadline>
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon />
        </StyledCloseButton>
      </StyledHeaderContainer>
      {selectItems.map(item => (
        <SelectItems key={item.selectLabel} {...item} />
      ))}
      <SiteSearchButtons handleReset={handleReset} handleSearch={handleSearch} />
    </StyledContainer>
  );
}

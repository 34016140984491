import React, {useState, ReactElement} from 'react';
import {FlatSwitchData} from '../interfaces/SwitchConnectivityState';
import SwitchTable from '../SwitchTable/SwitchTable';
import SwitchDiagramControls from './SwitchDiagramControls';
import SwitchAccordian from '../components/SwitchAccordian';
import {PortHistoryState} from '../interfaces/PortHistoryState';

const renderSwitchAccordion = (
  switchStackNames: string[],
  filteredFlattenedSwitchData: FlatSwitchData[],
  portHistoryData: PortHistoryState,
  anchorIDPrefix: string,
  expandedSwitches: string[],
  handleAccordianChange: (switchStack: string) => void
): ReactElement[] => {
  const switchAccordians: ReactElement[] = switchStackNames.map(switchStack => {
    const isExpanded: boolean = expandedSwitches.includes(switchStack);
    return (
      <SwitchAccordian
        header={switchStack}
        anchorId={`${anchorIDPrefix}${switchStack}`}
        isExpanded={isExpanded}
        key={`${anchorIDPrefix}${switchStack}`}
        handleAccordianChange={handleAccordianChange}
      >
        <SwitchTable
          data={filteredFlattenedSwitchData.filter(
            device => switchStack === device.switchStackName
          )}
          portHistoryData={portHistoryData.switchStacks[switchStack]}
          title={switchStack}
          loaded={true}
          noDataText={"There isn't anything to show here."}
        />
      </SwitchAccordian>
    );
  });

  return switchAccordians;
};

interface SwitchTableContainerProps {
  switchStackNames: string[];
  filteredFlattenedSwitchData: FlatSwitchData[];
  portHistoryData: PortHistoryState;
}

export default function SwitchTableContainer({
  switchStackNames,
  filteredFlattenedSwitchData,
  portHistoryData,
}: SwitchTableContainerProps) {
  const anchorIDPrefix: string = 'switch-table-';
  const [expandedSwitches, setExpandedSwitches] = useState<string[]>(switchStackNames);

  const handleAccordianChange = (switchStack: string): void => {
    const prevExpandedSwitches: string[] = [...expandedSwitches];
    const indexOfSwitchStack: number = prevExpandedSwitches.indexOf(switchStack);

    if (indexOfSwitchStack > -1) {
      prevExpandedSwitches.splice(indexOfSwitchStack, 1);
    } else {
      prevExpandedSwitches.push(switchStack);
    }

    setExpandedSwitches(prevExpandedSwitches);
  };

  const handleCollapseExpandAll = (): void => {
    if (expandedSwitches.length > 0) {
      setExpandedSwitches([]);
    } else {
      setExpandedSwitches(switchStackNames);
    }
  };

  const SwitchAccordions = renderSwitchAccordion(
    switchStackNames,
    filteredFlattenedSwitchData,
    portHistoryData,
    anchorIDPrefix,
    expandedSwitches,
    handleAccordianChange
  );

  return (
    <>
      <SwitchDiagramControls
        switchStackNames={switchStackNames}
        anchorIdPrefix={anchorIDPrefix}
        expandedSwitches={expandedSwitches}
        handleCollapseExpandAll={handleCollapseExpandAll}
      />
      {SwitchAccordions}
    </>
  );
}

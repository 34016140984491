import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

interface RRPSwitchProps {
  isToggled: boolean;
  label: string;
  handleToggle: () => void;
  className?: string;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: ${props => props.theme.grey};
  }
`;

const StyledSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    color: ${props => props.theme.grey};
  }
  .Mui-checked .MuiSwitch-thumb {
    color: ${props => props.theme.activeStateBlue};
  }
  .MuiSwitch-track {
    background-color: ${props => props.theme.grey};
    opacity: 1;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.activeStateBlue};
  }
`;

function RRPSwitch({isToggled, label, handleToggle, className}: RRPSwitchProps) {
  return (
    <FormControl component="fieldset" className={className}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={label}
          control={<StyledSwitch size="small" checked={isToggled} onChange={handleToggle} />}
          label={label}
          labelPlacement="start"
        />
      </FormGroup>
    </FormControl>
  );
}

export default RRPSwitch;

import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const StyledRectSkeleton = styled(Skeleton)`
  background-color: ${props => props.theme.text}11;
  border-radius: 10px;
  &.MuiSkeleton-wave::after {
    background: linear-gradient(90deg, transparent, ${props => props.theme.text}22, transparent);
  }
`;

function RectSkeleton(props) {
  const {children, className, ...rest} = props;

  return (
    <StyledRectSkeleton animation="wave" variant="rect" className={className} {...rest}>
      {children}
    </StyledRectSkeleton>
  );
}

export default RectSkeleton;

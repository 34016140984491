import React from 'react';
import styled from 'styled-components';
import TableText from '../TableStructure/TableText';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloudIcon from '@material-ui/icons/Cloud';
import DnsIcon from '@material-ui/icons/Dns';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import StarIcon from '@material-ui/icons/Star';
import DynamicFeedRoundedIcon from '@material-ui/icons/DynamicFeedRounded';
import {ReactComponent as CashRegisterIcon} from '../../../assets/icons/cash-register.svg';
import ToolTip from '../../ToolTip';
import ItemIndicator from '../TableStructure/ItemIndicator';

const StyledSvgIcon = styled(SvgIcon)`
  color: ${props => props.theme.tableText};
  width: 1.25rem;
  height: 1.25rem;
  &.withText {
    font-size: 1rem;
    position: relative;
    top: 2px;
    margin-right: 2px;
    vertical-align: middle;
  }

  //shared styles
  &.red,
  .red {
    color: ${props => props.theme.red};
  }
  &.orange,
  .orange {
    color: ${props => props.theme.yellow};
  }
  &.yellow,
  .yellow {
    color: ${props => props.theme.yellow};
  }
  &.green,
  .green {
    color: ${props => props.theme.green};
  }
  &.focus,
  .focus {
    color: ${props => props.theme.focusIcon};
  }

  &.disabled,
  .disabled {
    color: ${props => props.theme.disabled};
  }

  &.cardBg,
  .cardBg {
    color: ${props => props.theme.cardBg};
  }

  &.star,
  .star {
    font-size: 1rem;
  }

  &.circle-wrapper,
  .circle-wrapper {
    /* width: 20px; */
    /* height: 20px; */
    border-radius: 50%;
    background-color: ${props => props.theme.disabledStateBlue};
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  p {
    margin-left: 8px;
  }
`;

const renderIconType = function renderIconType(iconType, color) {
  const iconFormat = iconType.toLowerCase();
  if (iconFormat === 'warning') {
    return <WarningRoundedIcon className={'red'} />;
  } else if (iconFormat === 'network') {
    return <CloudIcon className={color} />;
  } else if (iconFormat === 'server') {
    return <DnsIcon className={color} />;
  } else if (iconFormat === 'register') {
    return <CashRegisterIcon className={color} />;
  } else if (iconFormat === 'phone' || iconFormat === 'athlete device') {
    return <PhoneIphoneIcon className={color} />;
  } else if (iconFormat === 'pc') {
    return <DesktopWindowsIcon className={color} />;
  } else if (iconFormat === 'check') {
    return <CheckRoundedIcon className={color} />;
  } else if (iconFormat === 'checkwithcircle') {
    return <CheckRoundedIcon className={color} />;
  } else if (iconFormat === 'aggregate') {
    return <DynamicFeedRoundedIcon className={color} />;
  } else if (iconFormat === 'star') {
    return <StarIcon className={color} />;
  } else {
    return <WarningRoundedIcon />;
  }
};

const renderIconFormat = function renderIconFormat(
  className,
  iconType,
  text,
  toolTipText,
  number,
  color
) {
  if (text) {
    //if text is provided, render with icon and text
    return (
      <StyledTextContainer>
        <StyledSvgIcon className={'withText ' + className + ' ' + color}>
          {renderIconType(iconType, color)}
        </StyledSvgIcon>
        <TableText>{text}</TableText>
      </StyledTextContainer>
    );
  } else if (number) {
    //if number is provided, render with icon and count as bubble
    return (
      <ItemIndicator count={number} className={color}>
        <SvgIcon>{renderIconType(iconType, color)}</SvgIcon>
      </ItemIndicator>
    );
  } else {
    // render with just icon
    return (
      <StyledSvgIcon className={className + ' ' + color}>
        {renderIconType(iconType, color)}
      </StyledSvgIcon>
    );
  }
};

function Icons(props) {
  const {className, iconType, text, toolTipText, number, color} = props;

  if (toolTipText) {
    return (
      <ToolTip title={toolTipText}>
        {renderIconFormat(className, iconType, text, toolTipText, number, color)}
      </ToolTip>
    );
  } else {
    return renderIconFormat(className, iconType, text, toolTipText, number, color);
  }
}

export default Icons;

import React, {useState, ReactElement, useEffect} from 'react';
import SwitchDiagram from './SwitchDiagram';
import SwitchDiagramControls from './SwitchDiagramControls';
import SwitchAccordian from '../components/SwitchAccordian';
import {SwitchStackData} from '../interfaces/SwitchConnectivityState';
import {PortHistoryState, PortHistorySwitchStack} from '../interfaces/PortHistoryState';

const renderSwitchDiagrams = (
  switchStack: SwitchStackData,
  portHistorySwitchStack: PortHistorySwitchStack | null
): ReactElement[] => {
  const switchDiagrams: ReactElement[] = [];

  switchStack.memberSwitches.forEach(switchItem => {
    const {switchNumber, mainPorts} = switchItem;
    const portHistorySwitch = portHistorySwitchStack?.switches[switchNumber] ?? null;

    switchDiagrams.push(
      <SwitchDiagram
        key={switchNumber}
        ports={mainPorts}
        switchNumber={switchNumber}
        portHistorySwitch={portHistorySwitch}
      />
    );
  });

  return switchDiagrams;
};

const renderSwitchAccordion = (
  switchStacks: SwitchStackData[],
  anchorIDPrefix: string,
  expandedSwitches: string[],
  switchStackNames: string[],
  portHistoryData: PortHistoryState,
  handleAccordianChange: (switchStack: string) => void
): ReactElement[] => {
  const switchAccordians: ReactElement[] = switchStacks.map(switchStack => {
    const isExpanded: boolean = expandedSwitches.includes(switchStack.switchStackName);
    const SwitchDiagrams = renderSwitchDiagrams(
      switchStack,
      portHistoryData.switchStacks[switchStack.switchStackName] ?? null
    );
    return (
      <SwitchAccordian
        key={switchStack.switchStackName}
        header={switchStack.switchStackName}
        anchorId={`${anchorIDPrefix}${switchStack.switchStackName}`}
        isExpanded={isExpanded}
        handleAccordianChange={handleAccordianChange}
      >
        {SwitchDiagrams}
      </SwitchAccordian>
    );
  });

  return switchAccordians;
};

interface SwitchDiagramContainerProps {
  switchStacks: SwitchStackData[];
  switchStackNames: string[];
  portHistoryData: PortHistoryState;
}

export default function SwitchDiagramContainer({
  switchStacks,
  switchStackNames,
  portHistoryData,
}: SwitchDiagramContainerProps) {
  const anchorIDPrefix: string = 'switch-diagram-';

  const [expandedSwitches, setExpandedSwitches] = useState<string[]>(switchStackNames);

  const handleAccordianChange = (switchStack: string): void => {
    const prevExpandedSwitches: string[] = [...expandedSwitches];
    const indexOfSwitchStack: number = prevExpandedSwitches.indexOf(switchStack);

    if (indexOfSwitchStack > -1) {
      prevExpandedSwitches.splice(indexOfSwitchStack, 1);
    } else {
      prevExpandedSwitches.push(switchStack);
    }

    setExpandedSwitches(prevExpandedSwitches);
  };

  const handleCollapseExpandAll = (): void => {
    if (expandedSwitches.length > 0) {
      setExpandedSwitches([]);
    } else {
      setExpandedSwitches(switchStackNames);
    }
  };

  useEffect(() => {
    setExpandedSwitches([...switchStackNames]);
  }, [switchStackNames]);

  const SwitchesDiagrams = renderSwitchAccordion(
    switchStacks,
    anchorIDPrefix,
    expandedSwitches,
    switchStackNames,
    portHistoryData,
    handleAccordianChange
  );

  return (
    <>
      <SwitchDiagramControls
        switchStackNames={switchStackNames}
        anchorIdPrefix={anchorIDPrefix}
        expandedSwitches={expandedSwitches}
        handleCollapseExpandAll={handleCollapseExpandAll}
      />
      {SwitchesDiagrams}
    </>
  );
}

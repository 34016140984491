import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const StyledButton = styled(MuiButton)`
  margin-left: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  color: ${props => props.theme.altText};
  background-color: ${props => props.theme.tableRow};
  text-decoration: none;
  text-transform: capitalize;
  display: block;

  &.outline {
    background-color: none;
    border: 1px solid ${props => props.theme.tableRow};
  }

  &.disabled {
    color: ${props => props.theme.subtleText};
    background-color: ${props => props.theme.altPrimary};
  }
`;

function Button(props) {
  const {variant = 'contained', children, disable, className, ...rest} = props;

  return (
    <StyledButton variant={variant} className={(disable ? 'disabled ' : ' ') + className} {...rest}>
      {children}
    </StyledButton>
  );
}

export default Button;

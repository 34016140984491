import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import StyledContainer from '../components/Container';
import StyledPaper from '../components/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../components/Typography/Title';
import StyledPaperTitle from '../components/PaperTitle';
import Button from '../components/CTA/SGButton';
import SGTitle from '../components/Typography/SGTitle';
import SGSubtitle from '../components/Typography/SGSubtitle';
import SGBody from '../components/Typography/SGBody';

const StyledComponentContainer = styled.div`
  padding: 0.5rem;
  border: 1px dashed blue;
  display: flex;
  .altBackground {
    background-color: ${props => props.theme.blue3};
  }
`;

const StyledHeader = styled(StyledComponentContainer)`
  border: 1px solid #00000000;
`;

function StyleGuide(props) {
  const {match} = props;

  return (
    <Layout match={match}>
      <StyledContainer>
        <Grid container spacing={5} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Title>Style Guide</Title>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <StyledPaper>
              <StyledPaperTitle>Typography</StyledPaperTitle>
              <br />
              <br />
              <StyledComponentContainer>
                <Grid container spacing={1} direction="row" alignItems="stretch">
                  <Grid item xs={1}>
                    <SGSubtitle variant={'s1'}>Title</SGSubtitle>
                  </Grid>
                  <Grid item xs={3}>
                    <SGTitle variant={'t1'}>Title 1</SGTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Trade Gothic LT</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Bold</SGBody>
                    <SGBody>Size: 36pt</SGBody>
                    <SGBody>Line Height: 36pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGTitle variant={'t2'}>Title 2</SGTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Trade Gothic LT</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Bold</SGBody>
                    <SGBody>Size: 24pt</SGBody>
                    <SGBody>Line Height: 22pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGTitle variant={'t3'}>Title 3</SGTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Trade Gothic LT</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Bold</SGBody>
                    <SGBody>Size: 18pt</SGBody>
                    <SGBody>Line Height: 20pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGTitle variant={'t4'}>Title 4</SGTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Trade Gothic LT</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Bold</SGBody>
                    <SGBody>Size: 18pt</SGBody>
                    <SGBody>Line Height: 16pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGTitle variant={'t5'}>Subtitle 5</SGTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Trade Gothic LT</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Bold</SGBody>
                    <SGBody>Size: 14pt</SGBody>
                    <SGBody>Line Height: 12pt</SGBody>
                  </Grid>
                </Grid>
              </StyledComponentContainer>

              <StyledComponentContainer>
                <Grid container spacing={1} direction="row" alignItems="stretch">
                  <Grid item xs={1}>
                    <SGSubtitle variant={'s1'}>Subtitle</SGSubtitle>
                  </Grid>
                  <Grid item xs={3}>
                    <SGSubtitle variant={'s1'}>Subtitle 1</SGSubtitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Roboto</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Medium</SGBody>
                    <SGBody>Size: 16pt</SGBody>
                    <SGBody>Line Height: 24pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGSubtitle variant={'s2'}>Subtitle 2</SGSubtitle>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Roboto</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Medium</SGBody>
                    <SGBody>Size: 14pt</SGBody>
                    <SGBody>Line Height: 20pt</SGBody>
                  </Grid>
                </Grid>
              </StyledComponentContainer>

              <StyledComponentContainer>
                <Grid container spacing={1} direction="row" alignItems="stretch">
                  <Grid item xs={1}>
                    <SGSubtitle variant={'s1'}>Body</SGSubtitle>
                  </Grid>
                  <Grid item xs={3}>
                    <SGBody variant={'b1'}>Body 1</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Roboto</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Regular</SGBody>
                    <SGBody>Size: 20pt</SGBody>
                    <SGBody>Line Height: 28pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGBody variant={'b2'}>Body 2</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Roboto</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Regular</SGBody>
                    <SGBody>Size: 16pt</SGBody>
                    <SGBody>Line Height: 24pt</SGBody>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <SGBody variant={'b3'}>Body 3</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Roboto</SGBody>
                  </Grid>
                  <Grid item xs={4}>
                    <SGBody>Weight: Regular</SGBody>
                    <SGBody>Size: 14pt</SGBody>
                    <SGBody>Line Height: 20pt</SGBody>
                  </Grid>
                </Grid>
              </StyledComponentContainer>
            </StyledPaper>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <StyledPaper>
              <StyledPaperTitle>Buttons</StyledPaperTitle>

              <StyledHeader>
                <Grid container spacing={1} direction="row" alignItems="stretch">
                  <Grid item xs={4}>
                    Default
                  </Grid>
                  <Grid item xs={4}>
                    Alt Background
                  </Grid>
                  <Grid item xs={4}>
                    Disabled
                  </Grid>
                </Grid>
              </StyledHeader>

              <StyledComponentContainer>
                <Grid container spacing={2} direction="row" alignItems="stretch">
                  <Grid item xs={4}>
                    <Button>Button</Button>
                  </Grid>
                  <Grid item xs={4} className={'altBackground'}>
                    <Button>Button</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button disabled>Button</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant={'outlined'} color={'primary'}>
                      Button
                    </Button>
                  </Grid>
                  <Grid item xs={4} className={'altBackground'}>
                    <Button variant={'outlined'} color={'primary'}>
                      Button
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant={'outlined'} color={'primary'} disabled>
                      Button
                    </Button>
                  </Grid>
                </Grid>
              </StyledComponentContainer>
            </StyledPaper>
          </Grid>
        </Grid>
      </StyledContainer>
    </Layout>
  );
}

export default StyleGuide;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import SGBody from '../../Typography/SGBody';
import TableProgressLinear from '../../Skeletons/Loading/TableProgressLinear';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 18rem;
  &.hidden {
    display: none;
  }
`;

function LoadingNoDataTable(props) {
  const {
    isLoaded,
    dataCount,
    noDataText = `There currently isn't anything to display here.`,
  } = props;

  const [visible, setVisible] = useState(true);
  const [useNoDataText, setUseNoDataText] = useState(false);

  useEffect(() => {
    if (isLoaded && dataCount === 0) {
      setUseNoDataText(true);
      setVisible(true);
    } else if (isLoaded && dataCount > 0) {
      setVisible(false);
    } else {
      setVisible(true);
      setUseNoDataText(false);
    }
  }, [dataCount, isLoaded]);

  return (
    <StyledContainer className={visible ? '' : 'hidden'}>
      {useNoDataText ? <SGBody>{noDataText}</SGBody> : <TableProgressLinear />}
    </StyledContainer>
  );
}

export default LoadingNoDataTable;

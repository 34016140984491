export const initialState = {
  dataRetrieved: false,
  pending: false,
  data: [],
  error: null,
};

const deviceHealthCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DEVICE_HEALTH_COUNT_PENDING':
      return {
        ...state,
        pending: true,
      };
    case 'GET_DEVICE_HEALTH_COUNT_SUCCESS':
      return {
        ...state,
        pending: false,
        data: action.data,
        dataRetrieved: true,
      };
    case 'GET_DEVICE_HEALTH_COUNT_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default deviceHealthCountReducer;

export const rdxDeviceHealthCountDataPending = state => state.deviceHealthCount.pending;
export const rdxDeviceHealthCountDataReady = state => state.deviceHealthCount.dataRetrieved;
export const rdxDeviceHealthCountData = state => state.deviceHealthCount.data;

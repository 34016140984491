import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
} = AlertSeverityEnum;

export default function wirelessClientHealthAvgAlertValue(
  wirelessClientGood: number,
  wirelessClientsTotal: number
): IAlert {
  if (
    wirelessClientGood === null ||
    wirelessClientsTotal === null ||
    isNaN(wirelessClientGood) ||
    isNaN(wirelessClientsTotal)
  ) {
    return defaultNullAlert(['wirelessClientGood', 'wirelessClientsTotal']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  const percentUp = wirelessClientGood / wirelessClientsTotal;
  if (percentUp < 0.8) {
    alert.value = MAJOR;
    alert.issue.push('Average wireless client health is below 80%');
  } else if (percentUp < 0.9) {
    alert.value = MINOR;
    alert.issue.push('Average wireless client health is below 90%.');
  }

  if (alert.value !== HEALTHY) {
    alert.effect.push('Low wireless client health averages may indicate slow or intermittently failing wireless network connections. Note that this metric can be negatively skewed by data collected when devices are not in use (such as handhelds placed in drawers, or guests leaving the store)');
    alert.solution.push('Meaningful only when paired with reports of issues with wireless connectivity.');
    alert.solution.push('Consider performing an active wireless survey in the store, followed by remediation of Wireless AP placement and count.');
    alert.solution.push('Contact Wireless Network Engineering for further investigation.');
  }

  return alert;
}

import React from 'react';
import Layout from './Layout';
import StyledContainer from '../components/Container';
import Grid from '@material-ui/core/Grid';
import CrowdstrikeTable from '../features/CrowdstrikeTable';
function Crowdstrike() {
  return (
    <Layout>
      <StyledContainer>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <CrowdstrikeTable />
          </Grid>
        </Grid>
      </StyledContainer>
    </Layout>
  );
}

export default Crowdstrike;

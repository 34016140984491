import {fetchTreeMenuDataPending, fetchTreeMenuDataSuccess, fetchTreeMenuDataError} from './index';
import config from '../../oktaConfig';

function getTreeMenuData(accessToken) {
  return dispatch => {
    dispatch(fetchTreeMenuDataPending());
    fetch(config.resourceServer.insightsApiUrlTreeMenuData, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        dispatch(fetchTreeMenuDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchTreeMenuDataError(error));
      });
  };
}

export default getTreeMenuData;

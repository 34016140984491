import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import SGHeadline from '../../../components/Typography/SGHeadline';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StoreIcon from '@material-ui/icons/Store';
import Icon from '@mdi/react';
import {mdiWan, mdiCloudLock, mdiServerNetwork, mdiAccessPointNetwork} from '@mdi/js';

const StyledHeader = styled.div`
  display: flex;
  padding: 0.5rem;
  width: calc(100% - 1rem);
  height: 1.5rem;
  background-color: ${props => props.theme.grey};
  align-items: center;
  justify-content: space-between;
  &.collapsed {
    width: calc(90px - 2rem);
  }
`;

const StyledHeadline = styled(SGHeadline)`
  color: ${props => props.theme.white};
  margin-left: 8px;
  &.collapsed {
    display: none;
  }
`;

const StyledLeftContainer = styled.div`
  display: flex;
  color: ${props => props.theme.white};
`;

const StyledClearAll = styled.button`
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-right: 0.5rem;
  color: ${props => props.theme.text};
`;

const renderIconType = function renderIconType(iconType) {
  switch (iconType) {
    case 'store':
      return <StoreIcon />;
    case 'wanTransport':
      return <Icon path={mdiWan} title="Wan Transport" size={1} />;
    case 'sdWan':
      return <Icon path={mdiCloudLock} title="SDWAN" size={1} />;
    case 'switching':
      return <Icon path={mdiServerNetwork} title="Switching" size={1} />;
    case 'wireless':
      return <Icon path={mdiAccessPointNetwork} title="Wireless" size={1} />;
    default:
      return <StoreIcon />;
  }
};

function SectionHeader(props) {
  const {icon, title, sectionId, handleTableColumns, handleStores, visibleColumns} = props;
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClick = function toggleExpand() {
    setIsExpanded(!isExpanded);
    handleTableColumns(sectionId);
  };

  const handleClearAll = event => {
    event.preventDefault();
    handleStores([]);
  };

  useEffect(() => {
    if (visibleColumns.includes(sectionId)) {
      setIsExpanded(true);
    }
  }, [visibleColumns, sectionId]);

  return (
    <StyledHeader className={isExpanded ? '' : 'collapsed'} key={sectionId}>
      <StyledLeftContainer>
        {renderIconType(icon)}
        <StyledHeadline variant={'h5'} className={isExpanded ? '' : 'collapsed'}>
          {title}
        </StyledHeadline>
      </StyledLeftContainer>
      {icon === 'store' ? (
        <StyledClearAll onClick={handleClearAll}>Clear All</StyledClearAll>
      ) : (
        <IconButton onClick={handleClick}>
          {isExpanded ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      )}
    </StyledHeader>
  );
}

export default SectionHeader;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Snackbar, IconButton, SnackbarContent} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';
import TextSkeleton from 'components/Skeletons/TextSkeleton';

// check white space

//#region styled components
const StyledClose = styled(CloseIcon)`
  font-size: 1.25rem;
  opacity: 1;
`;
const StyledFlexDiv = styled.div`
  display: flex;
`;
const StyledCenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledFlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: ${props => props.theme.yellow};
  color: ${props => props.theme.black};
`;
const StyledSnackbar = styled(Snackbar)`
  margin-left: calc(13.125rem / 2);
`;
//#endregion styled components

interface NoServicesSnackbarProps {
  isOpen: boolean;
  setCanSnackbarOpen: (value: boolean) => void;
  selectedStoresWithoutServices: Stores;
}

function NoServicesSnackBar({
  isOpen,
  setCanSnackbarOpen,
  selectedStoresWithoutServices,
}: NoServicesSnackbarProps) {
  const [storesNoServicesString, setStoresNoServicesString] = useState<string>();

  useEffect(() => {
    if (selectedStoresWithoutServices.length > 0) {
      setStoresNoServicesString(
        selectedStoresWithoutServices.map(store => getStoreDisplayName(store)).join(', ')
      );
    }

    if (isOpen && selectedStoresWithoutServices.length === 0) {
      console.error(
        'Selected store(s) without services should not be empty if the snackbar is showing.'
      );
    }
  }, [selectedStoresWithoutServices, isOpen]);

  return (
    <StyledSnackbar
      open={isOpen}
      autoHideDuration={null}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          setCanSnackbarOpen(false);
        }
      }}
      anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
    >
      <StyledSnackbarContent
        message={
          <StyledFlexDiv>
            <StyledFlexColumnDiv>
              {`The following selected store(s) have missing or empty store configurations in the database and will not be updated:`}
              {selectedStoresWithoutServices.length > 0 ? (
                <StyledCenterDiv>{storesNoServicesString}</StyledCenterDiv>
              ) : (
                <TextSkeleton />
              )}
            </StyledFlexColumnDiv>
            <IconButton
              onClick={() => {
                setCanSnackbarOpen(false);
              }}
            >
              <StyledClose />
            </IconButton>
          </StyledFlexDiv>
        }
      ></StyledSnackbarContent>
    </StyledSnackbar>
  );
}

export default NoServicesSnackBar;

import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import Container from '../components/Container';
import Grid from '@material-ui/core/Grid';
import Title from '../components/Typography/Title';
import ThemeSelect from '../components/Settings/ThemeSelect';
import OktaKey from '../components/Settings/OktaKey';
import Popup from '../components/Popup';
import PopupPatchNotes from '../components/PatchNotes/PopupPatchNotes';
import BuildVersion from '../components/Settings/BuildVersion';
import PaperTitle from '../components/PaperTitle';
import Paper from '../components/Paper';
import UserLog from '../components/Settings/UserLog';
import TableToggle from '../components/Settings/TableToggle';
import DebugView from '../components/Settings/DebugView';
import {useSelector} from 'react-redux';

const StyledSpacer = styled(Grid)`
  &.spacer {
    display: none;
    @media (min-width: 1280px) {
      display: block;
    }
  }
`;

const StyledContainer = styled(Container)`
  max-width: calc(100vw - 210px);
`;

function Settings(props) {
  const {match} = props;
  const isAdmin = useSelector(state => state.userInfo.admin);

  return (
    <Layout match={match}>
      <StyledContainer>
        <Grid container spacing={5} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Title>SETTINGS</Title>
          </Grid>
          <StyledSpacer item lg={12} className={'spacer'} />
          <Grid item xs={12}>
            <ThemeSelect />
          </Grid>
          <Grid item xs={12}>
            <TableToggle />
          </Grid>
          <Grid item xs={12}>
            <OktaKey />
          </Grid>
          {isAdmin && (
            <Grid item xs={12}>
              <UserLog isSettings />
            </Grid>
          )}
          <Grid item xs={12}>
            <BuildVersion />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <PaperTitle>Popup settings</PaperTitle>
              <Popup settings />
              <PopupPatchNotes settings />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <DebugView />
          </Grid>
        </Grid>
      </StyledContainer>
    </Layout>
  );
}

export default Settings;

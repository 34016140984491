import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchSuggestions from './SearchSuggestions';
import {useHistory} from 'react-router-dom';
import {fetchMinimumStoreInfo} from '../../redux/actions';
import {useOktaAuth} from '@okta/okta-react';

const StyledSearch = styled.div`
  /* margin-right: 20px; */
  /* padding-top: 20px; */
  height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  z-index: 11;
  display: none;
`;

const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: ${props => props.theme.barBg};
    color: ${props => props.theme.text};
    border-radius: 10px;
  }
  &.search-active .MuiFilledInput-root {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 1px;
  }
  .MuiFilledInput-input {
    padding: 8px 10px 8px;
    font-size: 0.8rem;
    width: 220px;
  }
  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0px 0px 0px;
  width: 24px;
`;

const StyledSearchIcon = styled(SearchRoundedIcon)`
  color: ${props => props.theme.subtleText};
`;

const StyledClearRoundedIcon = styled(ClearRoundedIcon)`
  color: ${props => props.theme.subtleText};
`;

const StyledCircleLoading = styled(CircularProgress)`
  height: 24px;
  width: 24px;
  svg {
    color: ${props => props.theme.subtleText};
  }
`;

function Search() {
  const {authState, oktaAuth} = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      dispatch(fetchMinimumStoreInfo(accessToken));
    }
  }, [oktaAuth, authState, dispatch]);

  let history = useHistory();
  const storeApi = useSelector(state => state.data.app.searchMinimumStoreInfo.data);
  const storeApiReady = useSelector(state => state.data.app.searchMinimumStoreInfo.dataRetrieved);

  const [searchValue, setSearchValue] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);

  useEffect(() => {
    if (storeApiReady && storeApi.length > 0 && searchValue.length > 0) {
      let storeNumberMatch = storeApi.filter(store => {
        return store.StoreNumber === parseInt(searchValue);
      });

      let otherMatchingStores = storeApi.filter(store => {
        return (
          store.StoreNumber.toString().match(searchValue) ||
          (store.StoreName !== null
            ? store.StoreName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            : false) ||
          store.StoreNumber.toString().indexOf(searchValue.toString()) > -1
        );
      });

      if (storeNumberMatch.length > 0) {
        let index = otherMatchingStores.indexOf(storeNumberMatch[0]);
        if (index !== -1) {
          otherMatchingStores.splice(index, 1);
        }
      }

      setFilteredSearch([...storeNumberMatch, ...otherMatchingStores]);
    } else {
      clearSearch();
    }
  }, [storeApiReady, storeApi, searchValue]);

  const handleChange = event => {
    setSearchValue(event.target.value);
  };

  const enterPressed = event => {
    let code = event.keyCode || event.which;
    const keyCode = 13; //13 is the enter keycode
    if (code === keyCode) {
      if (filteredSearch.length > 0) {
        history.push(`/store/${filteredSearch[0].StoreNumber}/overview`);
        clearSearch();
        clearUserInput();
      }
    }
  };

  const clearSearch = () => {
    setFilteredSearch([]);
  };

  const clearUserInput = () => {
    setSearchValue('');
  };

  return (
    <StyledSearch>
      <StyledTextField
        id="search-field"
        variant="filled"
        className={filteredSearch.length > 0 ? 'search-active' : ''}
        onChange={handleChange}
        placeholder={'Store Name or #'}
        autoComplete={'off'}
        value={searchValue}
        onKeyPress={enterPressed}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {storeApiReady ? <StyledSearchIcon /> : <StyledCircleLoading size={24} />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <StyledIconButton aria-label="clear search field" onClick={clearUserInput}>
                {searchValue.length > 0 ? <StyledClearRoundedIcon /> : ''}
              </StyledIconButton>
            </InputAdornment>
          ),
        }}
      />
      <SearchSuggestions stores={filteredSearch} clearSearch={clearSearch} />
    </StyledSearch>
  );
}

export default Search;

import React from 'react';
import styled from 'styled-components';
import TreeItem from '@material-ui/lab/TreeItem';
import {NavLink} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {ReactComponent as InfoIcon} from '../../../assets/icons/info.svg';
import PublicIcon from '@material-ui/icons/Public';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import TradingNumber from './TradingNumber';
import Icon from '@mdi/react';
import {mdiServerNetwork, mdiFileExportOutline} from '@mdi/js';

const StyledNavTreeItem = styled(TreeItem)`
  .MuiTypography-body1 {
    // Consider updating this to use a text component in the future.
    font-family: 'Trade Gothic';
  }
  .MuiTreeItem-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .MuiTreeItem-label {
    padding-left: 0px;
  }
  .subTree {
    padding-left: 8px;
  }
`;

const StyledLinkRouter = styled(NavLink)`
  margin: 0px 0px 0px 0px;
  text-decoration: none;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};

  &:visited {
    color: ${props => props.theme.navText};
    fill: ${props => props.theme.navText};
  }

  &:active,
  &:hover,
  &:focus,
  &.active {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
  }

  p {
    font-family: 'Trade Gothic';
    text-decoration: none;
    font-size: 1rem;
    margin: 3px auto 3px 15px;
  }
`;

const StyledButton = styled(Button)`
  height: 4rem;
  margin-left: 6px;
  border-radius: 10px;
  text-decoration: none;
  text-transform: capitalize;
  padding-left: 16px;
  justify-content: flex-start;
  font-family: 'Trade Gothic';
  text-decoration: none;
  font-size: 1rem;
  line-height: 16px;

  .MuiButton-label {
    text-align: left;
  }

  @media (min-width: 600px) {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    text-decoration: none;
    text-transform: capitalize;
    padding-left: 16px;
  }
`;

const StyledIconSpacer = styled.div`
  width: 8px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 3px;
`;

function NavTreeItem(props) {
  const {id, nodeId, label, url, icon, children} = props;

  const renderIcon = function renderIcon(icon, nodeId) {
    if (icon === 'map') {
      return <PublicIcon />;
    } else if (icon === 'dashboard') {
      return <DashboardRoundedIcon />;
    } else if (icon === 'contact') {
      return <EmailRoundedIcon />;
    } else if (icon === 'about') {
      return <StyledInfoIcon />;
    } else if (icon === 'settings') {
      return <SettingsIcon />;
    } else if (icon === 'code') {
      return <CodeRoundedIcon />;
    } else if (icon === 'events') {
      return <DnsRoundedIcon />;
    } else if (icon === 'switch') {
      return <Icon path={mdiServerNetwork} size={1} />;
    } else if (icon === 'inventory') {
      return <Icon path={mdiFileExportOutline} size={1} />;
    } else {
      return <StyledIconSpacer />;
    }
  };

  const renderTradingStatus = function renderTradingStatus(icon, nodeId) {
    if (icon === 'number') {
      return <TradingNumber geo={nodeId} />;
    } else {
      return '';
    }
  };

  const renderLabel = function renderLabel(label, icon, nodeId) {
    if (icon === 'number') {
      return (
        <StyledLinkRouter to={url} exact className={'subTree'}>
          <StyledButton color="inherit" aria-label={nodeId}>
            <p>{label}</p>
            {renderTradingStatus(icon, nodeId)}
          </StyledButton>
        </StyledLinkRouter>
      );
    } else if (icon !== null) {
      return (
        <StyledLinkRouter to={url} exact>
          <StyledButton color="inherit" aria-label={nodeId}>
            {renderIcon(icon)}
            <p>{label}</p>
          </StyledButton>
        </StyledLinkRouter>
      );
    } else {
      return (
        <StyledLinkRouter to={url} className={'subTree'}>
          <StyledButton color="inherit" aria-label={nodeId}>
            <p>{label}</p>
          </StyledButton>
        </StyledLinkRouter>
      );
    }
  };

  return (
    <StyledNavTreeItem key={id} nodeId={nodeId} label={renderLabel(label, icon, nodeId)}>
      {children}
    </StyledNavTreeItem>
  );
}

export default NavTreeItem;

NavTreeItem.defaultProps = {
  id: '#',
  nodeId: '#',
  label: 'no label',
  url: '#',
  icon: null,
};

import React from 'react';
import styled from 'styled-components';
import TableCell from './TableCell';
import MUITableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledTableHead = styled(MUITableHead)``;

const StyledTableRow = styled(TableRow)``;

const StyledHeaderSpan = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.tableText};
  text-transform: uppercase;
  &.disable {
    color: ${props => props.theme.tableText};
  }
`;

const StyledTableCell = styled(TableCell)`
  background-color: transparent;
  border-bottom: 1px solid transparent;
  &.MuiTableCell-head {
    padding-bottom: 4px;
  }
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    color: ${props => props.theme.tableText};
  }
  &&&.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: ${props => props.theme.subtleText};
  }
`;

function TableHead(props) {
  const {columns, order, orderBy, onRequestSort} = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <StyledTableRow>
        {columns.map(column => (
          <StyledTableCell
            key={column.id}
            align={column.align}
            style={{width: column.width}}
            sortDirection={orderBy === column.id ? order : false}
            className={`header-cell ${column.customClass} `}
          >
            {column.disableSort ? (
              <StyledHeaderSpan>{column.label}</StyledHeaderSpan>
            ) : (
              <StyledTableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
                IconComponent={ArrowDropDownIcon}
              >
                <StyledHeaderSpan>{column.label}</StyledHeaderSpan>
              </StyledTableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
}

export default TableHead;

import destructureIdString from './destructureIdString';

const hasActiveChildren = function hasActiveChildren(selectedNodeId, selectedData) {
  if (selectedNodeId === undefined) return [];

  const {eventType, deviceId, catId} = destructureIdString(selectedNodeId);
  let isActive = [];
  if (selectedData.length === 0) {
    return isActive;
  } else if (eventType === 'device') {
    // this is a device
  } else if (eventType === 'category') {
    // this is a category
    isActive.push(deviceId);
  } else if (eventType === 'subCategory') {
    // this is a subCategory
    isActive.push(deviceId);
    isActive.push(deviceId + '-' + catId);
  } else {
    // something went wrong
    console.log('componentActiveEventReducer.js', 'Invalid Selected Node: ' + selectedNodeId);
  }
  return isActive;
};

export default hasActiveChildren;

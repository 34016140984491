import React from 'react';
import styled from 'styled-components';

import Layout from './Layout';
import StyledContainer from '../components/Container';
import Grid from '@material-ui/core/Grid';
import InfoCard from '../components/InfoCard';
import MailImg from '../assets/icons/mail-icon-md.png';
import JiraImg from '../assets/icons/jira-icon-md.png';
import ConfluenceImg from '../assets/icons/confluence-icon-md.png';
import Title from '../components/Typography/Title';

const StyledContact = styled.div`
  h1.title {
    color: ${props => props.theme.focusText};
    font-size: 4em;
    font-family: 'Futura C XBd';
    margin: 0;
  }
  img {
    max-width: 100%;
  }
  .extra-spacing {
    margin-top: 48px;
  }
`;

const StyledSlackLink = styled.a`
  color: ${props => props.theme.altText};
  text-decoration: none;

  &:active,
  &:hover,
  &:focus,
  &.active {
    color: ${props => props.theme.text};
    text-decoration: underline;
  }
`;

const StyledText = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: ${props => props.theme.text};
`;

const StyledTitle = styled(Title)`
  text-align: center;
`;

const StyledHeader = styled(StyledText)`
  font-size: 1.4rem;
`;

function Contact(props) {
  const {match} = props;

  return (
    <Layout match={match}>
      <StyledContact id={'qc-contact-container'}>
        <StyledContainer>
          <Grid container spacing={3} direction="row" alignItems="stretch">
            <Grid item xs={12} id={'qc-contact-header'}>
              <StyledTitle>CONNECT WITH US</StyledTitle>
              <StyledHeader>Get in touch and let us know how we can help.</StyledHeader>
            </Grid>
            <Grid item xs={12} md={4} className={'extra-spacing'}>
              <InfoCard
                id={'qc-contact-team'}
                icon={MailImg}
                linkText={'Contact the team'}
                link={
                  'mailto:Lst-GT.TechOps.RSS.Insights@nike.com?subject=Retail%20Insight%20Support'
                }
              >
                Need further information? Send us an email and someone from the team will connect
                with you.
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={4} className={'extra-spacing'}>
              <InfoCard
                id={'qc-contact-progress'}
                icon={JiraImg}
                linkText={'See our progress'}
                link={
                  'https://jira.nike.com/secure/RapidBoard.jspa?rapidView=10407&projectKey=GRSTI'
                }
              >
                Come take a look at what we are currently building and what we have on the road-map
                for the future of Retail Insights.
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={4} className={'extra-spacing'}>
              <InfoCard
                id={'qc-contact-learn'}
                icon={ConfluenceImg}
                linkText={'Learn More'}
                link={'https://confluence.nike.com/display/GROS/Retail+Support+Solutions'}
              >
                Where we, Retail Support Solutions, fit within the Global Retail Technology
                Operations & Support.
              </InfoCard>
            </Grid>

            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <StyledText>
                <strong>Join Us in Slack</strong>
              </StyledText>
              <StyledText>
                If you have technical questions, chat live with developers in&nbsp;
                <StyledSlackLink
                  href="https://nikedigital.slack.com/archives/G75ESC0RJ"
                  target="_blank"
                >
                  #retailsupport-tools
                </StyledSlackLink>
                &nbsp;on Slack.
              </StyledText>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </StyledContainer>
      </StyledContact>
    </Layout>
  );
}

export default Contact;

import destructureIdString from './destructureIdString';

const tierListSelection = function tierListSelection(selectedNodeId, planarTree) {
  const {eventType, deviceId, catId} = destructureIdString(selectedNodeId);
  let relatedNodeIds = [];

  if (eventType === 'device') {
    // this is a device
    relatedNodeIds = planarTree.filter(node => node.eventType === eventType).map(node => node.id);
  } else if (eventType === 'category') {
    // this is a category
    relatedNodeIds = planarTree
      .filter(node => node.deviceId === deviceId && node.eventType === eventType)
      .map(node => node.id);
  } else if (eventType === 'subCategory') {
    // this is a subCategory
    relatedNodeIds = planarTree
      .filter(
        node => node.deviceId === deviceId && node.catId === catId && node.eventType === eventType
      )
      .map(node => node.id);
  } else {
    // something went wrong
    console.log('componentActiveEventReducer.js', 'Invalid Selected Node: ' + selectedNodeId);
  }

  return relatedNodeIds;
};

export default tierListSelection;

import React from 'react';
import styled from 'styled-components';
import FilterDropDown from './FilterDropDown';
import FilterOptions, {FilterValues} from '../../interfaces/FilterOptions';
import ClearFilters from './ClearFilters';
import Tabs from '../../interfaces/Tabs';
import NVSNSO from 'interfaces/NVSNSO';

const StyledDropDownContainer = styled.div`
  display: flex;
  gap: 16px;
  &.hide-geo-filter .filter-geo {
    display: none;
  }
`;

type FilterOptionKeys = keyof FilterOptions;
type FilterValueKeys = keyof FilterValues;

const renderFilters = (
  selectedFilters: string[],
  filterOptions: FilterOptions,
  handleFilterChange: (value: string[]) => void
) => {
  const allCategoryKeys = Object.keys(filterOptions) as FilterOptionKeys[];

  const filteredCategoryKeys = allCategoryKeys.filter(key => {
    if (filterOptions[key].excludedValues) {
      let excludedValueMatch = false;
      filterOptions[key].excludedValues?.forEach(value => {
        if (selectedFilters.includes(value)) {
          excludedValueMatch = true;
        }
      });
      return !excludedValueMatch;
    } else {
      return true;
    }
  });

  const dropDowns = filteredCategoryKeys.map(categoryKey => {
    const category = filterOptions[categoryKey];
    const allValueKeys = Object.keys(category.values) as FilterValueKeys[];

    const selectedValues = Object.keys(category.values).filter(key => {
      return selectedFilters.includes(key);
    });

    const filteredValueKeys = allValueKeys.filter(key => {
      if (category.values[key].excludedValues) {
        let excludedValueMatch = false;

        category.values[key].excludedValues?.forEach(value => {
          if (selectedFilters.includes(value)) {
            excludedValueMatch = true;
          }
        });

        return !excludedValueMatch;
      } else {
        return true;
      }
    });

    return (
      <FilterDropDown
        key={categoryKey}
        label={category.label}
        selectedValues={selectedValues}
        options={category.values}
        handleFilterChange={handleFilterChange}
        filteredValueKeys={filteredValueKeys}
        className={`filter-${categoryKey}`}
      />
    );
  });

  return dropDowns;
};

interface FiltersProps {
  selectedFilters: string[];
  filterOptions: FilterOptions;
  selectedTab: Tabs;
  handleFilterChange: (value: string[]) => void;
  handleClearFilters: () => void;
}

function Filters({
  selectedFilters,
  filterOptions,
  selectedTab,
  handleFilterChange,
  handleClearFilters,
}: FiltersProps) {
  const isGeoTabSelected = selectedTab !== Tabs.CUSTOM ? 'hide-geo-filter' : '';

  return (
    <StyledDropDownContainer className={`${isGeoTabSelected}`}>
      {renderFilters(selectedFilters, filterOptions, handleFilterChange)}
      <ClearFilters handleClearFilters={handleClearFilters} />
    </StyledDropDownContainer>
  );
}

export default Filters;

import React from 'react';
import styled from 'styled-components';
import SwitchPortGroup from './SwitchPortGroup';
import {Port} from '../interfaces/SwitchConnectivityState';
import {PortHistorySwitch} from '../interfaces/PortHistoryState';

const StyledPortRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 24px;
`;
interface SwitchPortRowProps {
  rowNumber: number;
  ports: Port[];
  portHistorySwitch: PortHistorySwitch | null;
}
export default function SwitchPortRow({rowNumber, ports, portHistorySwitch}: SwitchPortRowProps) {
  const filteredSwitchPorts = ports.filter(port => {
    const isEven = port.portNumber % 2 === 0;
    if (rowNumber === 1) {
      return !isEven;
    } else {
      return isEven;
    }
  });

  const groupSize = 6;
  const switchPortGroupData: Port[][] = [];
  for (let i = 0; i < filteredSwitchPorts.length; i += groupSize) {
    const group: Port[] = filteredSwitchPorts.slice(i, i + groupSize);
    switchPortGroupData.push(group);
  }

  const switchPortGroups = switchPortGroupData.map((switchPortGroup: Port[], index) => {
    return (
      <SwitchPortGroup key={index} ports={switchPortGroup} portHistorySwitch={portHistorySwitch} />
    );
  });

  return <StyledPortRowContainer>{switchPortGroups}</StyledPortRowContainer>;
}

import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const StyeledButton = styled(Button)`
  color: ${props => props.theme.grey};
  padding: 0px;
  font-family: 'Text Regular';
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  transition: 100ms ease-in-out;
  transition-property: color;
  white-space: nowrap;
  &:hover {
    color: ${props => props.theme.text};
  }
`;

interface ClearFiltersProps {
  handleClearFilters: () => void;
}

function ClearFilters({handleClearFilters}: ClearFiltersProps) {
  const handleClick = () => {
    handleClearFilters();
  };

  return <StyeledButton onClick={handleClick}>Clear Filters</StyeledButton>;
}

export default ClearFilters;

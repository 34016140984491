import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledLoader = styled.div`
  width: '100%';
`;

const StyledLinearProgress = styled(LinearProgress)`
  .MuiLinearProgress-root {
    width: 100%;
  }
  .MuiLinearProgress-bar1Indeterminate {
    /* background-color: ${props => props.theme.primary}; */
  }
  .MuiLinearProgress-bar2Indeterminate {
    background-color: ${props => props.theme.subtleText};
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${props => props.theme.cardBg};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => props.theme.subtleText};
  }
`;

function LinearIndeterminate() {
  return (
    <StyledLoader>
      <StyledLinearProgress />
    </StyledLoader>
  );
}

export default LinearIndeterminate;

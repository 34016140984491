import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

// component built with TypeScript and the ABnB Style guide

interface SGTitleProps {
  children: React.ReactNode;
  variant?: 't1' | 't2' | 't3' | 't4' | 't5' | 't6';
  className?: string;
  rest?: any;
}

const StyledTitle = styled(Typography)`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.subtleText};
  font-size: 1rem;

  &.t1 {
    font-size: 2.25rem;
    text-transform: uppercase;
  }
  &.t2 {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  &.t3 {
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  &.t4 {
    font-size: 1.125rem;
    text-transform: uppercase;
    line-height: 1.25rem;
  }
  &.t5 {
    font-size: 1.125rem;
    line-height: 1rem;
  }
  &.t6 {
    font-size: 0.875rem;
    line-height: 0.75rem;
  }
  &.underline {
    text-decoration: underline;
  }

  &.text-color {
    color: ${props => props.theme.text};
  }
  &.active-color {
    color: ${props => props.theme.activeStateBlue};
  }
`;

function SGTitle({children, variant = 't3', className, ...rest}: SGTitleProps) {
  return (
    <StyledTitle className={`${variant} ${className}`} {...rest}>
      {children}
    </StyledTitle>
  );
}

export default SGTitle;

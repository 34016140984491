import {ServiceDictionary} from './interfaces/Services';
import StoreServiceConfigurationPayload from './interfaces/StoreServiceConfigurationPayload';
import {Store, Stores} from 'interfaces/Stores';
import {StoreConfigStatus} from './types/StoreConfigStatus';

const getPayloadsFromStores = (
  selectedStores: Stores,
  storeConfigStatus: StoreConfigStatus,
  servicesByStore: ServiceDictionary,
  selectedStoresWithoutServices: Stores
) => {
  let payloads: StoreServiceConfigurationPayload[] = [];
  if (storeConfigStatus !== 'mixed') {
    selectedStores.forEach(store => {
      payloads.push(createStoreServiceConfigurationPayload(store, servicesByStore));
    });
  } else {
    selectedStores.forEach(store => {
      if (!selectedStoresWithoutServices.includes(store)) {
        payloads.push(createStoreServiceConfigurationPayload(store, servicesByStore));
      }
    });
  }
  return payloads;
};

const createStoreServiceConfigurationPayload: (
  store: Store,
  servicesByStore: ServiceDictionary
) => StoreServiceConfigurationPayload = (store: Store, servicesByStore: ServiceDictionary) => {
  const payload: StoreServiceConfigurationPayload = {
    $type: 'siteConfigurationPayloadItem',
    PayloadType: 'SiteConfiguration',
    PayloadSource: 'SiteServiceManager',
    Date: new Date().toISOString(),
    SiteId: store.SiteId,
    SiteConfiguration: {
      $type: 'serviceSiteConfigurationItem',
      SiteId: store.SiteId,
      Brand: 'Nike',
      Geo: store.Geo,
      SiteConfigurationType: 'Service',
      Type: 'SiteConfiguration',
      Services: servicesByStore[store.SiteId].map(service => service.id),
    },
  };

  return payload;
};

export default getPayloadsFromStores;

export default function extractNumberFromString(input: string): { number: number | null } {
  const rgx = /[0-9]*\.?[0-9]*/;
  const numAsString = input.match(rgx);

  if (numAsString === null) {
    return { number: null }
  }

  const number = parseFloat(numAsString.join(''));

  return {
    number,
  };
}

const deviceTypes = [
  'Default',
  'Null',
  'Router',
  'Switch',
  'VeloCloud',
  'Firewall',
  'Register',
  'PC',
  'Server',
  'Athlete',
  'Traffic',
  'TimeClock',
  'RetailMac',
] as const;
type DeviceType = typeof deviceTypes[number];
export function isDeviceType(x: string): x is DeviceType {
  return deviceTypes.includes(x as DeviceType);
}
export default DeviceType;

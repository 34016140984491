import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import {useSelector, useDispatch} from 'react-redux';
import {historicalComparisonSetLineChartData} from '../../../redux/actions';
// import {testMockLine} from './testMockLine';
import testMockLine from './generateLineData';
import SGSubtitle from '../../Typography/SGSubtitle';
import DateTimeSelector from './DateTimeSelector';
import ProgressLinear from '../../Skeletons/Loading/ProgressLinear';

const StyledApexLineChart = styled.div`
  .apexcharts-tooltip {
    background: ${props => props.theme.cardBg};
    border: none;
    box-shadow: 0px 0px 12px 0px ${props => props.theme.text}55;
    .apexcharts-tooltip-title {
      background: ${props => props.theme.cardBg};
      border-bottom: none;
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: none;
    }
    /* .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title */
  }

  .apexcharts-yaxis-title-text {
    letter-spacing: 1px;
    font-weight: 700;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function ApexLineChart(props) {
  const {debugLoaded, children, loaded, className} = props;
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);
  const themeSelected = theme[theme.select];
  const themeSgColors = theme[theme.select].sgColors;
  const selectedData = useSelector(state => state.componentHistoricalComparison.selectedData);
  const planarTree = useSelector(state => state.componentHistoricalComparison.planarTree);
  const {startDate, endDate} = useSelector(
    state => state.componentHistoricalComparison.epochDateRange
  );

  const [themeColors, setThemeColors] = useState(themeSgColors);
  const [chartOptions, setChartOptions] = useState({
    colors: themeColors,
    chart: {
      id: 'line-datetime',
      height: 200,
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 1,
        color: themeSelected.text,
        opacity: 0.55,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 350,
        },
      },
    },
    noData: {
      text: 'Loading...',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      height: 60,
      showForSingleSeries: true,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: themeSelected.text,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 16,
      },
    },
    markers: {
      size: 0,
      colors: themeSelected.cardBg,
      strokeColors: themeColors,
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 5,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    title: {
      align: 'left',
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0,
      },
    },
    xaxis: {
      type: 'datetime',
      min: startDate,
      max: endDate,
      labels: {
        style: {
          colors: themeSelected.grey,
          fontSize: '12px',
          fontFamily: 'Text Regular, Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM',
          day: 'MMM dd',
          hour: 'HH:mm',
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        show: true,
        style: {
          colors: themeSelected.grey,
          fontSize: '12px',
          fontFamily: 'Text Regular, Helvetica, Arial, sans-serif',
          fontWeight: 700,
          cssClass: 'apexcharts-yaxis-label',
          formatter: function (val) {
            return parseInt(val) === val ? val : '';
          },
        },
        formatter: value => {
          return Math.floor(value);
        },
      },
      title: {
        text: 'Number of Events',
        style: {
          color: themeSelected.grey,
          fontSize: '12px',
          fontFamily: 'Text Regular, Helvetica, Arial, sans-serif',
          fontWeight: 700,
          cssClass: 'label-title',
        },
      },
    },
    tooltip: {
      fillSeriesColor: false,
      shared: true,
      followCursor: true,
      colors: themeColors,
      x: {
        format: 'MMM dd yyyy',
      },
    },
    annotations: {
      position: 'front',
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      width: 2,
      dashArray: 0,
    },
  });

  // Selected Date Range
  const [select, setSelect] = useState('three_months');
  const [chartData, setChartData] = useState([
    {
      name: '',
      data: [0, 0],
    },
  ]);

  useEffect(() => {
    if (loaded) {
      const filteredData = testMockLine.filter(item =>
        //matches name to selectedData id, ignoring empty space.
        selectedData.includes(item.name.replace(/\s/g, ''))
      );

      const colorIds = selectedData.map(
        selectedId => planarTree.find(tree => tree.id === selectedId).colorId
      );

      const sortedColorIdsnum = colorIds.sort(function (a, b) {
        return a - b;
      });

      const colorMatch = sortedColorIdsnum.map(colorId => themeSgColors[colorId]);

      setThemeColors(colorMatch);
      setChartData(filteredData);
      dispatch(historicalComparisonSetLineChartData(filteredData));
      setChartOptions({
        ...chartOptions,
        colors: colorMatch,
        xaxis: {
          ...chartOptions.xaxis,
          min: startDate,
          max: endDate,
        },
      });

      updateData('three_month');
    }
  }, [loaded, selectedData, planarTree, startDate, endDate]);

  function updateData(timeline) {
    const date = new Date();
    setSelect(timeline);

    switch (timeline) {
      case 'today':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_week':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'two_weeks':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_month':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'three_months':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'six_months':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_year':
        ApexCharts.exec(
          'line-datetime',
          'zoomX',
          new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000).getTime(),
          new Date().getTime()
        );
        break;
      default:
    }
  }

  return (
    <StyledApexLineChart id="historical-comparison">
      <StyledButtonContainer>
        <DateTimeSelector />
      </StyledButtonContainer>

      <SGSubtitle>Overview - All Devices</SGSubtitle>
      {debugLoaded ? (
        <Chart
          options={chartOptions}
          series={chartData}
          height={250}
          type="line"
          className={className}
        />
      ) : (
        <ProgressLinear />
      )}
      {children}
    </StyledApexLineChart>
  );
}

export default ApexLineChart;

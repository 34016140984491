import React from 'react';
import styled from 'styled-components';
import SGSubtitle from 'components/Typography/SGSubtitle';

interface ClearAllProps {
  handleClick: () => void;
  subtitle?: string;
}

const StyledClearAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.grey};
  padding: 8px 16px;
`;

const StyledClearAllButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.text};
  text-decoration: underline;
  font-size: 0.75rem;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.grey};
  }
`;

function ClearAll({handleClick, subtitle = 'Selected Stores'}: ClearAllProps) {
  return (
    <StyledClearAllContainer>
      <SGSubtitle variant={'s1'}>{subtitle}</SGSubtitle>
      <StyledClearAllButton onClick={handleClick}>Clear All</StyledClearAllButton>
    </StyledClearAllContainer>
  );
}

export default ClearAll;

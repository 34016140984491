import {
  getServicesByStoreSuccess,
  getServicesByStoreError,
  getServicesByStorePending,
} from './index';
import config from '../../oktaConfig';

function getServicesByStore(accessToken, siteIds) {
  return dispatch => {
    dispatch(getServicesByStorePending());
    const controller = new AbortController();
    fetch(config.resourceServer.insightsApiUrlServicesByStore + '?siteIds=' + siteIds.join(','), {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getServicesByStoreSuccess(data));
      })
      .catch(err => {
        dispatch(getServicesByStoreError(err));
        /* eslint-disable no-console */
        console.error(err);
      });
    return () => {
      controller.abort();
    };
  };
}

export default getServicesByStore;

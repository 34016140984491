import Site from 'interfaces/Site';
import {MinimumSiteInfoApi} from './minimumSiteInfoReducer';
import {getSiteTypeFromSiteId} from 'utils/utils';

const getSiteCode = (site: MinimumSiteInfoApi): string => {
  const siteNumberFromId: string = site.SiteId.substring(3, 7);
  const convertToNumber = Number(siteNumberFromId);

  if (!isNaN(convertToNumber)) {
    return convertToNumber.toString();
  } else {
    return siteNumberFromId;
  }
};

const getSiteDisplayName = (site: MinimumSiteInfoApi): string => {
  const siteNumber = getSiteCode(site);

  return `${siteNumber} ${site.SiteName}`;
};

function siteInfoFormat(apiSites: MinimumSiteInfoApi[]): Site[] {
  const sites: Site[] = apiSites.map((apiSite, index) => {
    const {Brand, SiteName, SiteId, Geo, Address} = apiSite;
    const siteCode = getSiteCode(apiSite).toString();
    const displayName = getSiteDisplayName(apiSite);
    const siteType = getSiteTypeFromSiteId(SiteId);
    const address = Object.values(Address)
      .filter(value => value.length > 0)
      .join('|');
    const combinedSearchData = `${SiteId}|${SiteName}|${Brand}|${Geo}|${siteCode}|${displayName}|${siteType}|${siteCode}|${address}`;
    const siteSearch = combinedSearchData.toLowerCase();
    const id = `${index.toString()}${SiteId}`;

    return {
      siteId: SiteId,
      siteName: SiteName,
      brand: Brand,
      geo: Geo,
      siteCode,
      displayName,
      siteType,
      siteSearch,
      id,
    };
  });

  return sites;
}

export default siteInfoFormat;

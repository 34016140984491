import React from 'react';
import SGBody from 'components/Typography/SGBody';
import Icons from 'components/Tables/Icons/Icons';
import StoreName from './components/StoreName';

const presenceValue = function presenceValue(value) {
  if ('Not Installed' === value) {
    return 'Not Installed';
  } else {
    return 'Installed';
  }
};

const CrowdstrikeColumns = [
  {
    id: 'SiteId',
    label: 'Store',
    format: value => <StoreName siteId={value} />,
  },
  {
    id: 'DeviceName',
    label: 'Device Name',
    format: (value, row) => (
      <Icons toolTipText={row.DeviceType} iconType={row.DeviceType} text={value} />
    ),
  },
  {
    id: 'CurrentState',
    label: 'Presence',
    align: 'left',
    format: value => <SGBody variant={'b4'}>{presenceValue(value.State)}</SGBody>,
  },
  {
    id: 'CurrentState',
    label: 'Status',
    format: value => <SGBody variant={'b4'}>{value.Description}</SGBody>,
  },
  // {
  //   id: 'CurrentState',
  //   label: 'LastSeenDateTime',
  //   format: (value) => <SGBody variant={'b4'}>{value.LastSeenDateTime}</SGBody>,
  // },
];

export default CrowdstrikeColumns;

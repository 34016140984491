import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Table from '../../Tables/TableStructure/Table';
import SGTitle from '../../Typography/SGTitle';
import TableImg from '../../../assets/icons/table-load.png';

const StyledHeaderBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 2.375rem;
  p {
    display: block;
    margin-right: 1rem;
  }
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: space-around;
  }
`;

const StyledTable = styled(Table)`
  display: block;
  height: 19.5rem;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.text};
  text-decoration-color: ${props => props.theme.text};
  font-style: italic;
`;

const ImageContainer = styled.div`
  display: flex;
  height: 450px;
  justify-content: center;
  align-items: center;
`;

const columns = [
  {
    id: 'category',
    label: 'Category',
    width: '18%',
    icon: 'none',
  },
  {
    id: 'subCategory',
    label: 'Subcategory',
    width: '18%',
    icon: 'none',
  },
  {
    id: 'subject',
    label: 'Subject',
    width: '18%',
    icon: 'none',
  },
  {
    id: 'store',
    label: 'Store',
    width: '23%',
    icon: 'none',
  },
  {
    id: 'date',
    label: 'Date',
    width: '11%',
    icon: 'none',
  },
  {
    id: 'options',
    label: '',
    width: '12%',
    icon: 'none',
    format: type => <StyledLink href={'#' + type}>View More</StyledLink>,
  },
];

function EventDetailsTable({debugLoaded, loaded, events}) {
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (debugLoaded === false) {
      setDataReady(false);
    } else if (loaded === true && debugLoaded === true) {
      setDataReady(true);
    } else {
      setDataReady(false);
    }
  }, [debugLoaded, loaded]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <StyledHeaderBlock>
          <SGTitle className={'text-color'}>Event Details - Register</SGTitle>
        </StyledHeaderBlock>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {dataReady ? (
          <StyledHeaderBlock className={'right'}>
            <SGTitle variant={'t5'} className={'text-color'}>
              {events.length} Total Events
            </SGTitle>
            <SGTitle variant={'t5'} className={'text-color'}>
              {events.length} Total Stores Affected
            </SGTitle>
            <SGTitle variant={'t4'} className={'text-color'}>
              Last 30 Days
            </SGTitle>
          </StyledHeaderBlock>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12}>
        {dataReady ? (
          <StyledTable columns={columns} data={events} paginationType={'Total Stores'} />
        ) : (
          <ImageContainer>
            <img src={TableImg} alt={'Loading Table'} />
          </ImageContainer>
        )}
      </Grid>
    </Grid>
  );
}

export default EventDetailsTable;

import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiMinusBoxOutline} from '@mdi/js';
import RRPModal from '../../RRPModal';
import RRPAlertModal from './RRPAlertModal';

const StyledStoreCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid ${props => props.theme.tableRow}; */
`;

const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.text};
  flex-shrink: 0;
`;

const StyledButton = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    opacity: 0.7;
  }
`;

const StyledAlert = styled(RRPAlertModal)`
  margin-top: 5px;
`;

const StyledStoreName = styled.p`
  font-family: 'Trade Gothic Bold Condensed';
  text-transform: uppercase;
  font-size: 1rem;
  color: ${props => props.theme.text};
  margin-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

function StoreNameCell(props) {
  const {storeName, store, removeStore, isGeoView} = props;

  const handleClick = event => {
    event.preventDefault();
    removeStore(store.storeNumber);
  };

  return (
    <StyledStoreCell>
      <StyledNameContainer>
        {!isGeoView && (
          <StyledButton onClick={handleClick} value={store.storeNumber}>
            <StyledIcon path={mdiMinusBoxOutline} size={0.75} />
          </StyledButton>
        )}
        <RRPModal store={store} />
        <StyledAlert alert={store.alertValue} />
        <StyledStoreName>{storeName}</StyledStoreName>
      </StyledNameContainer>
    </StyledStoreCell>
  );
}

export default StoreNameCell;

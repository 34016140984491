import React, {useMemo} from 'react';
import styled from 'styled-components';
import Paper from 'components/Paper';
import FilterSelection from './components/FilterSelection';
import FilterChip from './components/FilterChip';
import filterOptions from './constants/FilterOptions';
import InventoryTable from './components/InventoryTable';
import Tabs from './interfaces/Tabs';
import DownloadCSV from './components/DownloadCSV';
import {InventoryState} from './reducer/InventoryReducer';

const StyledFilterContainer = styled.div`
  display: flex;
  gap: 48px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

interface InvetoriesProps {
  filters: string[];
  selectedTab: Tabs;
  invState: InventoryState;
  handleFilterChange: (filter: string[]) => void;
  handleClearFilters: () => void;
}

function Inventories({
  filters,
  selectedTab,
  invState,
  handleFilterChange,
  handleClearFilters,
}: InvetoriesProps) {
  const selectedFilters = useMemo(() => (filters.length > 0 ? filters.map(value => value) : []), [
    filters,
  ]);

  return (
    <>
      <FilterChip
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        handleFilterChange={handleFilterChange}
        selectedTab={selectedTab}
      />
      <Paper>
        <StyledFilterContainer>
          <FilterSelection
            selectedFilters={selectedFilters}
            filterOptions={filterOptions}
            selectedTab={selectedTab}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
          />
          <DownloadCSV invState={invState} />
        </StyledFilterContainer>

        <InventoryTable invState={invState} />
      </Paper>
    </>
  );
}

export default Inventories;

const patches = [
  {
    version: '1.4.1 - June 6th, 2024',
    link: 'https://confluence.nike.com/x/TteDPQ',
    overview: 'Switch Port History',
    changes: [
      {
        title: `Switch Port History - We are pleased to release the early version of the port history feature. It's in its initial stages, but ready to provide value. Your feedback is crucial to help us improve it for a more stable release. Please share your thoughts to make this feature better for you on our slack channel #retailsupport-tools.`,
        list: [
          'History of devices that have previously been connected to a port has been added.',
          'Options have been added under the Switch Filter search bar. You can pick and choose between Current, Historical, or both when filtering for a specific device.',
        ],
      },
      {
        title: `Inventories - View and export devices in our database to csv.`,
        list: [
          'Select Custom tab if you want to view/export multiple geos at the same time, or you can select a Geo tab to get started.',
        ],
      },
    ],
  },
  {
    version: '1.3 - April 2nd, 2024',
    link: 'https://confluence.nike.com/x/om7POg',
    overview: 'Switch Connectivity Fixes and Enhancements',
    changes: [
      {
        title:
          'Based on user feedback, we have made some changes to the Switch Connectivity feature. We have fixed some bugs and made some enhancements to the feature.',
        list: [
          'Improved Search - will better handle user input and narrow down the list as more information is inserted into the field',
          'Search now allows you to filter and sort all sites more effectively',
          'Reduced the number of alerts and messages',
          'VLAN legend can now be pinned to the top of the screen',
        ],
      },
    ],
  },
  {
    version: '1.2 - January 30th 2024',
    link: 'https://confluence.nike.com/x/5RmuNw',
    overview: 'New Feature - Switch Connectivity',
    changes: [
      {
        title:
          'The Switch Connectivity from Retail Advisor has been brought over into Insights with added support for Distribution Centers and Showrooms. Switch Connectivity allows you to quickly visualize switch stacks so you can see important information for troubleshooting.',
        list: ['Added support for Distribution Centers', 'Added support for Showrooms'],
      },
    ],
  },
  {
    version: '1.1 - October 27st 2023',
    link: 'https://confluence.nike.com/x/EGyuNw',
    overview: 'Primarily contains bug fixes to RRP dashboard',
    changes: [
      {
        title: 'RRP Dashboard',
        list: [
          'Fixed Mouse Hover Effect on the Status Summary Component',
          'Fixed spelling error in the Cellular Backup alert modal',
          'Fixed some false alerts that was not correctly matching up to the Hardware Standard',
        ],
      },
      {
        title: 'Usage Logs',
        list: [
          'Updated usage logs to send an update on each page view',
          'Changed the logs actions property to only send a single string instead of an array of strings',
        ],
      },
      {
        title: 'Contact Page',
        list: ['Updated links for Jira, Contact Email, and Slack channel'],
      },
    ],
  },
  {
    version: '1.0 - March 31st 2023',
    link: 'https://confluence.nike.com/pages/viewpage.action?spaceKey=GROS&title=Insights+v.1.3.3',
    overview: 'Reoccuring Retail Physical Dashboard',
    changes: [
      {
        title: 'New RRP Dashboard',
        list: [
          'Allows you view stores that are most important to you when you use the Custom View tab.',
          'Geo Tabs will display all stores within the selected Geo',
          'Toggling the Card View allows you to focus on a store at a time.',
          'Card View can be exported into a jpg',
          'Alerts Icons can be clicked on to view additional details on why there is an Alert',
        ],
      },
    ],
  },
];

export default patches;

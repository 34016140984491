import React from 'react';
import styled from 'styled-components';
import MUIContainer from '@material-ui/core/Container';

const StyledContainer = styled(MUIContainer)`
  padding-left: 12px;
  padding-right: 12px;
  /* max-width: 1920px; */
  @media (min-width: 600px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

function Container(props) {
  const {disableMaxWidth, children, className} = props;
  const maxWidth = disableMaxWidth ? false : 'xl';
  return (
    <StyledContainer maxWidth={maxWidth} className={className}>
      {children}
    </StyledContainer>
  );
}

export default Container;

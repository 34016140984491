import React from 'react';
import styled from 'styled-components';
import StyledPaper from '../Paper';
import TabbedContent from './TabbedContent';
import SGHeadline from '../Typography/SGHeadline';

const StyledStoreEventsTable = styled(StyledPaper)`
  .text-spacing {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

function StoreEventsTable(props) {
  const {geo} = props;

  return (
    <StyledStoreEventsTable>
      <SGHeadline variant={'h5'} className={'text-color text-spacing'}>
        Store Events
      </SGHeadline>
      <TabbedContent geo={geo}></TabbedContent>
    </StyledStoreEventsTable>
  );
}

export default StoreEventsTable;

import 'date-fns';
import React from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {createMuiTheme} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import SGBody from 'components/Typography/SGBody';
import DateRangeIcon from '@material-ui/icons/DateRange';
interface DateSelectorProps {
  selectedDate: Date | null;
  setSelectedDate: (value: Date | null) => void;
}

const StyledUtilsProvider = styled(MuiPickersUtilsProvider)`
  margin-top: 8px;
`;

const StyledDatePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  &.MuiFormControl-marginNormal {
    margin-top: 8px;
  }
  &.MuiFormControl-marginNormal {
    margin-top: none;
    margin-bottom: none;
  }
  .MuiPickersBasePicker-pickerView {
    background-color: ${props => props.theme.cardBg};
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
  label {
    display: none;
  }
  input {
    opacity: 0;
    width: 0px;
    padding: 0px;
  }
  .MuiInputBase-root {
    margin-top: 0;
  }
  .MuiInput-underline:before {
    border-bottom: none;
    content: unset;
  }
  .MuiIconButton-root {
    color: ${props => props.theme.disabledStateBlue};
    &:hover {
      color: ${props => props.theme.activeStateBlue};
    }
  }
`;

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'red',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#1A374B',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: '#1A374B',
        color: '#ffffff',
      },
      iconButton: {
        backgroundColor: '#1A374B',
        color: '#52779D',
      },
      dayLabel: {
        color: '#9EDAE5',
      },
      MuiSvgIcon: {
        root: {
          color: '#52779D',
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: '#ffffff',
      },
      daySelected: {
        backgroundColor: '#9EDAE5',
        color: '#1A374B',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
      },
      dayDisabled: {
        color: '#354C64',
      },
      current: {
        color: '#9EDAE5',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#1A374B',
      },
    },
  },
});

function DateSelector({selectedDate, setSelectedDate}: DateSelectorProps) {
  const dateValue = selectedDate ? selectedDate : new Date(new Date());

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <StyledDatePickerContainer>
        <StyledUtilsProvider utils={DateFnsUtils}>
          <StyledDatePicker
            disableToolbar
            disableFuture
            minDate={'2023-05-01T19:30:00.000Z'}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="rrp-date-picker-inline"
            label="Date picker inline"
            value={dateValue}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            keyboardIcon={<DateRangeIcon />}
          />
        </StyledUtilsProvider>
        <SGBody>View 30-day reports</SGBody>
      </StyledDatePickerContainer>
    </ThemeProvider>
  );
}

export default DateSelector;

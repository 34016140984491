import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
} = AlertSeverityEnum;

export default function switchingHardwarePortUtilizationAlertValue(
  switchPortUtilization_Used: number,
  switchPortUtilization_Total: number,
): IAlert {
  if (
    switchPortUtilization_Used === null
    || switchPortUtilization_Total === null
    || isNaN(switchPortUtilization_Used)
    || isNaN(switchPortUtilization_Total)
  ) {
    return defaultNullAlert(['switchPortUtilization_Used', 'switchPortUtilization_Total']);
  }

  const percentUsed = switchPortUtilization_Used / switchPortUtilization_Total;
  if (percentUsed >= 1) {
    return {
      value: MAJOR,
      issue: ['Store is using 100% of the ports available on the Switching Hardware, so has no additional available ports.'],
      effect: ['The store has run out of available ports, and is not able to connect any new wired devices to the network.'],
      solution: ['Consider adding additional Switching Hardware to the store.'],
    };
  } else if (percentUsed >= 0.9) {
    return {
      value: MINOR,
      issue: ['Store is using over 90% of the ports available on existing Switching Hardware, showing that the store is trending towards running out of ports.'],
      effect: ['The store may soon run out of available ports, and will be unable to connect any new additional wired devices to the network.'],
      solution: ['Consider adding additional Switching Hardware to the store.'],
    };
  } else {
    return {
      value: HEALTHY,
      issue: [],
      effect: [],
      solution: [],
    };
  }

}

const coordinatesReducer = data => {
  const circleColor = '#F43E60';

  const dataToApp = [];

  const getPropertyValue = (propertyName, event = undefined) => {
    if (event === undefined) {
      return '';
    } else {
      return event.hasOwnProperty(propertyName) ? event[propertyName] : '';
    }
  };

  data.forEach(event => {
    let formattedData = {
      title: getPropertyValue('StoreNumber', event) + ' - ' + getPropertyValue('StoreName', event),
      latitude: parseFloat(getPropertyValue('Latitude', event)),
      longitude: parseFloat(getPropertyValue('Longitude', event)),
      color: circleColor,
      url: `/store/${getPropertyValue('StoreNumber', event)}/overview`,
    };
    dataToApp.push(formattedData);
  });

  return dataToApp;
};

export default coordinatesReducer;

import {
  getDeviceHealthCountPending,
  getDeviceHealthCountSuccess,
  getDeviceHealthCountError,
} from './index';
import config from '../../oktaConfig';

function getDeviceHealthCount(accessToken) {
  return dispatch => {
    dispatch(getDeviceHealthCountPending());

    fetch(config.resourceServer.insightsApiUrlDeviceHealthCount, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          // console.log('Redux response', response);
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        // console.log('Redux data', data);
        dispatch(getDeviceHealthCountSuccess(data));
      })
      .catch(err => {
        console.log('Redux err', err);
        dispatch(getDeviceHealthCountError(err));
        /* eslint-disable no-console */
        console.error(err);
      });
  };
}

export default getDeviceHealthCount;

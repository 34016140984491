import {
  getDistinctIdfSearchItemsPending,
  getDistinctIdfSearchItemsSuccess,
  getDistinctIdfSearchItemsError,
} from './index';
import config from '../../oktaConfig';

function getDistinctIdfSearchItems(accessToken) {
  return dispatch => {
    dispatch(getDistinctIdfSearchItemsPending());
    const controller = new AbortController();
    fetch(config.resourceServer.insightsApiUrlDistinctIdfSearchItems, {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then(data => {
        dispatch(getDistinctIdfSearchItemsSuccess(data));
      })
      .catch(err => {
        dispatch(getDistinctIdfSearchItemsError(err));
        /* eslint-disable no-console */
        console.error(err);
      });

    return () => {
      controller.abort();
    };
  };
}

export default getDistinctIdfSearchItems;

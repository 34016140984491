import timeZone from '../constants/timeZone';
import storeInfoDataReducer from './storeInfoDataReducer';
import storeEventsDataReducer from './storeEventsDataReducer';
import storeTrendingDataReducer from './storeTrendingDataReducer';
import coordinatesReducer from './coordinatesReducer';
import storeIssuesCountReducter from './storeIssuesCountReducer';
import dashboardTrendingReducer from './dashboardTrendingReducer';
import historicalReducer from './historicalReducer';

export const initialState = {
  api: {
    landingPageCoordinates: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    storeInfo: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    storeEvents: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    storeTrending: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    geoTrending: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    dashboardRiskIssuesCount: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    dashboardDiminishedIssuesCount: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    dashboardTrending: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    searchMinimumStoreInfo: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
  },
  app: {
    timeZone: timeZone,
    landingPageCoordinates: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    storeInfo: {
      data: storeInfoDataReducer(),
      dataRetrieved: false,
    },
    storeEvents: {
      data: storeEventsDataReducer(),
      dataRetrieved: false,
    },
    storeTrending: {
      data: storeEventsDataReducer(),
      dataRetrieved: false,
    },
    geoTrending: {
      data: 'geoTrending',
      dataRetrieved: false,
    },
    dashboardRiskIssuesCount: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    dashboardDiminishedIssuesCount: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    dashboardTrending: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    searchMinimumStoreInfo: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LANDING_PAGE_COORDINATES_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          landingPageCoordinates: {
            pending: true,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_LANDING_PAGE_COORDINATES_SUCESS':
      return {
        ...state,
        api: {
          ...state.api,
          landingPageCoordinates: {
            pending: false,
            dataRetrieved: true,
            // data: action.data,
          },
        },
        app: {
          ...state.app,
          landingPageCoordinates: {
            dataRetrieved: true,
            data: coordinatesReducer(action.data),
          },
        },
      };

    case 'GET_LANDING_PAGE_COORDINATES_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          landingPageCoordinates: {
            pending: false,
            dataRetrieved: false,
            error: action.error,
          },
        },
      };

    case 'GET_DASHBOARD_RISK_ISSUES_COUNT_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardRiskIssuesCount: {
            ...state.api.dashboardRiskIssuesCount,
            dataRetrieved: false,
            pending: true,
          },
        },
        app: {
          ...state.app,
          dashboardRiskIssuesCount: {
            ...state.app.dashboardRiskIssuesCount,
            dataRetrieved: false,
            pending: true,
          },
        },
      };

    case 'GET_DASHBOARD_RISK_ISSUES_COUNT_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardRiskIssuesCount: {
            ...state.api.dashboardRiskIssuesCount,
            dataRetrieved: true,
            pending: false,
            // data: action.data,
            error: null,
          },
        },
        app: {
          ...state.app,
          dashboardRiskIssuesCount: {
            ...state.app.dashboardRiskIssuesCount,
            dataRetrieved: true,
            pending: false,
            data: storeIssuesCountReducter(action.data),
            error: null,
          },
        },
      };

    case 'GET_DASHBOARD_RISK_ISSUES_COUNT_ERROR':
      return {
        api: {
          ...state.api,
          dashboardRiskIssuesCount: {
            ...state.api.dashboardRiskIssuesCount,
            dataRetrieved: false,
            pending: false,
            data: null,
            error: action.error,
          },
        },
      };

    case 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardDiminishedIssuesCount: {
            ...state.api.dashboardDiminishedIssuesCount,
            dataRetrieved: false,
            pending: true,
          },
        },
        app: {
          ...state.app,
          dashboardDiminishedIssuesCount: {
            ...state.app.dashboardDiminishedIssuesCount,
            dataRetrieved: false,
            pending: true,
          },
        },
      };

    case 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardDiminishedIssuesCount: {
            ...state.api.dashboardDiminishedIssuesCount,
            dataRetrieved: true,
            pending: false,
            // data: action.data,
            error: null,
          },
        },
        app: {
          ...state.app,
          dashboardDiminishedIssuesCount: {
            ...state.app.dashboardDiminishedIssuesCount,
            dataRetrieved: true,
            pending: false,
            data: storeIssuesCountReducter(action.data),
            error: null,
          },
        },
      };

    case 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_ERROR':
      return {
        api: {
          ...state.api,
          dashboardDiminishedIssuesCount: {
            ...state.api.dashboardDiminishedIssuesCount,
            dataRetrieved: false,
            pending: false,
            data: null,
            error: action.error,
          },
        },
      };

    case 'GET_STORE_INFO_DATA_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          storeInfo: {
            pending: true,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_STORE_INFO_DATA_SUCCESS':
      const storeInfoApp = storeInfoDataReducer(action.data[0]);

      return {
        ...state,
        api: {
          ...state.api,
          storeInfo: {
            pending: false,
            data: action.data,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          storeInfo: {
            data: storeInfoApp,
            dataRetrieved: true,
          },
        },
      };

    case 'GET_STORE_INFO_DATA_ERROR':
      console.log('error check', action);
      return {
        ...state,
        api: {
          ...state.api,
          storeInfo: {
            ...state.api.storeInfo,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_STORE_EVENTS_DATA_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          storeEvents: {
            pending: true,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_STORE_EVENTS_DATA_SUCCESS':
      const storeEventsApp = storeEventsDataReducer(action.data);

      return {
        ...state,
        api: {
          ...state.api,
          storeEvents: {
            pending: false,
            data: action.data,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          storeEvents: {
            data: storeEventsApp,
            dataRetrieved: true,
          },
        },
      };

    case 'GET_STORE_EVENTS_DATA_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          storeEvents: {
            ...state.api.storeEvents,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_STORE_TRENDING_DATA_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          storeTrending: {
            pending: true,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_STORE_TRENDING_DATA_SUCCESS':
      const storeTrendingApp = storeTrendingDataReducer(action.data);

      return {
        ...state,
        api: {
          ...state.api,
          storeTrending: {
            pending: false,
            data: action.data,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          storeTrending: {
            data: storeTrendingApp,
            dataRetrieved: true,
          },
        },
      };

    case 'GET_STORE_TRENDING_DATA_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          storeTrending: {
            ...state.api.storeTrending,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_GEO_TRENDING_DATA_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          geoTrending: {
            pending: true,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          geoTrending: {
            ...state.app.geoTrending,
            pending: true,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_GEO_TRENDING_DATA_SUCCESS':
      const geoTrendingApp = storeTrendingDataReducer(action.data);
      const historical = historicalReducer(action.data);
      return {
        ...state,
        api: {
          ...state.api,
          geoTrending: {
            ...state.api.geoTrending,
            pending: false,
            // data: action.data,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          geoTrending: {
            ...state.app.geoTrending,
            data: geoTrendingApp,
            historical: historical,
            dataRetrieved: true,
          },
        },
      };

    case 'GET_GEO_TRENDING_DATA_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          geoTrending: {
            ...state.api.storeTrending,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          geoTrending: {
            ...state.app.storeTrending,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };
    case 'GET_DASHBOARD_TRENDING_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardTrending: {
            ...state.api.dashboardTrending,
            pending: true,
            error: null,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          dashboardTrending: {
            ...state.app.dashboardTrending,
            pending: true,
            error: null,
            dataRetrieved: false,
          },
        },
      };
    case 'GET_DASHBOARD_TRENDING_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardTrending: {
            ...state.api.dashboardTrending,
            pending: false,
            data: action.data,
            error: null,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          dashboardTrending: {
            ...state.app.dashboardTrending,
            pending: false,
            data: dashboardTrendingReducer(action.data),
            error: null,
            dataRetrieved: true,
          },
        },
      };
    case 'GET_DASHBOARD_TRENDING_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          dashboardTrending: {
            ...state.api.dashboardTrending,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          dashboardTrending: {
            ...state.app.dashboardTrending,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };

    case 'GET_MINIMUM_STORE_INFO_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          searchMinimumStoreInfo: {
            ...state.api.searchMinimumStoreInfo,
            pending: true,
            error: null,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          searchMinimumStoreInfo: {
            ...state.app.searchMinimumStoreInfo,
            pending: true,
            error: null,
            dataRetrieved: false,
          },
        },
      };
    case 'GET_MINIMUM_STORE_INFO_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          searchMinimumStoreInfo: {
            ...state.api.searchMinimumStoreInfo,
            pending: false,
            data: action.data,
            error: null,
            dataRetrieved: true,
          },
        },
        app: {
          ...state.app,
          searchMinimumStoreInfo: {
            ...state.app.searchMinimumStoreInfo,
            pending: false,
            // data: dashboardTrendingReducer(action.data),
            data: action.data,
            error: null,
            dataRetrieved: true,
          },
        },
      };
    case 'GET_MINIMUM_STORE_INFO_ERROR':
      return {
        ...state,
        // todo: Ask Byron why there's api and app
        api: {
          ...state.api,
          searchMinimumStoreInfo: {
            ...state.api.searchMinimumStoreInfo,
            pending: false,
            data: null,
            error: action.error,
            dataRetrieved: false,
          },
        },
        app: {
          ...state.app,
          searchMinimumStoreInfo: {
            ...state.app.searchMinimumStoreInfo,
            data: null,
            pending: false,
            error: action.error,
            dataRetrieved: false,
          },
        },
      };
    default:
      return state;
  }
};

export default dataReducer;

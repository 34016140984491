import {
  PortHistorySnapshot,
  PortHistorySwitch,
  PortHistorySwitchStack,
  PortHistoryPort,
  PortHistoryState,
} from '../interfaces/PortHistoryState';
import PortHistoryResponseData from '../interfaces/response/PortHistoryResponseData';

export default function formatPortHistoryData(apiData: PortHistoryResponseData[]) {
  let switchStacks: {[id: string]: PortHistorySwitchStack} = {};

  apiData.forEach(ss => {
    let switches: {[id: number]: PortHistorySwitch} = {};

    Object.keys(ss.switchPorts).forEach(portKey => {
      let switchPort = ss.switchPorts[portKey];

      let snapshots: PortHistorySnapshot[] = switchPort.switchPortSnapshots.map(snapshot => {
        const {connectedDeviceType} = snapshot;
        const deviceType =
          connectedDeviceType.toLowerCase() === 'null' ? 'Unknown' : connectedDeviceType;
        let date = new Date(snapshot.date);
        let searchValues: string[] = [
          snapshot.macAddress?.replace(/[.|:]/g, ''),
          snapshot.connectedDeviceName,
          snapshot.connectedDeviceManufacturer,
          deviceType,
        ];
        return {
          date: date === new Date(0) ? null : date,
          macAddress: snapshot.macAddress,
          manufacturer: snapshot.connectedDeviceManufacturer,
          deviceName: snapshot.connectedDeviceName,
          deviceType: deviceType,
          searchData: searchValues.join('|').toLowerCase(),
        } as PortHistorySnapshot;
      });

      let port: PortHistoryPort = {
        number: switchPort.portNumber,
        snapshots: snapshots,
      };

      let _switch: PortHistorySwitch =
        switches[switchPort.switchNumber] ??
        <PortHistorySwitch>{
          number: switchPort.switchNumber,
          ports: {},
        };

      _switch.ports[switchPort.portNumber] = port;
      switches[switchPort.switchNumber] = _switch;
    });

    let switchStack: PortHistorySwitchStack = {
      name: ss.switchName,
      switches: switches,
    };
    switchStacks[ss.switchName] = switchStack;
  });

  return <PortHistoryState>{switchStacks: switchStacks};
}

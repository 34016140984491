import IAlert from "features/RRP/interfaces/IAlert";
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
const {
  UNKNOWN,
} = AlertSeverityEnum;
export default function SDWANHardwareUtilizationAlertValue(): IAlert {

  let alert: IAlert = {
    value: UNKNOWN,
    issue: ['sdwanHardwareUtilizationAlertValue doesnt currently have a value.'],
    effect: ['This dashboard is inserting 0 at the moment.'],
    solution: ['Once sdwanHardwareUtilizationAlertValue is available, enable SDWANHardwareUtilizationAlertValue.js'],
  }

  // TODO This file is making an assumption on what the future data will look like.
  // Waiting on SDWANHardwareUtilization data from the endpoint before this gets updated/enabled
  // https://jira.nike.com/browse/GRSTI-1672

  // if (sdwanHardwareUtilizationAlertValue > 90) {
  //   value = 2;
  //   issue.push('SDWAN Hardware utilization is over 90%, showing that the store may already be overrunning the hardware.');
  //   effect.push('high likelihood of slow or intermittently failing network connections for whole store. ');
  //   solution.push('Consider upgrading to meet SDWAN Hardware standard of VCE-620N. If already within standard, contact Network Engineering.');
  // } else if (sdwanHardwareUtilizationAlertValue > 70) {
  //   value = 1;
  //   issue.push('SDWAN Hardware utilization is over 70%, showing that the store is trending toward overrunning the hardware.')
  //   effect.push('May cause slow or intermittently failing network connections for whole store.');
  //   solution.push('Consider upgrading to meet SDWAN Hardware standard of VCE-620N. If already within standard, contact Network Engineering.');
  // }

  return alert;
}

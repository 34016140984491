import floatToFixed from './alertMapping/utility/floatToFixed';
import internetAProvisionedAlertValue from './alertMapping/internetAProvisionedAlertValue';
import internetAUtilizationAlertValue from './alertMapping/internetAUtilizationAlertValue';
import internetBProvisionedAlertValue from './alertMapping/internetBProvisionedAlertValue';
import internetBUtilizationAlertValue from './alertMapping/internetBUtilizationAlertValue';
import cellularBackupAlertValue from './alertMapping/cellularBackupAlertValue';
import SDWANHardwareProvisionedAlertValue from './alertMapping/SDWANHardwareProvisionedAlertValue';
import SDWANHardwareUtilizationAlertValue from './alertMapping/SDWANHardwareUtilizationAlertValue';
import SDWANEffectivenessQoEAlertValue from './alertMapping/SDWANEffectivenessQoEAlertValue';
import switchingHardwareProvisionedAlertValue from './alertMapping/switchingHardwareProvisionedAlertValue';
import switchingHardwarePortUtilizationAlertValue from './alertMapping/switchingHardwarePortUtilizationAlertValue';
import switchingHardwareAPPortUtilizationAlertValue from './alertMapping/switchingHardwareAPPortUtilizationAlertValue';
import switchingHardwarePoEBudgetAlertValue from './alertMapping/switchingHardwarePoEBudgetAlertValue';
import switchingHardwarePoEAtCapacityAlertValue from './alertMapping/switchingHardwarePoEAtCapacityAlertValue';
import wirelessAPHardwareProvisionedAlertValue from './alertMapping/wirelessAPHardwareProvisionedAlertValue';
import wirelessAPUpStatusAlertValue from './alertMapping/wirelessAPUpStatusAlertValue';
import wirelessClientHealthAvgAlertValue from './alertMapping/wirelessClientHealthAvgAlertValue';
import wirelessAPHealthAlertValue from './alertMapping/wirelessAPHealthAlertValue';
import defaultNullAlert from './alertMapping/utility/defaultNullAlert';

const convertToRRPData = (storeObj, selectedStore) => {
  // const store = selectedStores.find(store => store.SiteId === storeObj.siteId);

  const storeName = selectedStore.StoreName;
  const storeNumber = selectedStore.StoreNumber;
  const geo = selectedStore.Geo;
  const {provisioned, utilization, wirelessAP, wireless, vmWareSDWAN} = storeObj;
  const storeSwitch = storeObj.switch;

  const {
    internetAProvisioned_Upload,
    internetAProvisioned_Download,
    internetAProvisioned_Type,
    internetBProvisioned_Upload,
    internetBProvisioned_Download,
    internetBProvisioned_Type,
  } = provisioned || {};

  const {
    internetAPeakUtilization_Upload,
    internetAPeakUtilization_Download,
    internetBPeakUtilization_Upload,
    internetBPeakUtilization_Download,
    // cellularBackupProvisioned,
  } = utilization || {};

  const {
    switchProvisioned,
    switchPortUtilization_Total,
    switchPortUtilization_Used,
    switchAPPortUtilization_Total,
    switchAPPortUtilization_Used,
    switchPoEBudget_Total,
    switchPoEBudget_Used,
    switchPoEStackMembers_AtCapacity,
    // switchPoEStackMembers_Total,
  } = storeSwitch || {};

  const {wirelessAPHardwareProvisioned, wirelessAPUpStatus_Total, wirelessAPUpStatus_Up} =
    wirelessAP || {};

  const {wirelessClientsTotal, wirelessClientGood, wirelessAPHealth} = wireless || {};

  const {sdwanHardwareProvisioned, sdwanEffectivenessQoE, cellularBackupProvisioned_POLR} =
    vmWareSDWAN || {};

  let formattedStoreObj = {
    storeName: `${storeNumber} ${storeName}`,
    storeNumber: storeNumber,
    internetAProvisioned: `
    ${internetAProvisioned_Download?.toLowerCase() || null} | 
    ${internetAProvisioned_Upload?.toLowerCase() || null} 
    (${internetAProvisioned_Type})
    `,
    internetAUtilization: `
    ${floatToFixed(internetAPeakUtilization_Download?.toLowerCase() || null)}m | 
    ${floatToFixed(internetAPeakUtilization_Upload?.toLowerCase() || null)}m`,
    internetBProvisioned: `${internetBProvisioned_Download?.toLowerCase() || null} | ${
      internetBProvisioned_Upload?.toLowerCase() || null
    } (${internetBProvisioned_Type})`,
    internetBUtilization: `${floatToFixed(internetBPeakUtilization_Download)}m | ${floatToFixed(
      internetBPeakUtilization_Upload
    )}m`,
    cellularBackup: cellularBackupProvisioned_POLR ? 'Installed' : 'Missing',
    SDWANHardwareProvisioned: sdwanHardwareProvisioned,
    SDWANHardwareUtilization: 0,
    SDWANEffectivenessQoE: sdwanEffectivenessQoE,
    switchingHardwareProvisioned: Array.isArray(switchProvisioned)
      ? switchProvisioned.join(', ')
      : switchProvisioned,
    switchingHardwarePortUtilization: `${switchPortUtilization_Used} / ${switchPortUtilization_Total}`,
    switchingHardwareAPPortUtilization: `${switchAPPortUtilization_Used} / ${switchAPPortUtilization_Total}`,
    switchingHardwarePoEBudget: `${switchPoEBudget_Used} / ${switchPoEBudget_Total}`,
    switchingHardwarePoEAtCapacity: switchPoEStackMembers_AtCapacity,
    wirelessAPHardwareProvisioned: Array.isArray(wirelessAPHardwareProvisioned)
      ? wirelessAPHardwareProvisioned.join(', ')
      : wirelessAPHardwareProvisioned,
    wirelessAPUpStatus: `${wirelessAPUpStatus_Up} / ${wirelessAPUpStatus_Total}`,
    wirelessClientHealthAvg:
      isNaN(wirelessClientGood) ||
      isNaN(wirelessClientsTotal) ||
      wirelessClientGood === null ||
      wirelessClientsTotal === null
        ? `${wirelessClientGood} / ${wirelessClientsTotal}`
        : `${Math.floor((wirelessClientGood / wirelessClientsTotal) * 100)}%`,
    wirelessAPHealth: isNaN(wirelessAPHealth) ? `${wirelessAPHealth}` : `${wirelessAPHealth}%`,
    geo: geo.toLowerCase(),
    internetAProvisionedAlertValue: internetAProvisionedAlertValue(
      internetAProvisioned_Upload,
      internetAProvisioned_Download,
      internetAProvisioned_Type
    ),
    internetAUtilizationAlertValue: internetAUtilizationAlertValue(
      internetAPeakUtilization_Upload,
      internetAProvisioned_Upload,
      internetAPeakUtilization_Download,
      internetAProvisioned_Download
    ),
    internetBProvisionedAlertValue: internetBProvisionedAlertValue(
      internetBProvisioned_Upload,
      internetBProvisioned_Download,
      internetBProvisioned_Type
    ),
    internetBUtilizationAlertValue: internetBUtilizationAlertValue(
      internetBPeakUtilization_Upload,
      internetBProvisioned_Upload,
      internetBPeakUtilization_Download,
      internetBProvisioned_Download
    ),
    SDWANHardwareProvisionedAlertValue: SDWANHardwareProvisionedAlertValue(
      sdwanHardwareProvisioned
    ),
    SDWANHardwareUtilizationAlertValue: SDWANHardwareUtilizationAlertValue(),
    SDWANEffectivenessQoEAlertValue: SDWANEffectivenessQoEAlertValue(sdwanEffectivenessQoE),
    switchingHardwareProvisionedAlertValue: switchingHardwareProvisionedAlertValue(
      switchProvisioned
    ),
    switchingHardwarePortUtilizationAlertValue: switchingHardwarePortUtilizationAlertValue(
      switchPortUtilization_Used,
      switchPortUtilization_Total
    ),
    switchingHardwareAPPortUtilizationAlertValue: switchingHardwareAPPortUtilizationAlertValue(
      switchAPPortUtilization_Used,
      switchAPPortUtilization_Total
    ),
    switchingHardwarePoEBudgetAlertValue: switchingHardwarePoEBudgetAlertValue(
      switchPoEBudget_Used,
      switchPoEBudget_Total
    ),
    switchingHardwarePoEAtCapacityAlertValue: switchingHardwarePoEAtCapacityAlertValue(
      switchPoEStackMembers_AtCapacity
    ),
    wirelessAPHardwareProvisionedAlertValue: wirelessAPHardwareProvisionedAlertValue(
      wirelessAPHardwareProvisioned
    ),
    wirelessAPUpStatusAlertValue: wirelessAPUpStatusAlertValue(
      wirelessAPUpStatus_Up,
      wirelessAPUpStatus_Total
    ),
    wirelessClientHealthAvgAlertValue: wirelessClientHealthAvgAlertValue(
      wirelessClientGood,
      wirelessClientsTotal
    ),
    cellularBackupAlertValue: cellularBackupAlertValue(cellularBackupProvisioned_POLR),
    wirelessAPHealthAlertValue: wirelessAPHealthAlertValue(wirelessAPHealth),
    get alertValue() {
      let highestAlertValue = 0;
      let alertObj;

      for (const property of [
        'internetAProvisionedAlertValue',
        'internetAUtilizationAlertValue',
        'internetBProvisionedAlertValue',
        'internetBUtilizationAlertValue',
        'SDWANHardwareProvisionedAlertValue',
        'SDWANHardwareUtilizationAlertValue',
        'SDWANEffectivenessQoEAlertValue',
        'switchingHardwareProvisionedAlertValue',
        'switchingHardwarePortUtilizationAlertValue',
        'switchingHardwarePoEBudgetAlertValue',
        'switchingHardwarePoEAtCapacityAlertValue',
        'wirelessAPHardwareProvisionedAlertValue',
        'wirelessAPUpStatusAlertValue',
        'wirelessClientHealthAvgAlertValue',
        'cellularBackupAlertValue',
        'wirelessAPHealthAlertValue',
      ]) {
        const value = this[property].value;
        if (value < 4 && value > highestAlertValue) {
          highestAlertValue = value;
          alertObj = this[property];
        }
      }
      if (alertObj === undefined) {
        return defaultNullAlert([storeObj.siteId]);
      }
      return alertObj;
    },
  };

  return formattedStoreObj;
};

export default convertToRRPData;

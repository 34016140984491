import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {uiToggleTable} from '../../redux/actions';
import Paper from '../Paper';
import PaperTitle from '../PaperTitle';
import Grid from '@material-ui/core/Grid';
import Button from '../Button';

function TableToggle() {
  const dispatch = useDispatch();
  const tableScroll = useSelector(state => state.ui.tableScroll);

  return (
    <Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperTitle>Table Toggle</PaperTitle>
        </Grid>
        <Grid item xs={12}>
          {tableScroll ? (
            <p>Tables are now using Scrollbars</p>
          ) : (
            <p>Tables are now using Pagination with 5 rows per page.</p>
          )}
          <Button onClick={() => dispatch(uiToggleTable())} disable={!tableScroll}>
            Toggle Scroll Bar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TableToggle;

import React from 'react';
import styled from 'styled-components';
import SGBody from 'components/Typography/SGBody';

//#region styled-components
const StyledStatusIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 8px;
  &.green {
    background-color: ${props => props.theme.green};
  }
  &.grey {
    background-color: ${props => props.theme.grey};
  }
  &.none {
    background-color: transparent;
  }
`;
//#endregion styled-components

//#region functions
export enum StatusIndicatorColors {
  GREEN = 'green',
  GREY = 'grey',
  NONE = 'none',
}

const determineHeader = (portStatus: string) => {
  // notconnect, connected, disabled
  let color = StatusIndicatorColors.NONE;
  let headerText = '';

  if (portStatus.toLowerCase() === 'connected') {
    headerText = 'Connected';
    color = StatusIndicatorColors.GREEN;
  } else if (portStatus.toLowerCase() === 'notconnect') {
    headerText = 'Not Connected';
    color = StatusIndicatorColors.GREY;
  } else if (portStatus.toLowerCase() === 'disabled') {
    headerText = 'Disabled';
    color = StatusIndicatorColors.NONE;
  } else {
    headerText = portStatus;
    color = StatusIndicatorColors.NONE;
  }

  return {color, headerText};
};

//#endregion functions

//#region components
interface StatusIndicatorProps {
  portStatus: string;
  className?: string;
}

function StatusIndicator({portStatus, className}: StatusIndicatorProps) {
  const {color, headerText} = determineHeader(portStatus);

  return (
    <StyledStatusIndicator className={className}>
      <StyledIndicator className={color} />
      <SGBody variant={'b4'}>{headerText}</SGBody>
    </StyledStatusIndicator>
  );
}
//#endregion components
export default StatusIndicator;

import {fetchTableEventsPending, fetchTableEventsSuccess, fetchTableEventsError} from './index';
import config from '../../oktaConfig';

function getTableEvents(accessToken) {
  return dispatch => {
    dispatch(fetchTableEventsPending());
    fetch(config.resourceServer.insightsApiUrlTableEvents, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        dispatch(fetchTableEventsSuccess(data));
      })
      .catch(error => {
        dispatch(fetchTableEventsError(error));
      });
  };
}

export default getTableEvents;

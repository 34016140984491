import {useState, useEffect} from 'react';
import config from '../../../oktaConfig';
import {useOktaAuth} from '@okta/okta-react';
import SwitchResponseData from '../interfaces/response/SwitchResponseData';
import FetchResponseError from 'interfaces/FetchResponseError';
import PortHistoryResponseData from '../interfaces/response/PortHistoryResponseData';

function useSwitchConnectivity(siteId: string, idf?: string) {
  const {authState, oktaAuth} = useOktaAuth();
  const [switchDataIsLoaded, setSwitchDataIsLoaded] = useState<boolean>(false);
  const [portHistoryDataIsLoaded, setPortHistoryDataIsLoaded] = useState<boolean>(false);
  const [switchData, setSwitchData] = useState<SwitchResponseData | null>(null);
  const [portHistoryData, setPortHistoryData] = useState<PortHistoryResponseData[] | null>(null);
  const [error, setError] = useState<FetchResponseError | null>(null);

  useEffect(() => {
    async function fetchPortHistory(accessToken: string, controller: AbortController) {
      let portHistoryFetchResult = await fetch(
        config.resourceServer.insightsApiUrlSwitchPortHistory + '?siteId=' + siteId,
        {
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        }
      );

      if (!portHistoryFetchResult.ok) return Promise.reject(portHistoryFetchResult);

      setPortHistoryData(await portHistoryFetchResult.json());
      setPortHistoryDataIsLoaded(true);
    }

    async function fetchSwitchData(
      idfParameter: string,
      accessToken: string | undefined,
      controller: AbortController
    ) {
      fetch(config.resourceServer.insightsApiUrlSwitchData + '?siteId=' + siteId + idfParameter, {
        mode: 'cors',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal: controller.signal,
      })
        .then(response => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(data => {
          setSwitchData(data);
        })
        .then(() => {
          setSwitchDataIsLoaded(true);
        })
        .catch(err => {
          console.error(err);
          console.log('error', err);
          if (err.name === 'AbortError') {
            const errorMsg: FetchResponseError = {
              ok: false,
              status: 'userAbortedRequest',
              statusText: 'DOMException: The user aborted a request.',
              type: '',
              url: config.resourceServer.insightsApiUrlSwitchData + '?siteId=' + siteId,
            };
            setError(errorMsg);
          } else {
            setError(err);
          }

          setSwitchDataIsLoaded(false);
        });
    }

    setSwitchDataIsLoaded(false);
    setSwitchData(null);
    setError(null);
    if (authState && authState.isAuthenticated && siteId) {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      const idfParameter = idf ? '&idf=' + idf : '';
      setError(null);

      fetchPortHistory(accessToken!!, controller);
      fetchSwitchData(idfParameter, accessToken, controller);

      return () => {
        controller.abort();
        setSwitchDataIsLoaded(false);
        setError(null);
        setSwitchData(null);
        setPortHistoryData(null);
      };
    } else {
      setSwitchData(null);
      setPortHistoryData(null);
      setSwitchDataIsLoaded(false);
      setPortHistoryDataIsLoaded(false);
      setError(null);
    }
  }, [siteId, idf, authState, oktaAuth]);

  let allDataLoaded = switchDataIsLoaded && portHistoryDataIsLoaded;

  return {
    allDataLoaded,
    switchData,
    portHistoryData,
    error,
  };
}

export default useSwitchConnectivity;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import StyledPaper from '../Paper';
import StyledPaperTitle from '../PaperTitle';
import Table from './TableStructure/Table';
import Icons from './Icons/Icons';
import {useHistory} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import ToolTip from '../ToolTip';

const StyledStoreTable = styled(StyledPaper)`
  .storeNumber,
  .storeName {
    p {
      font-family: 'Futura C XBd';
      text-transform: uppercase;
      margin: 0;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
  .keyMarket {
    padding-right: 2px;
  }
  .storeNumber {
    padding-left: 4px;
    padding-right: 4px;
  }
  .storeName {
    padding-left: 8px;
  }
  .align-right {
    text-align: right;
  }
`;

const StyledDash = styled.span`
  color: ${props => props.theme.tableText};
`;

const StyledLink = styled(Button)`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0;
  text-transform: capitalize;
  &:hover {
    color: ${props => props.theme.focusText};
  }
`;

function StoreTable(props) {
  const {
    className, //react specific
    data,
    loaded,
    color,
    title,
    expandProp,
    setExpand,
    noDataText,
  } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      const sortedData = data.sort(function (x, y) {
        // true values first
        return x.isKeyMarket === y.isKeyMarket ? y.total - x.total : x.isKeyMarket ? -1 : 1;

        // false values first
        // return (x === y)? 0 : x? 1 : -1;
      });
      setRows(sortedData);
    }
  }, [data]);

  // columns are defined manually
  const columns = [
    // {
    //   id: 'isKeyMarket',
    //   label: 'Key Market',
    //   width: '85px',
    //   align: 'center',
    //   icon: 'none',
    //   customClass: 'keyMarket',
    //   format: value =>
    //     value ? <Icons iconType={'star'} color={'focus'} className={'star'} /> : null,
    // },
    {
      id: 'storeNumber',
      label: 'Store #',
      width: '64px',
      align: 'right',
      icon: 'none',
      customClass: 'storeNumber',
    },
    {
      id: 'storeName',
      label: 'Name',
      width: 'auto',
      icon: 'none',
      customClass: 'storeName',
    },
    {
      id: 'total',
      label: 'Total Events',
      width: '120px',
      align: 'center',
      icon: 'none',
    },
    {
      id: 'network',
      label: 'Network',
      width: '80px',
      align: 'center',
      icon: 'none',
      format: value =>
        value ? (
          <Icons iconType={'network'} color={color} number={value} />
        ) : (
          <StyledDash>-</StyledDash>
        ),
    },
    {
      id: 'server',
      label: 'Server',
      width: '80px',
      align: 'center',
      icon: 'none',
      format: value =>
        value ? (
          <Icons iconType={'server'} color={color} number={value} />
        ) : (
          <StyledDash>-</StyledDash>
        ),
    },
    {
      id: 'register',
      label: 'Register',
      width: '80px',
      align: 'center',
      icon: 'none',
      format: value =>
        value ? (
          <Icons iconType={'register'} color={color} number={value} />
        ) : (
          <StyledDash>-</StyledDash>
        ),
    },
    {
      id: 'pc',
      label: 'PC',
      width: '80px',
      align: 'center',
      icon: 'none',
      format: value =>
        value ? <Icons iconType={'pc'} color={color} number={value} /> : <StyledDash>-</StyledDash>,
    },
    {
      id: 'athleteDevice',
      label: 'Athlete Device',
      width: '80px',
      align: 'center',
      icon: 'none',
      format: value =>
        value ? (
          <Icons iconType={'athlete device'} color={color} number={value} />
        ) : (
          <StyledDash>-</StyledDash>
        ),
    },
  ];

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const history = useHistory();
  const goToStore = e => {
    e.preventDefault();
    const storeNumber = e.currentTarget.getAttribute('clickkey');
    let path = `/store/${storeNumber}/overview`;
    history.push(path);
  };

  return (
    // <StyledStoreTable>
    //   <Grid container spacing={1}>
    //     <Grid item xs={6}>
    //       <StyledPaperTitle>{title}</StyledPaperTitle>
    //       {title === 'At Risk Stores' ? (
    //         <ToolTip
    //           icon
    //           title={`
    //                             A store is considered to be At Risk:
    //                             There is at least 1 Critical Network Device and the number of Critical Network Devices does not equal to the Total Network Devices.
    //                             The number of Major or Critical Server devices is at least 1.
    //                             The number of Critical Registers is greater than 50% of the Total Number of Registers.
    //                             All PCs are Critical when SOD has been completed. (SOD: Operating Status is “Open”).
    //                             The device health of Servers, Networks, Registers, and PCs do not fall under the Unable to Trade categories.
    //                         `}
    //         />
    //       ) : (
    //         <ToolTip
    //           icon
    //           title={`
    //                             A store is considered to be Diminished:
    //                             The number of Registers that are in Critical status is less than 50% of the stores Total Registers.
    //                             The device health of Servers, Networks, Registers, and PCs do not fall under the At Risk or Unable to Trade categories.
    //                         `}
    //         />
    //       )}
    //     </Grid>
    //     <Grid item xs={6} className={'align-right'}>
    //       <StyledLink
    //         onClick={() => {
    //           setExpand(!expandProp);
    //           topFunction();
    //         }}
    //       >
    //         {expandProp ? (
    //           <SvgIcon>
    //             <CloseIcon />
    //           </SvgIcon>
    //         ) : (
    //           'See All'
    //         )}
    //       </StyledLink>
    //     </Grid>
    //   </Grid>

    <Table
      className={className}
      columns={columns}
      data={rows}
      loaded={loaded}
      clickAction={goToStore}
      clickActionType={'storeNumber'}
      paginationType={'Total Stores'}
      showAll={expandProp}
      noDataText={noDataText}
    />
    // </StyledStoreTable>
  );
}

export default StoreTable;

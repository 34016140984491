import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import HistoricalComparisonState from '../../../redux/reducers/componentHistoricalComparison/HistoricalComparison';
import destructureIdString from '../../../redux/reducers/componentHistoricalComparison/destructureIdString';
import Grid from '@material-ui/core/Grid';
import SGTitle from '../../Typography/SGTitle';
import SGBody from '../../Typography/SGBody';
import TypeButton from './TypeButton';
import TrendCount from './TrendCount';
import DateRangeDisplay from './DateRangeDisplay';

interface EventsByTypeProps {
  debugLoaded?: boolean;
}

const StyledLabel = styled.div`
  margin-top: 4px;
`;

const StyledSubtitleGrid = styled(Grid)`
  && {
    padding-top: 1rem;
    padding-bottom: 0px;
  }
`;

const renderTypeButtons = function renderTypeButtons(
  loadingStatus: boolean,
  eventTypeId: {eventName: string; totalEvents: number}
) {
  const {eventName, totalEvents} = eventTypeId;
  return (
    <Grid item xs={3} sm={3} md={3} lg={2} xl={3}>
      <TypeButton
        debugLoaded={loadingStatus}
        count={totalEvents}
        deviceType={eventName}
        checked={true}
      />
    </Grid>
  );
};

const renderTrendCount = function renderTrendCount(
  loadingStatus: boolean,
  changeOverPreviousPeriod: {eventName: string; percentDifference: number}
) {
  const {eventName, percentDifference} = changeOverPreviousPeriod;
  const trendType: boolean = percentDifference >= 0 ? false : true;

  return (
    <Grid item xs={3} sm={3} md={3} lg={2} xl={3}>
      <TrendCount
        debugLoaded={loadingStatus}
        count={percentDifference}
        deviceType={eventName}
        trend={trendType}
      />
    </Grid>
  );
};

function EventsByType({debugLoaded = false}: EventsByTypeProps) {
  const {friendlyDateRange, eventCounts, changeOverPreviousPeriod} = useSelector(
    (state: HistoricalComparisonState) => state.componentHistoricalComparison
  );

  // const {eventType} = destructureIdString(eventCounts[0].eventName);

  return (
    <Grid container spacing={3} direction="row" alignItems="stretch">
      <Grid item xs={6} md={8}>
        <SGTitle className={'text-color'}>Total Number of Events by Device Type</SGTitle>
      </Grid>
      <Grid item xs={6} md={4}>
        <StyledLabel>
          <DateRangeDisplay
            debugLoaded={debugLoaded}
            friendlyDateRange={friendlyDateRange}
            variant={'subTitle'}
          />
        </StyledLabel>
      </Grid>
      {eventCounts.map(node => renderTypeButtons(debugLoaded, node))}
      <StyledSubtitleGrid item xs={12}>
        <SGBody variant={'b5'}>Changes over the previous period</SGBody>
      </StyledSubtitleGrid>
      {changeOverPreviousPeriod.map(node => renderTrendCount(debugLoaded, node))}
    </Grid>
  );
}

export default EventsByType;

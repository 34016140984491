import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Tabs from '../../../interfaces/Tabs';

const StyledTabContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 18px;
`;

const StyledTab = styled(Button)`
  display: block;
  font-size: 24px;
  padding: 6px 0px 0px 0px;
  color: ${props => props.theme.navText};
  text-decoration: none;
  font-family: 'Trade Gothic Bold Condensed';
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  transition: 250ms;
  .MuiButton-label {
    margin-bottom: 5px;
  }
  &.MuiButton-root {
    line-height: 22px;
  }
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.navTextActive};
    border-bottom: 3px solid ${props => props.theme.navBlue};
  }
  &.active {
    color: ${props => props.theme.navTextActive};
    border-bottom: 3px solid ${props => props.theme.navBlue};
  }
  &.disabled {
    color: ${props => props.theme.navDisabled};
    &:hover {
      cursor: default;
    }
  }
`;

interface TabProps {
  selectedTab: string;
  handleTabChange: (tab: Tabs) => void;
}

function Tab({selectedTab, handleTabChange}: TabProps) {
  const handleClick = (tab: Tabs) => {
    handleTabChange(tab);
  };

  return (
    <StyledTabContainer>
      <StyledTab
        className={'custom' === selectedTab ? 'active' : ''}
        onClick={() => handleClick(Tabs.CUSTOM)}
      >
        Custom View
      </StyledTab>
      <StyledTab
        className={'na' === selectedTab ? 'active' : ''}
        onClick={() => handleClick(Tabs.NA)}
      >
        NA
      </StyledTab>
      <StyledTab
        className={'emea' === selectedTab ? 'active' : ''}
        onClick={() => handleClick(Tabs.EMEA)}
      >
        EMEA
      </StyledTab>
      <StyledTab
        className={'gc' === selectedTab ? 'active' : ''}
        onClick={() => handleClick(Tabs.GC)}
      >
        GC
      </StyledTab>
      <StyledTab
        className={'apla' === selectedTab ? 'active' : ''}
        onClick={() => handleClick(Tabs.APLA)}
      >
        APLA
      </StyledTab>
    </StyledTabContainer>
  );
}

export default Tab;

import React, {useEffect, useState, ReactNode} from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface SnackbarProps {
  children?: ReactNode;
  alertType?: 'error' | 'warning' | 'info' | 'success';
}

export default function Snackbar({children, alertType = 'info'}: SnackbarProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (children !== null) {
      setIsOpen(true);
    }
  }, [children]);

  return (
    <MuiSnackbar open={isOpen} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertType}>
        {children}
      </Alert>
    </MuiSnackbar>
  );
}

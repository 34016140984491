const timeZone = {
  'America/Los_Angeles': {
    jsDate: 'America/Los_Angeles',
    zoneShort: 'PDT',
    jsZone: 'Pacific Daylight Time (GMT-7)',
  },
  'America/Tijuana': {
    jsDate: 'America/Tijuana',
    zoneShort: 'PDT',
    jsZone: 'Pacific Daylight Time (GMT-7)',
  },
  'America/Phoenix': {
    jsDate: 'America/Phoenix',
    zoneShort: 'MDT',
    jsZone: 'Mountain Daylight Time (GMT-6)',
  },
  'America/Denver': {
    jsDate: 'America/Denver',
    zoneShort: 'MDT',
    jsZone: 'Mountain Daylight Time (GMT-6)',
  },
  'America/Boise': {
    jsDate: 'America/Boise',
    zoneShort: 'MDT',
    jsZone: 'Mountain Daylight Time (GMT-6)',
  },
  'America/Chicago': {
    jsDate: 'America/Chicago',
    zoneShort: 'CDT',
    jsZone: 'Central Daylight Time (GMT-5)',
  },
  'America/Mexico_City': {
    jsDate: 'America/Mexico_City',
    zoneShort: 'CDT',
    jsZone: 'Central Daylight Time (GMT-5)',
  },
  'America/New_York': {
    jsDate: 'America/New_York',
    zoneShort: 'EST',
    jsZone: 'Eastern Standard Time (GMT-4)',
  },
  'America/Kentucky/Louisville': {
    jsDate: 'America/Kentucky/Louisville',
    zoneShort: 'EST',
    jsZone: 'Eastern Standard Time (GMT-4)',
  },
  'America/Detroit': {
    jsDate: 'America/Detroit',
    zoneShort: 'EDT',
    jsZone: 'Eastern Daylight Time (GMT-4)',
  },
  'America/Indiana/Indianapolis': {
    jsDate: 'America/Indiana/Indianapolis',
    zoneShort: 'EDT',
    jsZone: 'Eastern Daylight Time (GMT-4)',
  },
  'America/Puerto_Rico': {
    jsDate: 'America/Puerto_Rico',
    zoneShort: 'AST',
    jsZone: 'Atlantic Standard Time (GMT-4)',
  },
  'Asia/Tokyo': {
    jsDate: 'Asia/Tokyo',
    zoneShort: 'TST',
    jsZone: 'Tokyo Standard Time (GMT+9)',
  },
  'Tokyo Standard Time': {
    jsDate: 'Tokyo Standard Time',
    zoneShort: 'TST',
    jsZone: 'Tokyo Standard Time (GMT+9)',
  },
  'Asia/Hong_Kong': {
    jsDate: 'Asia/Hong_Kong',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'China Standard Time': {
    jsDate: 'Asia/Shanghai',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Asia/Shanghai': {
    jsDate: 'Asia/Shanghai',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Asia/Taipei': {
    jsDate: 'Asia/Taipei',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Taipei Standard Time': {
    jsDate: 'Asia/Taipei',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Asia/Krasnoyarsk': {
    jsDate: 'Asia/Krasnoyarsk',
    zoneShort: 'KST',
    jsZone: 'Krasnoyarsk Standard Time (GMT+7)',
  },
  'Asia/Chongqing': {
    jsDate: 'Asia/Chongqing',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Asia/Harbin': {
    jsDate: 'Asia/Harbin',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Asia/Macau': {
    jsDate: 'Asia/Macau',
    zoneShort: 'CST',
    jsZone: 'China Standard Time (GMT+8)',
  },
  'Australia/Brisbane': {
    jsDate: 'Australia/Brisbane',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'Australia/Sydney': {
    jsDate: 'Australia/Sydney',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'Australia/Melbourne': {
    jsDate: 'Australia/Melbourne',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'Australia/NSW': {
    jsDate: 'Australia/NSW',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'Asia/Bangkok': {
    jsDate: 'Asia/Bangkok',
    zoneShort: 'ICT',
    jsZone: 'Indochina Time (GMT+7)',
  },
  'Asia/Kuala_Lumpur': {
    jsDate: 'Asia/Kuala_Lumpur',
    zoneShort: 'MYT',
    jsZone: 'Malaysia Time (GMT+8)',
  },
  'Asia/Singapore': {
    jsDate: 'Asia/Singapore',
    zoneShort: 'SGT',
    jsZone: 'Singapore Time (GMT+8)',
  },
  'America/Sao_Paulo': {
    jsDate: 'America/Sao_Paulo',
    zoneShort: 'BRT',
    jsZone: 'Brasilia Time (GMT-3)',
  },
  'Atlantic/St_Helena': {
    jsDate: 'Atlantic/St_Helena',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'America/Bahia': {
    jsDate: 'America/Bahia',
    zoneShort: 'BRT',
    jsZone: 'Brasilia Time (GMT-3)',
  },
  'America/Recife': {
    jsDate: 'America/Recife',
    zoneShort: 'BRT',
    jsZone: 'Brasilia Time (GMT-3)',
  },
  'America/Fortaleza': {
    jsDate: 'America/Fortaleza',
    zoneShort: 'BRT',
    jsZone: 'Brasilia Time (GMT-3)',
  },
  'America/Santiago': {
    jsDate: 'America/Santiago',
    zoneShort: 'CLT',
    jsZone: 'Chile Standard Time (GMT-4)',
  },
  'Pacific SA Standard Time': {
    jsDate: 'America/Fortaleza',
    zoneShort: 'BRT',
    jsZone: 'Brasilia Time (GMT-3)',
  },
  'America/Argentina/Buenos_Aires': {
    jsDate: 'America/Argentina/Buenos_Aires',
    zoneShort: 'ART',
    jsZone: 'Argentina Time (GMT-3)',
  },
  'Central Standard Time (Mexico)': {
    jsDate: 'America/Mexico_City',
    zoneShort: 'CDT',
    jsZone: 'Central Daylight Time (GMT-5)',
  },
  'America/Cancun': {
    jsDate: 'America/Cancun',
    zoneShort: 'EST',
    jsZone: 'Eastern Standard Time (GMT-5)',
  },
  'America/Hermosillo': {
    jsDate: 'America/Hermosillo',
    zoneShort: 'MST',
    jsZone: 'Mountain Standard Time (GMT-7)',
  },
  'America/Monterrey': {
    jsDate: 'America/Monterrey',
    zoneShort: 'CDT',
    jsZone: 'Central Daylight Time (GMT-5)',
  },
  'Eastern Standard Time': {
    jsDate: 'America/New_York',
    zoneShort: 'EDT',
    jsZone: 'Eastern Standard Time (GMT-4)',
  },
  'Central Standard Time': {
    jsDate: 'America/Chicago',
    zoneShort: 'CDT',
    jsZone: 'Central Standard Time (GMT-5)',
  },
  'America/Houston': {
    jsDate: 'America/Chicago',
    zoneShort: 'CST',
    jsZone: 'Central Standard Time (GMT-5)',
  },
  'Pacific Standard Time': {
    jsDate: 'America/Los_Angeles',
    zoneShort: 'PDT',
    jsZone: 'Pacific Standard Time (GMT-7)',
  },
  'US Mountain Standard Time': {
    jsDate: 'America/Denver',
    zoneShort: 'MDT',
    jsZone: 'Mountain Standard Time (GMT-6)',
  },
  'Europe/London': {
    jsDate: 'Europe/London',
    zoneShort: 'BST',
    jsZone: 'British Summer Time (GMT+0)',
  },
  'GMT Standard Time': {
    jsDate: 'Europe/London',
    zoneShort: 'BST',
    jsZone: 'British Summer Time (GMT+0)',
  },
  'Europe/Amsterdam': {
    jsDate: 'Europe/Amsterdam',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Brussels': {
    jsDate: 'Europe/Brussels',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Madrid': {
    jsDate: 'Europe/Madrid',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Paris': {
    jsDate: 'Europe/Paris',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Zurich': {
    jsDate: 'Europe/Zurich',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Lisbon': {
    jsDate: 'Europe/Lisbon',
    zoneShort: 'WEST',
    jsZone: 'Western European Summer Time (GMT+1)',
  },
  'Europe/Vienna': {
    jsDate: 'Europe/Vienna',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Dublin': {
    jsDate: 'Europe/Dublin',
    zoneShort: 'IST',
    jsZone: 'Irish Standard Time (GMT+1)',
  },
  'Europe/Berlin': {
    jsDate: 'Europe/Berlin',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Rome': {
    jsDate: 'Europe/Rome',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'W. Europe Standard Time': {
    jsDate: 'Europe/Amsterdam',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Prague': {
    jsDate: 'Europe/Prague',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Warsaw': {
    jsDate: 'Europe/Warsaw',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Budapest': {
    jsDate: 'Europe/Budapest',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Stockholm': {
    jsDate: 'Europe/Stockholm',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Copenhagen': {
    jsDate: 'Europe/Copenhagen',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Central European Standard Time': {
    jsDate: 'Europe/Amsterdam',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Moscow': {
    jsDate: 'Europe/Moscow',
    zoneShort: 'MSK',
    jsZone: 'Moscow Standard Time (GMT+3)',
  },
  'Russian Standard Time': {
    jsDate: 'Europe/Moscow',
    zoneShort: 'MSK',
    jsZone: 'Moscow Standard Time (GMT+3)',
  },
  'Europe/Athens': {
    jsDate: 'Europe/Athens',
    zoneShort: 'EEST',
    jsZone: 'Eastern European Summer Time (GMT+3)',
  },
  'Europe/Istanbul': {
    jsDate: 'Europe/Istanbul',
    zoneShort: 'TRT',
    jsZone: 'Turkish Time (GMT+3)',
  },
  'Atlantic/Canary': {
    jsDate: 'Atlantic/Canary',
    zoneShort: 'AEST',
    jsZone: 'Australian Eastern Standard Time (GMT+10)',
  },
  'Asia/Yekaterinburg': {
    jsDate: 'Asia/Yekaterinburg',
    zoneShort: 'YEKT',
    jsZone: 'Yekaterinburg Time (GMT+5)',
  },
  'Europe/Zagreb': {
    jsDate: 'Europe/Zagreb',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Vatican': {
    jsDate: 'Europe/Vatican',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Monaco': {
    jsDate: 'Europe/Monaco',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Europe/Luxembourg': {
    jsDate: 'Europe/Luxembourg',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
  'Asia/Jerusalem': {
    jsDate: 'Asia/Jerusalem',
    zoneShort: 'IDT',
    jsZone: 'Israel Daylight Time (GMT+3)',
  },
  'Europe/Samara': {
    jsDate: 'Europe/Samara',
    zoneShort: 'SAMT',
    jsZone: 'Samara Time (GMT+4)',
  },
  'N. Central Asia Standard Time': {
    jsDate: 'Asia/Almaty',
    zoneShort: 'NCAST',
    jsZone: 'N. Central Asia Standard Time (GMT+6)',
  },
  'Asia/Novosibirsk': {
    jsDate: 'Asia/Novosibirsk',
    zoneShort: 'NOVT',
    jsZone: 'Novosibirsk Time (GMT+7)',
  },
  'Africa/Johannesburg': {
    jsDate: 'Africa/Johannesburg',
    zoneShort: 'SAST',
    jsZone: 'South Africa Standard Time (GMT+2)',
  },
  'South Africa Standard Time': {
    jsDate: 'Africa/Johannesburg',
    zoneShort: 'SAST',
    jsZone: 'South Africa Standard Time (GMT+2)',
  },
  'Europe/Oslo': {
    jsDate: 'Europe/Oslo',
    zoneShort: 'CEST',
    jsZone: 'Central European Summer Time (GMT+2)',
  },
};
export default timeZone;

import theme from '../../ui/theme/theme';

function getLocalTheme() {
  if (localStorage.getItem('theme') === null) {
    return 'default';
  } else {
    // return localStorage.getItem('theme');
    return 'default';
  }
}

export const initialState = {
  select: getLocalTheme(),
  theme: theme,
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THEME_SELECT_DEFAULT':
      localStorage.setItem('theme', 'default');
      return {
        ...state,
        select: 'default',
      };
    case 'THEME_SELECT_LIGHT':
      localStorage.setItem('theme', 'light');
      return {
        ...state,
        select: 'light',
      };
    case 'THEME_SELECT_DARK':
      localStorage.setItem('theme', 'dark');
      return {
        ...state,
        select: 'dark',
      };
    case 'THEME_SELECT_LIGHT_BLUE':
      localStorage.setItem('theme', 'lightBlue');
      return {
        ...state,
        select: 'lightBlue',
      };
    case 'THEME_SELECT_VOLT':
      localStorage.setItem('theme', 'volt');
      return {
        ...state,
        select: 'volt',
      };
    default:
      return state;
  }
};

export default themeReducer;

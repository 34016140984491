import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import SGBody from 'components/Typography/SGBody';
import {SearchItem, getSwitchConnectivityParamsPartialUrlFromSearchItem} from '../SiteIDSearch';

const StyledContainer = styled.div`
  background-color: ${props => props.theme.cardBg};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.2), 0px 1px 1px 0px rgba(255, 255, 255, 0.14),
    0px 1px 3px 0px rgba(255, 255, 255, 0.12);
  max-height: 70vh;
  max-width: 20.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const StyledSearchSuggestion = styled.ul`
  padding-left: 0px;
  margin: 0px;
`;

const StyledSearchItems = styled.li`
  color: ${props => props.theme.altText};
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
  overflow: hidden;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  font-size: 0.8rem;
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  width: 16.5rem;
  padding: 8px 10px 8px 54px;
  &:visited {
    color: ${props => props.theme.subtleText};
    text-decoration: none;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.altText};
    text-decoration: none;
    background-color: ${props => props.theme.tableRow};
  }
  ${StyledSearchItems}:first-of-type > & {
    /* color: ${props => props.theme.altText}; */
    /* background-color: ${props => props.theme.tableRow}; */
  }
`;

const StyledSubInfo = styled.div`
  display: flex;
`;

const StyledSGInfo = styled(SGBody)`
  margin-right: 6px;
`;

const siteTypeLabel: Record<string, string> = {
  DC: 'DC',
  retail: 'Retail',
  showroom: 'Showroom',
  idf: 'DC-IDF',
};

const getSiteTypeLabel = (siteType: string) => {
  const recordKey = siteType.toLowerCase();
  return siteTypeLabel[recordKey] || siteType;
};

const siteStatusLabel: Record<string, string> = {
  preopen: 'Pre-Open',
  open: 'Open',
  closed: 'Closed',
};

const getSiteStatusLabel = (siteStatus: string) => {
  const recordKey = siteStatus.toLowerCase();
  return siteStatusLabel[recordKey] || siteStatus;
};

interface SiteIdSearchSuggestionsProps {
  suggestedSearchItems: SearchItem[];
  urlType: string;
  clearUserInput: () => void;
  setSwitchError: (error: string) => void;
}

function SiteIdSearchSuggestions({
  suggestedSearchItems,
  urlType,
  clearUserInput,
  setSwitchError,
}: SiteIdSearchSuggestionsProps) {
  const numberOfStoresToDisplay = 30;

  return (
    <StyledContainer>
      <StyledSearchSuggestion>
        {suggestedSearchItems.slice(0, numberOfStoresToDisplay).map(searchItem => {
          const switchPartialUrl = getSwitchConnectivityParamsPartialUrlFromSearchItem(
            searchItem,
            setSwitchError
          );
          const toUrl: string = `/${urlType}${switchPartialUrl}`;
          return (
            <StyledSearchItems key={switchPartialUrl}>
              <StyledNavLink id={searchItem.siteId} to={toUrl} onClick={clearUserInput} exact>
                <SGBody variant={'b6'}>{searchItem.displayName}</SGBody>
                <StyledSubInfo>
                  <StyledSGInfo variant={'b6'} className={'subtle'}>
                    {searchItem.brand}
                  </StyledSGInfo>
                  <StyledSGInfo variant={'b6'} className={'subtle'}>
                    {searchItem.geo}
                  </StyledSGInfo>
                  <StyledSGInfo variant={'b6'} className={'subtle'}>
                    {getSiteTypeLabel(searchItem.siteType)}
                  </StyledSGInfo>
                  {searchItem.status && (
                    <StyledSGInfo variant={'b6'} className={'subtle'}>
                      {getSiteStatusLabel(searchItem.status)}
                    </StyledSGInfo>
                  )}
                  <StyledSGInfo variant={'b6'} className={'subtle'}>
                    {searchItem.siteId}
                  </StyledSGInfo>
                </StyledSubInfo>
              </StyledNavLink>
            </StyledSearchItems>
          );
        })}
      </StyledSearchSuggestion>
    </StyledContainer>
  );
}

export default SiteIdSearchSuggestions;

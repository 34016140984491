import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledTableText = styled(Typography)`
  font-family: 'Text Regular';
  font-size: 1rem;
  color: ${props => props.theme.text};
  &.subtle {
    color: ${props => props.theme.tableText};
  }
  &.red {
    color: ${props => props.theme.red};
    font-family: 'Text Bold';
  }
  &.orange {
    color: ${props => props.theme.orange};
    font-family: 'Text Bold';
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.green {
    color: ${props => props.theme.green};
  }
`;

function TableText(props) {
  const {className, children} = props;

  return <StyledTableText className={className}>{children}</StyledTableText>;
}

export default TableText;

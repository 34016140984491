import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Dialog, DialogTitle} from '@material-ui/core';
import SGButton from 'components/CTA/SGButton';
import {useHistory} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';
import {setUserInfo} from 'redux/actions';
import {useDispatch} from 'react-redux';

//#region styled components
const StandardTextDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  padding-top: 28px;
  padding-bottom: 4px;
  flex-direction: row;
  justify-content: center;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-style: bold;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(SGButton)`
  height: 30px;
  display: flex;
  align-items: center;
  margin: 8px;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${props => props.theme.cardBg};
    color: ${props => props.theme.text};
  }
`;
//#endregion styled components

function NoAccessDialog() {
  const {oktaAuth} = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const onClose = () => {
    history.replace('/');
  };

  useEffect(() => {
    const refreshUserInfo = async () => {
      const user = await oktaAuth.getUser();
      dispatch(setUserInfo(user));
    };

    refreshUserInfo();
  }, [dispatch, oktaAuth]);

  return (
    <StyledDialog onClose={onClose} aria-labelledby="simple-dialog-title" open={true}>
      <StyledDialogTitle id="simple-dialog-title">Unauthorized</StyledDialogTitle>
      <StandardTextDiv>
        You don't have access to this page. If you think you should have access, contact the RSS
        team.
      </StandardTextDiv>
      <StyledButtonContainerDiv>
        <StyledButton onClick={onClose}>Return to home page</StyledButton>
      </StyledButtonContainerDiv>
    </StyledDialog>
  );
}

export default NoAccessDialog;

import React, {Fragment, forwardRef} from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from 'components/Paper';
import SwitchModalHeader from './SwitchModalHeader';
import ModalItem from './ModalItem';
import SwitchPortHistoryAccordian from './SwitchPortHistoryAccordian';

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const StyledPaper = styled(Paper)`
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 800px;
`;

const StyledPortInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledConnectedDeviceInfoGrid = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  margin-bottom: 16px;
`;

const StyledPortHistoryContainer = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
`;

const StyledPortInfoSeparator = styled.div`
  height: 3px;
  background-color: ${props => props.theme.grey}33;
  margin: 8px 0;
`;

const StyledConnectedDeviceInfoSeparator = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  height: 1px;
  background-color: ${props => props.theme.grey}33;
  margin-top: -8px;
`;

const StyledPortHistorySeparator = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  height: 1px;
  background-color: ${props => props.theme.grey}33;
`;

const StyledGridMessage = styled.p`
  text-align: center;
  grid-column: 1 / ${prop => prop.span};
`;

const renderConnectedDevices = connectedDevices => {
  if (connectedDevices == null || connectedDevices.length < 1) {
    return <StyledGridMessage span={5}>No devices connected.</StyledGridMessage>;
  }

  return connectedDevices.map((device, index) => {
    return (
      <Fragment key={index}>
        <ModalItem value={device.macAddress} />
        <ModalItem value={device.deviceType} />
        <ModalItem value={device.deviceName} />
        <ModalItem value={device.manufacturer} columnSpan={2} />
      </Fragment>
    );
  });
};

const formatDateString = date => {
  if (date == null) return 'Unknown Date';
  const regExSplit = /[T.]+/;
  let [YMD, time] = date.toISOString().split(regExSplit);

  return `${YMD} ${time} UTC`;
};

const renderPortHistory = portHistory => {
  if (portHistory?.snapshots == null || portHistory.snapshots.length < 1) {
    return <StyledGridMessage span={6}>No history found for this port.</StyledGridMessage>;
  }

  return portHistory.snapshots.map((snapshot, index) => {
    let deviceType = snapshot.deviceType.toUpperCase() === 'NULL' ? 'Unknown' : snapshot.deviceType;

    return (
      <Fragment key={index}>
        <ModalItem value={formatDateString(snapshot.date)} />
        <ModalItem value={snapshot.macAddress} />
        <ModalItem value={deviceType} />
        <ModalItem value={snapshot.deviceName} />
        <ModalItem value={snapshot.manufacturer} />
      </Fragment>
    );
  });
};

const ForwardPaper = forwardRef((props, ref) => <StyledPaper {...props} innerRef={ref} />);

function SwitchPortModal({data, isModalOpen, handleModalClose, portHistory}) {
  const {connectedDevices, portStatus} = data;

  return (
    <StyledModal
      aria-labelledby="switch-port-modal"
      aria-describedby="switch-port-modal"
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <ForwardPaper>
          <SwitchModalHeader portStatus={portStatus} handleModalClose={handleModalClose} />
          <StyledPortInfoContainer>
            <ModalItem subTitle={'Switch'} value={data.switchNumber} big />
            <ModalItem subTitle={'Port'} value={data.portNumber} big />
            <ModalItem subTitle={'Port Description'} value={data.portDescription} big />
            <ModalItem subTitle={'VLAN'} value={data.vlanNumber} big />
          </StyledPortInfoContainer>
          <StyledPortInfoSeparator />
          <StyledConnectedDeviceInfoGrid>
            <ModalItem subTitle={'Mac Address'} />
            <ModalItem subTitle={'Device Type'} />
            <ModalItem subTitle={'Device Name'} />
            <ModalItem subTitle={'Manufacturer'} columnSpan={2} />
            <StyledConnectedDeviceInfoSeparator />
            {renderConnectedDevices(connectedDevices)}
          </StyledConnectedDeviceInfoGrid>
          <SwitchPortHistoryAccordian header={'Port History'}>
            <StyledPortHistoryContainer>
              <ModalItem subTitle={'Check-in Date'} />
              <ModalItem subTitle={'Mac Address'} />
              <ModalItem subTitle={'Device Type'} />
              <ModalItem subTitle={'Device Name'} />
              <ModalItem subTitle={'Manufacturer'} />
              <StyledPortHistorySeparator />
              {renderPortHistory(portHistory)}
            </StyledPortHistoryContainer>
          </SwitchPortHistoryAccordian>
        </ForwardPaper>
      </Fade>
    </StyledModal>
  );
}

export default SwitchPortModal;

const countEvents = function parseNumberOfEventsBetweenEpoch(
  arrrayEpochCount,
  epochStart,
  epochEnd
) {
  if (arrrayEpochCount.length === 0)
    return [
      {
        eventName: 'none',
        totalEvents: 0,
      },
    ];

  const countedEvents = arrrayEpochCount.map(node => {
    const eventName = node.name;
    const totalEvents = node.data.reduce((previousValue, currentValue) => {
      const [currentEpoch, currentCount] = currentValue;
      if (currentEpoch >= epochStart && currentEpoch <= epochEnd) {
        return previousValue + currentCount;
      } else {
        return previousValue;
      }
    }, 0);

    return {
      eventName: eventName,
      totalEvents: totalEvents,
    };
  });

  return countedEvents;
};

export default countEvents;

const destructureIdString = function destructureIdString(idString) {
  const splitId = idString.split('-');
  const splitLength = splitId.length;
  let eventType = '';
  switch (splitLength) {
    case 1:
      eventType = 'device';
      break;
    case 2:
      eventType = 'category';
      break;
    case 3:
      eventType = 'subCategory';
      break;
    default:
      eventType = 'invalidEvent';
  }

  return {
    eventType: eventType,
    deviceId: splitId.length >= 1 ? splitId[0] : undefined,
    catId: splitId.length >= 2 ? splitId[1] : undefined,
    subCatId: splitId.length >= 3 ? splitId[2] : undefined,
  };
};

export default destructureIdString;

import React, {useState, MouseEventHandler} from 'react';
import styled from 'styled-components';
import CardHeader from './CardHeader';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import CardSection from './CardSection';
import CardInfoGrid from './CardInfoGrid';
import IStoreData from '../../interfaces/IStoreData';
import CardInfoItem from './CardInfoItem';
import CardAlertAccordian from './CardAlertAccordian';
import exportAsImage from 'features/RRP/utilities/exportAsImage';

// import { mdiArrowExpand } from '@mdi/js';
import Icon from '@mdi/react';
import {mdiExportVariant} from '@mdi/js';
import {mdiClose} from '@mdi/js';

interface CardProps {
  storeData: IStoreData;
  defaultExpanded?: boolean;
  handleClose?: () => void;
  className?: string;
}

const StyledAccordion = styled(Accordion)`
  background-color: ${props => props.theme.cardBg};
  border-radius: 12px;
  color: ${props => props.theme.text};
  height: fit-content;
  &.defaultExpanded .MuiAccordionSummary-expandIcon {
    display: none;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

const StyledExpandIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.disabledStateBlue};
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.grey};
  transition: 500ms;
  &:first-of-type {
    margin-left: auto;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const StyledCloseButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  margin-left: 0;
`;

export default function Card({storeData, defaultExpanded, handleClose, className}: CardProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleExpand = (event: {}, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    exportAsImage('card' + storeData.storeNumber);
  };

  const handleCloseClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (handleClose !== undefined) {
      handleClose();
    }
  };

  return (
    <StyledAccordion
      defaultExpanded={defaultExpanded}
      id={'card' + storeData.storeNumber}
      className={`${className} ${defaultExpanded && 'defaultExpanded'}`}
      onChange={handleExpand}
    >
      <AccordionSummary
        expandIcon={<StyledExpandIcon />}
        aria-controls={'cardsum' + storeData.storeNumber}
        id={'cardsum' + storeData.storeNumber}
      >
        <>
          <CardHeader title={storeData.storeName} alert={storeData.alertValue.value} />
          {isExpanded && (
            <StyledButton type="button" onClick={handleClick} id={storeData.storeName}>
              <Icon path={mdiExportVariant} size={1} />
            </StyledButton>
          )}
          {defaultExpanded && (
            <StyledCloseButton type="button" onClick={handleCloseClick} id={storeData.storeName}>
              <Icon path={mdiClose} size={1} />
            </StyledCloseButton>
          )}
        </>
      </AccordionSummary>
      <StyledAccordionDetails>
        <CardSection title={'WAN Transport'} />
        <CardInfoGrid numItems={4}>
          <>
            <CardInfoItem
              title={'Internet A - Provisioned'}
              data={storeData.internetAProvisioned}
              alert={storeData.internetAProvisionedAlertValue}
            />
            <CardInfoItem
              title={'Internet A - Utilization'}
              data={storeData.internetAUtilization}
              alert={storeData.internetAUtilizationAlertValue}
            />
            {/* <CardInfoItem title={'WAN A - Designed'} data={'Internet - 100m / 100m Guaranteed'} /> */}
            {/* <CardInfoItem title={'WAN A - Deployed'} data={'Internet - 50m / 50m Guaranteed'} /> */}
            <CardInfoItem
              title={'Internet B - Provisioned'}
              data={storeData.internetBProvisioned}
              alert={storeData.internetBProvisionedAlertValue}
            />
            <CardInfoItem
              title={'Internet B - Utilization'}
              data={storeData.internetBUtilization}
              alert={storeData.internetBUtilizationAlertValue}
            />
            {/* <CardInfoItem title={'WAN B - Designed'} data={'Internet - 100m / 100m Guaranteed'} /> */}
            {/* <CardInfoItem title={'WAN B - Deployed'} data={'Internet - 50m / 50m Guaranteed'} /> */}
            <CardInfoItem
              title={'Cellular Backup Status'}
              data={storeData.cellularBackup}
              alert={storeData.cellularBackupAlertValue}
            />
            <CardInfoItem title={''} data={''} />
          </>
        </CardInfoGrid>
        <CardAlertAccordian
          alerts={[
            {
              title: 'Internet A - Provisioned',
              alert: storeData.internetAProvisionedAlertValue,
            },
            {
              title: 'Internet A - Utilization',
              alert: storeData.internetAUtilizationAlertValue,
            },
            {
              title: 'Internet B - Provisioned',
              alert: storeData.internetBProvisionedAlertValue,
            },
            {
              title: 'Internet B - Utilization',
              alert: storeData.internetBUtilizationAlertValue,
            },
            {
              title: 'Cellular Backup Status',
              alert: storeData.cellularBackupAlertValue,
            },
          ]}
        />
        <CardSection title={'SDWAN'} />
        <CardInfoGrid numItems={2}>
          <>
            <CardInfoItem
              title={'Hardware - Provisioned'}
              data={storeData.SDWANHardwareProvisioned}
              alert={storeData.SDWANHardwareProvisionedAlertValue}
            />
            <CardInfoItem title={''} data={''} />
            {/* <CardInfoItem
              title={'Hardware - Utilization'}
              data={storeData.SDWANHardwareUtilization}
              alert={storeData.SDWANHardwareUtilizationAlertValue}
            /> */}
            {/* <CardInfoItem title={'Router Model - Designed'} data={'VeloCloud Edge 520-V'} /> */}
            {/* <CardInfoItem title={'Router Count - Designed'} data={'VeloCloud Edge 520-V'} /> */}
            <CardInfoItem
              title={'Hardware - Effectiveneww (QOE)'}
              data={storeData.SDWANEffectivenessQoE}
              alert={storeData.SDWANEffectivenessQoEAlertValue}
            />
            <CardInfoItem
              title={'Internet B - Utilization'}
              data={storeData.internetBUtilization}
              alert={storeData.internetBUtilizationAlertValue}
            />
            {/* <CardInfoItem title={'Router Model - Deployed'} data={'2'} /> */}
            {/* <CardInfoItem title={'Router Count - Deployed'} data={'2'} /> */}
          </>
        </CardInfoGrid>
        <CardAlertAccordian
          alerts={[
            {
              title: 'Hardware - Provisioned',
              alert: storeData.SDWANHardwareProvisionedAlertValue,
            },
            // {
            //   title: 'Hardware - Utilization',
            //   alert: storeData.SDWANHardwareUtilizationAlertValue,
            // },
            {
              title: 'Hardware - Effectiveneww (QOE)',
              alert: storeData.SDWANEffectivenessQoEAlertValue,
            },
            {
              title: 'Internet B - Utilization',
              alert: storeData.internetBUtilizationAlertValue,
            },
          ]}
        />
        <CardSection title={'Switching'} />
        <CardInfoGrid numItems={6}>
          <>
            <CardInfoItem
              title={'Hardware - Provisioned'}
              data={storeData.switchingHardwareProvisioned}
              alert={storeData.switchingHardwareProvisionedAlertValue}
            />
            <CardInfoItem
              title={'Hardware - Utilization'}
              data={storeData.switchingHardwarePortUtilization}
              alert={storeData.switchingHardwarePortUtilizationAlertValue}
            />
            {/* <CardInfoItem
              title={'Access Switch Model - Designed'}
              data={'Cisco Catalyst 9300-48U'}
            /> */}
            {/* <CardInfoItem
              title={'Access Switch Count - Designed'}
              data={'Cisco Catalyst 3650-48PS-L'}
            /> */}
            <CardInfoItem
              title={'Hardware AP Port Utilization'}
              data={storeData.switchingHardwareAPPortUtilization}
              alert={storeData.switchingHardwareAPPortUtilizationAlertValue}
            />
            <CardInfoItem
              title={'Hardware POE Budget'}
              data={storeData.switchingHardwarePoEBudget}
              alert={storeData.switchingHardwarePoEBudgetAlertValue}
            />
            <CardInfoItem title={''} data={''} />
            <CardInfoItem title={''} data={''} />
            <CardInfoItem
              title={'Hardware POE at Capacity'}
              data={storeData.switchingHardwarePoEAtCapacity}
              alert={storeData.switchingHardwarePoEAtCapacityAlertValue}
            />
          </>
        </CardInfoGrid>
        <CardAlertAccordian
          alerts={[
            {
              title: 'Hardware - Provisioned',
              alert: storeData.switchingHardwareProvisionedAlertValue,
            },
            {
              title: 'Hardware - Utilization',
              alert: storeData.switchingHardwarePortUtilizationAlertValue,
            },
            {
              title: 'Hardware AP Port Utilization',
              alert: storeData.switchingHardwareAPPortUtilizationAlertValue,
            },
            {
              title: 'Hardware POE Budget',
              alert: storeData.switchingHardwarePoEBudgetAlertValue,
            },
            {
              title: 'Hardware POE at Capacity',
              alert: storeData.switchingHardwarePoEAtCapacityAlertValue,
            },
          ]}
        />
        <CardSection title={'Wireless'} />
        <CardInfoGrid numItems={4}>
          <>
            <CardInfoItem
              title={'AP Hardware - Provisioned'}
              data={storeData.wirelessAPHardwareProvisioned}
              alert={storeData.wirelessAPHardwareProvisionedAlertValue}
            />
            <CardInfoItem
              title={'AP Up Status'}
              data={storeData.wirelessAPUpStatus}
              alert={storeData.wirelessAPUpStatusAlertValue}
            />
            {/* <CardInfoItem title={'Access Point Model - Designed'} data={'Cisco Catalyst 9102AXi'} /> */}
            {/* <CardInfoItem title={'Access Point Count - Designed'} data={'Cisco Aironet 2702I'} /> */}
            <CardInfoItem
              title={'Client Health Average'}
              data={storeData.wirelessClientHealthAvg}
              alert={storeData.wirelessClientHealthAvgAlertValue}
            />
            <CardInfoItem
              title={'AP Health'}
              data={storeData.wirelessAPHealth}
              alert={storeData.wirelessAPHealthAlertValue}
            />
            {/* <CardInfoItem title={'Access Point Model - Deployed'} data={'7'} /> */}
            {/* <CardInfoItem title={'Access Point Count- Deployed'} data={'7'} /> */}
          </>
        </CardInfoGrid>
        <CardAlertAccordian
          alerts={[
            {
              title: 'AP Hardware - Provisioned',
              alert: storeData.wirelessAPHardwareProvisionedAlertValue,
            },
            {
              title: 'AP Up Status',
              alert: storeData.wirelessAPUpStatusAlertValue,
            },
            {
              title: 'Client Health Average',
              alert: storeData.wirelessClientHealthAvgAlertValue,
            },
            {
              title: 'AP Health',
              alert: storeData.wirelessAPHealthAlertValue,
            },
          ]}
        />
      </StyledAccordionDetails>
      <Divider />
    </StyledAccordion>
  );
}

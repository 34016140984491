import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchDashboardTrending, activeEventLoadTreeData} from '../../../redux/actions';
import Grid from '@material-ui/core/Grid';
import StyledPaperTitle from '../../PaperTitle';
import CircleChart from './CircleChart';
import DataSummary from './DataSummary';
import TreeMenu from './TreeMenu';
// import {mockTreeData, mockCircleData} from './mockData';

const ActiveEvent = styled.div``;

function ActiveEvents({param = 'na', debugLoaded}) {
  const {authState} = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const generateParam = function createGeoOrStoreNumber(param) {
      if (param === 'na' || param === 'emea' || param === 'gc' || param === 'apla') {
        return `geo=${param}`;
      } else if (typeof param === 'number') {
        return `storenumber=${param}`;
      } else {
        console.log('ActiveEvent', 'unexpected parameter for api: ' + param);
      }
    };

    // fetch component data on authentication
    if (authState.isAuthenticated && authState.accessToken !== undefined) {
      const {accessToken} = authState;

      const value = generateParam(param);
      dispatch(fetchDashboardTrending(accessToken, value));
    }
  }, [authState, dispatch, param]);

  // Dashboard Trending Data
  const dashboardTrendingDataRetrieved = useSelector(
    state => state.data.app.dashboardTrending.dataRetrieved
  );
  const dashboardTrendingData = useSelector(state => state.data.app.dashboardTrending.data);
  const rdxComponentActiveEvent = useSelector(state => state.componentActiveEvent);
  const [dataReady, setDataReady] = useState(false);
  const [circleState, setCircleState] = useState();
  const [treeData, setTreeData] = useState([]);
  const [summaryState, setSummaryState] = useState({
    percent: 75,
    totalEvents: 0,
    deviceCount: 100,
    storeCount: 100,
  });

  useEffect(() => {
    // Dev Overide
    debugLoaded ? setDataReady(true) : setDataReady(false);
  }, [debugLoaded]);

  useEffect(() => {
    const calculateTotalPercent = function calculateFromAPI(nodes) {
      let totalCount = 0;
      nodes.map(node => (totalCount += node.count));
      return totalCount;
    };

    if (dashboardTrendingDataRetrieved) {
      let totalNumberOfEvents = 0;
      let formatStateData = new Promise(function (resolve, reject) {
        // optional chaining is used to evaluate the object.
        // At the time of usage, optional chaining is at Stage 4 of the ECMA proposal process
        if (dashboardTrendingData?.tree !== undefined) {
          resolve(dashboardTrendingData.tree);
        } else {
          reject('error');
        }
      });

      formatStateData
        .then(resolve => {
          setTreeData(resolve);
          dispatch(activeEventLoadTreeData(resolve));
          totalNumberOfEvents = calculateTotalPercent(resolve);
        })
        .then(() => {
          setSummaryState({
            percent: 100,
            totalEvents: totalNumberOfEvents,
            deviceCount: 100,
            storeCount: 100,
          });
          setCircleState(dashboardTrendingData);
        })
        .then(() => {
          setDataReady(true);
        });
    }
  }, [dashboardTrendingDataRetrieved, dashboardTrendingData, dispatch]);

  useEffect(() => {
    const {totalEvents, selectedEventsCount} = rdxComponentActiveEvent;
    if (totalEvents > 0) {
      setSummaryState({
        percent: ((selectedEventsCount / totalEvents) * 100).toFixed(0),
        totalEvents: totalEvents,
        deviceCount: 100,
        storeCount: 100,
      });
    }
  }, [rdxComponentActiveEvent]);

  return (
    <ActiveEvent>
      <Grid container spacing={0} direction="row">
        <Grid item xs={12}>
          <StyledPaperTitle>
            {typeof param === 'number' ? 'Store ' : ''}
            {param} Active Events
          </StyledPaperTitle>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CircleChart geo={param} data={circleState} loaded={dataReady} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DataSummary
            percent={summaryState.percent}
            deviceCount={rdxComponentActiveEvent.selectedEventsCount}
            storeCount={rdxComponentActiveEvent.selectedStoresCount}
            loaded={dataReady}
          />
        </Grid>
        <Grid item xs={12}>
          <TreeMenu
            geoStore={param}
            loaded={dataReady}
            node={treeData}
            treeState={treeData}
            setTreeState={setTreeData}
          />
        </Grid>
      </Grid>
    </ActiveEvent>
  );
}

export default ActiveEvents;

import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';
import {useSelector} from 'react-redux';

const StyledLayout = styled.div`
  padding-bottom: 64px;
  position: relative;
  /* margin-left needs to match the width the the side nav */
  margin-left: 0px;
  transition: 0.25s;
  @media (min-width: 600px) {
    margin-left: 70px;
    &.menu-expanded {
      margin-left: 210px;
    }
  }

  /* padding-top needs to match the header height */
  /* padding-top: 62px; */
`;

function Layout(props) {
  const {children, className, match, breadCrumb, headerContent, disableMaxWidth} = props;
  const expand = useSelector(state => state.navigation);

  return (
    <StyledLayout
      id={'qc-layout-container'}
      className={(expand ? 'menu-expanded ' : '') + className}
    >
      <Navigation />
      <Header
        breadCrumb={breadCrumb}
        match={match}
        headerContent={headerContent}
        disableMaxWidth={disableMaxWidth}
      />

      {children}
    </StyledLayout>
  );
}

export default Layout;

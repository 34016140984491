import React from 'react';
import styled from 'styled-components';
import SwitchPortRow from './SwitchPortRow';
import SwitchDiagramTitle from './SwitchDiagramTitle';
import SGBody from 'components/Typography/SGBody';
import {Port} from '../interfaces/SwitchConnectivityState';
import {PortHistorySwitch} from '../interfaces/PortHistoryState';

const StyledDiagramContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff26;
`;

const StyledPortRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledSGBody = styled(SGBody)`
  margin-bottom: 1rem;
`;

interface SwitchDiagramProps {
  switchNumber: number;
  ports: Port[];
  portHistorySwitch: PortHistorySwitch | null;
}

export default function SwitchDiagram({
  ports,
  switchNumber,
  portHistorySwitch,
}: SwitchDiagramProps) {
  return (
    <StyledDiagramContainer>
      <StyledHeaderContainer>
        <SwitchDiagramTitle switchNumber={switchNumber} />
        <StyledSGBody variant={'b4'}>Click on any port for details</StyledSGBody>
        <StyledPortRowContainer>
          <SwitchPortRow rowNumber={1} ports={ports} portHistorySwitch={portHistorySwitch} />
          <SwitchPortRow rowNumber={2} ports={ports} portHistorySwitch={portHistorySwitch} />
        </StyledPortRowContainer>
      </StyledHeaderContainer>
    </StyledDiagramContainer>
  );
}

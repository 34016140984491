import React from 'react';
import styled from 'styled-components';
import SGTitle from 'components/Typography/SGTitle';
import AlertIcon from 'features/RRP/components/RRPTable/TableStructure/AlertIcon';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

interface AlertCountProps {
  alertType: AlertSeverityEnum;
  count?: number;
  className?: string;
}

const StyledAlertCount = styled.div`
  border: 1px solid ${props => props.theme.grey};
  border-radius: 4px;
  padding: 4px;
  margin: 4px 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 3.25rem;
  &:first-of-type {
    margin-left: 0px;
  }

  &.green {
    border-color: ${props => props.theme.green};
  }
  &.yellow {
    border-color: ${props => props.theme.yellow};
  }
  &.orange {
    border-color: ${props => props.theme.orange};
  }
  &.red {
    border-color: ${props => props.theme.red};
  }
  &.grey {
    border-color: ${props => props.theme.grey};
  }
`;

const StyledAlertIcon = styled(AlertIcon)`
  height: 1rem;
  width: 1rem;
`;

const generateAlertClassName = function generateAlertClassName(alertSeverity: AlertSeverityEnum) {
  let colorType;
  if (alertSeverity === AlertSeverityEnum.CRITICAL) {
    colorType = 'red';
  } else if (alertSeverity === AlertSeverityEnum.MAJOR) {
    colorType = 'orange';
  } else if (alertSeverity === AlertSeverityEnum.MINOR) {
    colorType = 'yellow';
  } else if (alertSeverity === AlertSeverityEnum.HEALTHY) {
    colorType = 'green';
  } else {
    colorType = 'grey';
  }

  return {
    colorType,
  };
};

function AlertCount({alertType, count, className}: AlertCountProps) {
  const {colorType} = generateAlertClassName(alertType);
  return (
    <StyledAlertCount className={`${colorType} ${className}`}>
      <StyledAlertIcon alertValue={alertType} />
      <SGTitle variant={'t6'} className={'text-color'}>
        {count}
      </SGTitle>
    </StyledAlertCount>
  );
}

export default AlertCount;

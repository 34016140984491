import Inventories from './Inventories';
import useInventories from './hooks/useInventories';
import useParamsInvetory from './hooks/useParamsInventory';
import type Tabs from './interfaces/Tabs';
import type FilterOptions from './interfaces/FilterOptions';

export {useInventories};
export {useParamsInvetory};
export type {Tabs};
export type {FilterOptions};
export default Inventories;

import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import PaperTitle from 'components/PaperTitle';
import Table from 'components/Tables/TableStructure/Table';
import crowdstrikeColumns from './crowdstrikeColumns';
import SiteFilter from './components/SiteFilter';
import FetchCrowdstrikeHook from './hooks/FetchCrowdstrikeHook';
import Paper from 'components/Paper';
import {Stores} from 'interfaces/Stores';

// check white space

function CrowdstrikeTable() {
  const [selectedStores, setSelectedStores] = useState<Stores>([]);
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const {data, isLoaded} = FetchCrowdstrikeHook({siteIds: siteIds});

  const handleStores = function handleStores(storeList: Stores) {
    if (storeList.length === 0) {
      setSelectedStores([]);
      setSiteIds([]);
    } else {
      setSelectedStores(storeList);
      const idsFromStores = storeList.map(store => store.SiteId);
      setSiteIds(idsFromStores);
    }
  };

  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
              <PaperTitle>Security - Crowdstrike Check</PaperTitle>
            </Grid>
            <Grid item xs={12}>
              <SiteFilter handleStores={handleStores} selectedStores={selectedStores} />
            </Grid>
            <Grid item xs={12}>
              <Table
                columns={crowdstrikeColumns}
                data={data}
                loaded={isLoaded}
                disablePagination
                showAll
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CrowdstrikeTable;

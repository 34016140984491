// import moment from "moment";

import SiteType from 'constants/SiteType';

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch: any) => {
  // const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  const buildDate = new Date(epoch);
  return (
    buildDate.toLocaleDateString() +
    '-' +
    buildDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
  );
};

export const getFullNameFromGeo = (geo: string) => {
  const normalizedGeo = geo.toLowerCase();

  switch (normalizedGeo) {
    case 'na':
      return 'North America';
    case 'emea':
      return 'Europe, Middle East & Africa';
    case 'gc':
      return 'Greater China';
    case 'apla':
      return 'Asia Pacific & Latin America';
    case '':
      return 'fetching geo';
    default:
      return geo + ' not listed';
  }
};

export function getSiteCodeFromSiteId(siteId: string) {
  return siteId.slice(3, 7);
}

export function getSiteTypeFromSiteId(siteId: string): SiteType {
  if (siteId === undefined || siteId.length < 3) return 'Default';

  const siteTypeChar = siteId[2];
  let siteType: SiteType;
  switch (siteTypeChar) {
    case 'R':
      siteType = 'Retail';
      break;
    case 'X':
      siteType = 'Distribution Center';
      break;
    case 'W':
      siteType = 'Showroom';
      break;
    default:
      siteType = 'Default';
      break;
  }
  return siteType;
}

import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import SGTitle from 'components/Typography/SGTitle';
import {useSelector} from 'react-redux';
import {
  rdxMinimumSiteInfoDataRetrieved,
  rdxMinimumSiteInfoData,
} from 'redux/reducers/minimumSiteInfoReducer';
import Site, {getSiteDisplayName} from 'interfaces/Site';
import SiteType from 'constants/SiteType';
import TextSkeleton from 'components/Skeletons/TextSkeleton';
import IdfSearchItem from '../../../components/SiteIDSearch/interfaces/IdfSearchItem';
import {getSiteCodeFromSiteId, getSiteTypeFromSiteId} from 'utils/utils';

const StyledSwitchHeaderTab = styled.div`
  min-height: 16px;
`;

const StyledSkeleton = styled(TextSkeleton)`
  width: 50rem;
`;

interface SwitchHeaderSubtitleProps {
  siteId: string | undefined;
  idfName: string | undefined;
}

function SwitchHeaderSubtitle({siteId, idfName}: SwitchHeaderSubtitleProps) {
  const isSitesLoaded: boolean = useSelector((state: any) =>
    rdxMinimumSiteInfoDataRetrieved(state)
  );
  const sites: Site[] = useSelector((state: any) => rdxMinimumSiteInfoData(state));
  const idfSearchItems: IdfSearchItem[] = useSelector(
    (state: any) => state.distinctIdfSearchItems.data
  );
  const isIdfSearchItemsLoaded: boolean = useSelector(
    (state: any) => state.distinctIdfSearchItems.dataRetrieved
  );
  const [switchHeaderSubtitle, setSwitchHeaderSubtitle] = useState<string>('');

  const setSubtitleForRetail = useCallback(
    (isStoresLoaded: boolean, sites: Site[], siteId: string) => {
      if (isStoresLoaded && sites.length > 0) {
        const site: Site | undefined = sites.find((store: Site) => store.siteId === siteId);
        if (site) {
          setSwitchHeaderSubtitle(getSiteDisplayName(site));
        } else {
          console.error(`Could not find store with siteId ${siteId}`);
          setSwitchHeaderSubtitle(`Could not find store with siteId ${siteId}`);
        }
      } else {
        setSwitchHeaderSubtitle('');
      }
    },
    []
  );

  const setSubtitleForDistributionCenter = useCallback(
    (
      idfName: string | undefined,
      isIdfSearchItemsLoaded: boolean,
      idfSearchItems: IdfSearchItem[],
      siteCode: string,
      siteId: string
    ) => {
      if (isIdfSearchItemsLoaded && idfSearchItems.length > 0) {
        if (idfName) {
          const idfSearchItem: IdfSearchItem | undefined = idfSearchItems.find(
            (item: IdfSearchItem) => item.idf === idfName && item.siteId === siteId
          );

          if (idfSearchItem) {
            const building = idfSearchItem.building;
            const buildingSubtitle =
              building !== null &&
              building !== '' &&
              building !== idfSearchItem.idf &&
              building !== 'Only Building'
                ? `- ${idfSearchItem.building}`
                : '';

            const opArea = idfSearchItem.operationalArea;
            const operationalAreaSubtitle =
              opArea !== null &&
              opArea !== '' &&
              opArea !== idfSearchItem.building &&
              opArea !== idfSearchItem.idf
                ? `- ${idfSearchItem.operationalArea}`
                : '';

            setSwitchHeaderSubtitle(
              `Distribution Center - ${siteCode} ${buildingSubtitle} ${operationalAreaSubtitle} - ${idfSearchItem.idf}`
            );
          } else {
            setSwitchHeaderSubtitle(`Could not find site details from IDF name: ${idfName}`);
            console.error(
              `Could not find IdfSearchItem with IDF name ${idfName} with site id: ${siteId}`
            );
          }
        } else {
          setSwitchHeaderSubtitle(`Could not find IDF name from URL`);
          console.error('Could not find IDF name from URL');
        }
      } else {
        setSwitchHeaderSubtitle('');
      }
    },
    []
  );

  useEffect(() => {
    if (siteId) {
      const siteType: SiteType = getSiteTypeFromSiteId(siteId);
      const siteCode: string = getSiteCodeFromSiteId(siteId);
      switch (siteType) {
        case 'Retail':
          setSubtitleForRetail(isSitesLoaded, sites, siteId);
          break;
        case 'Showroom':
          // todo: GRSTI-1988
          setSwitchHeaderSubtitle(`Showroom - ${siteCode}`);
          break;
        case 'Distribution Center':
          setSubtitleForDistributionCenter(
            idfName,
            isIdfSearchItemsLoaded,
            idfSearchItems,
            siteCode,
            siteId
          );
          break;
        case 'Default':
        default:
          setSwitchHeaderSubtitle('');
          break;
      }
    }
  }, [
    siteId,
    idfName,
    sites,
    isSitesLoaded,
    idfSearchItems,
    isIdfSearchItemsLoaded,
    setSubtitleForRetail,
    setSubtitleForDistributionCenter,
  ]);

  return (
    <StyledSwitchHeaderTab>
      {siteId && (
        <SGTitle variant={'t2'} className={'active-color'}>
          {switchHeaderSubtitle !== '' ? switchHeaderSubtitle : <StyledSkeleton />}
        </SGTitle>
      )}
    </StyledSwitchHeaderTab>
  );
}

export default SwitchHeaderSubtitle;

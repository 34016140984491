import React, {ReactNode, MouseEvent} from 'react';
import styled from 'styled-components';
import {withStyles, Theme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const StyledInfoIcon = styled(InfoIcon)`
  color: ${props => props.theme.text};
  font-size: 1.25rem;
  &.hidden {
    display: none;
  }
`;

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#f5f5f9',
  },
}))(Tooltip);

const handleClick = (event: MouseEvent<HTMLHeadingElement>) => {
  event.stopPropagation();
  event.preventDefault();
};

interface ToolTipProps {
  title: 'string' | ReactNode;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  customIcon?: ReactNode;
  className?: string;
}

function ToolTip({title, placement = 'right-start', customIcon, className}: ToolTipProps) {
  const defaultIcon = <StyledInfoIcon />;
  const useCustomIcon: ReactNode = customIcon ? customIcon : defaultIcon;

  return (
    <HtmlTooltip
      arrow
      interactive
      onClick={handleClick}
      placement={placement}
      title={<div onClick={handleClick}>{title}</div>}
    >
      <div className={className}>{useCustomIcon}</div>
    </HtmlTooltip>
  );
}

export default ToolTip;

import IAlert from "features/RRP/interfaces/IAlert";
import AlertSeverityEnum from "features/RRP/enums/AlertSeverityEnum";
const { UNKNOWN } = AlertSeverityEnum;

export default function defaultNullAlert(properties: string[]): IAlert {
  const listedProperties = properties.join(', ');
  return {
    value: UNKNOWN,
    issue: ['API data return is null or not a valid value'],
    effect: ['Cannot compare the values to create an accurate alert value.'],
    solution: [`Check the return values of: ${listedProperties}`],
  }
}
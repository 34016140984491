import React from 'react';
import styled from 'styled-components';
import FilterOptions from 'features/Inventories/interfaces/FilterOptions';
import Chip from './Chip';
import Tabs from 'features/Inventories/interfaces/Tabs';

const StyledFilterChipContainer = styled.div`
  padding: 8px 0 12px 0;
  display: none;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  max-width: calc(100vw - 48px);
  &.selected {
    display: flex;
  }
  &.hide-geo-chips .chip-geo {
    display: none;
  }

  @media screen and (min-width: 600px) {
    max-width: calc(100vw - 210px - 48px);
  }
`;

const renderChips = (
  filterOptions: FilterOptions,
  selectedFilters: string[],
  handleFilterChange: (value: string[]) => void
) => {
  const allCategoryKeys = Object.keys(filterOptions);
  const filteredCategoryKeys = allCategoryKeys.filter(catetoryKey => {
    const allCategoryValues = filterOptions[catetoryKey];
    const allValueKeys = Object.keys(allCategoryValues.values);
    const filteredCategoryValues = allValueKeys.filter(valueKey =>
      selectedFilters.includes(valueKey)
    );
    return filteredCategoryValues.length > 0;
  });

  return filteredCategoryKeys.map(categoryKey => {
    return (
      <Chip
        key={filterOptions[categoryKey].label}
        category={filterOptions[categoryKey].label}
        selectedFilters={selectedFilters}
        filterValues={filterOptions[categoryKey].values}
        handleFilterChange={handleFilterChange}
        className={`chip-${categoryKey}`}
      />
    );
  });
};

interface FilterChipProps {
  filterOptions: FilterOptions;
  selectedFilters: string[];
  selectedTab: Tabs;
  handleFilterChange: (value: string[]) => void;
}

function FilterChip({
  filterOptions,
  selectedFilters,
  selectedTab,
  handleFilterChange,
}: FilterChipProps) {
  const hasSelectedFilters = selectedFilters.length > 0 ? 'selected' : '';
  const isGeoTabSelected = selectedTab !== Tabs.CUSTOM ? 'hide-geo-chips' : '';
  return (
    <StyledFilterChipContainer className={`${hasSelectedFilters} ${isGeoTabSelected}`}>
      {renderChips(filterOptions, selectedFilters, handleFilterChange)}
    </StyledFilterChipContainer>
  );
}

export default FilterChip;

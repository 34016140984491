import React from 'react';
import styled from 'styled-components';
import SGTitle from 'components/Typography/SGTitle';
import SGHeadline from 'components/Typography/SGHeadline';
import AlertIcon from '../../RRPTable/TableStructure/AlertIcon';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import StatusSummaryFilterEnum from 'features/RRP/enums/StatusSummaryFilterEnum';

interface AlertSummaryButtonProps {
  alertType: AlertSeverityEnum;
  count: number;
  filters: StatusSummaryFilterEnum;
  value: StatusSummaryFilterEnum;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledSGTitle = styled(SGTitle)`
  margin-left: 0.25rem;
  transition: 200ms;
  &.green {
    color: ${props => props.theme.green};
  }
  &.yellow {
    color: ${props => props.theme.yellow};
  }
  &.orange {
    color: ${props => props.theme.orange};
  }
  &.red {
    color: ${props => props.theme.red};
  }
  &.grey {
    color: ${props => props.theme.grey};
  }
`;

const StyledAlertIcon = styled(AlertIcon)`
  transition: 200ms;
  height: 14px;
  width: 14px;
`;

const StyledAlertSummaryButton = styled.button`
  background-color: transparent;
  border: 2px solid ${props => props.theme.white};
  border-radius: 10px;
  min-width: 78px;
  min-height: 69px;
  margin: 4px;
  cursor: pointer;
  transition: 200ms;

  &:first-of-type {
    margin-left: 0px;
  }

  &:hover:not(.disabled) ${StyledSGTitle}, &.isActive ${StyledSGTitle} {
    color: ${props => props.theme.text};
  }

  &:hover:not(.disabled) ${StyledAlertIcon}, &.isActive ${StyledAlertIcon} {
    color: ${props => props.theme.text};
  }

  &.green {
    border-color: ${props => props.theme.green};
    &:hover,
    &.isActive {
      background-color: ${props => props.theme.green};
    }
  }
  &.yellow {
    border-color: ${props => props.theme.yellow};
    &:hover,
    &.isActive {
      background-color: ${props => props.theme.yellow};
    }
  }
  &.orange {
    border-color: ${props => props.theme.orange};
    &:hover,
    &.isActive {
      background-color: ${props => props.theme.orange};
    }
  }
  &.red {
    border-color: ${props => props.theme.red};
    &:hover,
    &.isActive {
      background-color: ${props => props.theme.red};
    }
  }
  &.grey {
    border-color: ${props => props.theme.grey};
    &:hover,
    &.isActive {
      background-color: ${props => props.theme.grey};
    }
  }

  &.disabled {
    opacity: 0.4;
    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }
`;

const StyledIconNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCountContainer = styled(StyledIconNameContainer)`
  margin-top: 0.75rem;
`;

const generateAlertClassName = function generateAlertClassName(alertSeverity: AlertSeverityEnum) {
  let colorType;
  let text;
  if (alertSeverity === AlertSeverityEnum.CRITICAL) {
    colorType = 'red';
    text = 'Critical';
  } else if (alertSeverity === AlertSeverityEnum.MAJOR) {
    colorType = 'orange';
    text = 'Major';
  } else if (alertSeverity === AlertSeverityEnum.MINOR) {
    colorType = 'yellow';
    text = 'Minor';
  } else if (alertSeverity === AlertSeverityEnum.HEALTHY) {
    colorType = 'green';
    text = 'Success';
  } else {
    colorType = 'grey';
    text = 'Unknown';
  }

  return {
    colorType,
    text,
  };
};

const generateActiveClassName = function generateActiveClassName(
  currentFilter: StatusSummaryFilterEnum,
  componentValue: StatusSummaryFilterEnum
) {
  let displayStateClass: string = '';
  if (currentFilter === StatusSummaryFilterEnum.All) {
    displayStateClass = '';
  } else if (currentFilter === componentValue) {
    displayStateClass = 'isActive';
  } else {
    displayStateClass = 'disabled';
  }
  return displayStateClass;
};

function AlertSummaryButton({alertType, count, filters, value, onClick}: AlertSummaryButtonProps) {
  const {colorType, text} = generateAlertClassName(alertType);
  const activeClass = generateActiveClassName(filters, value);
  return (
    <StyledAlertSummaryButton
      onClick={onClick}
      className={`${colorType} ${activeClass}`}
      value={alertType}
    >
      <StyledIconNameContainer>
        <StyledAlertIcon alertValue={alertType} showSuccess />
        <StyledSGTitle variant={'t6'} className={colorType}>
          {text}
        </StyledSGTitle>
      </StyledIconNameContainer>
      <StyledCountContainer>
        <SGHeadline variant={'h6'} className={'text-color'}>
          {count}
        </SGHeadline>
      </StyledCountContainer>
    </StyledAlertSummaryButton>
  );
}

export default AlertSummaryButton;

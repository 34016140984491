import DeviceType from '../types/DeviceType';

export interface Service {
  id: string;
  name: string;
  deviceType: DeviceType | string;
  stopMessage: string;
}

export interface Services extends Array<Service> {}

export interface ServiceDictionary {
  [key: string]: Services;
}

export const getServiceDisplayName: (service: Service) => string = (service: Service) => {
  return `${service.name} - ${service.deviceType}`;
};

export const serviceArraysAreEqual: (s1: Services, s2: Services) => boolean = (
  services1: Services,
  services2: Services
) => {
  return JSON.stringify(services1) === JSON.stringify(services2);
};

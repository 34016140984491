import React, {useState, MouseEvent} from 'react';
import styled, {useTheme} from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import Popover from '@material-ui/core/Popover';
import SiteIdMenu from './SiteIdMenu';

const StyledTuneIcon = styled(TuneIcon)`
  color: ${props => props.theme.disabledStateBlue};
  transition: color 150ms ease-in-out;
`;

const StyledIconButton = styled(IconButton)`
  padding: 5px;
  &:hover ${StyledTuneIcon} {
    color: ${props => props.theme.activeStateBlue};
  }

  &:hover {
    background-color: ${props => props.theme.activeStateBlue}0A;
  }
`;

interface SiteIdSettingsProps {
  urlType: string;
}

function SiteIdSettings({urlType}: SiteIdSettingsProps) {
  const theme: any = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <StyledIconButton onClick={handleClick}>
        <StyledTuneIcon />
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.cardBg,
            color: theme.text,
            borderRadius: '10px',
            border: '1px solid',
            borderColor: theme.grey,
            marginLeft: '12px',
          },
        }}
      >
        <SiteIdMenu handleClose={handleClose} urlType={urlType} />
      </Popover>
    </div>
  );
}

export default SiteIdSettings;

import React, {useState} from 'react';
import styled from 'styled-components';
import SGBody from '../../Typography/SGBody';
import Grid from '@material-ui/core/Grid';
import Modal from '../../Modal';
import ViewMoreTable from './ViewMoreTable';
import ITopEventItem from '../../../interfaces/ITopEventItem';

// export interface TopEventItemProps {
//   device: string;
//   eventCount: number;
//   date: string;
//   storesCount: number;
//   url: string;
// }

const StyledItem = styled.div`
  width: 100%;
  margin: 1.75rem 0;
`;

const StyledSGBody = styled(SGBody)`
  color: ${props => props.theme.red};
`;

const StyledLink = styled.a`
  color: ${props => props.theme.red};
  text-decoration-color: ${props => props.theme.red};
  text-decoration: underline;
`;

function TopEventItem({device, eventCount, date, storesCount, url}: ITopEventItem) {
  const [toggleModal, setToggleModal] = useState(false);

  return (
    <StyledItem>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4} md={2}>
          <StyledSGBody>{device}</StyledSGBody>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <StyledSGBody>{eventCount} Events</StyledSGBody>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <StyledSGBody>{date}</StyledSGBody>
        </Grid>
        <Grid item xs={8} sm={8} md={4}>
          <StyledSGBody>
            {storesCount} Store{storesCount > 1 ? 's' : ''} Affected
          </StyledSGBody>
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <StyledLink onClick={() => setToggleModal(!toggleModal)}>
            <StyledSGBody variant={'b3'}>View More</StyledSGBody>
          </StyledLink>
          <Modal modalState={toggleModal} modalToggle={setToggleModal} title={'PC '} subTitle={' '}>
            <ViewMoreTable />
          </Modal>
        </Grid>
      </Grid>
    </StyledItem>
  );
}

export default TopEventItem;

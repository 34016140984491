import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import {useSelector} from 'react-redux';
import TablePaginationActions from './TablePaginationActions';
import TablePaginationActionsStore from '../../../../../components/Tables/TableStructure/TablePaginationActionsStore';
import LoadingNoDataTable from '../../../../../components/Tables/TableStructure/LoadingNoData';
import TableHead from './TableHead';
import TableBody from './TableBody';

//#region styled-components
const StyledTableContainer = styled(TableContainer)`
  min-height: 350px;
  &.MuiTableContainer-root {
    overflow-x: visible;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: ${props => props.theme.disabled}22;
  }

  ::-webkit-scrollbar-thumb {
    border: 1px solid ${props => props.theme.navText}22;
    background-color: ${props => props.theme.navText}22;

    &:hover {
      border: 1px solid ${props => props.theme.navText}99;
      background-color: ${props => props.theme.navText}99;
    }
  }

  ::-webkit-resizer {
    display: none;
  }

  &.scroll {
    max-height: 550px;
    overflow-y: auto;
  }

  td:first-of-type,
  th:first-of-type {
    position: sticky;
    left: 13rem;
    z-index: 3;
  }
`;

const StyledTable = styled(Table)`
  width: fit-content;
  /* table-layout: auto; */
  table-layout: fixed;
  &.scroll tbody {
    overflow-y: auto; /* Trigger vertical scroll    */
    overflow-x: hidden; /* Hide the horizontal scroll */
  }
`;

const StyledSpaceer = styled.div`
  width: 100%;
  height: 2rem;
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${props => props.theme.subtleText};
  .MuiSelect-icon {
    color: ${props => props.theme.subtleText};
  }
  .MuiIconButton-root.Mui-disabled {
    color: ${props => props.theme.disabled};
  }
`;
//#endregion styled

function TableStructure(props) {
  const {
    columns,
    data,
    loaded,
    noDataText,
    clickAction,
    clickActionType,
    disablePagination,
    paginationType,
    showAll,
    tabSelected,
    isAlertsOnly,
    handleStores,
    removeStore,
    selectedStores,
    isGeoView,
    className,
    filters,
  } = props;

  const tableScroll = useSelector(state => state.ui.tableScroll);

  //#region useState
  const [visibleColumns, setVisibleColumns] = useState([
    'wanTransport',
    'sdWan',
    'switching',
    'wireless',
  ]);
  const [store, setStore] = useState([]);
  const [wanTransport, setWanTransport] = useState([]);
  const [sdWan, setSDWan] = useState([]);
  const [switching, setSwitching] = useState([]);
  const [wireless, setWireless] = useState([]);
  const tableColumns = [...store, ...wanTransport, ...sdWan, ...switching, ...wireless];

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //#endregion useState

  //#region handles
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTableColumns = sectionId => {
    let visibleTableColumns = [...visibleColumns];
    let newVisibleTableColumns = [];
    let emptyColumn = [
      {
        id: `empty${sectionId}`,
        label: '',
        width: '90px',
        sectionId: sectionId,
        customClass: 'no-border',
      },
    ];
    if (visibleTableColumns.includes(sectionId)) {
      // if sectionId exist in visibleColumns, then remove.
      newVisibleTableColumns = visibleTableColumns.filter(value => value !== sectionId);
      if (sectionId === 'wanTransport') {
        setWanTransport(emptyColumn);
      } else if (sectionId === 'sdWan') {
        setSDWan(emptyColumn);
      } else if (sectionId === 'switching') {
        setSwitching(emptyColumn);
      } else if (sectionId === 'wireless') {
        setWireless(emptyColumn);
      }
    } else {
      // if sectionId does not exist in visibleColumns, then add.
      newVisibleTableColumns = [sectionId, ...visibleTableColumns];
      const newTableColumns = columns.filter(column => column.sectionId === sectionId);
      if (sectionId === 'wanTransport') {
        // const sectionWanTransport = columns.filter(column => column.sectionId === 'wanTransport');
        setWanTransport(newTableColumns);
      } else if (sectionId === 'sdWan') {
        setSDWan(newTableColumns);
      } else if (sectionId === 'switching') {
        setSwitching(newTableColumns);
      } else if (sectionId === 'wireless') {
        setWireless(newTableColumns);
      }
    }
    setVisibleColumns(newVisibleTableColumns);
  };
  //#endregion handles

  //#region useEffect
  useEffect(() => {
    const sectionStore = columns.filter(item => item.sectionId === 'store');
    const sectionWanTransport = columns.filter(item => item.sectionId === 'wanTransport');
    const sectionSdWan = columns.filter(item => item.sectionId === 'sdWan');
    const sectionswitching = columns.filter(item => item.sectionId === 'switching');
    const sectionwireless = columns.filter(item => item.sectionId === 'wireless');
    setStore(sectionStore);
    setWanTransport(sectionWanTransport);
    setSDWan(sectionSdWan);
    setSwitching(sectionswitching);
    setWireless(sectionwireless);
  }, [columns]);

  useEffect(() => {
    setRows(data);
    setPage(0);
  }, [data]);

  useEffect(() => {
    const filterAlerts = function filterAlerts(store) {
      if (isAlertsOnly) {
        return store.alertValue.value > 0;
      } else {
        return true;
      }
    };
    const alertData = data.filter(filterAlerts);

    const filterGeo = function filterGeo(store) {
      if (tabSelected !== 'custom') {
        return store.geo === tabSelected;
      } else {
        return true;
      }
    };
    const geoData = alertData.filter(filterGeo);

    setRows(geoData);
    setPage(0);
  }, [tabSelected, isAlertsOnly, data]);

  useEffect(() => {
    const filterAlerts = function filterAlerts(store) {
      let alertValueToFilter = 0;
      if (filters === 'critical') {
        alertValueToFilter = 3;
      } else if (filters === 'major') {
        alertValueToFilter = 2;
      } else if (filters === 'minor') {
        alertValueToFilter = 1;
      } else if (filters === 'healthy') {
        alertValueToFilter = 0;
      } else if (filters === 'unknown') {
        alertValueToFilter = 4;
      }

      return store.alertValue.value === alertValueToFilter;
    };

    if (
      filters === 'critical' ||
      filters === 'major' ||
      filters === 'minor' ||
      filters === 'healthy' ||
      filters === 'unknown'
    ) {
      const alertData = data.filter(filterAlerts);
      setRows(alertData);
      setPage(0);
    } else {
      setRows(data);
      setPage(0);
    }
  }, [data, filters]);

  useEffect(() => {
    // const columnIds = ['wanTransport', 'sdWan', 'switching', 'wireless'];
    const sectionWanTransport = columns.filter(item => item.sectionId === 'wanTransport');
    const sectionSdWan = columns.filter(item => item.sectionId === 'sdWan');
    const sectionswitching = columns.filter(item => item.sectionId === 'switching');
    const sectionwireless = columns.filter(item => item.sectionId === 'wireless');
    if (filters === 'wanTransport') {
      setWanTransport(sectionWanTransport);
      setSDWan([]);
      setSwitching([]);
      setWireless([]);
    } else if (filters === 'sdWan') {
      setWanTransport([]);
      setSDWan(sectionSdWan);
      setSwitching([]);
      setWireless([]);
    } else if (filters === 'switching') {
      setWanTransport([]);
      setSDWan([]);
      setSwitching(sectionswitching);
      setWireless([]);
    } else if (filters === 'wireless') {
      setWanTransport([]);
      setSDWan([]);
      setSwitching([]);
      setWireless(sectionwireless);
    } else {
      setWanTransport(sectionWanTransport);
      setSDWan(sectionSdWan);
      setSwitching(sectionswitching);
      setWireless(sectionwireless);
    }

    setVisibleColumns(['wanTransport', 'sdWan', 'switching', 'wireless']);
  }, [filters, columns]);

  useEffect(() => {
    showAll || tableScroll ? setRowsPerPage('All') : setRowsPerPage(5);
  }, [showAll, tableScroll]);
  //#endregion useEffect

  return (
    <>
      <StyledTableContainer className={(tableScroll ? 'scroll ' : ' ') + className}>
        <StyledTable
          stickyHeader
          aria-label="sticky table"
          className={tableScroll ? 'scroll ' : ' '}
        >
          <TableHead
            columns={tableColumns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            visibleColumns={visibleColumns}
            handleTableColumns={handleTableColumns}
            handleStores={handleStores}
            selectedStores={selectedStores}
            isGeoView={isGeoView}
            filters={filters}
          />
          {loaded && (
            <TableBody
              columns={tableColumns}
              rows={rows}
              order={order}
              orderBy={orderBy}
              rowsPerPage={rowsPerPage}
              page={page}
              removeStore={removeStore}
              clickAction={clickAction}
              clickActionType={clickActionType}
              isGeoView={isGeoView}
            />
          )}
        </StyledTable>
        <LoadingNoDataTable dataCount={rows.length} noDataText={noDataText} isLoaded={loaded} />
      </StyledTableContainer>
      {tableScroll || disablePagination || showAll ? (
        <StyledSpaceer />
      ) : (
        <StyledTablePagination
          labelDisplayedRows={() => {}}
          rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          paginationType={paginationType}
          ActionsComponent={paginationType ? TablePaginationActionsStore : TablePaginationActions}
        />
      )}
    </>
  );
}

export default TableStructure;

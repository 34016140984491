import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import Container from '../components/Container';
import Grid from '@material-ui/core/Grid';
import SGHeadline from '../components/Typography/SGHeadline';
import SGTitle from 'components/Typography/SGTitle';
import SGBody from '../components/Typography/SGBody';
import SGButton from '../components/CTA/SGButton';
import WelcomeImg from '../assets/images/home_welcome.png';
import {useHistory} from 'react-router-dom';

const StyledHeadline = styled(SGHeadline)`
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  word-wrap: break-word;
  margin-bottom: 30px;
`;

const StyledSGBody = styled(SGBody)`
  &.margin-24 {
    margin-bottom: 24px;
  }
  &.margin-32 {
    margin-bottom: 32px;
  }
`;

const StyledButtonConatiner = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const StyledSGButton = styled(SGButton)`
  border-radius: 10px;
`;

const StyledImageContainer = styled.div`
  overflow: hidden;
  background-color: #ffffff11;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

function WelcomePage() {
  let history = useHistory();

  const handleClick = (path: string) => {
    history.push(path);
  };

  return (
    <Layout>
      <Container>
        <Grid container spacing={4} direction="row" alignItems="stretch">
          <Grid item md={12} lg={6}>
            <StyledHeadline variant={'h4'} className={'text-color'}>
              WELCOME TO INSIGHTS
            </StyledHeadline>
            <StyledSGBody variant={'b1'} className={'text-color margin-24'}>
              INSIGHTS currently features the following tools:
            </StyledSGBody>
            <SGTitle variant={'t1'} className={'text-color'}>
              REOCCURRING RETAIL PHYSICAL
            </SGTitle>
            <StyledSGBody variant={'b4'} className={'text-color margin-24'}>
              The Reoccurring Retail Physical (RRP) Dashboard is a dynamic health check of network
              devices in retail stores, globally. It reports on provisioned and utilized circuit
              data, switches, and WAP status, as well as other network-related details as it
              pertains to retail.
            </StyledSGBody>
            <SGTitle variant={'t1'} className={'text-color'}>
              switch connectivity
            </SGTitle>
            <StyledSGBody variant={'b4'} className={'text-color margin-32'}>
              The Switch Connectivity feature provides the status, data, and port configurations of
              switch devices in retail stores, showrooms, and distribution centers.
            </StyledSGBody>
            <StyledSGBody variant={'b1'} className={'text-color margin-24'}>
              What happened to the old Insights?
            </StyledSGBody>
            <SGTitle variant={'t1'} className={'text-color'}>
              Revamping old insights
            </SGTitle>
            <StyledSGBody variant={'b4'} className={'text-color margin-32'}>
              For those familiar with the older version of Insights in which users are able to view
              the status of retail-related technology events at a high level, we are currently
              working to improve the performance and UI of this function and will share updates in
              the future.
            </StyledSGBody>
            <StyledSGBody variant={'b1'} className={'text-color margin-24'}>
              Click a button below to navigate to the tools or use left navigation bar:
            </StyledSGBody>
            <StyledButtonConatiner>
              <StyledSGButton
                onClick={() => handleClick('/rrp')}
                size={'large'}
                variant={'outlined'}
              >
                RRP Dashboard
              </StyledSGButton>
              <StyledSGButton onClick={() => handleClick('/switch')} size={'large'}>
                Switch Connectivity
              </StyledSGButton>
            </StyledButtonConatiner>
          </Grid>
          <Grid item md={12} lg={6}>
            <StyledImageContainer>
              <StyledImage src={WelcomeImg} alt={'RRP and Switch'} />
            </StyledImageContainer>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default WelcomePage;

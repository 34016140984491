import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import Brand from '../../interfaces/Brand';
import Geo from '../../interfaces/SiteGeoSelection';
import SiteType from '../../interfaces/SiteType';
import Status from '../../interfaces/Status';
import SGHeadline from 'components/Typography/SGHeadline';
import SGBody from 'components/Typography/SGBody';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

const StyledSearchHeader = styled.div`
  padding: 8px 0 12px 0;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledChipContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const StyledChip = styled(Chip)`
  background-color: ${props => props.theme.disabledStateBlue};
  color: ${props => props.theme.activeStateBlue};
  text-transform: capitalize;
  font-size: 0.875rem;
  &.uppercase {
    text-transform: uppercase;
  }
  .MuiSvgIcon-root {
    color: ${props => props.theme.activeStateBlue};
    margin-right: 8px;
  }
  .MuiChip-deleteIcon {
    height: 1.125rem;
    width: 1.125rem;
  }

  &:hover .MuiSvgIcon-root {
    opacity: 0.5;
  }
`;

interface SiteSearchHeaderProps {
  urlKey: string;
  brands: Brand[];
  geos: Geo[];
  siteTypes: SiteType[];
  statuses: Status[];
}

function SiteSearchHeader({urlKey, brands, geos, siteTypes, statuses}: SiteSearchHeaderProps) {
  let history = useHistory();

  const handleDelete = (searchCategory: 'brand' | 'geo' | 'siteType' | 'status') => {
    const brandParams = searchCategory === 'brand' ? [Brand.NIKE, Brand.CONVERSE] : brands;
    const geoParams = searchCategory === 'geo' ? [Geo.NA, Geo.EMEA, Geo.GC, Geo.APLA] : geos;
    const siteTypeParams =
      searchCategory === 'siteType' ? [SiteType.RETAIL, SiteType.DC, SiteType.SHOWROOM] : siteTypes;
    const statusParams =
      searchCategory === 'status'
        ? [Status.OPEN, Status.PREOPEN, Status.CLOSED, Status.REMODEL]
        : statuses;

    history.push(
      `/site-search/${urlKey}/${brandParams.join(',')}/${geoParams.join(',')}/${siteTypeParams.join(
        ','
      )}/${statusParams.join(',')}`
    );
  };

  return (
    <StyledSearchHeader>
      <SGHeadline variant="h6" className="active-color">
        Search Results
      </SGHeadline>
      {brands.length === 1 && (
        <StyledChipContainer>
          <SGBody variant="b4">Brand:</SGBody>
          <StyledChip
            label={brands[0]}
            id={'btn_brand'}
            onDelete={() => handleDelete('brand')}
            deleteIcon={<CloseIcon />}
          />
        </StyledChipContainer>
      )}
      {geos.length === 1 && (
        <StyledChipContainer>
          <SGBody variant="b4">Geo:</SGBody>
          <StyledChip
            label={geos[0]}
            id={'btn_geo'}
            onDelete={() => handleDelete('geo')}
            deleteIcon={<CloseIcon />}
            className={'uppercase'}
          />
        </StyledChipContainer>
      )}
      {siteTypes.length === 1 && (
        <StyledChipContainer>
          <SGBody variant="b4">Site Type:</SGBody>
          <StyledChip
            label={siteTypes[0]}
            id={'btn_sitetype'}
            onDelete={() => handleDelete('siteType')}
            deleteIcon={<CloseIcon />}
          />
        </StyledChipContainer>
      )}
      {statuses.length === 1 && (
        <StyledChipContainer>
          <SGBody variant="b4">Status:</SGBody>
          <StyledChip
            label={statuses[0]}
            id={'btn_status'}
            onDelete={() => handleDelete('status')}
            deleteIcon={<CloseIcon />}
          />
        </StyledChipContainer>
      )}
    </StyledSearchHeader>
  );
}

export default SiteSearchHeader;

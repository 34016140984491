export interface Store {
  Brand: string;
  BusinessStatus: string;
  Geo: string;
  IsKeyMarket: boolean;
  SiteId: string;
  StoreConcept: string;
  StoreName: string;
  StoreNumber: number;
}

export const getStoreDisplayName: (site: Store) => string = (store: Store) => {
  return `${store.StoreNumber} ${store.StoreName}`;
};

export interface Stores extends Array<Store> {}

import React from 'react';
import styled from 'styled-components';

const StyledTabPanel = styled.div``;

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </StyledTabPanel>
  );
}

export default TabPanel;

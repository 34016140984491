import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@mdi/react';
import {mdiNewspaperVariantOutline} from '@mdi/js';
import IStoreData from 'features/RRP/interfaces/IStoreData';
import Card from '../Card';

interface RRPModalProps {
  store: IStoreData;
}

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const StyledContainer = styled.div`
  flex-shrink: 0;
  margin-top: 0.25rem;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const StyledIconButton = styled(Icon)`
  color: ${props => props.theme.disabledStateBlue};
  flex-shrink: 0;
`;

const StyledCard = styled(Card)`
  max-width: 800px;
`;

export default function RRPModal({store}: RRPModalProps) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledContainer>
      <StyledButton type="button" onClick={handleOpen}>
        <StyledIconButton path={mdiNewspaperVariantOutline} size={1} />
      </StyledButton>
      <StyledModal
        aria-labelledby="store-card-modal"
        aria-describedby="store-card-modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <StyledCard storeData={store} handleClose={handleClose} defaultExpanded />
        </Fade>
      </StyledModal>
    </StyledContainer>
  );
}

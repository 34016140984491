import isStringValidNum from './utility/isStringValidNum';
import IAlert from 'features/RRP/interfaces/IAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import defaultNullAlert from './utility/defaultNullAlert';

const {
  HEALTHY,
  MINOR,
  UNKNOWN,
} = AlertSeverityEnum;

export default function internetAProvisionedAlertValue(
  internetAProvisioned_Upload: string,
  internetAProvisioned_Download: string,
  internetAProvisioned_Type: string,
): IAlert {
  if (
    internetAProvisioned_Upload === null ||
    internetAProvisioned_Download === null ||
    internetAProvisioned_Type === null ||
    !isStringValidNum(internetAProvisioned_Upload) ||
    !isStringValidNum(internetAProvisioned_Download)
  ) {
    return defaultNullAlert([
      'internetAProvisioned_Upload',
      'internetAProvisioned_Download',
      'internetAProvisioned_Type'
    ]);
  }
  const alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  };

  const upload: RegExpMatchArray | null = internetAProvisioned_Upload.match(/\d/g);
  const download: RegExpMatchArray | null = internetAProvisioned_Download.match(/\d/g);

  if (upload === null || download === null) {
    return {
      value: UNKNOWN,
      issue: [`internetAProvisioned_Upload and/or internetAProvisioned_Download values were invalid.`],
      effect: ['Upload and download values could not be evaluated.'],
      solution: [`Check the file internetAProvisionedAlertValue.ts for values the function is not expecting.`],
    }
  }

  const uploadNum: number = parseInt(upload.join(''));
  const downloadNum: number = parseInt(download.join(''));
  if (isNaN(uploadNum) || isNaN(downloadNum)) {
    return {
      value: UNKNOWN,
      issue: [`internetAProvisioned_Upload and/or internetAProvisioned_Download values were invalid.`],
      effect: ['Upload and download values could not be evaluated.'],
      solution: [`Check the file internetAProvisionedAlertValue.ts for values the function is not expecting.`],
    };
  }

  if (uploadNum < 100) {
    alert.value = MINOR;
  }
  if (downloadNum < 100) {
    alert.value = MINOR;
  }
  if (internetAProvisioned_Type.toUpperCase() !== 'DIA') {
    alert.value = MINOR;
  }

  if (alert.value === MINOR) {
    alert.issue.push('If speed not met: The transport does not meet the WAN Transport A standard of 100M / 100M bandwidth.');
    alert.effect.push('If speed not met: May cause slow or intermittently failing network connections for whole store when paired with high utilization.');
    alert.solution.push('Consider upgrading transport to meet standard of WAN Transport A standard of 100M / 100M DIA. Especially critical when WAN Transport A Utilization is high, or SDWAN QoE is low.');
  }

  return alert;
}

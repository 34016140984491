import filterOptions from 'features/Inventories/constants/FilterOptions';

function getSelectedOptionsFromFilters(selectedFilters: string[]) {
  const filterObjectKeys: Record<string, string[]> = {};

  const allCategoryKeys = Object.keys(filterOptions);

  const reducedFiltersObject = allCategoryKeys.reduce((results, categoryKey) => {
    const allCategoryValues = filterOptions[categoryKey];
    const allValueKeys = Object.keys(allCategoryValues.values);
    const filteredCategoryValues = allValueKeys.filter(valueKey =>
      selectedFilters.includes(valueKey)
    );

    return {...results, [categoryKey]: filteredCategoryValues};
  }, filterObjectKeys);

  return reducedFiltersObject;
}

export default getSelectedOptionsFromFilters;

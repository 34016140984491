import React from 'react';
import styled from 'styled-components';
import AddRemoveIcon from './AddRemoveIcon';
import SGBody from 'components/Typography/SGBody';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';

interface ListItemProps {
  text: string;
  icon: 'add' | 'minus' | 'none';
  handleClick: () => void;
}

const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  padding: 0px;
  min-width: 0%;
  margin-right: 8px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0px;
`;

function ListItem({text, icon, handleClick}: ListItemProps) {
  return (
    <StyledListItem>
      <StyledListItemIcon>
        <StyledIconButton onClick={handleClick}>
          {icon !== 'none' && <AddRemoveIcon variant={icon} />}
        </StyledIconButton>
      </StyledListItemIcon>
      <SGBody variant={'b6'}>{text}</SGBody>
    </StyledListItem>
  );
}

export default ListItem;

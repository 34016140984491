import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
} = AlertSeverityEnum;

export default function switchingHardwarePoEAtCapacityAlertValue(switchPoEStackMembers_AtCapacity: number): IAlert {
  if (switchPoEStackMembers_AtCapacity === null || isNaN(switchPoEStackMembers_AtCapacity)) {
    return defaultNullAlert(['switchPoEStackMembers_AtCapacity']);
  }

  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  if (switchPoEStackMembers_AtCapacity > 0) {
    alert.value = 2;
    alert.issue.push('One or more pieces of Switching Hardware at this store is running at over 90% PoE watt utilization.');
    alert.effect.push('If one of the two redundant power feeds to the Switching Hardware is lost, any devices using wattage above 50% capacity will experience a network outage. Impact scales in severity based on the scale of usage beyond 50%.');
    alert.effect.push('Also; if the store runs out of PoE watts on the identified Switching Hardware, no additional PoE devices(such as Wireless APs or VoIP Phones) can be connected to that piece of Switching Hardware.');
    alert.solution.push('Investigate options for balancing PoE devices across all Switching Hardware in the store. If no options for balancing, consider adding additional Switching Hardware to the store.');
  }

  return alert;

}

import React from 'react';
import styled from 'styled-components';
import SGTitle from 'components/Typography/SGTitle';

interface CardSectionProps {
  title: string;
  children?: React.ReactElement;
}

const StyledCardSection = styled.div`
  width: calc(100% - 16px);
  padding: 0.5rem;
  background-color: transparent;
`;

const StyledSectionHeader = styled.div`
  padding: 0 0.5rem;
  background-color: ${props => props.theme.grey}19;
`;

const StyledSectionDetails = styled.div``;

export default function CardSection({title, children}: CardSectionProps) {
  return (
    <StyledCardSection>
      <StyledSectionHeader>
        <SGTitle variant={'t3'}>{title}</SGTitle>
      </StyledSectionHeader>
      <StyledSectionDetails>{children}</StyledSectionDetails>
    </StyledCardSection>
  );
}

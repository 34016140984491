import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import RectSkeleton from '../../Skeletons/RectSkeleton';
import {useSelector} from 'react-redux';

const StyledTradingNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 20px;
  border-radius: 5px;
  background-color: ${props => props.theme.red};
  font-size: 0.8rem;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.text};
  &.hidden {
    opacity: 0;
  }

  &.green {
    background-color: ${props => props.theme.green}33;
  }
`;

const StyledRectSkeleton = styled(RectSkeleton)`
  border-radius: 5px;
`;

export default function TradingNumber({geo}) {
  const {storeStatusCountsDataReady, storeStatusCounts} = useSelector(
    state => state.storeStatusCount
  );

  return (
    <>
      {storeStatusCountsDataReady ? (
        <StyledTradingNumber className={storeStatusCounts[geo].down === 0 ? 'green' : ''}>
          {storeStatusCounts[geo].down}
        </StyledTradingNumber>
      ) : (
        <StyledRectSkeleton>
          <StyledTradingNumber>0</StyledTradingNumber>
        </StyledRectSkeleton>
      )}
    </>
  );
}

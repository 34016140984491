import DevicesResponse from '../../interfaces/DevicesResponse';
import Site from 'interfaces/Site';
import InventoryDevice from '../../interfaces/InventoryDevice';

function replaceCommas(value: any) {
  const isString = typeof value === 'string';
  if (!isString) return value;

  return value.replace(/,/g, ' ');
}

interface mergeSiteAndDeviceProps {
  sites: Site[];
  devicesResponse: DevicesResponse[];
}

function mergeSiteAndDevice({
  sites: site,
  devicesResponse,
}: mergeSiteAndDeviceProps): InventoryDevice[] {
  const mergedDevices: InventoryDevice[] = devicesResponse.map((device: DevicesResponse) => {
    const siteInfo = site.find(site => site.siteId === device.siteId);

    const mergedDevice: InventoryDevice = {
      id: replaceCommas(device?.id) || '',
      type: device?.type || '',
      deviceType: device?.deviceType || '',
      deviceCategory: device?.deviceCategory || '',
      deviceName: device?.deviceName || '',
      serialNumber: replaceCommas(device?.serialNumber) || '',
      macAddress: device?.macAddress || '',
      status: !isNaN(device?.status) ? device?.status : '',
      afterHoursStatus: !isNaN(device?.afterHoursStatus) ? device?.afterHoursStatus : '',
      isActive: device?.isActive !== undefined ? device?.isActive : '',
      manufacturer: device?.manufacturer || '',
      model: replaceCommas(device?.model) || '',
      siteId: device?.siteId || '',
      brand: device?.brand || '',
      geo: device?.geo || '',
      siteType: device?.siteType || '',
      siteName: replaceCommas(siteInfo?.siteName) || '',
      siteStatus: siteInfo?.status || '',
      siteCode: siteInfo?.siteCode || '',
      storeConcept: siteInfo?.storeConcept || '',
    };

    return mergedDevice;
  });

  return mergedDevices;
}

export default mergeSiteAndDevice;

import {useEffect} from 'react';
import config from '../../../oktaConfig';
import {useOktaAuth} from '@okta/okta-react';
import {buildUserLog} from 'utils/logUsers';
import {patchVersion} from 'constants/releaseConfig';

interface useUserLogsParams {
  pathname: any;
  sessionId: string;
  themeSelected: string;
  payload: any;
  isSettings: boolean;
}

function useUserLogs({pathname, sessionId, themeSelected, payload, isSettings}: useUserLogsParams) {
  const {authState, oktaAuth} = useOktaAuth();

  useEffect(() => {
    if (authState && authState.isAuthenticated && !isSettings) {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      oktaAuth
        .getUser()
        .then(info => {
          const name = info ? info.name : null;
          const email = info ? info.email : null;
          const userLogObj = buildUserLog(
            name,
            email,
            new Date().getTime(),
            sessionId,
            themeSelected,
            pathname,
            payload,
            patchVersion.version
          );

          return {accessToken, userLogObj};
        })
        .then(({accessToken, userLogObj}) => {
          fetch(config.resourceServer.insightsApiUrlLogUserAction, {
            mode: 'cors',
            method: 'post',
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            signal: controller.signal,
            body: JSON.stringify(userLogObj),
            keepalive: true,
          })
            .then(response => {
              if (!response.ok) {
                return Promise.reject();
              }
            })
            .catch(err => {
              console.error(err);
            });

          return () => {
            controller.abort();
          };
        });
    }
  }, [authState, oktaAuth, sessionId, themeSelected, pathname, payload, isSettings]);
}

export default useUserLogs;

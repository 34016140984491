import extractNumberFromString from './utility/extractNumberFromString';
import IAlert from 'features/RRP/interfaces/IAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import defaultNullAlert from './utility/defaultNullAlert';
const {
  HEALTHY,
  MINOR,
  MAJOR,
  UNKNOWN,
} = AlertSeverityEnum;
export default function internetAUtilizationAlertValue(
  internetAPeakUtilization_Upload: string,
  internetAProvisioned_Upload: string,
  internetAPeakUtilization_Download: string,
  internetAProvisioned_Download: string,
): IAlert {
  if (
    internetAPeakUtilization_Upload === null ||
    internetAPeakUtilization_Download === null ||
    internetAProvisioned_Upload === null ||
    internetAProvisioned_Download === null
  ) {
    return defaultNullAlert([
      'internetAPeakUtilization_Upload',
      'internetAProvisioned_Upload',
      'internetAPeakUtilization_Download',
      'internetAProvisioned_Download'
    ]);
  }

  try {
    const { number: internetAPeakUtilization_UploadNum } = extractNumberFromString(
      internetAPeakUtilization_Upload
    );
    const { number: internetAPeakUtilization_DownloadNum } = extractNumberFromString(
      internetAPeakUtilization_Download
    );
    const { number: internetAProvisioned_UploadNum } = extractNumberFromString(internetAProvisioned_Upload);
    const { number: internetAProvisioned_DownloadNum } = extractNumberFromString(internetAProvisioned_Download);

    if (
      internetAProvisioned_UploadNum === null
      || internetAProvisioned_DownloadNum === null
      || internetAPeakUtilization_UploadNum === null
      || internetAPeakUtilization_DownloadNum === null
    ) {
      return defaultNullAlert([
        'internetAPeakUtilization_Upload',
        'internetAProvisioned_Upload',
        'internetAPeakUtilization_Download',
        'internetAProvisioned_Download'
      ])
    }

    const internetAPeakUtilization_UploadPercent =
      internetAPeakUtilization_UploadNum / internetAProvisioned_UploadNum;
    const internetAPeakUtilization_DownloadPercent =
      internetAPeakUtilization_DownloadNum / internetAProvisioned_DownloadNum;

    let alert: IAlert = {
      value: HEALTHY,
      issue: [],
      effect: [],
      solution: [],
    }

    if (
      internetAPeakUtilization_UploadPercent >= 0.9 ||
      internetAPeakUtilization_DownloadPercent >= 0.9
    ) {
      alert.value = MAJOR;
      alert.issue.push('Store utilization of WAN Transport A is over 90%, showing that the store may already be exhausting bandwidth on this transport.');
      alert.effect.push('High likelihood of slow or intermittently failing network connections for whole store.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport A standard of 100M / 100M DIA. If already within standard, contact Network Engineering.')
    } else if (
      internetAPeakUtilization_UploadPercent >= 0.7 ||
      internetAPeakUtilization_DownloadPercent >= 0.7
    ) {
      alert.value = MINOR;
      alert.issue.push('Store utilization of WAN Transport A is over 70%, showing that the store is trending towards exhausting bandwidth on this transport.');
      alert.effect.push('May cause slow or intermittently failing network connections for whole store.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport A standard of 100M / 100M DIA. If already within standard, contact Network Engineering.')
    }

    return alert;
  } catch (err) {

    return {
      value: UNKNOWN,
      issue: ['There was an error in internetAUtilizationAlertValue.js'],
      effect: ['When parsing through the returned api data the catch block was intitiated.'],
      solution: ['Check the file internetAUtilizationAlertValue.js for values the function is not expecting.'],
    };
  }
}

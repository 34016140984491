import getAddableServices from './getAddableServices';
import getUniqueServices from './getUniqueServices';
import {ServiceDictionary, Services} from './interfaces/Services';
import {Stores} from 'interfaces/Stores';
import {StoreAction} from './types/StoreAction';
import {StoreConfigStatus} from './types/StoreConfigStatus';

const getFilterableServices: (
  storeAction: StoreAction,
  servicesByStore: ServiceDictionary,
  allServices: Services,
  storeConfigStatus: StoreConfigStatus,
  selectedStores: Stores,
  selectedStoresWithoutServices: Stores
) => Services = (
  storeAction: StoreAction,
  servicesByStore: ServiceDictionary,
  allServices: Services,
  storeConfigStatus: StoreConfigStatus,
  selectedStores: Stores,
  selectedStoresWithoutServices: Stores
) => {
  let filterableServices: Services;

  if (storeAction === 'add') {
    filterableServices = getAddableServices(
      servicesByStore,
      allServices,
      storeConfigStatus === 'mixed'
        ? selectedStores.filter(store => !selectedStoresWithoutServices.includes(store))
        : selectedStores
    );
  } else if (storeAction === 'remove') {
    filterableServices = getUniqueServices(servicesByStore);
  } else {
    filterableServices = [];
    console.error('store action should not be default');
  }
  return filterableServices;
};

export default getFilterableServices;

import React, {FormEvent, MouseEvent, useRef} from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

interface TransferListSearchProps {
  id?: string;
  handleUserInput: (input: string) => void;
  placeholderText?: string;
  className?: 'default' | 'light' | 'service-manager';
}

const StyledInputContainer = styled.div`
  position: relative;
  width: fit-content;
  &.service-manager {
    width: calc(100% - 16px);
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 4px;
  top: 7px;
  z-index: 3;
  color: ${props => props.theme.text};
  cursor: pointer;
`;

const StyledClose = styled(CloseIcon)`
  font-size: 1.25rem;
  opacity: 0.75;
`;

const StyledStoreInput = styled.input`
  width: 252px;
  height: 2rem;
  background-color: ${props => props.theme.cardBg};
  border: 1px solid ${props => props.theme.text};
  border-radius: 10px;
  color: ${props => props.theme.text};
  padding-left: 1rem;
  position: relative;
  z-index: 3;
  margin-left: 8px;
  &.light {
    background-color: transparent;
    border: 1px solid ${props => props.theme.text};
  }
  &.light::placeholder {
    color: ${props => props.theme.text};
    opacity: 0.7;
  }
  &.service-manager {
    width: calc(100% - 16px);
    background-color: ${props => props.theme.barBg};
  }
`;

function TransferListSearch({
  id = 'transfer-list-search',
  handleUserInput,
  placeholderText = 'Search Store Name or #',
  className,
}: TransferListSearchProps) {
  const ref = useRef<HTMLInputElement>(null);
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    handleUserInput(event.currentTarget.value);
  };

  const clearInput = function clearInput(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    if (ref.current !== null) {
      ref.current.value = '';
      handleUserInput('');
    }
  };

  return (
    <StyledInputContainer className={className}>
      <StyledStoreInput
        id={id}
        type="text"
        placeholder={placeholderText}
        autoComplete="off"
        onChange={handleChange}
        className={className}
        ref={ref}
      />
      <StyledButton onClick={clearInput}>
        <StyledClose />
      </StyledButton>
    </StyledInputContainer>
  );
}

export default TransferListSearch;

const ADMINS = [
  'Byron.Chang@nike.com',
  'Ryan.Mackey@nike.com',
  'Alok.JosephCornelio@nike.com',
  'Justin.Habetz@nike.com',
  'Mary.Liu@nike.com',
  'Colton.Henry@nike.com',
  'Trixy.Woodhouse@nike.com',
  'Andrew.Fischbeck@nike.com',
  'Benjamin.Jackson@nike.com',
  'Omar.Valdes@nike.com',
  'Gerrardo.Herrera@nike.com',
  'Alan.Cortez@nike.com',
  'David.Miguel@nike.com',
  'Hayden.Liao@nike.com',
  'Tim.Odell@nike.com',
  'Adrian.Shepherd@nike.com',
  'Hans.Kersteter@nike.com',
  'Ben.Pickett@nike.com',
  'rodney.young@nike.com',
  'jt.cray@nike.com',
];

export const isAdmin = userEmail => {
  if (userEmail === null) {
    return false;
  }
  if (ADMINS.indexOf(userEmail) >= 0) {
    return true;
  } else {
    return false;
  }
};

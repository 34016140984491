import InventoryDevice from 'features/Inventories/interfaces/InventoryDevice';
import Geo from 'interfaces/SiteGeoSelection';
import getSelectedOptionsFromFilters from './getSelectedOptionsFromFilters';
import filterOptions from 'features/Inventories/constants/FilterOptions';
import nvsnsoFilter from './nvsnsoFilter';

interface FilterDataProps {
  selectedFilters: string[];
  selectedGeos: Geo[];
  deviceData: InventoryDevice[];
}

type InvDevKey = keyof InventoryDevice;

function filterData({selectedFilters, selectedGeos, deviceData}: FilterDataProps) {
  const parsedFilters = getSelectedOptionsFromFilters(selectedFilters);

  // Geo and NVSNSO are handled separately
  const filterCategoriesToIgnore = ['geo', 'nvsnso'];
  const filterKeys = Object.keys(parsedFilters).filter(
    key => !filterCategoriesToIgnore.includes(key)
  );

  const stringSelectedGeos = selectedGeos.map(geo => geo.toString().toLowerCase());

  const filteredData = deviceData.filter(device => {
    const geoMatch = stringSelectedGeos.includes(device.geo.toLowerCase());

    let filterMatch = true;
    filterKeys.forEach(key => {
      try {
        const keyUnion = key as InvDevKey;
        if (parsedFilters[key].length > 0) {
          const lowerCaseParsedFilters = parsedFilters[keyUnion].map(value => value.toLowerCase());
          const valueToCompare = device[keyUnion].toString().toLowerCase();
          filterMatch = filterMatch && lowerCaseParsedFilters.includes(valueToCompare);
        }
      } catch (error) {
        console.error(
          'filterData is trying to compare a non existing property. Check to see if filterOptions has the correct key/value',
          {error, key}
        );
      }
    });

    return geoMatch && filterMatch;
  });

  return filteredData;
}

export default filterData;

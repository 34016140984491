import React, {MouseEvent} from 'react';
import styled from 'styled-components';
import StyledPaper from 'components/Paper';
import VLANLegendItem from './VLANLegendItem';
import SGBody from 'components/Typography/SGBody';
import SGTitle from 'components/Typography/SGTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VLANLegendToolTip from './VLANLegendTooltip';
import {VlanLegendData} from '../interfaces/SwitchConnectivityState';
import SwitchConnectivityState from '../interfaces/SwitchConnectivityState';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react';
import {mdiPin} from '@mdi/js';
import {Tooltip} from '@material-ui/core';

const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 8px;
`;

const StyledLegendPaper = styled(StyledPaper)`
  transition: 100ms ease-in-out;
  border: 1px solid transparent;
  &.pinned {
    border: 1px solid ${props => props.theme.secondary};
  }
`;

const StyledLegendItemContainer = styled.div<{$legendRows: number}>`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: repeat(${props => props.$legendRows}, auto);
  grid-auto-flow: column;
  column-gap: 8px;
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  padding: 0;

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    padding-left: 0px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  .MuiAccordionSummary-content {
    margin: 0px 0;
    align-items: center;
    p {
      margin-right: 8px;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  padding-left: 0px;
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.text};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  color: ${props => props.theme.text};
  padding: 0;
  text-transform: none;
  text-decoration: underline;
  margin-left: auto;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.subtleText};
  font-size: 1.25rem;
  transition: 200ms ease-in-out;

  &.pinned {
    color: ${props => props.theme.secondary};
    transform: rotate(45deg);
  }
`;

interface VLANLegendProps {
  switchState: SwitchConnectivityState;
  isLegendPinned: boolean;
  handleClearLegendItems: () => void;
  handlePinLegend: () => void;
  onLegendItemSelectionChanged: (vlanNumber: string, isSelected: boolean) => void;
}

export default function VLANLegend({
  switchState,
  isLegendPinned,
  handleClearLegendItems,
  handlePinLegend,
  onLegendItemSelectionChanged,
}: VLANLegendProps) {
  const legendRows = Math.ceil(switchState.legendData.length / 4);
  const legendItems = switchState.legendData.map((vlan: VlanLegendData) => {
    return (
      <VLANLegendItem
        key={vlan.vlanNumber}
        vlanNumber={vlan.vlanNumber}
        shortDescription={vlan.shortDescription}
        color={vlan.color}
        isSelected={vlan.isSelected}
        onLegendItemSelectionChanged={onLegendItemSelectionChanged}
      />
    );
  });

  const handlePinClick = (event: MouseEvent) => {
    event.stopPropagation();
    handlePinLegend();
  };

  return (
    <StyledLegendPaper className={isLegendPinned ? 'pinned' : ''}>
      <StyledAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<StyledExpandMoreIcon />}
          aria-controls={'switch-vlan'}
          id={'switch-vlan'}
        >
          <SGTitle variant={'t3'} className={'text-color text-spacing'}>
            VLAN LEGEND
          </SGTitle>
          <VLANLegendToolTip />
          <StyledIconButton
            color="primary"
            aria-label="pin legend to top of screen"
            onClick={handlePinClick}
          >
            <Tooltip title={(isLegendPinned ? 'Unpin' : 'Pin') + ' VLAN Legend to top of screen'}>
              <StyledIcon path={mdiPin} size={0.85} className={isLegendPinned ? 'pinned' : ''} />
            </Tooltip>
          </StyledIconButton>
        </AccordionSummary>
        <StyledAccordionDetails>
          <StyledLegendContainer>
            <StyledHeader>
              <SGBody>You can click on any VLAN to filter the ports in the Switch Diagram.</SGBody>
              {switchState.selectedVLANs.length > 0 && (
                <StyledButton onClick={handleClearLegendItems}>
                  <SGBody variant={'b4'}>Clear Filters</SGBody>
                </StyledButton>
              )}
            </StyledHeader>
            <StyledLegendItemContainer $legendRows={legendRows}>
              {legendItems}
            </StyledLegendItemContainer>
          </StyledLegendContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledLegendPaper>
  );
}

import html2canvas from 'html2canvas';

const exportAsImage = async elementId => {
  const element = document.getElementById(elementId);
  var svgElements = element.querySelectorAll('svg');
  svgElements.forEach(function (item) {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
    item.style.width = null;
    item.style.height = null;
  });
  // Create a canvas from the HTML element using html2canvas
  const canvas = await html2canvas(element, {backgroundColor: null, scale: '2'});

  let link = document.createElement('a');
  link.download = `${elementId}.png`;
  link.href = canvas.toDataURL('image/png');
  link.click();
};

export default exportAsImage;

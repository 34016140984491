import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
// component built with TypeScript and the ABnB Style guide

type SGBodyProps = {
  children: React.ReactNode;
  variant?: 'b1' | 'b2' | 'b3' | 'b4' | 'b5' | 'b6';
  noWrap?: boolean;
  className?: string;
  rest?: any;
};

const StyledBody = styled(Typography)`
  font-family: 'Text Regular';
  color: ${props => props.theme.text};
  font-size: 1rem;

  &.b1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  &.b2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &.b3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: italic;
  }
  &.b4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &.b5 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: italic;
  }
  &.b6 {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
  &.subtle {
    color: ${props => props.theme.grey};
  }
  &.disabledStateText {
    color: ${props => props.theme.disabledStateText};
  }
`;

function SGBody({children, variant = 'b2', noWrap = false, className, ...rest}: SGBodyProps) {
  return (
    <StyledBody noWrap={noWrap} className={`${variant} ${className}`} {...rest}>
      {children}
    </StyledBody>
  );
}

export default SGBody;

import IAlert from "features/RRP/interfaces/IAlert";
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
} = AlertSeverityEnum;

export default function switchingHardwarePoEBudgetAlertValue(
  switchAPPortUtilization_Used: number,
  switchPoEBudget_Total: number,
): IAlert {
  if (
    switchAPPortUtilization_Used === null ||
    switchPoEBudget_Total === null ||
    isNaN(switchAPPortUtilization_Used) ||
    isNaN(switchPoEBudget_Total)
  ) {
    return defaultNullAlert(['switchAPPortUtilization_Used', 'switchPoEBudget_Total']);
  }

  const percentUsed = switchAPPortUtilization_Used / switchPoEBudget_Total;
  let alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  }

  if (percentUsed >= 0.9) {
    alert.value = MAJOR;
    alert.issue.push('Total PoE watt usage is over 90% of the wattage available on the Switching Hardware, showing that the store may soon run out of PoE watts.');
    alert.effect.push('If one of the two redundant power feeds to the Switching Hardware is lost, any devices using wattage above 50% capacity will experience a network outage. Impact scales in severity based on the scale of usage beyond 50%.')
    alert.effect.push('Also; if the store runs out of PoE watts, no additional PoE devices (such as Wireless APs or VoIP Phones) can be connected to the store network.');
    alert.solution.push('Consider adding additional Switching Hardware to the store.');
  } else if (percentUsed >= 0.7) {
    alert.value = MINOR;
    alert.issue.push('Total PoE watt usage is over 50% of the wattage available on the Switching Hardware, showing that the store is trending towards running out of PoE watts.');
    alert.effect.push('If one of the two redundant power feeds to the Switching Hardware is lost, any devices using wattage above 50% capacity will experience a network outage. Impact scales in severity based on the scale of usage beyond 50%.')
    alert.effect.push('Also; if the store runs out of PoE watts, no additional PoE devices (such as Wireless APs or VoIP Phones) can be connected to the store network.');
    alert.solution.push('Consider adding additional Switching Hardware to the store.');
  }

  return alert;
}
export const initialState = {
  dataRetrieved: false,
  pending: false,
  data: [],
  error: null,
  tradeData: {},
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STORE_DATA_PENDING':
      return {
        ...state,
        pending: true,
      };
    case 'GET_STORE_DATA_SUCCESS':
      return {
        ...state,
        pending: false,
        data: action.data,
        dataRetrieved: true,
      };
    case 'GET_STORE_DATA_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default apiReducer;

export const getStorePendingStatus = state => state.storeApiData.pending;
export const getStoreError = state => state.error;

export const rdxStorePendingStatus = state => state.storeApiData.pending;
export const rdxStoreDataReady = state => state.storeApiData.dataRetrieved;
export const rdxStoreData = state => state.storeApiData.data;

export const exampleState = {
  ui: {
    navigation: false,
    theme: [],
  },
  user: {
    profile: null,
    isLogged: false,
  },
  data: {
    api: {
      store: {
        data: [],
        dataRetrieved: false,
        pending: false,
        error: null,
      },
      stores: {
        data: [],
        dataRetrieved: false,
        pending: false,
        error: null,
      },
      activeEvents: {
        data: [],
        dataRetrieved: false,
        pending: false,
        error: null,
      },
    },
    app: {
      unableToTrade: [],
      diminishedCapabilities: [],
      deviceCategoryData: [],
      geoData: {
        allGeo: [],
        keyMarket: [],
      },
    },
  },
};

export const mockGeoKeyData = {
  na: {
    down: {
      type: 'down',
      count: '10',
      label: 'Unable to Trade',
    },
    issues: {
      type: 'issues',
      count: '230',
      label: 'Stores With Issues',
    },
    trading: {
      type: 'trading',
      count: '100',
      label: 'Fully Trading',
    },
  },
  emea: {
    down: {
      type: 'down',
      count: '113',
      label: 'Unable to Trade',
    },
    issues: {
      type: 'issues',
      count: '230',
      label: 'Stores With Issues',
    },
    trading: {
      type: 'trading',
      count: '450',
      label: 'Fully Trading',
    },
  },
  gc: {
    down: {
      type: 'down',
      count: '21',
      label: 'Unable to Trade',
    },
    issues: {
      type: 'issues',
      count: '120',
      label: 'Stores With Issues',
    },
    trading: {
      type: 'trading',
      count: '600',
      label: 'Fully Trading',
    },
  },
  apla: {
    down: {
      type: 'down',
      count: '6',
      label: 'Down',
    },
    issues: {
      type: 'issues',
      count: '10',
      label: 'Issues',
    },
    trading: {
      type: 'trading',
      count: '102',
      label: 'Trading',
    },
  },
};

export const mockGeoData = {
  na: {
    geoName: 'North America',
    geoShort: 'NA',
    stores: '226',
    lastScan: '11:26 AM PST',
    lastScanDate: 'Mon 2/6',
    nextScan: '12:26 PM PST',
    nextScanDate: 'Mon 2/6',
  },
  emea: {
    geoName: 'Europe, Middle East & Africa',
    geoShort: 'emea',
    stores: '178',
    lastScan: '17:26 PM GMT',
    lastScanDate: 'Mon 2/6',
    nextScan: '18:26 PM GMT',
    nextScanDate: 'Mon 2/6',
  },
  gc: {
    geoName: 'Greater China',
    geoShort: 'GC',
    stores: '352',
    lastScan: '3:26 AM GST',
    lastScanDate: 'Mon 2/6',
    nextScan: '4:26 AM GST',
    nextScanDate: 'Mon 2/6',
  },
  apla: {
    geoName: 'Asia Pacific & Latin America',
    geoShort: 'APLA',
    stores: '85',
    lastScan: '11:26 AM PST',
    lastScanDate: 'Mon 2/6',
    nextScan: '12:26 PM PST',
    nextScanDate: 'Mon 2/6',
  },
};

export const mockStoreStatusData = [
  {
    storeNumber: 100,
    storeName: 'Nike NYC',
    keyMarket: true,
    localTime: '11:43 pm EST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 10,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 10,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 10,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 101,
    storeName: 'Nike Portland',
    keyMarket: true,
    localTime: '11:43 pm EST',
    networkUpStatus: false,
    serverUpStatus: true,
    registerUpStatus: 0,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 8,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 10,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 116,
    storeName: 'Nike Employee Store',
    keyMarket: false,
    localTime: '11:43 pm EST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 3,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 8,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 10,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 200,
    storeName: 'Nike Toronto',
    keyMarket: true,
    localTime: '3:43 pm EST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 10,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 5,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 0,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 175,
    storeName: 'Nike Vancouver',
    keyMarket: true,
    localTime: '11:43 pm EST',
    networkUpStatus: false,
    serverUpStatus: false,
    registerUpStatus: 0,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 0,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 0,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 310,
    storeName: 'Nike Factory Store',
    keyMarket: false,
    localTime: '11:43 pm EST',
    networkUpStatus: true,
    serverUpStatus: false,
    registerUpStatus: 10,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 8,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 10,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 589,
    storeName: 'Nike LA Store',
    keyMarket: true,
    localTime: '11:43 pm PST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 10,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 7,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 9,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 349,
    storeName: 'Nike Seattle Store',
    keyMarket: true,
    localTime: '11:43 pm PST',
    networkUpStatus: true,
    serverUpStatus: false,
    registerUpStatus: 0,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 3,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 3,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 525,
    storeName: 'Nike Portland Store',
    keyMarket: true,
    localTime: '11:43 pm PST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 3,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 5,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 5,
    pcUpStatusTotal: 10,
  },
  {
    storeNumber: 773,
    storeName: 'Nike Shanghai Store',
    keyMarket: true,
    localTime: '6:43 am GST',
    networkUpStatus: true,
    serverUpStatus: true,
    registerUpStatus: 9,
    registerUpStatusTotal: 10,
    athleteDeviceUpStatus: 9,
    athleteDeviceUpStatusTotal: 10,
    pcUpStatus: 10,
    pcUpStatusTotal: 10,
  },
];

export const mockAthleteDeviceData = [
  {
    appName: 'Score',
    appStatus: '6.7.2',
    assignedVersion: '6.7.3',
    assignmentStatus: 'Managed (9/30/20 1:27PM)',
  },
  {
    appName: 'Nike + Demo',
    appStatus: '4.0.1',
    assignedVersion: '4.0.2',
    assignmentStatus: 'Managed (9/30/20 1:27PM)',
  },
];

export const mockProfilesData = [
  {
    profileDetails: 'Compliance - POS Passcode',
    installmentStatus: 'Not Installed',
    assignmentType: 'Compliance',
    managedBy: 'Nike CDT',
    configureType: 'Device',
  },
  {
    profileDetails: 'OE Assessment Portal',
    installmentStatus: 'Not Installed',
    assignmentType: 'Optional',
    managedBy: 'Nike CDT',
    configureType: 'Device',
  },
  {
    profileDetails: 'Compliance - POS Passcode',
    installmentStatus: 'Not Installed',
    assignmentType: 'Compliance',
    managedBy: 'Nike CDT',
    configureType: 'Device',
  },
];

export const mockCertData = [
  {
    name: 'nke-lnx-clp-p01.nike.com',
    expiresIn: '7 days',
    issuedBy: 'CN=Nike TLS CA, DC=Ad, DC=Nike, DC=Com',
    installmentStatus: 'Installed',
    profile: '',
  },
  {
    name: 'nke-lnx-clp-p01.nike.com',
    expiresIn: '30 days',
    issuedBy: 'CN=Nike TLS CA, DC=Ad, DC=Nike, DC=Com',
    installmentStatus: 'Installed',
    profile: '',
  },
];

export const mockNSTData = [
  {
    fieldId: 1,
    installation: 'Patch Panel(s) completed',
    installed: 2,
    tested: 2,
    status: 2,
    noteTimeStamp: '',
    notes: '',
  },
  {
    fieldId: 2,
    installation: 'CradlePoint Installed for PoLR q/ Smart OOBM Connections',
    installed: 2,
    tested: 1,
    status: 0,
    noteTimeStamp: '',
    notes: '',
  },
  {
    fieldId: 3,
    installation: 'Visual status of IT Rack',
    installed: 0,
    tested: 1,
    status: 1,
    noteTimeStamp: 'March 26th, 2021',
    notes: 'Visual check looked good on 3/15/2021',
  },
  {
    fieldId: 4,
    installation: 'Validate Primary Braodband Connection',
    installed: null,
    tested: undefined,
    status: 3,
    noteTimeStamp: 'March 26th, 2021',
    notes: 'Checking for improper data. This should be a rare case.',
  },
  {
    fieldId: 5,
    installation: 'RetailNext traffic camera installed: RNXT validated traffic zone',
    installed: 2,
    tested: 1,
    status: 1,
    noteTimeStamp: 'March 29th, 2021',
    notes: '2x Fixed Circuits // Install Scheduled for 11/9; 11/10 Service Activation.',
  },
  {
    fieldId: 6,
    installation: `Validate all WAP's online`,
    installed: 2,
    tested: 1,
    status: 1,
    noteTimeStamp: '',
    notes: '',
  },
];

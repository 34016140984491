export const patchVersion = {
  patchNotesNumber: 11,
  version: '1.4.0',
};

export const maintenance = {
  maintenanceEnabled: false,
  messageDate: 'January 25th, 2023',
  messageContent:
    'Welcome to Retail Insights! We are rapidly developing new ideas and concepts. Feel free to explore, but understand that the data and the information we have here may not be accurate.',
};

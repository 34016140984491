const distributionCenterLegendColors = [
  '#D19900',
  '#3969E4',
  '#9D9569',
  '#FF6024',
  '#AA24FD',
  '#FD2499',
  '#00A1C4',
  '#FFE500',
  '#FF9C9C',
  '#9F9F9F',
  '#CED6FF',
  '#C74A04',
  '#4BF43F',
  '#5DA700',
  '#9878A9',
  '#D798BA',
  '#34EEFF',
  '#00E4B1',
  '#CEE000',
  '#BC4D58',
  '#1E8168',
  '#A37630',
  '#ABC5BE',
  '#00A59B',
  '#FFD7B4',
  '#6D8891',
  '#638BB3',
  '#FF775F',
  '#C8F7C5',
  '#5D995D',
  '#C5EFF7',
  '#8DB0BB',
  '#808080',
  '#B2CCE5',
  '#FFFACD',
  '#F5D76E',
  '#D4D0AB',
  '#D48566',
  '#34B9DB',
  '#C7A720',
  '#7BACDD',
  '#AE59B6',
  '#6AD4B1',
  '#F5D76E',
  '#FFA07A',
  '#FFECDB',
  '#008080',
  '#AA8F00',
  '#D48566',
  '#ABE338',
  '#F39C12',
  '#A2DED0',
  '#D400D4',
];

export default distributionCenterLegendColors;

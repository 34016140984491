import React from 'react';
import styled from 'styled-components';
import SGTitle from '../../Typography/SGTitle';
import SGBody from '../../Typography/SGBody';
import RectSkeleton from '../../Skeletons/RectSkeleton';

interface DateRangeDisplayProps {
  debugLoaded?: boolean;
  friendlyDateRange: string;
  variant?: 'title' | 'subTitle';
}

// interface HeadingType {
//   textType?: 'title' | 'subTitle';
// }

const StyledRectSkeleton = styled(RectSkeleton)`
  float: right;
`;

const StyledSGBody = styled(SGBody)`
  float: right;
`;

const renderDateRange = function renderDateRange(headingType: string, dateText: string) {
  if (headingType === 'title') {
    return (
      <SGTitle variant={'t4'} className={'text-color'}>
        {dateText}
      </SGTitle>
    );
  } else if (headingType === 'subTitle') {
    return (
      <StyledSGBody variant={'b5'} className={'subtle'}>
        {dateText}
      </StyledSGBody>
    );
  } else {
    return (
      <StyledSGBody variant={'b5'} className={'subtle'}>
        {dateText}
      </StyledSGBody>
    );
  }
};

function DateRangeDisplay({debugLoaded, friendlyDateRange, variant}: DateRangeDisplayProps) {
  return (
    <>
      {debugLoaded ? (
        <StyledSGBody variant={'b5'} className={'subtle'}>
          {friendlyDateRange}
        </StyledSGBody>
      ) : (
        <StyledRectSkeleton width={'5rem'} height={'1.25rem'} />
      )}
    </>
  );
}

export default DateRangeDisplay;

export const initialState = {
  api: {
    storeServices: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
    servicesByStore: {
      dataRetrieved: false,
      pending: false,
      data: null,
      error: null,
    },
  },
};

const storeServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STORE_SERVICES_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          storeServices: {
            dataRetrieved: false,
            pending: true,
            data: null,
            error: null,
          },
        },
      };
    case 'GET_STORE_SERVICES_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          storeServices: {
            dataRetrieved: true,
            pending: false,
            data: action.data,
            error: null,
          },
        },
      };
    case 'GET_STORE_SERVICES_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          storeServices: {
            dataRetrieved: false,
            pending: false,
            data: null,
            error: action.error,
          },
        },
      };
    case 'GET_SERVICES_BY_STORE_PENDING':
      return {
        ...state,
        api: {
          ...state.api,
          servicesByStore: {
            dataRetrieved: false,
            pending: true,
            data: null,
            error: null,
          },
        },
      };
    case 'GET_SERVICES_BY_STORE_SUCCESS':
      return {
        ...state,
        api: {
          ...state.api,
          servicesByStore: {
            dataRetrieved: true,
            pending: false,
            data: action.data,
            error: null,
          },
        },
      };
    case 'GET_SERVICES_BY_STORE_ERROR':
      return {
        ...state,
        api: {
          ...state.api,
          servicesByStore: {
            dataRetrieved: false,
            pending: false,
            data: null,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
};

export default storeServiceReducer;

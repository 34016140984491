import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const StyledTextSkeleton = styled(Skeleton)`
  background-color: rgba(255, 255, 255, 0.5);
`;

function TextSkeleton(props) {
  const {children, ...rest} = props;

  return (
    <StyledTextSkeleton variant="text" {...rest}>
      {children}
    </StyledTextSkeleton>
  );
}

export default TextSkeleton;

import React, {ReactNode} from 'react';
import styled from 'styled-components';
import Snackbar from 'components/Snackbar';
import ErrorMessageDictionary from 'components/ErrorMessageDictionary';
import FetchResponseError from 'interfaces/FetchResponseError';
import {useSelector} from 'react-redux';
import SwitchError from '../interfaces/SwitchError';

const StyledCodeBlock = styled.div`
  word-wrap: break-word;
  display: inline-block;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: ${props => props.theme.grey}66;
`;

const addDebugInformation = function addDebugInformation(
  msgKey: number | string,
  error: FetchResponseError | SwitchError
) {
  if (msgKey === 500 && 'url' in error) {
    return <StyledCodeBlock>{error.url}</StyledCodeBlock>;
  } else {
    return null;
  }
};

interface SwitchSnackbarProps {
  error: FetchResponseError | SwitchError;
}

function SwitchSnackbar({error}: SwitchSnackbarProps) {
  const msgKey = 'status' in error ? error.status : error.code;
  const {statusKey, statusText, userText, alertType, debugOnly} = ErrorMessageDictionary({msgKey});
  const isDebugEnabledView = useSelector((state: any) => state.ui.debugView);

  let debugInfo: null | ReactNode;
  let switchName = 'switchName' in error ? error.switchName : null;

  debugInfo = addDebugInformation(msgKey, error);

  if (isDebugEnabledView) {
    return (
      <Snackbar alertType={alertType ? alertType : 'info'}>
        {statusKey} - {statusText}
        <br />
        {userText}
        <br />
        {switchName && 'Device: ' + switchName}
        <br />
        Admin Debug View:
        <br />
        {debugInfo && debugInfo}
        <StyledCodeBlock>{JSON.stringify(error)}</StyledCodeBlock>
      </Snackbar>
    );
  } else if (debugOnly && !isDebugEnabledView) {
    return <></>;
  } else {
    return (
      <Snackbar alertType={alertType ? alertType : 'info'}>
        {statusKey} - {statusText}
        <br />
        {userText}
        <br />
        {switchName && 'Device: ' + switchName}
      </Snackbar>
    );
  }
}

export default SwitchSnackbar;

import React from 'react';
import styled from 'styled-components';

import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {StyledButton} from './Navigation';
import {useSelector} from 'react-redux';

const StyledMenuDropDown = styled.div`
  position: relative;
  z-index: 2;

  .z-button {
    position: relative;
    z-index: 3;
  }
  .z-index-fix {
    position: absolute;
    z-index: 2;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0px;
`;

const StyledLink = styled(Link)`
  font-family: 'Text Bold';
  color: ${props => props.theme.navText};
  text-decoration: none;
  font-size: 1.25rem;
  width: 100%;
  padding: 8px;
  line-height: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    font-size: 1rem;
    padding: 3px 16px 10px 42px;
  }

  &:visited {
    color: ${props => props.theme.navText};
  }
  &:hover {
    font-size: 1.2rem;
    color: ${props => props.theme.navTextActive};
  }
`;

const StyledPaper = styled(Paper)`
  background-color: ${props => props.theme.navBg};
  color: ${props => props.theme.altText};
  position: relative;
  z-index: 0;
  top: -10px;
  width: 158px;
  padding: 16px;
  border-radius: 10px 10px 10px 10px;
  @media (min-width: 600px) {
    display: none;
    border-radius: 0px 0px 10px 10px;
  }
`;

const StyledArrowIcon = styled(ArrowDropDownIcon)`
  display: none;
  position: absolute;
  color: ${props => props.theme.navText};
  right: -5px;
  transition: 0.25s;
  &.active {
    color: ${props => props.theme.navTextActive};
    transform: rotate(-90deg);
  }
`;

const StyledNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 20px;
  border-radius: 15px;
  background-color: ${props => props.theme.red};
  font-size: 0.8rem;
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.text};

  &.hidden {
    opacity: 0;
  }
`;

function MenuDropDown(props) {
  const {children} = props;

  // const geoData = useSelector(state => state.data.app.landingPage.geoData);
  const geoData = useSelector(state => state.storeStatusCount.storeStatusCounts);
  const geoStoreDown = [geoData.na.down, geoData.emea.down, geoData.gc.down, geoData.apla.down];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <StyledMenuDropDown>
      <StyledButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={(open ? ' active' : '') + ' z-button'}
      >
        {children}
        <StyledArrowIcon className={(open ? 'active' : '') + ' hoverClass'} />
      </StyledButton>

      <Popper
        className={'z-index-fix'}
        open={open}
        placement={'bottom-start'}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps}) => (
          <Grow {...TransitionProps} style={{transformOrigin: 'top center'}}>
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/rrp`}>
                      RRP Dashboard
                    </StyledLink>
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/switch`}>
                      Switch Connectivity
                    </StyledLink>
                  </StyledMenuItem>
                  {/* <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/dashboard/na`}>
                      NA
                      <StyledNumber className={geoStoreDown[0] > 0 ? '' : 'hidden'}>
                        {geoStoreDown[0]}
                      </StyledNumber>
                    </StyledLink>
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/dashboard/emea`}>
                      EMEA
                      <StyledNumber className={geoStoreDown[1] > 0 ? '' : 'hidden'}>
                        {geoStoreDown[1]}
                      </StyledNumber>
                    </StyledLink>
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/dashboard/gc`}>
                      GC
                      <StyledNumber className={geoStoreDown[2] > 0 ? '' : 'hidden'}>
                        {geoStoreDown[2]}
                      </StyledNumber>
                    </StyledLink>
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <StyledLink onClick={handleClose} to={`/dashboard/apla`}>
                      APLA
                      <StyledNumber className={geoStoreDown[3] > 0 ? '' : 'hidden'}>
                        {geoStoreDown[3]}
                      </StyledNumber>
                    </StyledLink>
                  </StyledMenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </StyledMenuDropDown>
  );
}

export default MenuDropDown;

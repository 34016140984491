import getStoreData from './getStoreData';
import getActiveEventData from './getActiveEventData';
import getLandingPageCounts from './getLandingPageCounts';
import getLandingPageKeyCounts from './getLandingPageKeyCounts';
import getStoreInfoData from './getStoreInfoData';
import getStoreEventsData from './getStoreEventsData';
import getStoreTrendingData from './getStoreTrendingData';
import getGeoTrendingData from './getGeoTrendingData';
import getLandingPageCoordinates from './getLandingPageCoordinates';
import getDashboardRiskIssuesCount from './getDashboardRiskIssuesCount';
import getDashboardDiminishedIssuesCount from './getDashboardDiminishedIssuesCount';
import getTrendingIssueCount from './getTrendingIssueCount';
import getMinimumStoreInfo from './getMinimumStoreInfo';
import getMinimumSiteInfo from './getMinimumSiteInfo';
import postLogUserAction from './postLogUserAction';
import getTreeMenuData from './getTreeMenuData';
import getTopEvents from './getTopEvents';
import getTableEvents from './getTableEvents';
import getDeviceHealthCount from './getDeviceHealthCount';
import getStoreServices from './getStoreServices';
import getServicesByStore from './getServicesByStore';
import postStoreServiceConfigurationAction from './postStoreServiceConfigurationAction';
import getDistinctIdfSearchItems from './getDistinctIdfSearchItems';

// Theme Actions
export const themeSelectDefault = () => {
  return {
    type: 'THEME_SELECT_DEFAULT',
  };
};

export const themeSelectLight = () => {
  return {
    type: 'THEME_SELECT_LIGHT',
  };
};

export const themeSelectDark = () => {
  return {
    type: 'THEME_SELECT_DARK',
  };
};

export const themeSelectLightBlue = () => {
  return {
    type: 'THEME_SELECT_LIGHT_BLUE',
  };
};

export const themeSelectVolt = () => {
  return {
    type: 'THEME_SELECT_VOLT',
  };
};

// Dashboard.jsx Actions
export const fetchStoreData = accessToken => {
  //needs refactoring
  return dispatch => {
    dispatch(getStoreData(accessToken));
  };
};

export const getStoreDataPending = () => {
  //needs refactoring
  return {
    type: 'GET_STORE_DATA_PENDING',
  };
};

export const getStoreDataSuccess = data => {
  //needs refactoring
  return {
    type: 'GET_STORE_DATA_SUCCESS',
    data: data,
  };
};

export const getStoreDataError = error => {
  //needs refactoring
  return {
    type: 'GET_STORE_DATA_ERROR',
    error: error,
  };
};

// DeviceHealthCount
export const fetchDeviceHealthCount = accessToken => {
  return dispatch => {
    dispatch(getDeviceHealthCount(accessToken));
  };
};

export const getDeviceHealthCountPending = () => {
  return {
    type: 'GET_DEVICE_HEALTH_COUNT_PENDING',
  };
};

export const getDeviceHealthCountSuccess = data => {
  return {
    type: 'GET_DEVICE_HEALTH_COUNT_SUCCESS',
    data: data,
  };
};

export const getDeviceHealthCountError = error => {
  return {
    type: 'GET_DEVICE_HEALTH_COUNT_ERROR',
    error: error,
  };
};

// StoreDetail.jsx Actions
export const fetchStoreInfoData = (accessToken, storeNumber) => {
  return dispatch => {
    dispatch(getStoreInfoData(accessToken, storeNumber));
  };
};

export const getStoreInfoDataPending = () => {
  return {
    type: 'GET_STORE_INFO_DATA_PENDING',
  };
};

export const getStoreInfoDataSuccess = data => {
  return {
    type: 'GET_STORE_INFO_DATA_SUCCESS',
    data: data,
  };
};

export const getStoreInfoDataError = error => {
  return {
    type: 'GET_STORE_INFO_DATA_ERROR',
    error: error,
  };
};

export const fetchStoreEventsData = (accessToken, storeNumber) => {
  return dispatch => {
    dispatch(getStoreEventsData(accessToken, storeNumber));
  };
};

export const getStoreEventsDataPending = () => {
  return {
    type: 'GET_STORE_EVENTS_DATA_PENDING',
  };
};

export const getStoreEventsDataSuccess = data => {
  return {
    type: 'GET_STORE_EVENTS_DATA_SUCCESS',
    data: data,
  };
};

export const getStoreEventsDataError = error => {
  return {
    type: 'GET_STORE_EVENTS_DATA_ERROR',
    error: error,
  };
};

export const fetchStoreTrendingData = (accessToken, storeNumber) => {
  return dispatch => {
    dispatch(getStoreTrendingData(accessToken, storeNumber));
  };
};

export const getStoreTrendingDataPending = () => {
  return {
    type: 'GET_STORE_TRENDING_DATA_PENDING',
  };
};

export const getStoreTrendingDataSuccess = data => {
  return {
    type: 'GET_STORE_TRENDING_DATA_SUCCESS',
    data: data,
  };
};

export const getStoreTrendingDataError = error => {
  return {
    type: 'GET_STORE_TRENDING_DATA_ERROR',
    error: error,
  };
};

// Dashboard.jsx Actions
export const fetchDashboardTrending = (accessToken, param) => {
  // dashboard issue circle chart
  return dispatch => {
    dispatch(getTrendingIssueCount(accessToken, param));
  };
};

export const getDashboardTrendingPending = () => {
  return {
    type: 'GET_DASHBOARD_TRENDING_PENDING',
  };
};

export const getDashboardTrendingSuccess = data => {
  return {
    type: 'GET_DASHBOARD_TRENDING_SUCCESS',
    data: data,
  };
};

export const getDashboardTrendingError = error => {
  return {
    type: 'GET_DASHBOARD_TRENDING_ERROR',
    error: error,
  };
};

export const fetchDashboardRiskIssuesCount = (accessToken, geo) => {
  return dispatch => {
    dispatch(getDashboardRiskIssuesCount(accessToken, geo));
  };
};

export const getDashboardRiskIssuesCountPending = () => {
  return {
    type: 'GET_DASHBOARD_RISK_ISSUES_COUNT_PENDING',
  };
};

export const getDashboardRiskIssuesCountSuccess = data => {
  return {
    type: 'GET_DASHBOARD_RISK_ISSUES_COUNT_SUCCESS',
    data: data,
  };
};

export const getDashboardRiskIssuesCountError = error => {
  return {
    type: 'GET_DASHBOARD_RISK_ISSUES_COUNT_ERROR',
    error: error,
  };
};

export const fetchDashboardDiminishedIssuesCount = (accessToken, geo) => {
  return dispatch => {
    dispatch(getDashboardDiminishedIssuesCount(accessToken, geo));
  };
};

export const getDashboardDiminishedIssuesCountPending = () => {
  return {
    type: 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_PENDING',
  };
};

export const getDashboardDiminishedIssuesCountSuccess = data => {
  return {
    type: 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_SUCCESS',
    data: data,
  };
};

export const getDashboardDiminishedIssuesCountError = error => {
  return {
    type: 'GET_DASHBOARD_DIMINISHED_ISSUES_COUNT_ERROR',
    error: error,
  };
};

export const fetchActiveEventData = accessToken => {
  return dispatch => {
    dispatch(getActiveEventData(accessToken));
  };
};

export const getActiveEventPending = () => {
  return {
    type: 'GET_ACTIVE_EVENT_DATA_PENDING',
  };
};

export const getActiveEventSuccess = data => {
  return {
    type: 'GET_ACTIVE_EVENT_DATA_SUCCESS',
    data: data,
  };
};

export const getActiveEventError = error => {
  return {
    type: 'GET_ACTIVE_EVENT_DATA_ERROR',
    error: error,
  };
};

export const fetchTreeMenuData = accessToken => {
  return dispatch => {
    dispatch(getTreeMenuData(accessToken));
  };
};

export const fetchTreeMenuDataPending = () => {
  return {
    type: 'FETCH_TREE_MENU_DATA_PENDING',
  };
};

export const fetchTreeMenuDataSuccess = data => {
  return {
    type: 'FETCH_TREE_MENU_DATA_SUCCESS',
    data: data,
  };
};

export const fetchTreeMenuDataError = error => {
  return {
    type: 'FETCH_TREE_MENU_DATA_ERROR',
    error: error,
  };
};

export const fetchTopEvents = accessToken => {
  return dispatch => {
    dispatch(getTopEvents(accessToken));
  };
};

export const fetchTopEventsPending = () => {
  return {
    type: 'FETCH_TOP_EVENTS_PENDING',
  };
};

export const fetchTopEventsSuccess = data => {
  return {
    type: 'FETCH_TOP_EVENTS_SUCCESS',
    data: data,
  };
};

export const fetchTopEventsError = error => {
  return {
    type: 'FETCH_TOP_EVENTS_ERROR',
    error: error,
  };
};

export const fetchTableEvents = accessToken => {
  return dispatch => {
    dispatch(getTableEvents(accessToken));
  };
};

export const fetchTableEventsPending = () => {
  return {
    type: 'FETCH_TABLE_EVENTS_PENDING',
  };
};

export const fetchTableEventsSuccess = data => {
  return {
    type: 'FETCH_TABLE_EVENTS_SUCCESS',
    data: data,
  };
};

export const fetchTableEventsError = error => {
  return {
    type: 'FETCH_TABLE_EVENTS_ERROR',
    error: error,
  };
};

export const formatActiveEventData = () => {
  return {
    type: 'FORMAT_ACTIVE_EVENT_DATA',
  };
};

export const formatDeviceCategory = () => {
  return {
    type: 'FORMAT_DEVICE_CATEGORY',
  };
};

export const mergeEventStore = (event, store) => {
  return {
    type: 'MERGE_EVENT_STORE',
    event: event,
    store: store,
  };
};

export const mergeDeviceCategoryGeo = (event, store) => {
  return {
    type: 'MERGE_DEVICE_CATEGORY_GEO',
    activeEvent: event,
    store: store,
  };
};

export const fetchGeoTrendingData = (accessToken, geo) => {
  return dispatch => {
    dispatch(getGeoTrendingData(accessToken, geo));
  };
};

export const getGeoTrendingDataPending = () => {
  return {
    type: 'GET_GEO_TRENDING_DATA_PENDING',
  };
};

export const getGeoTrendingDataSuccess = data => {
  return {
    type: 'GET_GEO_TRENDING_DATA_SUCCESS',
    data: data,
  };
};

export const getGeoTrendingDataError = error => {
  return {
    type: 'GET_GEO_TRENDING_DATA_ERROR',
    error: error,
  };
};

// UI Actions
export const updateNav = pl => {
  return {
    type: 'UPDATE_NAV',
    payload: pl,
  };
};

export const setUiPopOpViewed = () => {
  return {
    type: 'UI_POPUP_VIEWED',
  };
};

export const setUiPopOpReset = () => {
  return {
    type: 'UI_POPUP_RESET',
  };
};

export const setUiPatchNotesViewed = patchVersion => {
  return {
    type: 'UI_PATCHNOTES_VIEWED',
    patchVersion: patchVersion,
  };
};

export const setUiPatchNotesReset = () => {
  return {
    type: 'UI_PATCHNOTES_RESET',
  };
};

export const uiToggleTable = () => {
  return {
    type: 'UI_TOGGLE_TABLE',
  };
};

export const uiToggleDebug = () => {
  return {
    type: 'UI_TOGGLE_DEBUG',
  };
};

// Landing Page Actions
export const fetchLandingPageCountsData = accessToken => {
  return dispatch => {
    dispatch(getLandingPageCounts(accessToken));
  };
};

export const getLandingPageCountsPending = () => {
  return {
    type: 'GET_LANDING_PAGE_COUNTS_PENDING',
  };
};

export const getLandingPageCountsSuccess = data => {
  return {
    type: 'GET_LANDING_PAGE_COUNTS_SUCCESS',
    data: data,
  };
};

export const getLandingPageCountsError = error => {
  return {
    type: 'GET_LANDING_PAGE_COUNTS_ERROR',
    error: error,
  };
};

export const fetchLandingPageKeyCountsData = accessToken => {
  return dispatch => {
    dispatch(getLandingPageKeyCounts(accessToken));
  };
};

export const getLandingPageKeyCountsPending = () => {
  return {
    type: 'GET_LANDING_PAGE_KEY_COUNTS_PENDING',
  };
};

export const getLandingPageKeyCountsSuccess = data => {
  return {
    type: 'GET_LANDING_PAGE_KEY_COUNTS_SUCCESS',
    data: data,
  };
};

export const getLandingPageKeyCountsError = error => {
  return {
    type: 'GET_LANDING_PAGE_KEY_COUNTS_ERROR',
    error: error,
  };
};

export const fetchLandingPageCoordinates = accessToken => {
  return dispatch => {
    dispatch(getLandingPageCoordinates(accessToken));
  };
};

export const getLandingPageCoordinatesPending = () => {
  return {
    type: 'GET_LANDING_PAGE_COORDINATES_PENDING',
  };
};

export const getLandingPageCoordinatesSuccess = data => {
  return {
    type: 'GET_LANDING_PAGE_COORDINATES_SUCESS',
    data: data,
  };
};

export const getLandingPageCoordinatesError = error => {
  return {
    type: 'GET_LANDING_PAGE_COORDINATES_ERROR',
    error: error,
  };
};

export const fetchMinimumStoreInfo = accessToken => {
  return dispatch => {
    dispatch(getMinimumStoreInfo(accessToken));
  };
};

export const getMinimumStoreInfoPending = () => {
  return {
    type: 'GET_MINIMUM_STORE_INFO_PENDING',
  };
};

export const getMinimumStoreInfoSuccess = data => {
  return {
    type: 'GET_MINIMUM_STORE_INFO_SUCCESS',
    data: data,
  };
};

export const getMinimumStoreInfoError = error => {
  return {
    type: 'GET_MINIMUM_STORE_INFO_ERROR',
    error: error,
  };
};

export const fetchMinimumSiteInfo = accessToken => {
  return dispatch => {
    dispatch(getMinimumSiteInfo(accessToken));
  };
};

export const getMinimumSiteInfoPending = () => {
  return {
    type: 'GET_MINIMUM_SITE_INFO_PENDING',
  };
};

export const getMinimumSiteInfoSuccess = data => {
  return {
    type: 'GET_MINIMUM_SITE_INFO_SUCCESS',
    payload: data,
  };
};

export const getMinimumSiteInfoError = error => {
  return {
    type: 'GET_MINIMUM_SITE_INFO_ERROR',
    payload: error,
  };
};

export const mergeStoreSite = data => {
  return {
    type: 'MERGE_STORE_AND_SITE',
    payload: data,
  };
};

// Post User Log
export const postUserInfo = (accessToken, data) => {
  return dispatch => {
    dispatch(postLogUserAction(accessToken, data));
  };
};

export const postUserInfoPending = () => {
  return {
    type: 'POST_USER_INFO_PENDING',
  };
};

export const postUserInfoSuccess = () => {
  return {
    type: 'POST_USER_INFO_SUCESS',
  };
};

export const postUserInfoError = error => {
  return {
    type: 'POST_USER_INFO_ERROR',
    error: error,
  };
};

export const userPageView = page => {
  return {
    type: 'USER_INFO_PAGE_VIEW',
    page: page,
  };
};

export const setUserInfo = user => {
  return {
    type: 'SET_USER_INFO',
    user: user,
  };
};

export const unsetUserInfo = () => {
  return {
    type: 'UNSET_USER_INFO',
  };
};

export const setUserAccessToken = accessToken => {
  return {
    type: 'SET_USER_ACCESS_TOKEN',
    accessToken: accessToken,
  };
};

export const setUserPayload = payload => {
  return {
    type: 'SET_USER_PAYLOAD',
    payload: payload,
  };
};

// Component Actions
// export const activeEventDefaultSelectionLoad = payload => {
//   return {
//     type: 'COMPONENT_ACTIVE_EVENT_DEFAULT_SELECTION_LOAD',
//     payload: payload,
//   };
// };

export const activeEventLoadTreeData = payload => {
  return {
    type: 'COMPONENT_ACTIVE_EVENT_LOAD_TREE_DATA',
    payload: payload,
  };
};

export const activeEventAddRemoveSelectedData = payload => {
  return {
    type: 'COMPONENT_ACTIVE_EVENT_ADD_REMOVE_SELECTED_DATA',
    payload: payload,
  };
};

export const historicalComparisonDefaultSelectionLoad = payload => {
  return {
    type: 'COMPONENT_HISTORICAL_COMPARISON_LOAD_TREE_DATA',
    payload: payload,
  };
};

export const historicalComparisonClearSelection = () => {
  return {
    type: 'COMPONENT_HISTORICAL_COMPARISON_CLEAR_TREE_DATA',
  };
};

export const historicalComparisonAddRemoveSelectedData = payload => {
  return {
    type: 'COMPONENT_HISTORICAL_COMPARISON_SELECT',
    payload: payload,
  };
};

export const historicalComparisonDateRange = payload => {
  return {
    type: 'COMPONENT_HISTORICAL_COMPARISON_DATERANGE',
    payload: payload,
  };
};

export const historicalComparisonSetLineChartData = payload => {
  return {
    type: 'COMPONENT_HISTORICAL_COMPARISON_SET_LINE_CHART_DATA',
    payload: payload,
  };
};

export const fetchStoreServices = accessToken => {
  return dispatch => {
    dispatch(getStoreServices(accessToken));
  };
};

export const getStoreServicesPending = () => {
  return {
    type: 'GET_STORE_SERVICES_PENDING',
  };
};

export const getStoreServicesSuccess = data => {
  return {
    type: 'GET_STORE_SERVICES_SUCCESS',
    data: data,
  };
};

export const getStoreServicesError = error => {
  return {
    type: 'GET_STORE_SERVICES_ERROR',
    error: error,
  };
};

export const fetchServicesByStore = (accessToken, siteIds) => {
  return dispatch => {
    dispatch(getServicesByStore(accessToken, siteIds));
  };
};

export const getServicesByStorePending = () => {
  return {
    type: 'GET_SERVICES_BY_STORE_PENDING',
  };
};

export const getServicesByStoreSuccess = data => {
  return {
    type: 'GET_SERVICES_BY_STORE_SUCCESS',
    data: data,
  };
};

export const getServicesByStoreError = error => {
  return {
    type: 'GET_SERVICES_BY_STORE_ERROR',
    error: error,
  };
};

export const postStoreServiceConfiguration = (accessToken, data) => {
  return dispatch => {
    dispatch(postStoreServiceConfigurationAction(accessToken, data));
  };
};

export const postStoreServiceConfigurationPending = () => {
  return {
    type: 'POST_STORE_CONFIGURATION_PENDING',
  };
};

export const postStoreServiceConfigurationSuccess = () => {
  return {
    type: 'POST_STORE_CONFIGURATION_SUCCESS',
  };
};

export const postStoreServiceConfigurationError = error => {
  return {
    type: 'POST_STORE_CONFIGURATION_ERROR',
    error: error,
  };
};

export const fetchDistinctIdfSearchItems = accessToken => {
  return dispatch => {
    dispatch(getDistinctIdfSearchItems(accessToken));
  };
};

export const getDistinctIdfSearchItemsPending = () => {
  return {
    type: 'GET_DISTINCT_IDF_SEARCH_ITEMS_PENDING',
  };
};

export const getDistinctIdfSearchItemsSuccess = data => {
  return {
    type: 'GET_DISTINCT_IDF_SEARCH_ITEMS_SUCCESS',
    data: data,
  };
};

export const getDistinctIdfSearchItemsError = error => {
  return {
    type: 'GET_DISTINCT_IDF_SEARCH_ITEMS_ERROR',
    error: error,
  };
};

import uiReducer from './uiReducer';
import toggleThemeReducer from './theme';
import apiReducer from './apiReducer';
import activeEventReducer from './activeEventReducer';
import dataMerge from './dataMerge';
import navigation from './navigation';
import userInfoReducer from './userInfoReducer';
import dataReducer from './data';
import {getmockGeoData} from './mockData';
import {combineReducers} from 'redux';
import componentActiveEventReducer from './componentActiveEventReducer';
import componentHistoricalComparisonReducer from './componentHistoricalComparison';
import componenGeoTradeStatusReducer from './componentGeoTradeStatusReducer';
import storeStatusCountReducer from './storeStatusCountReducer';
import deviceHealthCount from './deviceHealthCountReducer';
import storeServiceReducer from './storeServiceReducer';
import distinctIdfSearchItemsReducer from './distinctIdfSearchItemsReducer';
import minimumSiteInfoReducer from './minimumSiteInfoReducer';

const allReducers = combineReducers({
  ui: uiReducer,
  theme: toggleThemeReducer,
  navigation: navigation,
  mockGeoData: getmockGeoData,
  storeApiData: apiReducer,
  deviceHealthCount: deviceHealthCount,
  activeEventData: activeEventReducer,
  dataMerge: dataMerge,
  data: dataReducer,
  userInfo: userInfoReducer,
  storeStatusCount: storeStatusCountReducer,
  componentActiveEvent: componentActiveEventReducer,
  componentHistoricalComparison: componentHistoricalComparisonReducer,
  componentGeoTradeStatus: componenGeoTradeStatusReducer,
  storeService: storeServiceReducer,
  distinctIdfSearchItems: distinctIdfSearchItemsReducer,
  minimumSiteInfo: minimumSiteInfoReducer,
});

export default allReducers;

import Site from 'interfaces/Site';
import {SearchItem} from '../SiteIDSearch';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';

function getIdfSearchItemDisplayName(idfSearchItem: IdfSearchItem): string {
  let displayName = '';
  const siteName = idfSearchItem.siteName;
  const building = idfSearchItem.building;
  const opArea = idfSearchItem.operationalArea;
  if (building !== '' && building !== idfSearchItem.idf && building !== 'Only Building') {
    displayName = displayName.concat(`${building} - `);
  }

  if (opArea !== '' && opArea !== idfSearchItem.building && opArea !== idfSearchItem.idf) {
    displayName = displayName.concat(`${idfSearchItem.operationalArea} - `);
  }
  displayName = displayName.concat(`${idfSearchItem.idf}`);
  return `${siteName} ${displayName}`;
}

interface SearchAndSortProps {
  filteredSearchValue: string;
  sites: Site[];
  idfSearchItems: IdfSearchItem[];
}

export default function searchAndSort({
  filteredSearchValue,
  sites,
  idfSearchItems,
}: SearchAndSortProps): SearchItem[] {
  //#region functionLogic
  const retailAndShowroomSites = sites.filter(
    site => site.siteType === 'Retail' || site.siteType === 'Showroom'
  );

  const searchStores: SearchItem[] = retailAndShowroomSites;
  const searchIdfs: SearchItem[] = idfSearchItems.map(item => {
    const idfDisplayName = getIdfSearchItemDisplayName(item);
    const searchItem: SearchItem = {
      ...item,
      siteCode: item.siteId.substring(3),
      displayName: idfDisplayName,
      siteSearch: `${item.brand}|${idfDisplayName}|${item.idf.replace(/[^\w\s]/gi, '')}|${
        item.geo
      }|${item.siteId.substring(3)}|${item.siteId}|`.toLowerCase(),
      siteType: 'IDF',
    };

    return searchItem;
  });

  const searchItems = searchStores.concat(searchIdfs);
  const splitSearch: string[] = filteredSearchValue.split(' ');

  const siteCodeExactMatches: SearchItem[] = searchItems.filter((item: SearchItem) => {
    const siteCodeAsInt = parseInt(item.siteCode);
    const isMatch: boolean = splitSearch.some(searchTerm => {
      return (
        item.siteCode.toLowerCase() === searchTerm ||
        (!isNaN(siteCodeAsInt) && siteCodeAsInt === parseInt(searchTerm))
      );
    });

    return isMatch;
  });

  const siteIdMatches: SearchItem[] = searchItems.filter(item => {
    const isMatch: boolean = splitSearch.some(
      searchTerm => item.siteId.toLowerCase() === searchTerm
    );

    return !siteCodeExactMatches.includes(item) && isMatch;
  });

  const combinedSearch: SearchItem[] = searchItems.filter(item => {
    const isMatch: boolean = splitSearch.every(word => {
      return item.siteSearch.includes(word);
    });
    return !siteCodeExactMatches.includes(item) && !siteIdMatches.includes(item) && isMatch;
  });

  //#endregion functionLogic

  const value = [...siteCodeExactMatches, ...siteIdMatches, ...combinedSearch];

  return value;
}

import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {setUserInfo, updateNav} from '../../../redux/actions';
import TreeView from '@material-ui/lab/TreeView';
import NavTreeItem from './NavTreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useOktaAuth} from '@okta/okta-react';

interface Node {
  id: string;
  name: string;
  url: string;
  icon?: string;
  children?: Node[];
}

//#region styled components
const StyledNavTree = styled(TreeView)`
  width: 90%;
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
`;
//#endregion styled components

const navNodes: Node[] = [
  {
    id: 'rrp',
    name: 'RRP Dashboard',
    url: '/rrp',
    icon: 'dashboard',
  },
  {
    id: 'switch',
    name: 'Switch Connectivity',
    url: '/switch',
    icon: 'switch',
  },
  {
    id: 'inventories',
    name: 'Inventories',
    url: '/inventories',
    icon: 'inventory',
  },
  {
    id: 'storeServiceManager',
    name: 'Store Service Manager',
    url: '/store-service-manager',
    icon: 'code',
  },
  {
    id: 'contact',
    name: 'Contact',
    url: '/contact',
    icon: 'contact',
  },
  {
    id: 'settings',
    name: 'Settings',
    url: '/settings',
    icon: 'settings',
  },
];

const devNavNodes: Node[] = [
  {
    id: 'styleGuide',
    name: 'Style Guide',
    url: '/styleguide',
    icon: 'code',
  },
  {
    id: 'development',
    name: 'In Development',
    url: '/development',
    icon: 'code',
    children: [
      {
        id: 'newStoreTeam',
        name: 'New Store Team',
        url: '/nst/19',
      },
    ],
  },
];

function NavTree() {
  const dispatch = useDispatch();
  const {oktaAuth} = useOktaAuth();
  const expandedBranches = useSelector((state: any) => state.navigation);
  const debugView = useSelector((state: any) => state.ui.debugView);
  const isAuthorizedForStoreServiceManager = useSelector(
    (state: any) => state.userInfo.user?.isAuthorizedForStoreServiceManager
  );

  useEffect(() => {
    const dispatchSetUserInfo = async () => {
      const user = await oktaAuth.getUser();
      dispatch(setUserInfo(user));
    };

    dispatchSetUserInfo();
  }, [oktaAuth, dispatch]);

  const handleToggle = function handleToggle(nodeIds: string[]) {
    dispatch(updateNav(nodeIds));
  };

  function renderTree(node: Node) {
    return (
      <NavTreeItem
        key={node.id}
        id={node.id}
        nodeId={node.id}
        label={node.name}
        url={node.url}
        icon={node.icon}
      >
        {Array.isArray(node.children) ? node.children.map((node: Node) => renderTree(node)) : null}
      </NavTreeItem>
    );
  }

  return (
    <StyledNavTree
      expanded={expandedBranches}
      defaultCollapseIcon={<StyledExpandMoreIcon />}
      defaultExpandIcon={<StyledChevronRightIcon />}
      onNodeToggle={(e, nodeIds: string[]) => {
        handleToggle(nodeIds);
      }}
    >
      {navNodes.map(node => {
        return node.id !== 'storeServiceManager' || isAuthorizedForStoreServiceManager
          ? renderTree(node)
          : null;
      })}

      {/* debug view is meant for users who have admin access */}
      {debugView ? devNavNodes.map(node => renderTree(node)) : ''}
    </StyledNavTree>
  );
}

export default NavTree;

import IAlert from 'features/RRP/interfaces/IAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import defaultNullAlert from './utility/defaultNullAlert';
import isStringValidNum from './utility/isStringValidNum';
const {
  HEALTHY,
  MINOR,
  MAJOR,
  UNKNOWN,
} = AlertSeverityEnum;
export default function internetBProvisionedAlertValue(
  internetBProvisioned_Upload: string,
  internetBProvisioned_Download: string,
  internetBProvisioned_Type: string,
): IAlert {
  if (
    internetBProvisioned_Upload === null ||
    internetBProvisioned_Download === null ||
    internetBProvisioned_Type === null ||
    !isStringValidNum(internetBProvisioned_Upload) ||
    !isStringValidNum(internetBProvisioned_Download)
  ) {
    return defaultNullAlert([
      'internetBProvisioned_Upload',
      'internetBProvisioned_Download',
      'internetBProvisioned_Type'
    ]);
  }


  const alert: IAlert = {
    value: HEALTHY,
    issue: [],
    effect: [],
    solution: [],
  };

  const upload: RegExpMatchArray | null = internetBProvisioned_Upload.match(/\d/g);
  const download: RegExpMatchArray | null = internetBProvisioned_Download.match(/\d/g);

  if (upload === null || download === null) {
    return {
      value: UNKNOWN,
      issue: [`internetBProvisioned_Upload and/or internetBProvisioned_Download values were invalid.`],
      effect: ['Upload and download values could not be evaluated.'],
      solution: [`Check the file internetBProvisionedAlertValue.ts for values the function is not expecting.`],
    }
  }

  const uploadNum: number = parseInt(upload.join(''));
  const downloadNum: number = parseInt(download.join(''));
  if (isNaN(uploadNum) || isNaN(downloadNum)) {
    return {
      value: UNKNOWN,
      issue: [`internetBProvisioned_Upload and/or internetBProvisioned_Download values were invalid.`],
      effect: ['Upload and download values could not be evaluated.'],
      solution: [`Check the file internetBProvisionedAlertValue.ts for values the function is not expecting.`],
    };
  }

  if (internetBProvisioned_Type.toUpperCase() === 'BROADBAND') {
    if (uploadNum < 35 || downloadNum < 600) {
      alert.value = MINOR;
      alert.issue.push('If speed not met (Broadband) : The transport does not meet the WAN Transport B standard of  600M / 35M bandwidth when using Broadband.');
      alert.effect.push('May cause slow or intermittently failing network connections for whole store when paired with high utilization.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport B standard of 100M / 100M DIA or 600M / 35M Broadband. Especially critical when WAN Transport B Utilization is high, or SDWAN QoE is low.');
    }
  } else if (internetBProvisioned_Type.toUpperCase() === 'DIA') {
    if (uploadNum < 100 || downloadNum < 100) {
      alert.value = MAJOR;
      alert.issue.push('The transport does not meet the WAN Transport B standard of 100M / 100M bandwidth when using Dedicated Internet Access (DIA).');
      alert.effect.push('May cause slow or intermittently failing network connections for whole store when paired with high utilization.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport B standard of 100M / 100M DIA or 600M / 35M Broadband. Especially critical when WAN Transport B Utilization is high, or SDWAN QoE is low.');
    }
  }

  if (alert.value === MINOR) {
    alert.issue.push('The transport does not meet the WAN Transport B standard of 100M / 100M bandwidth when using Dedicated Internet Access (DIA).');
    alert.effect.push('May cause slow or intermittently failing network connections for whole store when paired with high utilization.');
    alert.solution.push('Consider upgrading transport to meet standard of WAN Transport B standard of 100M / 100M DIA or 600M / 35M Broadband. Especially critical when WAN Transport B Utilization is high, or SDWAN QoE is low.');
  }

  return alert;
}

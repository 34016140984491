import IStatusSummary from "features/RRP/interfaces/IStatusSummary";
import IStoreData from "features/RRP/interfaces/IStoreData";
import AlertSeverityEnum from "features/RRP/enums/AlertSeverityEnum";

export const defaultStatusSummary: IStatusSummary = {
  isLoaded: false,
  stores: {
    CRITICAL: 0,
    MAJOR: 0,
    MINOR: 0,
    HEALTHY: 0,
    UNKNOWN: 0,
  },
  WANTransport: {
    CRITICAL: 0,
    MAJOR: 0,
    MINOR: 0,
    HEALTHY: 0,
    UNKNOWN: 0,
  },
  SDWAN: {
    CRITICAL: 0,
    MAJOR: 0,
    MINOR: 0,
    HEALTHY: 0,
    UNKNOWN: 0,
  },
  switching: {
    CRITICAL: 0,
    MAJOR: 0,
    MINOR: 0,
    HEALTHY: 0,
    UNKNOWN: 0,
  },
  wireless: {
    CRITICAL: 0,
    MAJOR: 0,
    MINOR: 0,
    HEALTHY: 0,
    UNKNOWN: 0,
  },
};

const getgetOccurrence = function getOccurrence(array: AlertSeverityEnum[], value: AlertSeverityEnum) {
  return array.filter((v) => (v === value)).length;
}

function createStatusSummaryObj(storeData: IStoreData[]) {
  //deep copy the object
  let summedAlerts: IStatusSummary = JSON.parse(JSON.stringify(defaultStatusSummary));
  let WANTransport: AlertSeverityEnum[] = [];
  let SDWAN: AlertSeverityEnum[] = [];
  let switching: AlertSeverityEnum[] = [];
  let wireless: AlertSeverityEnum[] = [];

  storeData.forEach((store) => {
    if (AlertSeverityEnum.CRITICAL === store.alertValue.value) {
      summedAlerts.stores.CRITICAL += 1;
    } else if (AlertSeverityEnum.MAJOR === store.alertValue.value) {
      summedAlerts.stores.MAJOR += 1;
    } else if (AlertSeverityEnum.MINOR === store.alertValue.value) {
      summedAlerts.stores.MINOR += 1;
    } else if (AlertSeverityEnum.HEALTHY === store.alertValue.value) {
      summedAlerts.stores.HEALTHY += 1;
    } else if (AlertSeverityEnum.UNKNOWN === store.alertValue.value) {
      summedAlerts.stores.UNKNOWN += 1;
    }

    WANTransport.push(store.internetAProvisionedAlertValue.value);
    WANTransport.push(store.internetAUtilizationAlertValue.value);
    WANTransport.push(store.internetBProvisionedAlertValue.value);
    WANTransport.push(store.internetBUtilizationAlertValue.value);
    WANTransport.push(store.cellularBackupAlertValue.value);

    SDWAN.push(store.SDWANHardwareProvisionedAlertValue.value);
    SDWAN.push(store.SDWANEffectivenessQoEAlertValue.value);

    switching.push(store.switchingHardwareProvisionedAlertValue.value);
    switching.push(store.switchingHardwarePortUtilizationAlertValue.value);
    switching.push(store.switchingHardwarePoEBudgetAlertValue.value);
    switching.push(store.switchingHardwarePoEAtCapacityAlertValue.value);

    wireless.push(store.wirelessAPHardwareProvisionedAlertValue.value);
    wireless.push(store.wirelessAPUpStatusAlertValue.value);
    wireless.push(store.wirelessClientHealthAvgAlertValue.value);
    wireless.push(store.wirelessAPHealthAlertValue.value);
  });

  summedAlerts.WANTransport.CRITICAL = getgetOccurrence(WANTransport, AlertSeverityEnum.CRITICAL);
  summedAlerts.WANTransport.MAJOR = getgetOccurrence(WANTransport, AlertSeverityEnum.MAJOR);
  summedAlerts.WANTransport.MINOR = getgetOccurrence(WANTransport, AlertSeverityEnum.MINOR);
  summedAlerts.WANTransport.HEALTHY = getgetOccurrence(WANTransport, AlertSeverityEnum.HEALTHY);
  summedAlerts.WANTransport.UNKNOWN = getgetOccurrence(WANTransport, AlertSeverityEnum.UNKNOWN);

  summedAlerts.SDWAN.CRITICAL = getgetOccurrence(SDWAN, AlertSeverityEnum.CRITICAL);
  summedAlerts.SDWAN.MAJOR = getgetOccurrence(SDWAN, AlertSeverityEnum.MAJOR);
  summedAlerts.SDWAN.MINOR = getgetOccurrence(SDWAN, AlertSeverityEnum.MINOR);
  summedAlerts.SDWAN.HEALTHY = getgetOccurrence(SDWAN, AlertSeverityEnum.HEALTHY);
  summedAlerts.SDWAN.UNKNOWN = getgetOccurrence(SDWAN, AlertSeverityEnum.UNKNOWN);

  summedAlerts.switching.CRITICAL = getgetOccurrence(switching, AlertSeverityEnum.CRITICAL);
  summedAlerts.switching.MAJOR = getgetOccurrence(switching, AlertSeverityEnum.MAJOR);
  summedAlerts.switching.MINOR = getgetOccurrence(switching, AlertSeverityEnum.MINOR);
  summedAlerts.switching.HEALTHY = getgetOccurrence(switching, AlertSeverityEnum.HEALTHY);
  summedAlerts.switching.UNKNOWN = getgetOccurrence(switching, AlertSeverityEnum.UNKNOWN);

  summedAlerts.wireless.CRITICAL = getgetOccurrence(wireless, AlertSeverityEnum.CRITICAL);
  summedAlerts.wireless.MAJOR = getgetOccurrence(wireless, AlertSeverityEnum.MAJOR);
  summedAlerts.wireless.MINOR = getgetOccurrence(wireless, AlertSeverityEnum.MINOR);
  summedAlerts.wireless.HEALTHY = getgetOccurrence(wireless, AlertSeverityEnum.HEALTHY);
  summedAlerts.wireless.UNKNOWN = getgetOccurrence(wireless, AlertSeverityEnum.UNKNOWN);

  summedAlerts.isLoaded = true;

  return summedAlerts;
}

export default createStatusSummaryObj;
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Stores} from 'interfaces/Stores';
import SGBody from 'components/Typography/SGBody';

// check white space

interface StoreNameProps {
  siteId: string;
}

function StoreName({siteId}: StoreNameProps) {
  const isStoreInfoListReady: boolean = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo.dataRetrieved
  );
  const storeInfoList: Stores = useSelector(
    (state: any) => state.data.app.searchMinimumStoreInfo?.data
  );
  const [storeName, setStoreName] = useState<string>();
  // consider convert to just pure json instead of react component for better re-usability
  useEffect(() => {
    if (isStoreInfoListReady) {
      const matchedStore = storeInfoList.find(store => {
        return store.SiteId === siteId;
      });
      setStoreName(`${matchedStore?.StoreNumber} ${matchedStore?.StoreName}`);
    }
  }, [isStoreInfoListReady, storeInfoList, siteId]);

  return <SGBody variant={'b4'}>{storeName}</SGBody>;
}

export default StoreName;

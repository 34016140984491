import React from 'react';
import styled from 'styled-components';
import Paper from 'components/Paper';
import SGHeadline from 'components/Typography/SGHeadline';
import Grid from '@material-ui/core/Grid';
import SGTitle from 'components/Typography/SGTitle';
import AlertSummaryButton from './components/AlertSummaryButton';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';
import DeviceButton from './components/DeviceButton';
import IStatusSummary from 'features/RRP/interfaces/IStatusSummary';
import StatusSummaryFilterEnum from 'features/RRP/enums/StatusSummaryFilterEnum';
interface StatusSummaryProps {
  filters: StatusSummaryFilterEnum;
  handleFilters: (value: StatusSummaryFilterEnum) => void;
  statusCounts: IStatusSummary;
}

const StyledPaper = styled(Paper)`
  width: 100%;
`;

const StyledStoreAlertSummary = styled.div``;
const StyledButtonContainer = styled.div`
  margin-top: 10px;
`;

function StatusSummary({filters, handleFilters, statusCounts}: StatusSummaryProps) {
  const handleClick = function handleClick(
    event: React.MouseEvent<HTMLButtonElement>,
    value: StatusSummaryFilterEnum
  ) {
    event.preventDefault();
    handleFilters(value);
  };

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SGHeadline variant={'h5'} className={'text-color'}>
            Status Summary
          </SGHeadline>
        </Grid>
        <Grid item>
          <StyledStoreAlertSummary>
            <SGTitle variant={'t4'}>Number of stores with Alerts</SGTitle>
            <StyledButtonContainer>
              <AlertSummaryButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.Critical)}
                alertType={AlertSeverityEnum.CRITICAL}
                count={statusCounts.stores.CRITICAL}
                filters={filters}
                value={StatusSummaryFilterEnum.Critical}
              />
              <AlertSummaryButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.Major)}
                alertType={AlertSeverityEnum.MAJOR}
                count={statusCounts.stores.MAJOR}
                filters={filters}
                value={StatusSummaryFilterEnum.Major}
              />
              <AlertSummaryButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.Minor)}
                alertType={AlertSeverityEnum.MINOR}
                count={statusCounts.stores.MINOR}
                filters={filters}
                value={StatusSummaryFilterEnum.Minor}
              />
              <AlertSummaryButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.Healthy)}
                alertType={AlertSeverityEnum.HEALTHY}
                count={statusCounts.stores.HEALTHY}
                filters={filters}
                value={StatusSummaryFilterEnum.Healthy}
              />
            </StyledButtonContainer>
          </StyledStoreAlertSummary>
        </Grid>
        <Grid item>
          <StyledStoreAlertSummary>
            <SGTitle variant={'t4'}>Number of alerts per device type</SGTitle>
            <StyledButtonContainer>
              <DeviceButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.WANTransport)}
                value={StatusSummaryFilterEnum.WANTransport}
                filters={filters}
                deviceName={'WAN Transport'}
                statusCounts={statusCounts.WANTransport}
              />
              <DeviceButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.SDWAN)}
                value={StatusSummaryFilterEnum.SDWAN}
                filters={filters}
                deviceName={'SDWAN'}
                statusCounts={statusCounts.SDWAN}
              />
              <DeviceButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.switching)}
                value={StatusSummaryFilterEnum.switching}
                filters={filters}
                deviceName={'Switching'}
                statusCounts={statusCounts.switching}
              />
              <DeviceButton
                onClick={event => handleClick(event, StatusSummaryFilterEnum.wireless)}
                value={StatusSummaryFilterEnum.wireless}
                filters={filters}
                deviceName={'Wireless'}
                statusCounts={statusCounts.wireless}
              />
            </StyledButtonContainer>
          </StyledStoreAlertSummary>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default StatusSummary;

import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import allReducer from './reducers';
import {initialState as apiState} from './reducers/apiReducer';
import theme from '../ui/theme/theme';
import {initialState as navigationState} from './reducers/navigation';
import {mockGeoData} from './mockData';

const initialState = {
  theme: theme,
  mockGeoData: mockGeoData,
  storeApiData: apiState,
  navigation: navigationState,
};

window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

const store = createStore(
  allReducer,
  initialState,
  compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f)
);

export default store;

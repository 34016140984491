import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchServicesByStore,
  fetchStoreServices,
  postStoreServiceConfiguration,
} from 'redux/actions';
import styled from 'styled-components';
import {Grid, List} from '@material-ui/core';
import Layout from './Layout';
import Paper from '../components/Paper';
import StyledContainer from '../components/Container';
import Title from '../components/Typography/Title';
import ListItem from 'components/StoreTransferList/components/ListItem';
import PaperTitle from 'components/PaperTitle';
import SGButton from 'components/CTA/SGButton';
import ServiceTransferList from 'components/StoreServiceManager/ServiceTransferList';
import ConfirmationDialog from 'components/StoreServiceManager/ConfirmationDialog';
import StoreTransferList from 'components/StoreServiceManager/StoreTransferList';
import AddOrRemoveRadioButtons from 'components/StoreServiceManager/AddOrRemoveRadioButtons';
import {StoreAction} from 'components/StoreServiceManager/types/StoreAction';
import {Stores, getStoreDisplayName} from 'interfaces/Stores';
import {ServiceDictionary, Services} from 'components/StoreServiceManager/interfaces/Services';
import {StoreConfigStatus} from 'components/StoreServiceManager/types/StoreConfigStatus';
import NoServicesDialog from 'components/StoreServiceManager/NoServicesDialog';
import NoServicesSnackbar from 'components/StoreServiceManager/NoServicesSnackbar';
import NavigationButtons from 'components/StoreServiceManager/NavigationButtons';
import {useOktaAuth} from '@okta/okta-react';
import NoAccessDialog from 'components/StoreServiceManager/NoAccessDialog';
import getPayloadsFromStores from 'components/StoreServiceManager/getPayloadsFromStores';

// check white space

//#region styled components
const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 16px;
`;
const StyledRightDiv = styled.div`
  display: flex;
  justify-content: right;
`;
//#endregion styled components

function StoreServiceManager() {
  const dispatch = useDispatch();
  const {oktaAuth} = useOktaAuth();
  const accessToken: string | undefined = oktaAuth.getAccessToken();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedStores, setSelectedStores] = useState<Stores>([]);
  const [selectedServices, setSelectedServices] = useState<Services>([]);
  const [storeAction, setStoreAction] = useState<StoreAction>('default');
  const [storeConfigStatus, setStoreConfigStatus] = useState<StoreConfigStatus>('default');
  const [canSnackbarOpen, setCanSnackbarOpen] = useState<boolean>(true);
  const [canNoServicesDialogOpen, setCanNoServicesDialogOpen] = useState<boolean>(true);
  const [selectedStoresWithoutServices, setSelectedStoresWithoutServices] = useState<Stores>([]);
  const [updatedServicesByStore, setUpdatedServicesByStore] = useState<ServiceDictionary>({});

  //#region handlers
  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 2) {
        dispatch(
          postStoreServiceConfiguration(
            accessToken,
            getPayloadsFromStores(
              selectedStores,
              storeConfigStatus,
              updatedServicesByStore,
              selectedStoresWithoutServices
            )
          )
        );
        setStoreAction('default');
        setStoreConfigStatus('default');
        setSelectedStores([]);
        setSelectedServices([]);
        return 0;
      }
      if (prevActiveStep === 0) {
        setCanNoServicesDialogOpen(true);
        setCanSnackbarOpen(true);
        dispatch(
          fetchServicesByStore(
            accessToken,
            selectedStores.map(store => store.SiteId)
          )
        );
        return (prevActiveStep + 1) % 3;
      } else {
        return (prevActiveStep + 1) % 3;
      }
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 1) {
        setStoreConfigStatus('default');
      }
      return prevActiveStep - 1;
    });
  };
  //#endregion handlers

  const isAuthorizedForStoreServiceManager = useSelector<boolean>(
    (state: any) => state.userInfo.user?.isAuthorizedForStoreServiceManager
  );

  useEffect(() => {
    dispatch(fetchStoreServices(accessToken));
  }, [dispatch, accessToken]);

  const storeServiceManager = (
    <Layout>
      <StyledContainer>
        <Grid container spacing={4} direction="row">
          <Grid item xs={12}>
            <StyledTitle>Store Service Manager</StyledTitle>
          </Grid>
          <Grid item xs={2} sm={4}>
            {activeStep === 0 && (
              <AddOrRemoveRadioButtons storeAction={storeAction} setStoreAction={setStoreAction} />
            )}
            {activeStep !== 0 && (
              <Paper>
                <PaperTitle>Selected Stores</PaperTitle>
                <List>
                  {selectedStores.map(store => (
                    <ListItem
                      icon={'none'}
                      handleClick={() => {}}
                      text={getStoreDisplayName(store)}
                      key={store.SiteId}
                    />
                  ))}
                </List>
              </Paper>
            )}
          </Grid>
          <Grid item xs={10} sm={8}>
            {activeStep === 0 && (
              <>
                <StoreTransferList
                  setSelectedStores={setSelectedStores}
                  selectedStores={selectedStores}
                  storeAction={storeAction}
                />
                <NavigationButtons
                  isBackButtonVisible={false}
                  handleBack={() => {}}
                  handleNext={handleNext}
                  isDisabled={storeAction === 'default' || selectedStores.length === 0}
                />
              </>
            )}
            {activeStep !== 0 && (
              <>
                <ServiceTransferList
                  storeAction={storeAction}
                  selectedStores={selectedStores}
                  selectedServices={selectedServices}
                  setStoreConfigStatus={setStoreConfigStatus}
                  setSelectedServices={setSelectedServices}
                  setSelectedStoresWithoutServices={setSelectedStoresWithoutServices}
                  setUpdatedServicesByStore={setUpdatedServicesByStore}
                />
                <NavigationButtons
                  handleBack={handleBack}
                  handleNext={handleNext}
                  nextButtonLabel="Apply"
                  nextButtonTooltip={
                    storeConfigStatus === 'hasNoServices' && selectedServices.length === 1
                      ? 'Select more than 1 service. Review alert message for details'
                      : ''
                  }
                  isDisabled={
                    selectedServices.length === 0 ||
                    (storeConfigStatus === 'hasNoServices' && selectedServices.length <= 1)
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {activeStep === 1 && storeConfigStatus === 'hasNoServices' && !canNoServicesDialogOpen && (
              <StyledRightDiv>
                <SGButton onClick={() => setCanNoServicesDialogOpen(true)}>
                  Review Alert Message
                </SGButton>
              </StyledRightDiv>
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          onClose={handleBack}
          isOpen={activeStep === 2}
          handleNext={handleNext}
          stores={
            storeConfigStatus === 'mixed'
              ? selectedStores.filter(store => !selectedStoresWithoutServices.includes(store))
              : selectedStores
          }
          services={selectedServices}
          storeAction={storeAction}
        ></ConfirmationDialog>
        <NoServicesDialog
          onClose={() => {
            setCanNoServicesDialogOpen(false);
            if (storeAction === 'remove') {
              handleBack();
            }
          }}
          isOpen={
            canNoServicesDialogOpen && activeStep === 1 && storeConfigStatus === 'hasNoServices'
          }
          stores={selectedStores}
          storeAction={storeAction}
        ></NoServicesDialog>
        <NoServicesSnackbar
          isOpen={canSnackbarOpen && activeStep !== 0 && storeConfigStatus === 'mixed'}
          setCanSnackbarOpen={setCanSnackbarOpen}
          selectedStoresWithoutServices={selectedStoresWithoutServices}
        />
      </StyledContainer>
    </Layout>
  );

  return isAuthorizedForStoreServiceManager ? storeServiceManager : <NoAccessDialog />;
}

export default StoreServiceManager;

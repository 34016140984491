import React, {useState} from 'react';
import './App.css';
import styled from 'styled-components';
import {ThemeProvider} from 'styled-components';
import GlobalStyle from './ui/theme/globalStyle';
import {StylesProvider} from '@material-ui/core/styles';
import {Route, useHistory, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import config from './oktaConfig';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import Error from 'components/okta/Error';
import Contact from './views/Contact';
import Settings from './views/Settings';
import StyleGuide from './views/StyleGuide';
import NotFound from './views/NotFound';
import Development from './views/Development';
import Maintenance from './components/Maintenance';
import ReoccurringRetailPhysical from './views/ReoccuringRetailPhysical';
import {maintenance} from './constants/releaseConfig';
import BrowserWarning from 'views/BrowserWarning';
import AuthRequiredModal from 'components/okta/AuthRequiredModal';
import Crowdstrike from 'views/Crowdstrike';
import SwitchConnectivity from 'views/SwitchConnectivity';
import StoreServiceManager from 'views/StoreServiceManager';
import WelcomePage from 'views/WelcomePage';
import SiteSearch from 'views/SiteSearch';
import Inventories from 'views/Inventories';

const StyledApp = styled.div`
  background-color: #155667;
  background-image: linear-gradient(
    to bottom right,
    ${props => props.theme.bgStart},
    ${props => props.theme.bgEnd}
  );
  min-height: 100vh;
  width: fit-content;
  min-width: calc(100vw - (100vw - 100%));
`;

const oktaAuth = new OktaAuth(config.oidc);
function App() {
  //okta
  // const { authState } = useOktaAuth();

  //theme
  const theme = useSelector(state => state.theme);
  const themeSelected = theme[theme.select];
  const {maintenanceEnabled} = maintenance;
  const [maintenanceWindow, setMaintenanceWindow] = useState(maintenanceEnabled);

  // const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);

  const Callback = () => <LoginCallback errorComponent={Error} />;

  const history = useHistory(); // example from react-router

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async oktaAuth => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    // todo: Ask Byron if we need to keep this if statement
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      // setAuthRequiredModalOpen(true);
      await triggerLogin();
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ThemeProvider theme={themeSelected}>
        {/* injectFirst is required here to allow material ui to work with styled-components https://material-ui.com/guides/interoperability/#styled-components */}
        <StylesProvider injectFirst>
          <GlobalStyle />
          <StyledApp>
            <AuthRequiredModal
              authRequiredModalOpen={authRequiredModalOpen}
              setAuthRequiredModalOpen={setAuthRequiredModalOpen}
              triggerLogin={triggerLogin}
            />
            {maintenanceWindow && (
              <Maintenance
                maintenanceWindow={maintenanceWindow}
                setMaintenanceWindow={setMaintenanceWindow}
              />
            )}

            <Switch>
              <SecureRoute path="/" exact component={WelcomePage} />
              <Route path="/implicit/callback" component={Callback} />
              <Route path="/logout" component={BrowserWarning} />
              <SecureRoute path="/contact" component={Contact} />
              <SecureRoute path="/settings" component={Settings} />
              <SecureRoute path="/rrp" component={ReoccurringRetailPhysical} />
              <SecureRoute path="/crowdstrike" component={Crowdstrike} />
              <SecureRoute path="/styleguide" component={StyleGuide} />
              <SecureRoute path="/development" component={Development} />
              <SecureRoute path="/switch" exact component={SwitchConnectivity} />
              <SecureRoute path="/switch/:siteId/:idfName?" component={SwitchConnectivity} />
              <SecureRoute path="/store-service-manager" component={StoreServiceManager} />
              <SecureRoute
                path="/site-search/:urlKey/:brand/:geo/:siteType/:status"
                component={SiteSearch}
              />
              <SecureRoute path="/inventories/:tab?/:filters?" component={Inventories} />
              <Route component={NotFound} />
              {/* <Route path="/dashboard" exact component={Home} /> */}
              {/* <Route path="/dashboard/:id" component={Dashboard} /> */}
              {/* <Route path="/store/:id" exact component={StoreDetail} /> */}
              {/* <Route path="/store/:id/:view" component={StoreDetail} /> */}
              {/* <Route path="/nst/:id" exact component={NewStoreTeam} /> */}
              {/* <Route path="/events" component={EventViewer} /> */}
              {/* <Route path="/about" component={About} /> */}
              {/* <Route path="/about-trading" component={AboutTrading} /> */}
              {/* <Route path="/about-faq" component={AboutFAQ} /> */}
              {/* <Route path="/about-patch" component={AboutPatch} /> */}
              {/* <Route path="/data-source" component={DataSources} /> */}
              {/* <Route path="/sredev" component={SREDev} /> */}
              {/* <Route path="/maptest" component={MapTest} /> */}
            </Switch>
          </StyledApp>
        </StylesProvider>
      </ThemeProvider>
    </Security>
  );
}

export default App;

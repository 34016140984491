import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import Tab from './Tab';
import TabPane from './TabPane';
import StoreEvents from '../../Tables/StoreEventsTable';
import DashboardEventsTable from '../../Tables/DashboardEventsTable';

const StyledTabbedContent = styled.div``;

const StyledTabbedContainer = styled.div`
  display: flex;
  border-bottom: 1px solid grey;
`;

const StyledTabPanel = styled.div`
  min-height: 29rem;
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabbedContent(props) {
  const {geo = 'na'} = props;

  // Fetching Issues Data
  const reduxStoreData = useSelector(state => state.storeApiData);
  const dashboardRiskIssuesCountRedux = useSelector(
    state => state.data.app.dashboardRiskIssuesCount
  );
  const dashboardDiminishedIssuesCountRedux = useSelector(
    state => state.data.app.dashboardDiminishedIssuesCount
  );

  const [activeId, setActiveId] = useState(0);
  const [riskDataLoaded, setRiskDataLoaded] = useState(false);
  const [diminishedLoaded, setDiminishedDataLoaded] = useState(false);
  const [riskStateData, setRiskStateData] = useState([]);
  const [issueStateData, setIssueStateData] = useState([]);

  const handleClick = newValue => {
    setActiveId(newValue);
  };

  useEffect(() => {
    const riskReduxData = dashboardRiskIssuesCountRedux.data;
    const riskPending = dashboardRiskIssuesCountRedux.pending;
    const riskRetrieved = dashboardRiskIssuesCountRedux.dataRetrieved;

    const searchStoreForKey = function searchStoreForKey(issueData, storeData) {
      let mergedData = [...issueData];
      mergedData.forEach(event => {
        let matchedStore = storeData.find(store => store.StoreNumber == event.storeNumber);
        if (matchedStore !== undefined) {
          event.isKeyMarket = matchedStore.IsKeyMarket;
        } else {
          // console.log('no match', event);
        }
      });
      return mergedData;
    };

    if (riskPending) {
      setRiskStateData([]);
    } else if (riskRetrieved) {
      setRiskStateData(searchStoreForKey(riskReduxData, reduxStoreData.data));
      setRiskDataLoaded(true);
    }

    const issueReduxData = dashboardDiminishedIssuesCountRedux.data;
    const diminishedPending = dashboardDiminishedIssuesCountRedux.pending;
    const diminishedRetrieved = dashboardDiminishedIssuesCountRedux.dataRetrieved;
    if (diminishedPending) {
      setIssueStateData([]);
    } else if (diminishedRetrieved) {
      setIssueStateData(searchStoreForKey(issueReduxData, reduxStoreData.data));
      setDiminishedDataLoaded(true);
    }
  }, [geo, dashboardRiskIssuesCountRedux, dashboardDiminishedIssuesCountRedux, reduxStoreData]);

  return (
    <StyledTabbedContent>
      <StyledTabbedContainer>
        <Tab
          title={'Unable to Trade'}
          tabId={0}
          activeId={activeId}
          onClick={() => handleClick(0)}
          {...a11yProps(0)}
        />
        <Tab
          title={'At Risk'}
          tabId={1}
          activeId={activeId}
          onClick={() => handleClick(1)}
          {...a11yProps(1)}
        />
        <Tab
          title={'Diminished Capabilities'}
          tabId={2}
          activeId={activeId}
          onClick={() => handleClick(2)}
          {...a11yProps(2)}
        />
      </StyledTabbedContainer>
      <StyledTabPanel>
        <TabPane tabId={0} activeId={activeId}>
          <StoreEvents geo={geo} />
        </TabPane>
        <TabPane tabId={1} activeId={activeId}>
          <DashboardEventsTable
            title={`At Risk Stores`}
            data={riskStateData}
            loaded={riskDataLoaded}
            color={'orange'}
            noDataText={'No At Risk Stores to show at the moment.'}
          />
        </TabPane>
        <TabPane tabId={2} activeId={activeId}>
          <DashboardEventsTable
            title={`Diminished Store Capabilities`}
            data={issueStateData}
            loaded={diminishedLoaded}
            color={'yellow'}
            noDataText={'No Diminished Stores to show at the moment.'}
          />
        </TabPane>
      </StyledTabPanel>
    </StyledTabbedContent>
  );
}

export default TabbedContent;

import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const StyledLinkRouter = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  font-family: 'Trade Gothic';
  font-size: 1.2rem;
  color: ${props => props.theme.text};

  &:active,
  &:visited,
  &:hover,
  &:focus {
    color: ${props => props.theme.focusText};
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.theme.focusText};
  }
`;

const StyledChevronIcon = styled(ChevronRightRoundedIcon)`
  display: block;
  transition: all 0.25s ease;
  width: 24px;
  ${StyledLinkRouter}:hover & {
    width: 0px;
  }
`;

const StyledArrowIcon = styled(ArrowForwardRoundedIcon)`
  transition: all 0.25s ease;
  width: 0px;
  ${StyledLinkRouter}:hover & {
    width: 24px;
  }
`;

function CustomButton(props) {
  const {children, to} = props;
  return (
    <StyledLinkRouter to={to}>
      {children}
      <StyledArrowIcon />
      <StyledChevronIcon />
    </StyledLinkRouter>
  );
}

export default CustomButton;

import {useState, useEffect} from 'react';
import config from '../../../oktaConfig';
import {useSelector} from 'react-redux';
import dataReducerRRP from '../components/dataReducerRRP';
import {useOktaAuth} from '@okta/okta-react';

function FetchGeoHook(geo) {
  const {authState, oktaAuth} = useOktaAuth();
  const [isGeoLoaded, setIsGeoLoaded] = useState(false);
  const [geoData, setGeoData] = useState([]);

  const storeInfoList = useSelector(state => state.data.app.searchMinimumStoreInfo?.data);
  useEffect(() => {
    setIsGeoLoaded(false);
    setGeoData([]);
    if (authState && authState.isAuthenticated && geo !== 'custom') {
      const accessToken = oktaAuth.getAccessToken();
      const controller = new AbortController();
      setIsGeoLoaded(false);
      fetch(
        config.resourceServer.insightsApiUrlRetailRecurringPhysical + `?geo=${geo}&brand=nike`,
        {
          mode: 'cors',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: controller.signal,
        }
      )
        .then(response => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(data => {
          setGeoData(dataReducerRRP(data, storeInfoList));
          setIsGeoLoaded(true);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          // setIsGeoLoaded(true);
        });

      return () => {
        controller.abort();
        setIsGeoLoaded(false);
      };
    } else {
      setGeoData([]);
      setIsGeoLoaded(false);
    }
  }, [geo, storeInfoList, authState, oktaAuth]);

  return {
    isGeoLoaded,
    geoData,
  };
}

export default FetchGeoHook;

import destructureIdString from './destructureIdString';

const nestedObjHammer = function nestedObjHammer(payload) {
  let planarArray = [];
  let selectedNodeIds = [];

  const nestHammer = nodes => {
    nodes.forEach((node, index) => {
      const {eventType, deviceId, catId, subCatId} = destructureIdString(node.id);
      let planarNode = {
        name: node.name,
        id: node.id,
        checked: false,
        deviceId: deviceId,
        catId: catId,
        subCatId: subCatId,
        eventType: eventType,
        colorId: index,
      };

      if (node.children?.length >= 1) {
        nestHammer(node.children);
      }

      if (eventType === 'device') {
        selectedNodeIds.push(node.id);
      }

      planarArray.push(planarNode);
    });
  };

  nestHammer(payload);

  return {
    planarArray: planarArray,
    selectedNodeIds: selectedNodeIds,
  };
};

export default nestedObjHammer;

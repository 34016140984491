import {ServiceDictionary, Services} from './interfaces/Services';

/**
 * @param servicesByStore
 * @returns a list of unique services that appear in servicesByStore
 */
export const getUniqueServices: (servicesByStore: ServiceDictionary) => Services = (
  servicesByStore: ServiceDictionary
) => {
  const uniqueServicesInStores: Services = [];
  const uniqueIds: Set<string> = new Set();

  Object.values(servicesByStore).forEach(services => {
    services.forEach(service => {
      if (!uniqueIds.has(service.id)) {
        uniqueIds.add(service.id);
        uniqueServicesInStores.push(service);
      }
    });
  });

  return uniqueServicesInStores;
};

export default getUniqueServices;

import extractNumberFromString from './utility/extractNumberFromString';
import IAlert from 'features/RRP/interfaces/IAlert';
import defaultNullAlert from './utility/defaultNullAlert';
import AlertSeverityEnum from 'features/RRP/enums/AlertSeverityEnum';

const {
  HEALTHY,
  MINOR,
  MAJOR,
  UNKNOWN,
} = AlertSeverityEnum;

export default function internetBUtilizationAlertValue(
  internetBPeakUtilization_Upload: string,
  internetBProvisioned_Upload: string,
  internetBPeakUtilization_Download: string,
  internetBProvisioned_Download: string
): IAlert {
  if (
    internetBPeakUtilization_Upload === null ||
    internetBPeakUtilization_Download === null ||
    internetBProvisioned_Upload === null ||
    internetBProvisioned_Download === null
  ) {
    return defaultNullAlert(['internetBPeakUtilization_Upload', 'internetBPeakUtilization_Download', 'internetBProvisioned_Upload', 'internetBProvisioned_Download']);
  }

  try {
    const { number: internetBPeakUtilization_UploadNum } = extractNumberFromString(
      internetBPeakUtilization_Upload
    );
    const { number: internetBPeakUtilization_DownloadNum } = extractNumberFromString(
      internetBPeakUtilization_Download
    );
    const { number: internetBProvisioned_UploadNum } = extractNumberFromString(internetBProvisioned_Upload);
    const { number: internetBProvisioned_DownloadNum } = extractNumberFromString(internetBProvisioned_Download);

    if (
      internetBProvisioned_UploadNum === null
      || internetBProvisioned_DownloadNum === null
      || internetBPeakUtilization_UploadNum === null
      || internetBPeakUtilization_DownloadNum === null
    ) {
      return defaultNullAlert([
        'internetBPeakUtilization_Upload',
        'internetBPeakUtilization_Download',
        'internetBProvisioned_Upload',
        'internetBProvisioned_Download'
      ])
    }

    const internetBPeakUtilization_UploadPercent =
      internetBPeakUtilization_UploadNum / internetBProvisioned_UploadNum;
    const internetBPeakUtilization_DownloadPercent =
      internetBPeakUtilization_DownloadNum / internetBProvisioned_DownloadNum;

    let alert: IAlert = {
      value: HEALTHY,
      issue: [],
      effect: [],
      solution: [],
    }

    if (
      internetBPeakUtilization_UploadPercent >= 0.9 ||
      internetBPeakUtilization_DownloadPercent >= 0.9
    ) {
      alert.value = MAJOR;
      alert.issue.push('Store utilization of WAN Transport B is over 90%, showing that the store may already be exhausting bandwidth on this transport.')
      alert.effect.push('High likelihood of slow or intermittently failing network connections for whole store.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport B standard of 100M / 100M DIA or 600M / 35M Broadband. If already within standard, contact Network Engineering.');
    } else if (
      internetBPeakUtilization_UploadPercent >= 0.7 ||
      internetBPeakUtilization_DownloadPercent >= 0.7
    ) {
      alert.value = MINOR;
      alert.issue.push('Store utilization of WAN Transport B is over 70%, showing that the store is trending toward exhausting bandwidth on this transport');
      alert.effect.push('May cause slow or intermittently failing network connections for whole store.');
      alert.solution.push('Consider upgrading transport to meet standard of WAN Transport B standard of 100M / 100M DIA or 600M / 35M Broadband. If already within standard, contact Network Engineering.');
    }

    return alert;
  } catch (err) {
    return {
      value: UNKNOWN,
      issue: ['There was an error in internetBUtilizationAlertValue.js'],
      effect: ['When parsing through the returned api data the catch block was intitiated.'],
      solution: ['Check the file internetBUtilizationAlertValue.js for values the function is not expecting.'],
    };
  }
}

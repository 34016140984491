import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Paper from './Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PaperTitle from './PaperTitle';
import Typography from '@material-ui/core/Typography';
import ButtonLink from './ButtonLink';
import Button from './Button';
import {useSelector, useDispatch} from 'react-redux';
import {setUiPopOpViewed, setUiPopOpReset} from '../redux/actions';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const StyledPopup = styled.div``;

const MessageBox = styled(Paper)`
  max-width: 50vw;
  position: relative;
  top: 25vh;
  left: 25vw;
`;

const StyledButton = styled(MuiButton)`
  font-family: 'Trade Gothic Bold Condensed';
  color: ${props => props.theme.subtleText};
  text-decoration: none;
  font-style: italic;
  padding: 0;
  text-transform: capitalize;
  &:hover {
    color: ${props => props.theme.focusText};
  }
`;

const StyledGrid = styled(Grid)`
  &.align-right {
    text-align: right;
  }
`;

function Popup(props) {
  const {msgType, firstTimeCheck, settings} = props;

  const defaultMsg = () => {
    return (
      <>
        <Typography>
          Welcome to Tech Insights! We are currently building out new tools and features. If you
          have any questions please don't hesitate to send us a message.
        </Typography>
        <br />
        <ButtonLink to={'/contact'}>Contact Us</ButtonLink>
      </>
    );
  };

  const dispatch = useDispatch();
  const msgViewed = useSelector(state => state.ui.firstTimeMsgViewed);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(defaultMsg);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (msgType === 'ieMsg') {
      setMessage(
        () =>
          `Unfortunately Trade Insights is not compatible with Internet Explorer. Please use Google Chrome, Firefox, Safari, or the latest version of Microsoft Edge.`
      );
    } else if (firstTimeCheck && !msgViewed) {
      setOpen(true);
      dispatch(setUiPopOpViewed());
    }
  }, [msgType, firstTimeCheck, msgViewed, dispatch]);

  return (
    <StyledPopup>
      {settings ? <br></br> : ''}

      {settings ? (
        <Button variant="outlined" onClick={handleOpen}>
          Trigger message
        </Button>
      ) : (
        ''
      )}

      {settings ? (
        <Button variant="outlined" onClick={() => dispatch(setUiPopOpReset())}>
          Reset Messages
        </Button>
      ) : (
        ''
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MessageBox>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <PaperTitle id="transition-modal-title">Retail Insights</PaperTitle>
              </Grid>
              <StyledGrid item xs={6} className={'align-right'}>
                <StyledButton onClick={handleClose}>
                  <CloseIcon />
                </StyledButton>
              </StyledGrid>
              <Grid item xs={12}>
                <p id="transition-modal-description">{message}</p>
              </Grid>
            </Grid>
          </MessageBox>
        </Fade>
      </Modal>
    </StyledPopup>
  );
}

export default Popup;

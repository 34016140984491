const storeIssuesCountReducer = data => {
  const dataToApp = [];

  const getPropertyValue = (propertyName, event = undefined) => {
    if (event === undefined) {
      return '';
    } else {
      return event.hasOwnProperty(propertyName) ? event[propertyName] : '';
    }
  };

  data.forEach(event => {
    const formattedData = {
      isKeyMarket: '',
      storeNumber: getPropertyValue('StoreNumber', event),
      storeName: getPropertyValue('StoreName', event),
      network: getPropertyValue('Network', event),
      server: getPropertyValue('Server', event),
      register: getPropertyValue('Register', event),
      pc: getPropertyValue('PC', event),
      athleteDevice: getPropertyValue('Athlete Device', event),
      total:
        getPropertyValue('Network', event) +
        getPropertyValue('Server', event) +
        getPropertyValue('Register', event) +
        getPropertyValue('PC', event) +
        getPropertyValue('Athlete Device', event),
    };
    dataToApp.push(formattedData);
  });

  return dataToApp;
};

export default storeIssuesCountReducer;

import SwitchConnectivityState, {
  SwitchStackData,
  Switch,
  FlatSwitchData,
  Port,
  VlanLegendData,
} from '../interfaces/SwitchConnectivityState';
import InsightsAction from 'redux/Action';
import flattenSwitchData from './flattenSwitchData';
import formatDefaultSwitchData from './formatDefaultSwitchData';
import SwitchResponseData from '../interfaces/response/SwitchResponseData';
import PortHistoryResponseData from '../interfaces/response/PortHistoryResponseData';
import formatPortHistoryData from './formatPortHistoryData';
import {SwitchSearchOptions} from '../Search/SwitchSearchCheckboxes';
import {
  PortHistoryPort,
  PortHistorySwitch,
  PortHistorySwitchStack,
} from '../interfaces/PortHistoryState';

export const initialState: SwitchConnectivityState = {
  switchStacks: [],
  flattenedSwitchData: [],
  filteredFlattenedSwitchData: [],
  legendData: [],
  selectedVLANs: [],
  hasPreassignedColor: false,
  switchStackNames: [],
  isSwitchDataReady: false,
  portHistoryState: {switchStacks: {}},
  isPortHistoryDataReady: false,
};

export const reducer = (
  state: SwitchConnectivityState,
  action: InsightsAction
): SwitchConnectivityState => {
  switch (action.type) {
    case 'LEGEND_ITEM_SELECTION_CHANGED':
      return handleLegendItemSelectionChanged(state, action);
    case 'LEGEND_ITEM_CLEAR':
      return handleClearSelectedVLAN(state);
    case 'SEARCH_TEXT_CHANGED':
      return handleSearchTextChanged(
        state,
        action.payload.searchText,
        action.payload.searchOptions
      );
    case 'UPDATE_SWITCH_DATA':
      return updateSwitchDataFromAPI(state, action.payload);
    case 'UPDATE_PORT_HISTORY_DATA':
      return updatePortHistoryDataFromAPI(state, action.payload);
    case 'RESET_SWITCH_CONNECTIVITY':
      return {
        ...state,
        isSwitchDataReady: false,
      };
    default:
      return state;
  }
};

//#region LEGEND_ITEM_SELECTION_CHANGED
function handleLegendItemSelectionChanged(
  state: SwitchConnectivityState,
  action: any
): SwitchConnectivityState {
  const vlanNumber = action.payload.vlanNumber;
  const isSelected = action.payload.isSelected;
  const selectedVLANs = updateSelectedVLANs(state, vlanNumber, isSelected);
  const legendData = updateLegendSelection(state, vlanNumber, isSelected);
  const switchStacks = updateSwitchPortSelection(state, selectedVLANs);
  const filteredFlattenedSwitchData = updateFilteredFlattenedSwitchData(state, selectedVLANs);
  return {
    ...state,
    switchStacks,
    selectedVLANs,
    legendData,
    filteredFlattenedSwitchData,
  };
}

function handleClearSelectedVLAN(state: SwitchConnectivityState): SwitchConnectivityState {
  const selectedVLANs: string[] = [];
  const legendData = resetVLANLegend(state);

  const switchStacks: SwitchStackData[] = state.switchStacks.map(switchStack => {
    const memberSwitches: Switch[] = switchStack.memberSwitches.map(switchItem => {
      const mainPorts: Port[] = switchItem.mainPorts.map(switchPort => {
        return {...switchPort, isSelected: true};
      });
      return {
        ...switchItem,
        mainPorts,
      };
    });
    return {
      ...switchStack,
      memberSwitches,
    };
  });

  const filteredFlattenedSwitchData: FlatSwitchData[] = updateFilteredFlattenedSwitchData(
    state,
    selectedVLANs
  );

  return {
    ...state,
    selectedVLANs,
    legendData,
    switchStacks,
    filteredFlattenedSwitchData,
  };
}

function updateSelectedVLANs(
  state: SwitchConnectivityState,
  vlanNumber: string,
  isSelected: boolean
): string[] {
  if (isSelected) {
    return [...state.selectedVLANs, vlanNumber];
  } else {
    return state.selectedVLANs.filter(vlan => vlan !== vlanNumber);
  }
}

function updateLegendSelection(
  state: SwitchConnectivityState,
  vlanNumber: string,
  isSelected: boolean
): VlanLegendData[] {
  return state.legendData.map(legendItem => {
    const selectedVLAN = vlanNumber;
    const updatedIsSelected =
      legendItem.vlanNumber === selectedVLAN ? isSelected : legendItem.isSelected;

    return {...legendItem, isSelected: updatedIsSelected};
  });
}

function updateSwitchPortSelection(state: SwitchConnectivityState, selectedVLANs: string[]) {
  const switchStacks = state.switchStacks.map(switchStack => {
    const memberSwitches = switchStack.memberSwitches.map(switchItem => {
      const mainPorts = switchItem.mainPorts.map(switchPort => {
        // Used copolit to simplify the following code block
        const isSelected =
          selectedVLANs.length === 0 ||
          selectedVLANs.includes(switchPort.vlanNumber) ||
          (selectedVLANs.includes('VLAN') && switchPort.vlanNumber === null) ||
          (selectedVLANs.includes('Plug Icon') && switchPort.portStatus === 'connected');

        return {...switchPort, isSelected};
      });

      return {
        ...switchItem,
        mainPorts,
      };
    });

    return {
      ...switchStack,
      memberSwitches,
    };
  });

  return switchStacks;
}

function updateFilteredFlattenedSwitchData(
  state: SwitchConnectivityState,
  selectedVLANs: any[]
): FlatSwitchData[] {
  if (selectedVLANs.length === 0) {
    return state.flattenedSwitchData;
  } else {
    return state.flattenedSwitchData.filter(switchPort => {
      return selectedVLANs.includes(switchPort.vlanNumber);
    });
  }
}
//#endregion LEGEND_ITEM_SELECTION_CHANGED

//#region SEARCH_TEXT_CHANGED
function handleSearchTextChanged(
  state: SwitchConnectivityState,
  searchText: string,
  searchOptions: SwitchSearchOptions
) {
  const switchStacks = updateSwitchDataAfterSearchTextChanged(state, searchText, searchOptions);
  const filteredFlattenedSwitchData = updateFilteredFlattenedSwitchDataAfterSearchTextChanged(
    state,
    searchText,
    searchOptions
  );
  const legendData = resetVLANLegend(state);
  return {...state, switchStacks, legendData, filteredFlattenedSwitchData, selectedVLANs: []};
}

function updateFilteredFlattenedSwitchDataAfterSearchTextChanged(
  state: SwitchConnectivityState,
  searchText: string,
  searchOptions: SwitchSearchOptions
) {
  const filteredSearchText = searchText.replace(/[.|:]/g, '').toLowerCase();
  const filteredFlattenedSwitchData = state.flattenedSwitchData.filter(switchPort => {
    if (searchOptions.includeCurrentData && switchPort.searchData.includes(filteredSearchText))
      return true;

    if (searchOptions.includeHistoricalData) {
      let portHistory: PortHistoryPort =
        state.portHistoryState?.switchStacks[switchPort.switchStackName]?.switches[
          switchPort.switchNumber
        ]?.ports[switchPort.portNumber];
      if (!portHistory) return false;
      return (
        portHistory.snapshots.filter(s => s.searchData.includes(filteredSearchText)).length > 0
      );
    }

    return false;
  });

  return filteredFlattenedSwitchData;
}

function updateSwitchDataAfterSearchTextChanged(
  state: SwitchConnectivityState,
  searchText: string,
  searchOptions: SwitchSearchOptions
) {
  const filteredSearchText = searchText.replace(/[.|:]/g, '').toLowerCase();

  const switchStacks: SwitchStackData[] = state.switchStacks.map(switchStack => {
    const portHistorySwitchStack: PortHistorySwitchStack =
      state.portHistoryState.switchStacks[switchStack.switchStackName];

    const memberSwitches: Switch[] = switchStack.memberSwitches.map(switchItem => {
      const portHistorySwitch: PortHistorySwitch =
        portHistorySwitchStack?.switches[switchItem.switchNumber];

      const mainPorts: Port[] = switchItem.mainPorts.map(switchPort => {
        const portHistoryPort: PortHistoryPort = portHistorySwitch?.ports[switchPort.portNumber];
        if (
          searchOptions.includeCurrentData &&
          switchPort.searchData.includes(filteredSearchText)
        ) {
          return {...switchPort, isSelected: true};
        }

        if (
          searchOptions.includeHistoricalData &&
          portHistoryPort?.snapshots?.length > 0 &&
          portHistoryPort.snapshots.filter(s => s.searchData.includes(filteredSearchText)).length >
            0
        ) {
          return {...switchPort, isSelected: true};
        }

        return {...switchPort, isSelected: false};
      });
      return {
        ...switchItem,
        mainPorts,
      };
    });
    return {
      ...switchStack,
      memberSwitches,
    };
  });

  return switchStacks;
}

function resetVLANLegend(state: SwitchConnectivityState) {
  return state.legendData.map(legendItem => {
    return {...legendItem, isSelected: false};
  });
}
//#endregion

//#region UPDATE_SWITCH_DATA
function updateSwitchDataFromAPI<SwitchConnectivityState>(
  state: SwitchConnectivityState,
  apiData: SwitchResponseData
) {
  const {switchStacks, legendData, switchStackNames} = formatDefaultSwitchData(apiData);

  const flattenedSwitchData = flattenSwitchData(apiData);

  const filteredFlattenedSwitchData = [...flattenedSwitchData];
  return {
    ...state,
    flattenedSwitchData,
    filteredFlattenedSwitchData,
    legendData,
    switchStacks,
    switchStackNames,
    isSwitchDataReady: true,
    selectedVlans: [],
  };
}
//#endregion UPDATE_SWITCH_DATA

//#region UPDATE_PORT_HISTORY_DATA
function updatePortHistoryDataFromAPI<SwitchConnectivityState>(
  state: SwitchConnectivityState,
  apiData: PortHistoryResponseData[]
) {
  const formattedPortHistoryData = formatPortHistoryData(apiData);
  return {
    ...state,
    portHistoryState: formattedPortHistoryData,
    isPortHistoryDataReady: true,
  };
}

//#endregion

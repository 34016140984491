import React from 'react';
import styled from 'styled-components';
import MuiTableCell from '@material-ui/core/TableCell';

const StyledTableCell = styled(MuiTableCell)`
  border-bottom: 1px solid ${props => props.theme.tableRow};
  padding-left: 8px;
  padding-right: 8px;
  &:first-of-type {
    padding-left: 0px;
  }
  &.body {
    background-color: ${props => props.theme.cardBg};
  }
`;

function TableCell(props) {
  const {className, children, body, ...rest} = props;

  return (
    <StyledTableCell className={(body ? 'body ' : '') + className} {...rest}>
      {children}
    </StyledTableCell>
  );
}

export default TableCell;

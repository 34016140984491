import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import SGBody from '../../Typography/SGBody';
import ToggleButton from '@material-ui/lab/ToggleButton';
import RectSkeleton from '../../Skeletons/RectSkeleton';

interface TypeButtonProps {
  debugLoaded: boolean;
  count: number;
  deviceType: string;
  checked: boolean;
}

const StyledButton = styled(ToggleButton)`
  background-color: ${props => props.theme.navyGrey};
  padding: 0.25rem 0.625rem;
  border-radius: 10px;
  transition: 0.25s;

  &.MuiToggleButton-root.Mui-selected {
    background-color: ${props => props.theme.disabledStateBlue};
  }
`;

const StyledLabel = styled.div`
  min-width: 5rem;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  align-items: flex-start;
`;

const StyledNumber = styled.div`
  font-family: 'Futura C XBd';
  color: ${props => props.theme.text};
  font-size: 1.125rem;
`;

function TypeButton({debugLoaded, count, deviceType, checked}: TypeButtonProps) {
  const [selected, setSelected] = useState(true);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  return (
    <>
      {debugLoaded ? (
        <StyledButton
          value="check"
          selected={selected}
          onChange={() => {
            setSelected(!selected);
          }}
        >
          <StyledLabel>
            <StyledNumber>{count}</StyledNumber>
            <SGBody variant={'b5'} className={'disabledStateText'}>
              {deviceType}
            </SGBody>
          </StyledLabel>
        </StyledButton>
      ) : (
        <RectSkeleton width={'6.375rem'} height={'3.875rem'} />
      )}
    </>
  );
}

export default TypeButton;

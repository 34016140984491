import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import {ReactComponent as SlackIconMono} from '../../assets/icons/Slack_Mark_Monochrome_Black.svg';
import MenuDropDown from './MenuDropDown';
import SettingsIcon from '@material-ui/icons/Settings';
import Popup from '../Popup';
import PopupPatchNotes from '../PatchNotes/PopupPatchNotes';
import NavHeader from './NavHeader';
import NavTree from './NavTree';
import UserLog from 'components/Settings/UserLog';

const StyledNav = styled(AppBar)`
  height: 4rem;
  width: 100vw;
  position: fixed;
  z-index: 6;
  bottom: 0;
  left: 0;
  transition: 0.15s;
  background-color: ${props => props.theme.navBg};
  display: flex;

  @media (min-width: 600px) {
    display: flex;
    height: 100%;
    width: 13.125rem;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.15s;
    background-color: ${props => props.theme.navBg};
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    &.flex-end {
      align-items: flex-end;
    }
  }
`;

const StyledLinkRouter = styled(NavLink)`
  margin: 0px 0px 0px 0px;
  text-decoration: none;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};

  @media (min-width: 600px) {
    display: none;
  }

  &:visited {
    color: ${props => props.theme.navText};
    fill: ${props => props.theme.navText};
  }

  &:active,
  &:hover,
  &:focus,
  &.active {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
  }

  &:hover .hoverClass {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
  }

  p {
    font-family: 'Trade Gothic';
    text-decoration: none;
    font-size: 1rem;
    margin: 0;
    margin-top: 3px;
  }
`;

const StyledSlackLink = styled.a`
  display: none;
  margin: 0px 0px 0px 0px;
  text-decoration: none;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
  margin-top: auto;

  @media (min-width: 600px) {
    display: block;
  }

  &:visited {
    color: ${props => props.theme.navText};
    fill: ${props => props.theme.navText};
  }

  &:active,
  &:hover,
  &:focus,
  &.active {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
  }

  &:hover .hoverClass {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
  }

  p {
    font-family: 'Trade Gothic';
    text-decoration: none;
    font-size: 0.8rem;
    margin: 0;
    margin-top: 3px;
  }
`;

export const StyledButton = styled(Button)`
  height: 4rem;
  margin-left: 6px;
  border-radius: 10px;
  color: ${props => props.theme.navText};
  fill: ${props => props.theme.navText};
  text-decoration: none;
  text-transform: capitalize;
  padding-left: 16px;

  @media (min-width: 600px) {
    height: 40px;
    width: 190px;
    margin-left: 6px;
    border-radius: 10px;
    color: ${props => props.theme.navText};
    fill: ${props => props.theme.navText};
    text-decoration: none;
    text-transform: capitalize;
    padding-left: 16px;
    cursor: pointer;
  }

  ${StyledLinkRouter}:active &, ${StyledLinkRouter}:hover &, ${StyledLinkRouter}:focus &, ${StyledLinkRouter}.active & {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
    background-color: ${props => props.theme.navBg};
  }

  ${StyledSlackLink}:active &, ${StyledSlackLink}:hover &, ${StyledSlackLink}:focus &, ${StyledSlackLink}.active & {
    color: ${props => props.theme.navTextActive};
    fill: ${props => props.theme.navTextActive};
    text-decoration: none;
    background-color: ${props => props.theme.navBg};
  }

  span {
    flex-direction: row;
    justify-content: flex-start;
  }
  p {
    margin-left: 15px;
    margin-bottom: 3px;
    font-size: 15px;
    display: none;
    @media (min-width: 600px) {
      display: block;
    }
  }

  .noPointer .MuiButton-root {
    cursor: context-menu;
  }
`;

const SlackIconContainer = styled.div`
  overflow: hidden;
  object-fit: cover;

  width: 24px;
  height: 24px;
`;

const StyledSlackMono = styled(SlackIconMono)`
  position: relative;
  top: -40%;
  left: -45%;
  width: 42px;
  height: 42px;
`;

function Navigation() {
  return (
    <StyledNav position="static" elevation={1}>
      <NavHeader />

      <StyledToolbar variant="dense">
        {/* The following LinkRouter components are for mobile only */}
        {/* <StyledLinkRouter to={`/`} exact>
          <StyledButton color="inherit" aria-label="map">
            <PublicIcon />
            <p>Map</p>
          </StyledButton>
        </StyledLinkRouter> */}

        <StyledLinkRouter
          to={'#'}
          className={'noPointer'}
          isActive={match => {
            if (!match) {
              return false;
            } else if (match.url === '/dashboard/na') {
              return true;
            } else if (match.url === '/dashboard/emea') {
              return true;
            } else if (match.url === '/dashboard/gc') {
              return true;
            } else if (match.url === '/dashboard/apla') {
              return true;
            } else {
              return false;
            }
          }}
        >
          <MenuDropDown>
            <>
              <DashboardRoundedIcon />
              <p>Dashboard</p>
            </>
          </MenuDropDown>
        </StyledLinkRouter>

        <StyledLinkRouter to={`/contact`}>
          <StyledButton color="inherit" aria-label="contact">
            <EmailRoundedIcon />
            <p>Contact</p>
          </StyledButton>
        </StyledLinkRouter>

        {/* <StyledLinkRouter to={`/about`}>
          <StyledButton color="inherit" aria-label="about">
            <StyledInfoIcon />
            <p>About</p>
          </StyledButton>
        </StyledLinkRouter> */}

        <StyledLinkRouter to={`/settings`}>
          <StyledButton color="inherit" aria-label="settings">
            <SettingsIcon />
            <p>Settings</p>
          </StyledButton>
        </StyledLinkRouter>

        {/* NavTree is desktop only navigation */}
        <NavTree />

        <Popup firstTimeCheck />
        <PopupPatchNotes />
        <UserLog />
      </StyledToolbar>
      <StyledSlackLink href="https://nikedigital.slack.com/archives/G75ESC0RJ" target="_blank">
        <StyledButton color="inherit" aria-label="slack">
          <SlackIconContainer>
            <StyledSlackMono />
          </SlackIconContainer>
          <p>Questions?</p>
        </StyledButton>
      </StyledSlackLink>
    </StyledNav>
  );
}

export default Navigation;

import SwitchResponseData from '../interfaces/response/SwitchResponseData';
import formatDefaultLegendData from './formatLegendData';
import {Switch, SwitchStackData, Port, SwitchDevice} from '../interfaces/SwitchConnectivityState';

function formatDefaultSwitchData(apiData: SwitchResponseData) {
  const {legendColorsInUse, legendData} = formatDefaultLegendData(apiData);

  let switchStackNames: string[] = [];

  const swtichStacks: SwitchStackData[] = apiData.switchStacks.map(switchStack => {
    // gather all switchStackNames
    if (!switchStackNames.includes(switchStack.switchStackName)) {
      switchStackNames.push(switchStack.switchStackName);
    }

    const memberSwitches: Switch[] = switchStack.memberSwitches.map(switchItem => {
      const {switchNumber} = switchItem;
      const mainPorts: Port[] = switchItem.mainPorts.map(port => {
        let portColor: string | null = port.portColor ? port.portColor : null;
        let vlanNumber: string = port.vlanNumber ? port.vlanNumber : 'unknown';
        if (vlanNumber === 'unknown') {
          portColor = 'transparent';
        } else if (port.portColor === null && legendColorsInUse[vlanNumber] !== undefined) {
          portColor = legendColorsInUse[vlanNumber];
        } else if (port.portColor === null && legendColorsInUse[vlanNumber] === undefined) {
          // TODO: once error handling is implemented, add this to the error module
          console.log(
            'Something unexpected occured when attempting to assign colors to the VLAN port. Check formatDefaultSwitchData.ts',
            {
              legendData,
              port,
            }
          );
        }

        const searchData: string = port.connectedDevices
          .reduce((accumulator: any, connectedDevice: SwitchDevice) => {
            const macAddress = connectedDevice.macAddress.replace(/[.|:]/g, '');
            const deviceName = connectedDevice.deviceName;
            const deviceType = connectedDevice.deviceType;
            const manufacturer = connectedDevice.manufacturer;
            return `${accumulator}|${macAddress}|${deviceName}|${manufacturer}|${deviceType}|${vlanNumber}`;
          }, '')
          .toLowerCase();

        return {
          ...port,
          portColor: portColor ? portColor : 'transparent',
          isSelected: true,
          vlanNumber: vlanNumber,
          switchNumber,
          searchData,
        };
      });
      const sortedPorts = mainPorts.sort(function (a, b) {
        return a.portNumber - b.portNumber;
      });
      return {
        ...switchItem,
        mainPorts: sortedPorts,
      };
    });

    return {
      switchStackName: switchStack.switchStackName,
      memberSwitches: memberSwitches,
    };
  });

  const sortedSwitchStacks: SwitchStackData[] = swtichStacks.sort(function (a, b) {
    return a.switchStackName.localeCompare(b.switchStackName);
  });

  const sortedSwitchStackNames: string[] = switchStackNames.sort(function (a, b) {
    return a.localeCompare(b);
  });

  return {
    switchStacks: sortedSwitchStacks,
    legendData: legendData,
    switchStackNames: sortedSwitchStackNames,
  };
}

export default formatDefaultSwitchData;

import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-size: 0.75rem;
  margin-left: 8px;
  border-radius: 10px;
  min-width: 70px;
  &.MuiButton-contained {
    background-color: ${props => props.theme.activeStateBlue};
  }
  &.MuiButton-outlined {
    border-color: ${props => props.theme.activeStateBlue};
    color: ${props => props.theme.activeStateBlue};
  }

  &:hover {
    opacity: 0.5;
  }
`;

interface SiteIdMenuProps {
  handleReset: () => void;
  handleSearch: () => void;
}

function SiteIdMenu({handleReset, handleSearch}: SiteIdMenuProps) {
  return (
    <StyledButtonContainer>
      <StyledButton variant="outlined" size="medium" onClick={handleReset}>
        Reset
      </StyledButton>
      <StyledButton variant="contained" size="medium" onClick={handleSearch}>
        Search
      </StyledButton>
    </StyledButtonContainer>
  );
}

export default SiteIdMenu;

import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import RRP from '../features/RRP';
import RRPHeader from 'features/RRP/components/RRPHeader';
import HeaderTabs from 'features/RRP/components/RRPHeader/HeaderTabs';
import RRPSwitch from 'features/RRP/components/RRPSwitch';

const StyledFullWidthContainer = styled.div`
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledRRPSwitch = styled(RRPSwitch)`
  margin-right: 1rem;
`;

function ReoccurringRetailPhysical(props) {
  // const { match } = props;

  const [tabSelected, setTabSelected] = useState('custom');
  const [isCardView, setIsCardView] = useState(false);
  const [isAlertsOnly, setIsAlertOnly] = useState(false);

  const handleSetTabSelected = value => {
    setTabSelected(value);
  };

  const handeCardView = () => {
    setIsCardView(prev => !prev);
  };

  const handeAlertsOnly = () => {
    setIsAlertOnly(prev => !prev);
  };

  return (
    <Layout
      disableMaxWidth
      headerContent={
        <RRPHeader setTabSelected={handleSetTabSelected} tabSelected={tabSelected}>
          <HeaderTabs
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
            options={
              <>
                <StyledRRPSwitch
                  isToggled={isAlertsOnly}
                  label={'Alerts only'}
                  handleToggle={handeAlertsOnly}
                />
                <RRPSwitch
                  isToggled={isCardView}
                  label={'Scorecard view'}
                  handleToggle={handeCardView}
                />
              </>
            }
          />
        </RRPHeader>
      }
    >
      <StyledFullWidthContainer>
        <Grid container spacing={3}>
          <Grid item>
            <RRP tabSelected={tabSelected} isCardView={isCardView} isAlertsOnly={isAlertsOnly} />
          </Grid>
        </Grid>
      </StyledFullWidthContainer>
    </Layout>
  );
}

export default ReoccurringRetailPhysical;

import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';

interface SwitchProps {
  onClick: () => void;
  checked: boolean;
  color?: 'default' | 'primary' | 'secondary';
  colorIndex?: number;
  disabled?: boolean;
  className?: string;
}

const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase {
    color: ${props => props.theme.disabledStateBlue};
  }
  .MuiSwitch-track {
    background-color: ${props => props.theme.navyGrey};
  }
  &.sw0.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[0]};
  }
  &.sw0.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[0]};
  }

  &.sw1.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[1]};
  }
  &.sw1.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[1]};
  }

  &.sw2.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[2]};
  }
  &.sw2.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[2]};
  }

  &.sw3.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[3]};
  }
  &.sw3.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[3]};
  }

  &.sw4.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[4]};
  }
  &.sw4.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[4]};
  }

  &.sw5.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.sgColors[5]};
  }
  &.sw5.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.sgColors[5]};
  }
`;

export const colorClassNames: Array<string> = ['sw0', 'sw1', 'sw2', 'sw3', 'sw4', 'sw5'];

function SGSwitch({
  onClick,
  checked,
  color,
  colorIndex = 0,
  disabled = false,
  className,
}: SwitchProps) {
  return (
    <StyledSwitch
      size="small"
      onClick={onClick}
      checked={checked}
      color={color}
      disabled={disabled}
      className={`${className} ${colorClassNames[colorIndex]}`}
    />
  );
}

export default SGSwitch;

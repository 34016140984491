import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import SGTitle from '../../Typography/SGTitle';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  flex-direction: column;
`;

const StyledLoadingText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 0.5rem;
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 80%;
  border-radius: 5px;
  &.MuiLinearProgress-root {
    height: 10px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${props => props.theme.text}11;
  }
  .MuiLinearProgress-bar1Buffer {
    background-color: ${props => props.theme.text}33;
  }
  .MuiLinearProgress-bar2Buffer {
    background-color: ${props => props.theme.text}22;
  }
  .MuiLinearProgress-dashedColorPrimary {
    background-size: 500px 10px;
    background-image: linear-gradient(
      90deg,
      transparent,
      ${props => props.theme.text}22,
      transparent
    );
  }
`;

const renderLoadingText = function renderLoadingText(percentage) {
  const x = Math.round(percentage);
  let loadingText = 'Initiate API Call';
  if (x < 10) {
    loadingText = 'Initiate API Call';
  } else if (x >= 10 && x < 30) {
    loadingText = 'Fetching Data';
  } else if (x >= 30 && x < 60) {
    loadingText = 'Filtering Data';
  } else if (x >= 60 && x < 90) {
    loadingText = 'Formatting for Table';
  } else {
    loadingText = 'Complete';
  }
  return loadingText;
};

export default function TableProgressLinear() {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <StyledContainer>
      <StyledLoadingText>
        {/* <SGTitle variant={'t4'} className={'text-color'}>
          INSIGHTS API [{renderLoadingText(progress)}]
        </SGTitle> */}
        {/* <SGTitle variant={'t4'} className={'text-color'}>
          {renderLoadingSteps(progress)} OUT OF 10
        </SGTitle> */}
      </StyledLoadingText>
      <StyledLinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </StyledContainer>
  );
}

import {ServiceDictionary, Services} from './interfaces/Services';
import {Stores} from 'interfaces/Stores';

/**
 * A service is addable if it is not already in use by all the selected stores with ids provided in the service dictionary (i.e. it is missing from any of the selected stores)
 * @param servicesByStore
 * @param allServices
 * @param selectedStores
 * @returns services that are missing from at least 1 store in the service dictionary
 */
const getAddableServices: (
  servicesByStore: ServiceDictionary,
  allServices: Services,
  selectedStores: Stores
) => Services = (
  servicesByStore: ServiceDictionary,
  allServices: Services,
  selectedStores: Stores
) => {
  const servicesCounter: Map<string, number> = Object.values(servicesByStore).reduce(
    (counter, services) => {
      services.forEach(service => {
        const currentServicesCount = counter.get(service.id) ?? 0;
        counter.set(service.id, currentServicesCount + 1);
      });
      return counter;
    },
    new Map<string, number>()
  );

  const addableServices: Services = allServices.filter(
    service => servicesCounter.get(service.id) !== selectedStores.length
  );
  return addableServices;
};

export default getAddableServices;

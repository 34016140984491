import React from 'react';
import styled from 'styled-components';
import SGTitle from '../../Typography/SGTitle';
import Grid from '@material-ui/core/Grid';
import TopEventItem from './TopEventItem';
import DateRangeDisplay from './DateRangeDisplay';
import RectSkeleton from '../../Skeletons/RectSkeleton';
import ITopEventItem from '../../../interfaces/ITopEventItem';
// import {mockTopEvents} from './mockData';

interface TopEventsProps {
  debugLoaded?: boolean;
  loaded: boolean;
  friendlyDateRange: string;
  topEventItems: Array<ITopEventItem>;
}

const StyledLabel = styled.div`
  margin-top: 4px;
`;

const StyledRectSkeleton = styled(RectSkeleton)`
  margin-bottom: 16px;
`;

const renderItems = function renderItems(topEvent: ITopEventItem) {
  return (
    <TopEventItem
      device={topEvent.device}
      eventCount={topEvent.eventCount}
      date={topEvent.date}
      storesCount={topEvent.storesCount}
      url={topEvent.url}
    />
  );
};

function TopEvents({debugLoaded, loaded, friendlyDateRange, topEventItems}: TopEventsProps) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={8}>
        <SGTitle className={'text-color'}>Top 3 Dates with Highest Number of Events</SGTitle>
      </Grid>
      <Grid item xs={6} md={4}>
        <StyledLabel>
          <DateRangeDisplay
            debugLoaded={debugLoaded}
            friendlyDateRange={friendlyDateRange}
            variant={'subTitle'}
          />
        </StyledLabel>
      </Grid>
      <Grid item xs={12}>
        {debugLoaded ? (
          topEventItems.map(topEvent => renderItems(topEvent))
        ) : (
          <>
            <StyledRectSkeleton width={'100%'} height={'1.5rem'} />
            <StyledRectSkeleton width={'100%'} height={'1.5rem'} />
            <StyledRectSkeleton width={'100%'} height={'1.5rem'} />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default TopEvents;

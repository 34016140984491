import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export interface ListContainerProps {
  variant: 'left' | 'right';
  children?: any;
  isServiceManager?: boolean;
}

const StyledListContainer = styled.div`
  width: 300px;
  height: 325px;
  background-color: ${props => props.theme.navyGrey};
  padding: 8px;
  /* overflow: hidden; */

  &.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow-y: auto;
  }
  &.right {
    display: flex;
    flex-direction: column;
    border-left: 8px solid ${props => props.theme.grey};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.right ul {
    flex-grow: 1;
    display: block;
    overflow-y: auto;
    max-height: 274px;
  }

  &.service-manager {
    width: 50%;
    background-color: ${props => props.theme.cardBg};
  }
`;

function ListContainer({variant, children, isServiceManager = false}: ListContainerProps) {
  return (
    <StyledListContainer className={classNames(variant, isServiceManager ? 'service-manager' : '')}>
      {children}
    </StyledListContainer>
  );
}

export default ListContainer;

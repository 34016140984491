import React from 'react';
import styled from 'styled-components';
import IDFTable from './components/IDFTable';
import IdfSearchItem from 'components/SiteIDSearch/interfaces/IdfSearchItem';
import SiteWithIdf from './interfaces/SiteWithIdf';
import SGBody from 'components/Typography/SGBody';
import StatusIndicator from './components/StatusIndicator';
import Status from '../../interfaces/Status';

const StyledCustomCell = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
`;

const statusText: Record<string, string> = {
  [Status.ALL]: '',
  [Status.OPEN]: 'Open',
  [Status.PREOPEN]: 'Pre-Open',
  [Status.CLOSED]: 'Closed',
  [Status.REMODEL]: 'Remodel',
};

function SiteSearchTableColumns(urlKey: string) {
  return [
    {
      id: 'siteCode',
      label: 'Store # / Site Code',
      width: '5.5rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <SGBody variant={'b4'}>{value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'siteName',
      label: 'Name',
      width: '12rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <SGBody variant={'b4'}>{value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'brand',
      label: 'Brand',
      width: '4.5rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <SGBody variant={'b4'}>{value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'geo',
      label: 'Geo',
      width: '4.5rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <SGBody variant={'b4'}>{value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'siteType',
      label: 'Site Type',
      width: '8rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <SGBody variant={'b4'}>{value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      width: '5rem',
      customClass: 'top-alignment',
      format: (value: string) => {
        return (
          <StyledCustomCell>
            <StatusIndicator status={value} />
            <SGBody variant={'b4'}>{statusText[value] ? statusText[value] : value}</SGBody>
          </StyledCustomCell>
        );
      },
    },
    {
      id: 'idfs',
      label: 'idfs',
      format: (value: IdfSearchItem[], site: SiteWithIdf) => {
        if (value) {
          return <IDFTable urlKey={urlKey} data={value} siteId={site.siteId} />;
        }
      },
    },
  ];
}

export default SiteSearchTableColumns;

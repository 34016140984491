import {useMemo} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import Tabs from '../interfaces/Tabs';
import filterOptions from '../constants/FilterOptions';

//#region Constants
const tabDictionary: Record<string, string> = {
  [Tabs.CUSTOM]: Tabs.CUSTOM,
  [Tabs.NA]: Tabs.NA,
  [Tabs.EMEA]: Tabs.EMEA,
  [Tabs.GC]: Tabs.GC,
  [Tabs.APLA]: Tabs.APLA,
};
//#endRegion Constants

//#region Helper Functions
const parseFiltersForGeos = (selectedFilters: string) => {
  try {
    const allGeosKeys = Object.keys(filterOptions.geo.values);
    const filteredGeos = allGeosKeys.filter(geoKey => selectedFilters.includes(geoKey));
    return filteredGeos;
  } catch (error) {
    return [];
  }
};

const parseFilterParams = (selectedFilters: string) => {
  if (!selectedFilters) return [];

  try {
    const filterStrings: string[] = [];
    const selectedFiltersArray = selectedFilters.split(',');
    const allCategoryKeys = Object.keys(filterOptions);

    const reducedURLArray = allCategoryKeys.reduce((results, categoryKey) => {
      const allCategoryValues = filterOptions[categoryKey];
      const allValueKeys = Object.keys(allCategoryValues.values);
      const filteredCategoryValues = allValueKeys.filter(valueKey =>
        selectedFiltersArray.includes(valueKey)
      );

      return [...results, ...filteredCategoryValues];
    }, filterStrings);

    return reducedURLArray;
  } catch (error) {
    return [];
  }
};

const parseSelectedFilters = (selectedFilters: string[], parsedFilterParams: string[]) => {
  const newFilters: string[] = [...parsedFilterParams];
  selectedFilters.forEach(filter => {
    if (!newFilters.includes(filter)) {
      newFilters.push(filter);
    } else {
      newFilters.splice(newFilters.indexOf(filter), 1);
    }
  });
  const urlFilterParams = newFilters.join(',');
  return urlFilterParams;
};

const parseTabParams = (tabParams: string) => {
  const lowerCaseTabParams = tabParams?.toLowerCase() || Tabs.CUSTOM;
  const selectedTab = tabDictionary[lowerCaseTabParams] || Tabs.CUSTOM;

  return selectedTab as Tabs;
};
//#endRegion Helper Functions

function useFilters() {
  const {tab: tabParams, filters: filterParams} = useParams<any>();

  const parsedFilterParams = useMemo(() => parseFilterParams(filterParams), [filterParams]);
  const parsedTabParams = useMemo(() => parseTabParams(tabParams), [tabParams]);
  const parsedGeosFromFilters = useMemo(() => parseFiltersForGeos(filterParams), [filterParams]);

  let history = useHistory();

  //#region handles
  const handleFilterChange = (selectedFilters: string[]) => {
    const urlFilter = parseSelectedFilters(selectedFilters, parsedFilterParams);
    history.push(`/inventories/${parsedTabParams}/${urlFilter}`);
  };

  const handleClearFilters = () => {
    history.push(`/inventories/${parsedTabParams}`);
  };

  const handleTabChange = (tab: Tabs) => {
    const tabUrl = parseTabParams(tab);
    history.push(`/inventories/${tabUrl}/${parsedFilterParams}`);
  };
  //#endRegion handles

  return {
    handleFilterChange,
    handleClearFilters,
    handleTabChange,
    tab: parsedTabParams,
    filters: parsedFilterParams,
    geos: parsedGeosFromFilters,
  };
}

export default useFilters;

const storeInfoDataReducer = data => {
  function getPropertyValue(propertyName) {
    if (data === undefined) {
      return '';
    } else if (propertyName === 'StoreHours') {
      return getStoreHours();
    } else if (propertyName === 'OperatingStatusDate') {
      return formatOperatingStatusDate(propertyName);
    } else {
      return data.hasOwnProperty(propertyName) ? data[propertyName] : '';
    }
  }

  function getStoreHours() {
    if (data.StoreHours.SpecialHours !== null) {
      return ['SpecialHours Active'];
    } else {
      let hourFormat = [
        data.TimeZone.ZoneShort,
        `Sun: ${data.StoreHours.Sunday.Open} - ${data.StoreHours.Sunday.Duration}`,
        `Mon - Sat: ${data.StoreHours.Monday.Open} - ${data.StoreHours.Monday.Duration}`,
      ];
      return hourFormat;
    }
  }

  function formatOperatingStatusDate(propertyName) {
    if (data[propertyName]) {
      return data[propertyName].split(' ');
    } else {
      return data[propertyName];
    }
  }

  let storeInfoApp = {
    storeNumber: getPropertyValue('StoreNumber'),
    storeName: getPropertyValue('StoreName'),
    geo: getPropertyValue('Geo'),
    type: getPropertyValue('Type'),
    tradingStatus: getPropertyValue('TradingStatus'),
    isKeyMarket: getPropertyValue('IsKeyMarket'),
    storeHours: getPropertyValue('StoreHours'),
    address: getPropertyValue('Address'),
    phoneNumber: getPropertyValue('PhoneNumber'),
    businessStatus: getPropertyValue('BusinessStatus'),
    operatingStatus: getPropertyValue('OperatingStatus'),
    operatingStatusDate: getPropertyValue('OperatingStatusDate'),
    timeZone: getPropertyValue('TimeZone'),
    totalAthleteDevices: getPropertyValue('TotalAthleteDevices'),
    healthyAthleteDevices: getPropertyValue('HealthyAthleteDevices'),
    minorAthleteDevices: getPropertyValue('MinorAthleteDevices'),
    majorAthleteDevices: getPropertyValue('MajorAthleteDevices'),
    criticalAthleteDevices: getPropertyValue('CriticalAthleteDevices'),
  };

  return storeInfoApp;
};

export default storeInfoDataReducer;

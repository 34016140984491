import React from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import {mdiVideoInputHdmi} from '@mdi/js';
import SGTitle from 'components/Typography/SGTitle';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0px;
  margin-bottom: 0.5rem;
`;

export default function SwitchDiagramTitle(props) {
  const {switchNumber} = props;

  return (
    <StyledTitleContainer>
      <Icon path={mdiVideoInputHdmi} size={1} />
      <SGTitle className={'text-color'}>SWITCH {switchNumber}</SGTitle>
    </StyledTitleContainer>
  );
}

import React, {Suspense, lazy} from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import StatusIndicator from '../StatusIndicator';
import SGBody from 'components/Typography/SGBody';
import SGTitle from 'components/Typography/SGTitle';
import Status from 'interfaces/Status';
import {InventoryState} from '../../reducer/InventoryReducer';

const Table = lazy(() => import('../../../../components/Tables/TableStructure/Table'));

const StyledTableContainer = styled.div`
  border: 1px solid ${props => props.theme.text};
  border-radius: 10px;
  padding: 8px 16px;
  .MuiTableBody-root .MuiTableCell-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;
const StyledCustomCell = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInstructionContainer = styled.div`
  min-height: 403px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 300px;
  background-color: ${props => props.theme.text}0D;
`;
const statusText: Record<string, string> = {
  [Status.ALL]: '',
  [Status.OPEN]: 'Open',
  [Status.PREOPEN]: 'Pre-Open',
  [Status.CLOSED]: 'Closed',
  [Status.REMODEL]: 'Remodel',
};

const columns = [
  {
    id: 'siteCode',
    label: 'NO/CODE',
    width: '5.5rem',
  },
  {
    id: 'siteName',
    label: 'Site Name',
    width: '14rem',
  },
  {
    id: 'brand',
    label: 'Brand',
    width: '4.5rem',
  },
  {
    id: 'geo',
    label: 'GEO',
    width: '4.5rem',
  },
  {
    id: 'siteType',
    label: 'Site Type',
    width: '8rem',
  },
  {
    id: 'siteStatus',
    label: 'Status',
    width: '5rem',
    format: (value: string) => {
      return (
        <StyledCustomCell>
          <StatusIndicator status={value} />
          <SGBody variant={'b4'}>{statusText[value] ? statusText[value] : value}</SGBody>
        </StyledCustomCell>
      );
    },
  },
  {
    id: 'deviceName',
    label: 'Device',
    width: '8rem',
  },
  {
    id: 'model',
    label: 'Model',
    width: '10rem',
  },
];

const customRowsPerPage = {
  defaultRowsPerPage: 20,
  rowsPerPageOptions: [20, 50, 100, {label: 'All', value: -1}],
};

interface InventoryTableProps {
  invState: InventoryState;
}

function InventoryTable({invState}: InventoryTableProps) {
  const {tableDeviceData, loadedGeos, selectedGeos} = invState;

  const notLoadedGeos = selectedGeos.filter(geo => loadedGeos[geo] === false);
  const isAGeoSelected = selectedGeos.length > 0 ? true : false;
  const isAllSelectedGeosLoaded = notLoadedGeos.length === 0 ? true : false;

  const tableCanBeLoaded = isAllSelectedGeosLoaded && isAGeoSelected;

  return (
    <StyledTableContainer>
      <Suspense fallback={<StyledSkeleton variant="rect" animation="wave" />}>
        <Table
          paginationText={'Devices'}
          customRowsPerPage={customRowsPerPage}
          columns={columns}
          data={tableDeviceData}
          noDataText={'Based on the selected filters, no devices were found.'}
          loaded={tableCanBeLoaded}
        />
      </Suspense>
    </StyledTableContainer>
  );
}

export default InventoryTable;
